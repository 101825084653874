import React from "react";
import { InputField } from "./Input.style";

export const Input = ({
  id = "",
  type = "",
  name = "",
  height = "",
  width = "",
  labelClasses = "",
  inputClasses = "",
  placeholder = "",
  labelContent = "",
  borderRadius = "",
  background = "",
  color = "",
  border = "",
  borderBottom = "",
  value = "",
  onChange,
  onBlur,
  dataPosition = "",
  dataTooltip = "",
  maxLength = 1000,
  dataHtml = false
}) => (
  <>
    <label className={labelClasses} htmlFor={id}>
      {labelContent}
    </label>
    <InputField
      maxLength={maxLength}
      type={type}
      id={id}
      width={width}
      height={height}
      name={name}
      placeholder={placeholder}
      className={inputClasses}
      borderRadius={borderRadius}
      background={background}
      color={color}
      border={border}
      borderBottom={borderBottom}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      data-position={dataPosition}
      data-tooltip={dataTooltip}
      data-html={dataHtml}
    />
  </>
);
