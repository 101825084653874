import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import PodcastsActions from "../ducks/podcasts";

export function* getPodcasts(params) {
  try {
    const response = yield call(api.get, "podcasts");
    yield put(PodcastsActions.getPodcastsSuccess(response.data));
  } catch (error) {
    // console.log(error);
  }
}

export function* getCurrentPodcasts(params) {
  try {
    debugger;
    const response = yield call(api.get, `/podcasts/${params.id}`);
    yield put(PodcastsActions.getPodcastsSuccess(response.data));
  } catch (error) {
    // console.log(error);
  }
}
