import { createGlobalStyle } from "styled-components";

const Colors = createGlobalStyle`
    :root {
    
    --color-negative-darken:#000;
    --color-negative: #313131;
    --color-negative-lighten:#4A4A49; 
    

    --color-zero-darken:#F2F2F2
    --color-zero: #FFF;
    
    --color-first-lighten: #0EADDB; 
    --color-first: #2196F3;
  
    --color-second-lighten: #D94C75 ;
    --color-second: #E52321;
   

    --color-third-lighten: #eeeeee;
    --color-third: #ECF0F1;
    --color-third-darken: #9e9e9e;
    
    --color-fourth: #F08332

    --color-fifth: #FFCC00;
    
    --color-sixth-lighten: #AD97C8;
    --color-sixth: #5E4696

    --color-seventh-lighten:#6EBD8F ;
    --color-seventh: #65B32E;
    --color-seventh-darken:#00784A;
    
    --color-eighth: #7F7F7F;
    }
`;

export default Colors;
