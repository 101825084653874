import gql from "graphql-tag";

const CategoryQuery = gql`
query(
    $id: Int, 
    $w: Int, 
    $h: Int, 
    $cw: Int, 
    $ch: Int, 
    $pageTopChannels: Int,
    $pageLatestEpisodes: Int
){
    mainCategories(id: $id){
        id
        name
    }
    topChannels(
        main_category_id: $id, 
        page: $pageTopChannels, 
        limit: 4
    ){
        data{
            channel{
                id
                name
                codename
                main_category {
                    id
                }
                thumbnail(width:$w,height:$h)
                description
                cover(width:$cw,height:$ch)
                following

            }
        }
        current_page
        has_more_pages
    }
    topEpisodes(
        main_category_id: $id, 
        limit: 4
    ){
        data {
            episode {
                id
                thumbnail(width:$w,height:$h)
                title
                rating
                restricted
                duration
                description
                content
                duration
                external
                channel{
                    codename
                    flag
                }
            }
        }
    }
    episodes(
        main_category_id: $id,  
        #premium: true, 
        original: true,
        page: 1, 
        limit: 4
    ){
        data {
            id
            thumbnail(width:$w,height:$h)
            title
            rating
            duration
            restricted
            description
            content
            channel{
                codename
                flag
            }
        }
        current_page
        has_more_pages
    }
    latestEpisodes(
        main_category_id: $id,
        page: $pageLatestEpisodes,
        limit: 4
    ) {
        data {
            id 
            title
            rating
            restricted
            duration
            description
            thumbnail(width:$w,height:$h)
            content
            channel{
                codename
                flag
            }
        }
        current_page
        has_more_pages
    }
}
`;

export default CategoryQuery;
