import React, { Component } from "react";
import { Link } from "react-router-dom";
import { sendMessageToUser } from '../../../common';

//styles
import { Container, SocialContent } from "./LoginPassword.style.js";

//icons
import {
    Facebook,
    Google
} from "styled-icons/boxicons-logos";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';

//elements
import { Title, Text } from "../../../elements/Typography";

class LoginPassword extends Component {
    state = {
        phone_or_email: '',
        password: '',
        jaEntrou: false
    }
    
    componentWillReceiveProps(props){
        if(!!props.state && props.state.emailToken && !this.state.jaEntrou){
            this.setState({phone_or_email: props.state.emailToken});
            this.props.updateState({phone_or_email: props.state.emailToken})

            this.setState({jaEntrou: true});
        }
    }
    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }
    
    _responseFacebook = (response) => {
        // console.log("RESPOSTA FACEBOOK LOGIN: ",response);
        if (response.accessToken) {
            this.updateInput({provider: "facebook"});
            this.updateInput({access_token: response.accessToken});
            this.updateInput({phone_or_email: response.email});
            this.updateInput({name: response.name});
            this.updateInput({nickname: response.email.substring(0, response.email.lastIndexOf("@"))});
            this.props.submitForm();
        }            
    }

    _responseGoogle = (response) => {
        // console.log("Resposta GOOGLE LOGIN: ",response);
        if (response.accessToken) {
            this.updateInput({provider: "google"});
            this.updateInput({access_token: response.accessToken}); 
            this.updateInput({phone_or_email: response.profileObj.email});
            this.updateInput({name: response.profileObj.name});
            this.updateInput({nickname: response.profileObj.email.substring(0, response.profileObj.email.lastIndexOf("@"))});
            this.props.submitForm();
        }            
    }

    updateInput(input){
        this.props.updateState(input)
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        this.setState({loading: true});
        let { phone_or_email, password} = this.state;
        
        if(!phone_or_email.length || !phone_or_email.indexOf('@') > -0 || !phone_or_email.indexOf('.') > 0 ){
            sendMessageToUser('Você deve inserir um telefone ou email!')
        } else if(password === undefined || password.length < 8 || !password.match(/[a-z]/i) || !password.match(/[0-9]/i) ){
            sendMessageToUser('Sua senha precisa ter, no mínimo, 8 caracteres e conter letras e números')
        } else {
            this.props.submitForm();
        }
    }
    render(){
        // console.log("Props aqui ", this.props);
        return(
            <Container>
                <Title size="1" content="Ola, todos seus podcasts num só lugar. E muito mais." typeClass="auth-title" />
                <Input
                    id="phone_or_email_login"
                    type="text"
                    name="phone_or_email"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    placeholder="Seu email ou telefone"
                    borderRadius="3px"
                    background="var(--color-third)"
                    border="1px solid var(--color-third)"
                    value={this.state.phone_or_email || this.props.state.emailToken}
                    onChange={ (event) => { this.setState({phone_or_email: event.target.value }); this.props.updateState({phone_or_email: event.target.value}) } }
                />

                <Input
                    name="password"
                    type="password"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    placeholder="Digite sua senha"
                    borderRadius="3px"
                    background="var(--color-third)"
                    border="1px solid var(--color-third)"
                    value={this.state.password}
                    onChange={ (event) => { this.setState({password: event.target.value }); this.props.updateState({password: event.target.value}) } }
                />
                <Button
                    type="submit"
                    className="auth-button"
                    Background="var(--color-first)"
                    Color="var(--color-zero)"
                    loading={this.props.loading}
                    title="Entrar"
                    onClick={() => {this.setState({loading: true}); this.submitForm()}}
                >
                    Entrar
                </Button>
                <div className="recoveryAndRegister">
                    <Link
                        onClick={() => this._setModalScreen("forgot-password")}
                    >
                        Esqueceu a senha?
                    </Link>
                    <Link
                        onClick={() => this._setModalScreen("register")}
                    >
                        Cadastrar agora.
                    </Link>
                </div>
                <hr/>
                <div className="positionOr">
                    <span>ou</span>
                </div>
                <SocialContent>
                    {
                    !this.props.state.lockEmail &&
                    <FacebookLogin
                        appId="938777479814882"
                        fields="name,email"
                        // callback={this._responseFacebook}
                        callback={(resp) => this._responseFacebook(resp)}
                        isMobile={true}
                        cookie={true}
                        xfbml={true}
                        autoLoad={false}
                        disableMobileRedirect={true}
                        render={renderProps => (
                            <React.Fragment>
                                <Link to="#" onClick={renderProps.onClick} >
                                    <span><Facebook className="icon" /></span>
                                    <span>Entrar com facebook</span>
                                </Link>
                            </React.Fragment>
                            )}                            
                    />
                    }
                    {
                    !this.props.state.lockEmail &&
                    <GoogleLogin
                        clientId="357482474055-56mparnm6hjpomb5qf2caf46cgejqoqe.apps.googleusercontent.com"
                        onSuccess={this._responseGoogle}
                        onFailure={this._responseGoogle}
                        render={renderProps => (
                            <React.Fragment>
                                <Link to="#" onClick={renderProps.onClick} >
                                    <span><Google className="icon" /></span>
                                    <span>Entrar com google</span>
                                </Link>
                            </React.Fragment>
                            )}
                    />
                    }
                </SocialContent>
                {/*                 
                <div className="auth-login">
                    <StyledLink
                        type="submit"
                        to="#"
                        className="login-button"
                        Color="var(--color-first)"
                    >
                        Voltar
                    </StyledLink>
                </div> 
                */}
            </Container>
        )
    }
}

export default LoginPassword;
