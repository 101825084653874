import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: var(--size-small);
  margin-top: var(--size-small);
  color: #000000;
  width: 400px;
  .title{
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: var(--size-medium);
  }
  .img-perfil{
    margin-top: var(--size-small);
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;

    img{
      
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    button{
      margin-left: var(--size-small);
      width: auto;
      border: 1px solid #2196F3;
      border-radius: var(--radius-small);
      padding: 5px 16px;
      font-size: 12px;
      line-height: 24px;
    }
  }
  .formulario{
    margin-top: var(--size-big);
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    display: flex;
    flex-direction: column;


    input{
      margin-bottom: 10px;
      width: auto;
      flex: 1;
      display: flex;
      padding: 9px 10px;
    }
    .password{
        margin-bottom: 36px;
      }
    .drop{
      margin-bottom: 10px;
      background: #eee;
      border: none;
    }

    .auth-button{
      width: 50%;
      margin-top: var(--size-base);
      border-radius: var(--radius-small);
      background: #2196F3;
      color: #FFF;
      border-radius: 3px;
    }
  }
  @media (max-width: 992px) {
    width: auto;
    padding-bottom: 50px;
    margin-right: var(--size-small);
    .formulario {
      margin-top: 0;
      .auth-button {
        padding: var(--size-base);
      }
    }
  }
`;
