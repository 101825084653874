import React, { Component } from "react";

import { Container } from "../styles";

//component
import { Header } from "../../../components/Header";
import { NavBar } from "../../../components/NavBar";

import SearchResult from "../../../components/SearchResult";
import Layout from "../../../hoc/Layout/Layout";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class Search extends Component {
  render() {
    return (

        <Layout>
          <Container>
            <SearchResult location={this.props.location}/>
          </Container>
        </Layout>
    );
  }
}
