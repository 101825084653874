import React, { Component } from "react";
import { Link } from "react-router-dom";

//styles
import {
  Container,
  Trends,
} from "./styles";

import "./style.css"
// //Components
import AloComponent from "../../components/alos/AloComponent";
import Banner from "../../components/BannerComponent";

//elements
import { EditorialCard } from "../../elements/Card";

import EpisodeCard from "../../components/EpisodeCardComponent";

import { Title } from "../../elements/Typography";
import { Wrapper } from "../../elements/Layout";

import M from "materialize-css";
import { ChevronRight, ChevronLeft } from "styled-icons/material";
import { ACCESS_TOKEN, USER_ACCOUNT } from '../../constants';
import OlaFrontWeb from "../../assets/img/OlaFrontWeb.jpg";


// icons generos
import { Cotidiano, Conhecimento, Variedades, Humor, Familia, Historias, LifeStyle, Mercado, Esportes, Tecnologia } from "../../assets/icons";

//images
import BANNER_BOTTOM from "../../assets/img/ola_15dias.jpg";
import TOP_CORONA from "../../assets/img/corona_top.png";
import BANNER_NESTLE from "../../assets/img/banners/banner_nestle.jpeg";

const generos = [
  {
    icon: <Cotidiano className="icon" />,
    name: "Cotidiano",
    class: "cotidiano",
    catecory: 5
  },
  {
    icon: <Conhecimento className="icon" />,
    name: "Conhecimento",
    class: "conhecimento",
    catecory: 7
  },
  {
    icon: <Variedades className="icon" />,
    name: "Variedades",
    class: "variedades",
    catecory: 2
  },
  {
    icon: <Humor className="icon" />,
    name: "Humor",
    class: "humor",
    catecory: 1
  },
  {
    icon: <Familia className="icon" />,
    name: "Família",
    class: "familia",
    catecory: 9
  },
  {
    icon: <Historias className="icon" />,
    name: "Histórias",
    class: "historias",
    catecory: 8
  },
  {
    icon: <LifeStyle className="icon" />,
    name: "Lifestyle",
    class: "lifestyle",
    catecory: 3
  },
  {
    icon: <Mercado className="icon" />,
    name: "Mercado",
    class: "mercado",
    catecory: 6
  },
  {
    icon: <Esportes className="icon" />,
    name: "Esportes",
    class: "esportes",
    catecory: 4
  },
  {
    icon: <Tecnologia className="icon" />,
    name: "Tecnologia",
    class: "tecnologia",
    catecory: 10
  }
];

function visualise(object,ind){
  if(object){
    object.forEach((element) => {
      if(element.episode.id === ind){
        return true;
      }
    })
  }
  return false; 
}

class Browse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      instanceOptions: {
        numVisible: 10,
        dist: 0,
        padding: 0,
        fullWidth: true,
      },
    }
  }
  componentDidMount(){
    
    if(window.location.pathname.includes('cadastro')){
      var elems = document.getElementById('signup');
      // console.log("Elems: ",elems);
      var instances = M.Modal.init(elems, {
          onOpenStart: function () {
              var ev = new CustomEvent('modal-entrar', { "detail": "register"});
              document.dispatchEvent(ev);
        }
      });
      var instance = M.Modal.getInstance(elems);
      instance.open();
    }

    // let carouselEle = document.querySelectorAll('.episodes-carousel.carousel');
    // this.instanceCarousel = M.Carousel.init(carouselEle,this.state.instanceOptions);


    // EDITORIAL OUVINDO CARROSEL
    let elementAlo = document.querySelectorAll('.contentAlos');
    this.instanceAlo = M.Carousel.init(elementAlo,this.state.instanceOptions);

    // EDITORIAL OUVINDO CARROSEL
    let elem = document.querySelectorAll('#editorial-carrosel');
    this.instance = M.Carousel.init(elem,this.state.instanceOptions);

    // CONTINUAR OUVINDO CARROSEL
    this.elementContinue = document.querySelectorAll("#continuar-ouvindo-carrosel");
    this.instanceContinue = M.Carousel.init(this.elementContinue,this.state.instanceOptions);

    // MINHAS INSCRICOES CARROSEL
    this.elementInscricoes = document.querySelectorAll("#minhas-inscricoes-carrosel");
    this.instanceInscricoes = M.Carousel.init(this.elementInscricoes,this.state.instanceOptions);

    // EM ALTA CARROSEL
    this.elementEnAlta = document.querySelectorAll("#em-alta-carrosel");
    this.instanceEnAlta = M.Carousel.init(this.elementEnAlta,this.state.instanceOptions);
    // RECOMENDADOS CARROSEL
    this.elementRecomendados = document.querySelectorAll("#recommended-carrosel");
    this.instanceRecomendados = M.Carousel.init(this.elementRecomendados,this.state.instanceOptions);

    // PREMIUM CARROSEL
    this.elementPremium = document.querySelectorAll("#premium-carrosel");
    this.instancePremium = M.Carousel.init(this.elementPremium,this.state.instanceOptions);
  }
  

  abreModalCadastro = () => {
    var elems = document.getElementById('signup');
    // console.log("Elems: ",elems);
    var instances = M.Modal.init(elems, {
        onOpenStart: function () {
            var ev = new CustomEvent('modal-entrar', { "detail": "register"});
            document.dispatchEvent(ev);
      }
    });
    var instance = M.Modal.getInstance(elems);
    instance.open();
}


  render(){
    let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
    const {  
      trends,
      topChannels,
      recommendedEpisodes,

      premium,
      allPlaybacks,
      userAllEpisodesFollowing,
      homeFeaturedAlos,
      editorialList,
      top,
      bottom
    } = this.props;
    let d = new Date().getTime();
    let timestamp = parseInt(d / 1000);
    let newArrTop = [];
    if(top && top.data){
      top.data.map(
        top => {
          // console.log("TOP: ", top)
          if(
            top.initialDate && top.initialDate <= timestamp && 
            top.finalDate && top.finalDate >= timestamp
          ){
            newArrTop.push(top);
          }
        }
      )
      //newArrTop = top.data;
    }
    // {!!userAccount && userAccount.nestle &&
    //   newArrTop.unshift({id: 'banner_nestle', title: 'Nestlé', description: 'Banner Nestle', action: 'channel', arguments: 'falanestle', hide_title: false, hide_description: false, visibility: "all", hint_position: "none", cover: BANNER_NESTLE})
    // }
    //top.data = newArrTop;

    // PAGINACAO CARROCEIS
    const continuePrev = () => { this.instanceContinue[0].prev(1); }
    const continueNext = () => { this.instanceContinue[0].next(1); }
    const inscricoesPrev = () => { this.instanceInscricoes[0].prev(1); }
    const inscricoesNext = () => { this.instanceInscricoes[0].next(1); }
    const enAltaNext = () => { this.instanceEnAlta[0].next(1); }
    const enAltaPrev = () => { this.instanceEnAlta[0].prev(1) }
    const recomendadosPrev = () => { this.instanceRecomendados[0].prev(1); }
    const recomendadosNext = () => { this.instanceRecomendados[0].next(1); }
    const premiumPrev = () => { this.instancePremium[0].prev(1); }
    const premiumNext = () => { this.instancePremium[0].next(1); }

    // const continueNext = () => { this.instanceCarousel[0].next(1); }
    // const inscricoesNext = () => { this.instanceCarousel[0].next(1); }
    // const enAltaNext = () => { this.instanceCarousel[0].next(1); }
    // const enAltaPrev = () => { this.instanceCarousel[0].prev(1) }
    // const recomendadosPrev = () => { this.instanceCarousel[1].prev(1); }
    // const recomendadosNext = () => { this.instanceCarousel[1].next(1); }
    // const premiumPrev = () => { this.instanceCarousel[2].prev(1); }
    // const premiumNext = () => { this.instanceCarousel[2].next(1); }

    
    return(
    <Container>
      <div className="container">
        {
        !!homeFeaturedAlos && 
        !!homeFeaturedAlos.length && 
        <div className="alos">
          <div className="alo-wrapper">
            <Title size="5" typeClass="trend-title" content="Alo" />
            <div className="alo-description">
              Audios rápidos que ficam ativos por 24 horas. Quando alguém adicionar um Alô, ele aparecerá aqui.
            </div>
          </div>
          <div className="contentAlos carousel">
            {homeFeaturedAlos.map((channel) =>                              
                <AloComponent key={`aloCOmp-${channel.id}`} id={channel.id} channel={channel}  /> 
            )}
            {
            homeFeaturedAlos.length >= 3 &&
            homeFeaturedAlos.map((channel) =>                              
                <AloComponent key={`aloComponente-2-${channel.id}`} id={channel.id} channel={channel}  /> 
            )}
          </div>
        </div> 
        }
        {!!newArrTop && <Banner slides={newArrTop} />} 
        {
          !!userAllEpisodesFollowing && 
          !!userAllEpisodesFollowing.data && 
          userAllEpisodesFollowing.data.length > 0 &&
          <Trends className="trends">
            {/* <Link to="/continuar-ouvindo/"> */}
              <Wrapper
                alignItems="center"
                justifyContent="flex-start"
                className="trends-header"
              >
                <Title size="5" typeClass="trend-title" content="Minhas inscrições" />
              </Wrapper>
            {/* </Link> */}
            <Wrapper className="trend-list minhas-inscricoes">
              <div className="minhas-inscricoes-pagination"
                onClick={() => inscricoesPrev()}
              >
                <ChevronLeft className="paginationIcon" />
              </div>
              <div id="minhas-inscricoes-carrosel" className="episodes-carousel carousel">
              {
                userAllEpisodesFollowing.data.map(
                  (inscricao) => {
                    return (
                      <div key={`inscricoes-ep-${inscricao.id}`} className="content-colon carousel-item">
                        <EpisodeCard episode={inscricao} visualise={visualise(allPlaybacks,inscricao.id )} area="minhas-inscricoes" />
                      </div>
                    )
                  }
                )
              }
              { 
                userAllEpisodesFollowing.data.length > 3 && userAllEpisodesFollowing.data.length < 12 &&
                userAllEpisodesFollowing.data.map(
                  (inscricao) => {
                    return (
                      <div key={`inscricoes-ep-repeat-${inscricao.id}`} className="content-colon carousel-item">
                        <EpisodeCard episode={inscricao} visualise={visualise(allPlaybacks,inscricao.id )} area="minhas-inscricoes-repeat" />
                      </div>
                    )
                  }
                )
              }
              </div>
              <div className="em-alta-pagination"
                onClick={() => inscricoesNext()}
              >
                <ChevronRight className="paginationIcon" />
              </div>
            </Wrapper>
          </Trends>
        }
        {/* POSTERIORMENTE COLOCAR ITENS DO CONTINUAR OUVINDO / INICIA AQUI */}
        {
        allPlaybacks && 
        allPlaybacks.length > 0 &&
          <Trends className="trends">
            {/* <Link to="/continuar-ouvindo/"> */}
              <Wrapper
                alignItems="center"
                justifyContent="flex-start"
                className="trends-header"
              >
                <Title size="5" typeClass="trend-title" content="Continuar ouvindo" />
              </Wrapper>
            {/* </Link> */}
            <Wrapper className="trend-list continuar-ouvindo">
              <div className="continuar-ouvindo-pagination"
                onClick={() => continuePrev()}
              >
                <ChevronLeft className="paginationIcon" />
              </div>
              <div id="continuar-ouvindo-carrosel" className="episodes-carousel carousel">
              {
                allPlaybacks.map(
                  (playback) => {
                    return (
                      <div key={`continuar-ouvindo-ep-${playback.episode.id}`} className="content-colon carousel-item">
                        <EpisodeCard episode={playback.episode} position={playback.position} visualise={visualise(allPlaybacks,playback.episode.id )} area="continuar-ouvindo" />
                      </div>
                    )
                  }
                )
              }
              { 
                allPlaybacks.length > 3 && allPlaybacks.length < 12 &&
                allPlaybacks.map(
                  (playback) => {
                    return (
                      <div key={`continuar-ouvindo-ep-repeat-${playback.episode.id}`} className="content-colon carousel-item">
                        <EpisodeCard episode={playback.episode} position={playback.position} visualise={visualise(allPlaybacks,playback.episode.id )} area="continuar-ouvindo-repeat" />
                      </div>
                    )
                  }
                )
              }
              </div>
              <div className="em-alta-pagination"
                onClick={() => continueNext()}
              >
                <ChevronRight className="paginationIcon" />
              </div>
            </Wrapper>
          </Trends>
        }
        {/* POSTERIORMENTE COLOCAR ITENS DO CONTINUAR OUVINDO / TERMINA AQUI */}
        {
        trends && 
        trends.data &&
          <Trends className="trends">
            {/* <Link to="/em-alta/"> */}
              <Wrapper
                alignItems="center"
                justifyContent="flex-start"
                className="trends-header"
              >
                <Title size="5" typeClass="trend-title" content="Em Alta" />
              </Wrapper>
            {/* </Link> */}
            <Wrapper className="trend-list em-alta">
              <div className="em-alta-pagination-prev"
                onClick={() => enAltaPrev()}
              >
                <ChevronLeft className="paginationIcon" />
              </div>
              <div id="em-alta-carrosel" className="episodes-carousel carousel">
              {
                trends.data.map(
                  (episode) => {
                    return (
                      <div key={`em-alta-ep-${episode.id}`} className="content-colon carousel-item setOpacity">
                        <EpisodeCard episode={episode} visualise={visualise(allPlaybacks,episode.id )} area="em-alta" />
                      </div>
                    )
                  }
                )
              }
              {
                trends.data.length < 16 &&
                trends.data.map(
                  (episode) => {
                    return (
                      <div key={`em-alta-ep-${episode.id}-2`} className="content-colon carousel-item setOpacity">
                        <EpisodeCard episode={episode} visualise={visualise(allPlaybacks,episode.id )} area="em-alta" />
                      </div>
                    )
                  }
                )
              }
              </div>
              <div className="em-alta-pagination"
                onClick={() => enAltaNext()}
              >
                <ChevronRight className="paginationIcon" />
              </div>
            </Wrapper>
          </Trends>
        }
        <div className="content-top-premium-genero">

          {/* INICIO TOP CANAIS PREMIUM */}
          {/*
            topChannels && 
            topChannels.data &&
            <Trends className="trends">
              {
                // <Link to="/em-alta/">
              }
                <Wrapper
                  alignItems="center"
                  justifyContent="flex-start"
                  className="trends-header"
                >
                  <Title size="5" typeClass="trend-title" content="Top canais originais" />
                </Wrapper>
              {
                //</Link> 
              }
              <Wrapper className="trend-list decobrir-genero">
                <div className="content-generos">
                { 
                  topChannels.data.map(
                    (canal, index) => {
                      return (
                        <div key={`top-premium-channel-${index}`} className="content-genero">
                          <Link to={`/channels/${canal.channel.codename}`} className="top-channel-link" style={{backgroundImage:`url(${canal.channel.thumbnail})`}}>
                          </Link>
                        </div>
                      )
                    }
                  )
                }
                </div>
              </Wrapper>
            </Trends>
          */}
          {/* TERMINO TOP CANAIS PREMIUM */}

          {/* INICIO DESCOBRIR GENERO */}
          {/*
            generos &&
            <Trends className="trends">
              {
                //<Link to="/em-alta/">
              }
                <Wrapper
                  alignItems="center"
                  justifyContent="flex-start"
                  className="trends-header"
                >
                  <Title size="5" typeClass="trend-title" content="Descobrir verticais" />
                </Wrapper>
              {
                //</Link>
              }
              <Wrapper className="trend-list decobrir-genero">
                <div className="content-generos">
                {
                  generos.map(
                    (genero, index) => {
                      return (
                        <div key={`genero-${index}`} className="content-genero">
                          <Link to={`/verticals/${genero.catecory}`} className={genero.class}>
                            {genero.icon}
                            <span>{genero.name}</span>
                          </Link>
                        </div>
                      )
                    }
                  )
                }
                </div>
              </Wrapper>
            </Trends>
          */}
          {/* TERMINO DESCOBRIR GENERO */}
        </div>

        {/* INICIO EDITORIAL */}
        {/*
        editorialList && 
        editorialList.data &&
          <Trends className="trends editorial">
            <Wrapper
              alignItems="center"
              justifyContent="flex-start"
              className="trends-header"
            >
              <Title size="5" typeClass="trend-title" content="OLA Blog" />
            </Wrapper>
            <Wrapper className="trend-list ">
            <div id="editorial-carrosel" className="carousel">
              {
                editorialList.data.map((editorial) => {
                  return <div key={`editorial-${editorial.id}`} className="content-colon carousel-item">
                    <EditorialCard editorial={editorial} />
                    </div>
                })
              }
              {
                //REMOVER POSTERIORMENTE O MAP A BAIXO 
              }
              {
                editorialList && editorialList.data &&
                editorialList.data.map((editorial,index) => {
                  return <div key={`editorial-${editorial.id}-${index}`} className="content-colon carousel-item">
                    <EditorialCard editorial={editorial} />
                    </div>
                })
              }
            </div>

            </Wrapper>
          </Trends>
        */}
        {/* TERMINO EDITORIAL */}

        {/* RECOMENDADOS INICIO */}
        {
        recommendedEpisodes && 
        recommendedEpisodes.data &&
          <Trends className="trends">
            {/* <Link to="/em-alta/"> */}
              <Wrapper
                alignItems="center"
                justifyContent="flex-start"
                className="trends-header"
              >
                <Title size="5" typeClass="trend-title" content="Recomendados" />
              </Wrapper>
            {/* </Link> */}
            <Wrapper className="trend-list recommended">
              {<div className="recommended-pagination"
                onClick={() => recomendadosPrev()}
              >
                <ChevronLeft className="paginationIcon" />
              </div>
              }
              <div id="recommended-carrosel" className="episodes-carousel carousel">
              {
                recommendedEpisodes.data.map(
                  (episode, index) => {
                    return (
                      <div key={`recommended-ep-${episode.id}-${index}-list-1`} className="content-colon carousel-item">
                        <EpisodeCard episode={episode} visualise={visualise(allPlaybacks,episode.id )} area="recomendados" />
                      </div>
                    )
                  }
                )
              }
              {
                recommendedEpisodes.data.length < 16 &&
                recommendedEpisodes.data.map(
                  (episode, index) => {
                    return (
                      <div key={`recommended-ep-${episode.id}-${index}-list-2`} className="content-colon carousel-item">
                        <EpisodeCard episode={episode} visualise={visualise(allPlaybacks,episode.id )} area="recomendados" />
                      </div>
                    )
                  }
                )
              }
              </div>
              <div className="em-alta-pagination"
                onClick={() => recomendadosNext()}
              >
                <ChevronRight className="paginationIcon" />
              </div>
            </Wrapper>
          </Trends>
        }
        {/* RECOMENDADOS FINAL */}

        
        {/* PREMIUM INICIO */}
        {
          premium &&
          <Trends className="trends">
            {/* <Link to="/em-alta/"> */}
              <Wrapper
                alignItems="center"
                justifyContent="flex-start"
                className="trends-header"
              >
                <Title size="5" typeClass="trend-title" content="Originais OLA Podcasts" />
              </Wrapper>
            {/* </Link> */}
            <Wrapper className="trend-list premium">
              <div className="premium-pagination"
                onClick={() => premiumPrev()}
              >
                <ChevronLeft className="paginationIcon" />
              </div>
              <div id="premium-carrosel" className="episodes-carousel carousel">
              {
                premium.data.map(
                  (episode) => {
                    // console.log("PREMIUM: ", episode)
                    return (
                      <div key={`premium-ep-${episode.id}`} className="content-colon carousel-item">
                        <EpisodeCard episode={episode} visualise={visualise(allPlaybacks,episode.id )} />
                      </div>
                    )
                  }
                )
              }
              </div>
              <div className="em-alta-pagination"
                onClick={() => premiumNext()}
              >
                <ChevronRight className="paginationIcon" />
              </div>
            </Wrapper>
          </Trends>
        }
        {/* PREMIUM FINAL */}

        {(!localStorage.getItem(ACCESS_TOKEN) || !userAccount.subscribed) &&
          <Trends id="banner_" className="trends">
            <Wrapper className="trend-list content-banner">
            {/* {!!bottom && !! bottom.data && */}
            <div className="carousel ola-banner">
                <Link
                  className="carousel-item modal-trigger"
                  // onClick={() => {
                  //   let event = new CustomEvent('modal-entrar', { "detail": "register"});
                  //   document.dispatchEvent(event);
                  // }}
                  // to="/cadastro"
                  onClick={() => this.abreModalCadastro()}
                  style={{ width: "100%", display: "block" }}
                >
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${BANNER_BOTTOM})`
                    }}
                  ></div>
                </Link>
            </div>
            {/* } */}
            </Wrapper>
          </Trends>
        }
        {/* 
          <Trends className="trends">
            <Wrapper className="trend-list content-banner">
            {!!bottom && !! bottom.data &&
                <div className="carousel ola-banner">
                <a
                  className="carousel-item modal-trigger"
                  // onClick={() => this.openModalPremium()}
                  href="#modal-premium"
                  style={{ width: "100%", display: "block" }}
                >
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${bottom.data[Math.floor(Math.random()*bottom.data.length)].cover})`
                    }}
                  ></div>
                </a>
            </div>
            }
            </Wrapper>
          </Trends> 
        */}
      </div>
    </Container>
    );
  }
}

export default Browse;
