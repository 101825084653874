import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  /*padding-left:250px;*/
  display: flex;
`;
export const ContainerView = styled.section`
  /*padding-left:250px;*/
  display: flex;
  @media (max-width: 992px) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
