import gql from "graphql-tag";

const ChannelQuery = gql`

  query Channels($channel_id: Int!, $thumbWidth: Int!, $thumbHeight: Int!, $coverWidth: Int!, $coverHeight: Int!) {
        channels(id: $channel_id) {
            data {
                id
                name
                codename
                main_category {
                    id
                }
                description
                flag
                thumbnail(width: $thumbWidth, height: $thumbHeight)
                cover(width: $coverWidth, height: $coverHeight)
                main_category{
                    id
                    name
                }
                alternate_category{
                    id
                    name
                }
            }
        }
        mainCategories{
            id
            name    
        }    
        alternateCategories{
            data{
                id
                name
            }
        }
     
}`;

const UPDATE_CHANNEL = gql`
    mutation ($channel_id: Int!, $name: String, $thumbnail: Upload, $cover: Upload, $main_category_id: Int, $alternate_category_id: Int, $description: String){
        editChannel(
            channel_id: $channel_id
            name: $name
            thumbnail: $thumbnail
            cover: $cover
            main_category_id: $main_category_id
            alternate_category_id: $alternate_category_id
            description: $description
        ) {
            id
            name
            codename
            description
        }
}`;


export { ChannelQuery, UPDATE_CHANNEL };
