import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Play3, Clock } from 'styled-icons/icomoon/';
import { EllipsisV } from 'styled-icons/fa-solid';

import { Query, Mutation, graphql } from 'react-apollo';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import apolloClient from '../services/apollo';
import { ACCESS_TOKEN, USER_ACCOUNT } from '../constants';

import M from 'materialize-css';

import moment, { duration } from 'moment';
import 'moment/locale/pt-br';

import defaultImage from '../assets/img/thumbDefault.jpg';
import {
  FAVORITE_EPISODE_MUTATION,
  REMOVE_FROM_PLAYLIST_MUTATION,
} from '../services/graphql/mutations';

import { sendMessageToUser } from '../common';

import LIVRE from '../assets/img/livre.png';
import RESTRITO from '../assets/img/restrito.png';
import { Image } from '../elements/Image';
import { ReactComponent as ORIGINAL } from '../assets/img/original.svg';

import { ReactComponent as MoreVertical } from '../assets/icons/more-vertical.svg';
import { ReactComponent as Check } from '../assets/icons/checked.svg';

export default class EpisodeCardComponent extends Component {
  state = {
    showBlackMask: false,
  };

  componentDidMount() {
    var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems, {});
  }

  kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
      : Math.sign(num) * Math.abs(num);
  }

  renderDuration(seconds) {
    let duration = new Date(1000 * seconds)
      .toISOString()
      .substr(11, 8)
      .replace(/^[0:]*/g, '');
    return (
      <>
        <Clock className='play-icon' /> {duration}
      </>
    );
  }

  renderPlayCount(playCount) {
    if (!playCount) {
      return (
        <>
          <Play3 className='play-icon' /> {this.kFormatter(playCount)}
        </>
      );
    } else {
      return <></>;
      // return <>nenhum play ainda</>
    }
  }

  setPlay = async () => {
    var elems = document.getElementById('modal-restrito-idade');
    const { episode } = this.props;
    var instances = M.Modal.init(elems, {
      onOpenStart: function () {
        var ev = new CustomEvent('modal-restrito-idade', { detail: episode });
        document.dispatchEvent(ev);
      },
    });
    if (episode.rating === '18') {
      var instance = M.Modal.getInstance(elems);
      instance.open();
    } else {
      // console.log("SetPlay: ", episode);
      document.player.pause();
      let current = await document.player.setCurrent(episode);
      let posit = this.props.position ? this.props.position : 0;
      await document.player.setInitialTime(posit);
      await document.player.setCurrentTimeFromCache(posit);

      // console.log("Current time" + document.player.getCurrentTime());

      // console.log("Eh aqui mesmo")
      if (current) document.player.play();
    }
  };

  setQueue = () => {
    // console.log("Set QUEUE")
    const { episode } = this.props;
    document.player.addToEndQueue(episode);
    sendMessageToUser('Episódio adicionado a lista de reprodução!');
  };

  addLater = () => {
    const { episode } = this.props;

    apolloClient.mutate({
      mutation: FAVORITE_EPISODE_MUTATION,
      variables: {
        episode_id: episode.id,
        type: 'later',
      },
    });
    sendMessageToUser('Episódio marcado para ouvir mais tarde!');
  };

  removeFromPlaylist = () => {
    const { episode } = this.props;

    apolloClient
      .mutate({
        mutation: REMOVE_FROM_PLAYLIST_MUTATION,
        variables: {
          id: this.props.playlist,
          episode_id: episode.id,
          // type: 'Playlist'
        },
      })
      .then(data => {
        // console.log("ADD PLAYLIST: ",data)
        sendMessageToUser('Episódio removido da playlist com sucesso!');
        window.location.reload();
      });
  };

  choosePlaylist = tipo => {
    this.setState({ showBlackMask: true });
    let params = {
      episode: this.props.episode,
      modalType: tipo == 'playlist' ? 'choosePlaylist' : 'denuncia',
    };
    var ev = new CustomEvent('abreBlackMask', { detail: params });
    document.dispatchEvent(ev);
  };

  // denunciar = () => {
  //   this.setState({showBlackMask: true});
  //   let params = {
  //     episode: this.props.episode,
  //     modalType: "denuncia",
  //   }
  //   var ev = new CustomEvent("abreBlackMask", { 'detail': params });
  //   document.dispatchEvent(ev);
  // }

  updateState = (state: Object) => {
    this.setState(state);
  };

  render() {
    let {
      id,
      thumbnail,
      title,
      rating,
      duration,
      channel,
      playCount,
      episode_status_id,
      episode_status_message,
      publishDate,
      description,
    } = this.props.episode;
    let flag =
      !!this.props.episode && !!this.props.episode.channel
        ? this.props.episode.channel.flag
        : false;
    let { scope, position, visualise, area } = this.props;
    let percent = 0;

    // console.log("channel: ", channel)

    if (position) {
      percent = 100 - ((duration - position) / duration) * 100;
    }
    if (visualise) {
      // console.log("VISUALISE: ", visualise)
    }
    // if(id=="ffa336c2dc1907fd912d35784a1eeccc" || id=="934774"){
    // console.log("[TIARA]",this.props)
    // }
    if (!!channel) {
      return (
        <div
          className={`episode-card ${
            this.props.horizontal ? 'horizontal' : ''
          }`}
        >
          {/* <ChoosePlaylist episode={this.props.episode} showBlackMask={this.state.showBlackMask} updateState={this.updateState}/> */}

          <div className='episode-thumb' onClick={this.setPlay}>
            <div
              className='episode-thumb_image'
              style={{
                backgroundImage: `url('${
                  thumbnail ? thumbnail : defaultImage
                }')`,
              }}
            >
              <div className='episode-thumb-hover'>
                <div id='play' className='play-icon'>
                  <i className='material-icons'>play_arrow</i>
                </div>
                {/* Abilitar na proxima faze */}
              </div>
              {/* <img src={thumbnail} alt={title} className="episode-thumb" /> */}
              {/* {channel && channel.premium &&
              <div className="isPremium">
                premium
              </div>
            } */}
              {!position && visualise && (
                <div className='isVisualize'>
                  <Check className='iconCheck' />
                </div>
              )}
            </div>
          </div>
          {position ? (
            <div className='progress'>
              <div
                className='determinate'
                style={{ width: `${percent}%` }}
              ></div>
            </div>
          ) : (
            <div></div>
          )}
          <div className='wrapper-block'>
            <div className='title-block'>
              {scope != 'externalFeed' && flag != 'feed' ? (
                <Link to={`/episodes/${id}`}>
                  <span className='title'>
                    {rating == 'L' && (
                      <Image srcImage={LIVRE} classImage='typeContent' />
                    )}
                    {rating == '18' && (
                      <Image srcImage={RESTRITO} classImage='typeContent' />
                    )}
                    {this.props.horizontal
                      ? title.length < 40
                        ? `${title}`
                        : `${title.substr(0, 37)}...`
                      : title.length < 50
                      ? `${title}`
                      : `${title.substr(0, 47)}...`}
                  </span>
                </Link>
              ) : (
                <span className='title'>
                  {this.props.horizontal
                    ? title.length < 40
                      ? `${title}`
                      : `${title.substr(0, 37)}...`
                    : title.length < 50
                    ? `${title}`
                    : `${title.substr(0, 47)}...`}
                </span>
              )}
              {channel && channel.original && (
                <div className='contentOriginal'>
                  <ORIGINAL className='iconOriginal' />
                  <span className='textOriginal'>ORIGINAL</span>
                </div>
              )}
              {(!scope || (scope != 'channel' && scope != 'externalFeed')) && (
                <Link
                  to={
                    !channel.id || !isNaN(channel.id) || channel.id == null
                      ? `/channels/${channel.codename}`
                      : `/externalFeed/${channel.id}`
                  }
                  className='channel-link'
                >
                  <span
                    className={`channel-codename channel-${
                      channel.premium ? '' : channel.flag
                    }`}
                  >
                    {channel.codename.length < 15
                      ? channel.codename
                      : `${channel.codename.substr(0, 12)}...`}
                  </span>
                  {/* <img src={PremiumChecked} alt={channel.flag} /> */}
                </Link>
              )}
              <Link
                className='queue-icon dropdown-trigger'
                data-target={`dropdown-${id}${
                  !position && visualise ? '-visualise' : ''
                }${area ? '-' + area : ''}`} /*onClick={this.setQueue}*/
              >
                <i className='material-icons iconMenu'>more_vert</i>
              </Link>
              <ul
                id={`dropdown-${id}${
                  !position && visualise ? '-visualise' : ''
                }${area ? '-' + area : ''}`}
                className='dropdown-content'
              >
                {/*<li><a href="#!">Salvar em</a></li>*/}
                <li id='adicionar_fila' onClick={this.setQueue}>
                  <Link>Adicionar a fila</Link>
                </li>
                {this.props.playlist && (
                  <li onClick={this.removeFromPlaylist}>
                    <Link>Remover da Playlist</Link>
                  </li>
                )}
                <li className='divider' tabIndex='-1'></li>
                {!!localStorage.getItem(ACCESS_TOKEN) && (
                  <>
                    <li onClick={this.addLater}>
                      <Link>Ouvir mais tarde</Link>
                    </li>
                    <li className='divider' tabIndex='-1'></li>
                    <a
                      href='#backmask-modal'
                      className='modal-trigger'
                      datatarget='blackmask-modal'
                      onClick={() => this.choosePlaylist('playlist')}
                    >
                      <li> Salvar na Playlist</li>
                    </a>
                    <li className='divider' tabIndex='-1'></li>
                    <a
                      href='#backmask-modal'
                      className='modal-trigger'
                      datatarget='blackmask-modal'
                      onClick={() => this.choosePlaylist('denuncia')}
                    >
                      <li>Denunciar</li>
                    </a>
                  </>
                )}
              </ul>
            </div>
            {!!description && this.props.showDetails && (
              <div className='content-block'>
                <span className='description'>
                  {description.length < 50
                    ? `${description}`
                    : `${description.substr(0, 47)}...`}
                </span>
              </div>
            )}
            <div className='footer-block'>
              {episode_status_id !== 0 && (
                <span className='time-ago'>
                  {episode_status_id === 2
                    ? 'Erro no processamento. Tente novamente após alguns minutos.'
                    : episode_status_message}
                </span>
              )}
            </div>
            <div className='footer-block'>
              {/* { playCount > 10 &&
              <span className="play-count">
                { this.renderPlayCount(playCount) }
              </span>
            } */}
              {/* { duration &&
              <span className="duration">
                { this.renderDuration(duration) }
              </span>
            } */}
              <span className='time-ago'>
                {moment(publishDate, 'X', 'pt-BR', true).fromNow()}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
