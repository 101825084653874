import gql from "graphql-tag";

const SignaturesTypeQuery = gql`

query SignatureTypes{
  signatureTypes{
      tipo_assinatura
      id_operadora
      id_tipo_assinatura
      descricao
  }
}`;

const UserSignaturesQuery = gql`

query UserSignatures{
    signatures
}`;


export { SignaturesTypeQuery, UserSignaturesQuery };
