import React, { Component } from "react";
import { Link } from "react-router-dom";
import { sendMessageToUser } from '../../../common';
import validator from 'validator'
//styles
import { Container, SocialContent } from "./ShowFeed.style.js";

//elements
import { Input } from "../../../elements/Input";
import { Button } from '../../../elements/ButtonElement';
import LOADING from "../../../assets/img/loading.gif";

//elements
import { Title, Text } from "../../../elements/Typography";

class ShowFeed extends Component {

    constructor(props){
        super(props);
        this.state = { ...props.state, loading: false };
    }


    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }

    updateInput(input){
        this.props.updateState(input)
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        let { url } = this.state;
        if(!url.length || !validator.isURL(url)){
            sendMessageToUser('Você deve inserir uma url!')
        } else {
            this.setState({loading: true}); 
            this.props.submitForm();
        }
    }
    
    render(){
        if(!this.state.externalFeed || !this.state.externalFeed.id){
            return (
                <Container>
                    {/* <Title size="1" content="Verificando URL" typeClass="auth-title" /> */}
                    <div className="divImg"><img src={LOADING}/></div>
                </Container>
            )
        }else{
            return(
                <Container>
                    <img src={this.state.externalFeed.thumbnail} style={{width: "170px", height: "170px"}}/><br />
                    <b>Canal: {this.state.externalFeed.name}</b><br />
                    {this.state.externalFeed.description} <br /><br />

                    <Button
                        type="submit"
                        className="auth-button"
                        Background="var(--color-first)"
                        Color="var(--color-zero)"
                        loading={this.props.loading}
                        title="INSCREVER-SE"
                        onClick={() => {this.submitForm()}}
                    >
                        INSCREVER-SE
                    </Button>
                <br />
                    <Link
                          
                        onClick={() => {this._setModalScreen('add-feed')}}
                    >
                        VOLTAR
                    </Link>
                    
                </Container>
            )
        }
    }
}

export default ShowFeed;
