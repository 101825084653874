import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  .step-indicator {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }
  .step {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .step-indicator .step-icon {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #fff;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    border: 1px solid var(--color-first);
    position: relative;
    line-height: 50px;
    font-size: 10px;
  }
  .step.active .step-icon {
    background: var(--color-first);
  }
  .step p {
    text-align: center;
    position: absolute;
    bottom: -40px;
    color: var(--color-eighth);
    font-size: 14px;
    font-weight: bold;
  }
  .step.active p {
    color: var(--color-first);
  }
  .step.step2 p,
  .step.step3 p {
    left: 50%;
    transform: translateX(-50%);
  }
  .indicator-solid-line {
    width: 100%;
    height: 2px;
    border-bottom: 2px dashed var(--color-eighth);
    flex: 1;
  }

  .indicator-dashed-line {
    width: 100%;
    height: 2px;
    border-bottom: 2px solid var(--color-eighth);
    flex: 1;
  }
  .indicator-line {
    width: 100%;
    height: 2px;
    border-bottom: 2px solid var(--color-eighth);
    flex: 1;
  }
  .indicator-line.active {
    background: var(--color-first);
  }
`;
