import React from "react";

import LogoDefault from "../../assets/img/logo-ola-colorido.svg";
import LogoWave from "../../assets/img/logo-ola-colorido.svg";

import ColorfulLogoMini from "../../assets/img/logo-ola-colorido-mini.svg";
import ColorfulLogo from "../../assets/img/logo-ola-colorido.svg";

const Logo = ({ classImage }) => (
  <picture>
    <source media="(max-width: 600px)" srcSet={ColorfulLogoMini} />
    <img src={ColorfulLogo} className={classImage} alt="Logo Ola" />
  </picture>
);

export default Logo;
