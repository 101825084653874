import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link).attrs({
  className:props => props.classesLink 
})`
  transition: background-color 0.15s ease;
  border: 0;
  background: ${props => props.Background};
  height: 35px;
  width: auto;
  border-width: 0;
  border-radius: 26px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;
  color: ${props => props.Color};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.filled === false &&
    css`
      background: ${props => props.Color};
      color: ${props => props.Background};
      border: 1px solid var(--color-first);

      &:hover {
        background: none;
        border: 1px solid var(--color-first-darken);
      }
    `};
`;
