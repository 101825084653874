import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: row;

  .navbar-container {
    width: 250px;
    height: 100%;
    padding: var(--spacing-small);
    display: block;
    margin-bottom: 28px;

    @media (max-width: 900px) {
      & {
        display: none;
      }
    }
  }
  .browse-container {
    margin-left: 250px;
    width: 100%;

    @media (max-width: 900px) {
      & {
        width: 100%;
      }
    }
  }
`;
