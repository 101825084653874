import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import M from "materialize-css";

import { SignInAlt, FileSignature } from "styled-icons/fa-solid";

//styles
import {
  HeaderContainer,
  WrapperProfile,
  SearchIcon,
  CloseIcon,
  WrapperLogo,
  WrapperSearch,
  WrapperProfileMobile
} from "./Header.style";

import {
  User
} from "styled-icons/boxicons-regular";
//icons
import { Rss, NewChannel, Sino, Search, LogoOla } from "../../assets/icons/";

//component
import DropDownUser from "../DropDownUser";
import DropDownUserMobile from "../DropDownUserMobile";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserActions from "../../store/ducks/user";

// user data
import { Query } from "react-apollo";
import HeaderQuery from "./Header.graphql";
import { parseSearchQuery } from "../../common";

//elements
import { Logo, Image } from "../../elements/Image";
import { Input } from "../../elements/Input";
import { ButtonGroup } from "../../elements/CTA";
import { Button } from '../../elements/ButtonElement';

//assets
import { ModalRestrationLogin } from "../ModalRestrationLogin";
import { ModalSejaPremium } from "../ModalSejaPremium";
import { ModalAssinar } from "../ModalAssinar";
import { ModalRestritoIdade } from "../ModalRestritoIdade";
import { ModalAudio } from "../ModalAudio";
import { ModalVideo } from "../ModalVideo";

export const UserComponent = ({ profile }) => {
  return (
    <div className="contentUserSession">
      {!!profile.subscribed ? (
        <></>
      ) : (
        <></>
        // <Button>
        //     <Link onClick={() => {
        //           var elems = document.getElementById('modal-assinar');
        //           var instances = M.Modal.init(elems, {
        //               onOpenStart: function () {
        //                   var ev = new CustomEvent("abre-assinar", { 'detail': "detalhesAqui" });
        //                   document.dispatchEvent(ev);
        //               }
        //           });
        //           var instance = M.Modal.getInstance(elems);
        //           instance.open();
        //     }}>Seja Premium</Link>
        // </Button>
      )}
      {/* <div className="contentUserName">Ola, {profile.profile.name}</div> */}
      <div className="headerLinksChannel">
        <Link to="#" data-target="addFeed" className="modal-trigger" onClick={() => {
          let event = new CustomEvent('modal-addFeed', { "detail": "add-feed" });
          document.dispatchEvent(event);
        }}>
          <Rss className="menu-icon" />
          <span>Agregar Canal</span>
        </Link>
      </div>
      <div className="headerLinksChannel">
        <Link to="/channels/create">
          <NewChannel className="menu-icon" />
          <span>Criar Canal</span>
        </Link>
      </div>
      {/* <div className="headerLinksChannel">
        <Link>
          <Sino className="menu-icon" />
        </Link>
      </div> */}
      <DropDownUser userImage={profile.profile.profilePicture} />
    </div>
  );
};

export const UserComponentMobile = ({ profile }) => {
  return (
    <div className="contentUserSessionMobile">
      <DropDownUserMobile userImage={profile.profile.profilePicture} />
    </div>
  );
};

class Header extends Component {
  state = {
    search: ""
  };

  constructor(props) {
    super(props);
    this.props.getMeRequest();
    let search = parseSearchQuery(props.location);
    if (search) this.state = { search, showSearchForm: false };
  }

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    const options = {
      onCloseEnd: () => {
        // // console.log("Bindando evento de fechar os alos");
        var ev = new CustomEvent("fechaModalAlo", { 'detail': "detalhesAqui" });
        document.dispatchEvent(ev);
      }
    };
    var instances = M.Modal.init(elems, options);
    document.addEventListener("toastMessage", this.handleMessage);


    var elemSearch = document.querySelectorAll("#addFeed");
    this.instances = M.Modal.init(elemSearch, {});

  }
  componentWillUnmount() {
    document.removeEventListener("toastMessage", this.handleMessage);
  }

  handleInputChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleMessage = ({ detail }) => {
    let message: String = detail;
    M.toast({ html: message })
  }

  static getDerivedStateFromProps(props, state) {
    let { data } = props;
    if (props.user && props.user !== state.user) return { user: props.user };
    if (!!data && data.user && props.user !== state.user)
      return { user: data.user, loading: data.loading };
    if (!!data && data.loading !== state.loading)
      return { loading: data.loading };
    return null;
  }

  renderUserComponent() {
    if (this.state.user === undefined) {
      return <div>carregando...</div>;
    } else if (
      !!this.state.user &&
      this.state.user.data !== undefined &&
      this.state.user.data.profile !== undefined
    ) {
      return <UserComponent profile={this.state.user.data} />;
    } else {
      return (
        <ButtonGroup>
          {/* {<a 
            className="modal-trigger" 
            href="#signup"
            onClick={() => {
              let event = new CustomEvent('modal-entrar', { "detail": "register"});
              document.dispatchEvent(event);
            }}
          >
            Cadastro
          </a>} */}
          <Link data-target="signup" id="login_btn" className="modal-trigger" to="/signin"
            onClick={() => {
              let event = new CustomEvent('modal-entrar', { "detail": "login" });
              document.dispatchEvent(event);
            }}
          >Login
          </Link>
          {/* <Link data-target="modal-restrito-idade" className="modal-trigger" to="/signin" 
            onClick={() => {
              let event = new CustomEvent('modal-retrito', { "detail": "login"});
              document.dispatchEvent(event);
            }}
            >Login
          </Link> */}
        </ButtonGroup>
      );
    }
  }
  renderUserComponentMobile() {
    if (this.state.user === undefined) {
      return <div>carregando...</div>;
    } else if (
      !!this.state.user &&
      !!this.state.user.data &&
      !!this.state.user.data.profile
    ) {
      return <UserComponentMobile profile={this.state.user.data} />;
    } else {
      return (
        <a
          className="modal-trigger btnLogin"
          href="#signup"
          onClick={() => {
            let event = new CustomEvent('modal-entrar', { "detail": "register" });
            document.dispatchEvent(event);
          }}
          style={{
            padding: '10px',
            margin: '0 0 0 10px',
            width: '44px',
            height: '44px',
            borderRadius: '50% 0 0 50%',
            border: 'none',
          }}
        >
          <User style={{ width: "1.7rem" }} />
        </a>
      );
    }
  }

  setSearch = (search) => {
    if (!!search.indexOf('http') > 0 && !!search.indexOf('www') > 0) {
      this.setState({ search: search });
    } else {
      let event = new CustomEvent('modal-addFeed', { "detail": search });
      document.dispatchEvent(event);
      this.instances[0].open();
      this.setState({ search: '' });
    }
  }
  submitSearch(e, state) {
    e.stopPropagation();
    e.preventDefault();
    // console.log(this.props.history);
    this.props.history.push(`/search/?q=${this.state.search}`);
  }
  render() {
    // console.log("LocalStorage: ",localStorage);
    return (
      <HeaderContainer className={'header-ola'} ref={(ref) => this.headerComponent = ref}>
        <WrapperProfileMobile>
          {this.renderUserComponentMobile()}
        </WrapperProfileMobile>
        <WrapperLogo className="logo-component">
          <Link to="/">
            <LogoOla classImage="logo-image" />
          </Link>
        </WrapperLogo>
        <WrapperSearch className={`search-component ${this.state.showSearchForm ? 'active' : ''}`}>
          <form onSubmit={(e) => this.submitSearch(e, this.state)} className="form-search">
            <Input
              type="text"
              placeholder="O que você quer ouvir?"
              id="search"
              name="q"
              width="100%"
              value={this.state.search}
              onChange={e => this.setSearch(e.target.value)}
            />
            {/* <button type="submit">
              <SearchIcon className="search-icon" />
            </button> */}
            <Link id="search_btn" to={`/search/?q=${this.state.search}`}><Search className="search-icon" style={{ zIndex: 1 }} /></Link>

          </form>
        </WrapperSearch>
        <div className="searchMobile hide-on-large-only">
          {!this.state.showSearchForm ?
            <SearchIcon onClick={() => this.setState({ showSearchForm: true })} className="search-icon" style={{ zIndex: 1 }} />
            :
            <CloseIcon onClick={() => this.setState({ showSearchForm: false })} className="search-icon" style={{ zIndex: 1 }} />
          }

        </div>
        <WrapperProfile>
          {this.renderUserComponent()}
        </WrapperProfile>
        <ModalRestrationLogin />
        <ModalSejaPremium />
        <ModalAssinar />
        <ModalRestritoIdade />
        <ModalAudio />
        <ModalVideo />
      </HeaderContainer>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

const mapDistapchToProps = dispatch =>
  bindActionCreators({ ...UserActions }, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDistapchToProps
)(Header));
