import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container } from "./Thumb.style.js";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';

//elements
import { Title, Text } from "../../../elements/Typography";
import ThumbCanal from "../../../assets/img/thumbcanal.png";
class Thumb extends Component {
  
    
    render(){
        return(
            <Container className="container white config-container">
                <div className="div1 bg-header-container">
                    Escolha uma imagem para seu canal
                </div>
                <div className="imgThumb">
                    <img src={ThumbCanal} />
                </div>
                <div className="divBtn row block-contanier-footer">
                    <Link to="#" className="pular" onClick={() => this.props.selectOption("cover")}>
                        PULAR
                    </Link>
                    <div  className="btnEnviar">
                    <div className="file-field input-field col s12">
                        <div className="btn button-primary">
                            <span>ENVIAR UMA IMAGEM</span>
                            <input type="file" onChange={ (event) => { console.log(event.target.files[0]); this.props.updateState({ thumbnail: event.target.files[0] }, () => this.props.submitForm() ) } } />
                        </div>
                        <div className="file-path-wrapper hidden">
                            <input className="file-path validate" type="text" />
                        </div>
                    </div>
                        {/* <Button
                            type="submit"
                            className="auth-button"
                            onClick={() => props.submitForm()}
                        >
                                ENVIAR UMA IMAGEM
                        </Button>  */}
                    </div>
                </div>
            </Container>
        )
    }

}

export default Thumb;
