import React, { Component } from "react";
import M from "materialize-css";

import REMOVE_PLAYLIST from "../Thumb/Thumb.graphql";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';

import { Title } from "../../../../elements/Typography";
import { Button } from "../../../../elements/ButtonElement";

import { sendMessageToUser } from '../../../../common';

import {
    Container,
} from "./RemovePlaylist.styles";

class RemovePlayList extends Component {
    componentDidMount(){
        var elems = document.querySelectorAll('.modal');
        this.instances = M.Modal.init(elems, {});
    }
    onDelete = () => {
        let body = {
            id: this.props.indice
        }
        this.props.mutate({mutation: REMOVE_PLAYLIST, variables: body, errorPolicy: 'all'})
        .then(({register, errors}) => {
            if(errors){
                // console.log(errors)
            } else {
                sendMessageToUser("Playlist removida com sucesso");
                this.props.onRefresh();
            }
        })
    }
    render() {
        return (
            <Container id={`RemovePlaylist_${this.props.indice}`} className="modal" style={{width:"400px"}}>
                <div className="modal-content">
                    <Title size="1" content="Tem certeza que deseja remover a playlist?" typeClass="modal-title" />
                </div>
                <div className="modal-footer">
                    <Button
                        type="submit"
                        className="auth-button modal-close"
                        Background="var(--color-first)"
                        color="var(--color-zero)"
                        onClick={() => this.onDelete() }
                    >
                        Remover Playlist
                    </Button>
                    <Button
                        type="submit"
                        className="auth-button modal-close"
                        Background="var(--color-first)"
                        color="var(--color-zero)"
                        //onClick={() => this.submitForm() }
                    >
                        Cancelar
                    </Button>
                </div>
            </Container>
        )
    }
}

export default compose(graphql(REMOVE_PLAYLIST))(RemovePlayList);
