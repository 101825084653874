import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Container } from './styles';

import { Button } from '../../elements/ButtonElement';

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { FOLLOW_EXTERNAL_FEED_MUTATION  } from '../../services/graphql/mutations';
import defaultImage from "../../assets/img/thumbDefault.jpg";



const SubscriberComponent = ({loading, following,  actionFollow}) => (

    <Button
      loading={loading}
      style={{height: "34px", width: "35%"}}
      className={`btn subscribe-button ${following ? 'button-secondary' : 'button-primary'}`}
      onClick={(event) => { event.preventDefault(); actionFollow() } }
    >
      {following ? 'Inscrito' : 'Inscreva-se'}
    </Button>

)
class FeedCard extends Component {

  constructor(props){
    super(props);
    this.state = {
      feed: props.feed,
      loading: false,
    }
  }

  actionFollow = async () => {
    this.setState({loading: true})
    // console.log('actionFollow feed', this.state)
    let bodyConsult = { id: this.state.feed.id }
    if(this.state.feed.following){
      bodyConsult.unfollow = true
    }else{
      bodyConsult.unfollow = false
    }
    // console.log('actionFollow ', bodyConsult)
    let followFeed = await this.props.mutate({ variables: bodyConsult });
    // console.log('followFeed ', followFeed)
    this.setState({
      feed: followFeed.data.followExternalFeed,
      loading: false
    })
  }

  static getDerivedStateFromProps(props, state){
      let { data } = props;
      let new_state = state;
      if(!!data && !!data.feed &&data.feed.data[0] !== state.feed ) new_state = { ...new_state, feed: data.feed.data[0] }
      if(props.feed !== state.feed) new_state = { ...new_state, feed: props.feed }
      if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
      return new_state;
  }

  render() {
    // console.log('FeedCard ', this.props)
    let {
      id,
      name,
      description,
      thumbnail,
      followers_count,
      following
    } = this.state.feed;
    return (
      <div className="feed" key={`feed-${id}`}>
          <div className="img-feed">
              <Link to={`/externalfeed/${id}`}><img src={thumbnail ? thumbnail : defaultImage} /></Link>
          </div>
          <div className="name-desc">
              <div className="name-feed"><Link to={`/externalfeed/${id}`}><b>{name}</b></Link></div>
              <div className="desc-feed">{description.substring(0,80)} ...</div>

              <SubscriberComponent loading={this.state.loading} following={following} actionFollow={this.actionFollow} />

          </div>
      </div>
    );
  }
}

export default compose(graphql(FOLLOW_EXTERNAL_FEED_MUTATION))(FeedCard);
