import React, { Component } from "react";

import { Container } from "../styles";

//component
import { Header } from "../../../components/Header";
import { NavBar } from "../../../components/NavBar";
import {
  Notifications,
  Security,
  MyData
} from "../../../components/Configurations";
//import Tabs from "../../../components/Tabs";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class Configurations extends Component {
  render() {
    return (
      <>
        <Container>
          <NavBar />
          <div className="browse-container">
            <MyData />
            <Security />
            <Notifications />
          </div>
        </Container>
      </>
    );
  }
}
