import React from "react";

import { ListItem, Item } from "./styles";

export const List = ({children, classList}) => {
  return (
    <ListItem className={classList}>
      {children}
    </ListItem>
  );
};
