import React, { Component } from "react";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

//component
import Layout from "../../../hoc/Layout/Layout";

//styles
import { Container } from "./AssistirMaisTarde.styles";
import EpisodeCard from "../../../components/EpisodeCardComponent";
import { ThumbEpisode } from "../../../components/ThumbEpisode";
import { Button} from "../../../elements/CTA";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

const EPISODES = [
    {
        id: 1,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    },
    {
        id: 2,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    },
    {
        id: 3,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    },
    {
        id: 4,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    },
    {
        id: 5,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    },
    {
        id: 6,
        thumbnail:
            "https://as.com/meristation/imagenes/2018/09/19/header_image/385464361537362246.jpg",
        title: "Empreendedor 100 - Empreendendo com chocolate nas veias",
        description: "Mussum Ipsum, cacilds vidis litro abertis. A ordem dos tratores não altera o pão duris. Copo furadis é disculpa de bebadis, arcu quam ...",
        timeAgo: "1569021991",
        playCount: "1234450",
        channel: {
            code: "nerdcast",
            flag: "agregaded-verified"
        }
    }
];

export default class AssistirMaisTarde extends Component {
    render() {
        return (
            <Layout>
                <Container>
                    <div className="colunLeft">
                        <div className="content-thumbs">
                            {
                                EPISODES.map((episode,index) => {
                                    if(index < 4){
                                        return (
                                            <ThumbEpisode key={episode.id} episode={episode} />
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="contentTitle">
                            <h4>Assistir mais tarde</h4>
                        </div>
                        <Button
                            type="submit"
                            className="play-all"
                            Background="var(--color-first)"
                            Color="var(--color-zero)"
                        >
                            TOCAR TODOS
                        </Button>
                    </div>
                    <div className="colunRight">
                        <div className="contentDropdown">
                        <Dropdown options={['AAAA','BBBBB']} value='ORDENAR POR' menuClassName="drop" controlClassName="drop" />
                        </div>
                        {
                            EPISODES.map((episode,index) => {
                                return (
                                    <div className="contentEpisode">
                                        <div className="contentIndice">{index+1}</div>
                                        <EpisodeCard horizontal showDetails key={episode.id} episode={episode} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Container>
            </Layout>
        )
    }
}
