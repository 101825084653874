import styled from "styled-components";

export const CircleButton = styled.button`
  width: ${props => props.size};
  height: ${props => props.size};
  color: ${props => props.color};
  border-radius: 50%;
  background: ${props =>
    !!props.img ? `url(${props.img}) no-repeat center center` : props.bgColor};
  background-color: #fff;
  background-size: cover;
  border: 2px solid ${props => props.borderColor || "transparent"};

  &:hover {
    opacity: 0.7;
  }
`;
