import gql from "graphql-tag";

const HeaderQuery = gql`

  query Header($thumbWidth: Int!, $thumbHeight: Int!) {
      profile {
        profile {
          nickname
          profilePicture(width: $thumbWidth, height: $thumbHeight)
        }
      }
    }`;

export default HeaderQuery;
