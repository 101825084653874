import React, { Component } from "react";
import M from 'materialize-css';

//styles
import { Container } from "./ModalRestrationLogin.style.js";

//assets
import LogoDefault from "../../assets/img/ola_logo_vertical.png";
// import LogoCadastro from "../../assets/img/img_cadastro.png";
import LogoCadastro from "../../assets/img/logo_cadastro.png";



import { FinalizeRegistration } from "./FinalizeRegistration";
import { LoginPassword } from "./LoginPassword";
import { Register } from "./Register";
import { TelephoneOperator } from "./TelephoneOperator";

import { ForgotPassword } from "./ForgotPassword";

import { PinCodeCarrier } from "./PinCodeCarrier";

import { sendMessageToUser } from '../../common';

import { REGISTER_MUTATION, 
         REGISTER_OPERATOR_MUTATION, 
         REGISTER_PIN_MUTATION, 
         REGISTER_PIN_VALIDATION_MUTATION, 
         LOGIN_MUTATION, 
         SOCIAL_LOGIN_MUTATION, 
         REGISTER_SOCIAL_MUTATION, 
         RESET_PASSWORD_MUTATION 
        } from '../../services/graphql/mutations';

import { CURRENT_PLAYBACK} from '../../services/graphql/query';
import apolloClient from "../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';
import LOADING from "../../assets/img/loading.gif";
class ModalRestrationLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFormScreen: "register",
            loading: false,
            emailToken: '',
            referrer: '',
            lockEmail: false
        }

        document.addEventListener("modal-entrar", this.goToLogin);
        document.addEventListener("setaEmail", this.setaEmail);
        document.addEventListener("setaReferrer", this.setaReferrer);
        document.addEventListener("travaEmail", this.travaEmail);
    }

    goToLogin = ({detail}) => {
        switch(detail){
            case "login":
                return this.setState({modalFormScreen: 'login-password'})
            case "register":
                return this.setState({modalFormScreen: 'register'})
            case "telephone-operator":
                return this.setState({modalFormScreen: 'telephone-operator'})
            }
    }

    setaEmail = ({detail}) => {
        // console.log("Email: ",detail);
        return this.setState({emailToken: detail})
    }

    setaReferrer = ({detail}) => {
        // console.log("Referrer: ",detail);
        return this.setState({referrer: detail})
    }

    travaEmail = ({detail}) => {
        // console.log("Trava email: ",detail);
        return this.setState({lockEmail: detail})
    }

    componentDidMount(){
        // var elems = document.querySelectorAll('.modal');
        // var instances = M.Modal.init(elems);
    }

    setModalScreen(screen){
        this.setState(state => ({ modalFormScreen: screen }))
    }
    updateState = (state: Object) => {  
        this.setState(state);
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    submitForm = async () => {
        this.setState({loading: true})
        // console.log("SUBMIT STATE: ", this.state)
        switch(this.state.modalFormScreen){
            case "register":
            case "finalize-registration":

                if (!!this.state.provider && this.state.access_token) {
                    // console.log("REGISTER BY FACE/GOOGLE")
                    let body = {
                        email: this.state.phone_or_email, 
                        password: this.state.password,
                        name: this.state.name,
                        nickname: this.state.nickname,
                        referrer: this.state.referrer,
                        provider: this.state.provider,
                        access_token: this.state.access_token
                    }
                    this.props.mutate({mutation: REGISTER_SOCIAL_MUTATION, variables: body, errorPolicy: 'all'}).then(({data, errors}) => {
                        if(errors){
                            let {validation} = errors[0].extensions;
                            // console.log("ERRO GRAPHQL ", errors);
                            if( !!validation.email ){
                                // sendMessageToUser('O email já está cadastrado!');
                                    login_body = {
                                        provider: this.state.provider,
                                        access_token: this.state.access_token
                                    }
                                    QUERY_MUTATION = SOCIAL_LOGIN_MUTATION;
                                

                                this.props.mutate({mutation: SOCIAL_LOGIN_MUTATION, variables: login_body, errorPolicy: 'all', fetchPolicy: 'no-cache'})
                                .then(async ({data, errors}) => {
                                    if(errors){
                                        // console.log("Error: ");
                                    }
                                    let { login, socialLogin } = data;
                                    let content = login ? login : socialLogin;
                                    //SETANDO TODOS COMO ASSINADOS NO LOGIN
                                    // content.subscribed = true;
            
                                    if(content){
                                        // content.subscribed = true;
                                        localStorage.setItem('UserAccount', JSON.stringify(content));
                                        localStorage.setItem('user_token', content.access_token);
                                        
                                        let playerStatus = localStorage.getItem('playerComponent') ? JSON.parse(localStorage.getItem('playerComponent')) : undefined;
                                        if(!playerStatus || !playerStatus.current){
                                            let {data} = await apolloClient.query({query: CURRENT_PLAYBACK});
                                            if(!!data && !!data.currentPlayback && data.currentPlayback.episode){
                                                let position = data.currentPlayback.episode.duration ?
                                                    1.0 * data.currentPlayback.position/data.currentPlayback.episode.duration : 0;
                                                let player = {
                                                    current: data.currentPlayback.episode,
                                                    queue: [],
                                                    currentTime: position
                                                }
                                                // console.log("Player data: ", player);
                                                localStorage.setItem('playerComponent', JSON.stringify(player))
                                            }
                                        }
                                        if(window.location.href.includes("/resplanding/") || window.location.href.includes("/resplandingnestle/") || window.location.href.includes("/register/") || !!this.state.provider){
                                            window.location.replace("/");
                                            this.setState({modalFormScreen: ''});
                                        }else{
                                            window.location.reload();
                                        }
                
                                        sendMessageToUser('Você foi logado com sucesso!')
                                    }
                                    this.setState({loading: false})
                                })
                            } else if( !!validation.nickname ){
                                sendMessageToUser('O nome de usuário já está cadastrado');
                            }
                            this.setModalScreen("finalize-registration");
                            
                            this.setState({loading: false})

                        } else {
                            // console.log("USUARIO CADASTRADO COM SUCESSO!!! ", data);
                            let { createUserBySocialProvider } = data;
                            if(createUserBySocialProvider){
                                // console.log("VOLTOU USUARIO")
                                // createUserBySocialProvider.subscribed = true;
                                localStorage.setItem('UserAccount', JSON.stringify(createUserBySocialProvider))
                                localStorage.setItem('user_token', createUserBySocialProvider.access_token)
                                this.setState({loading: false})
                                window.location.reload();
                                sendMessageToUser('Você foi cadastrado com sucesso!')
                            }
                        }
                    })
                    .catch((err) => {
                        // console.log("ERRO NA SOLICITACAO ", err)
                        sendMessageToUser('Houve um erro na sua solicitação. Por favor, tente novamente')
                        this.setState({loading: false})
                        window.location.reload();  
                    })

                } else {
                    let body = {
                        email: this.state.phone_or_email, 
                        password: this.state.password,
                        name: this.state.name,
                        nickname: this.state.nickname,
                        referrer: this.state.referrer
                    }
                    // console.log(body)                 

                    this.props.mutate({mutation: REGISTER_MUTATION, variables: body, errorPolicy: 'all'})
                    .then(({data, errors}) => {
                        // console.log("DATA: ", data, "ERRORS: ", errors)
                        if(errors){
                            let {validation} = errors[0].extensions;
                            if( validation.email ){
                                sendMessageToUser('O email já está cadastrado!');
                            } else if( validation.msisdn ){
                                sendMessageToUser('O telefone já está cadastrado');
                            } else if( validation.name ){
                                sendMessageToUser('O nome já está cadastrado');
                            } else if( validation.nickname ){
                                sendMessageToUser('O nome de usuário já está cadastrado');
                            }
                            this.setState({loading: false})
                        } else {
                            let { createUser } = data;
                            if(createUser){
                                // createUser.subscribed = true;
                                localStorage.setItem('UserAccount', JSON.stringify(createUser))
                                localStorage.setItem('user_token', createUser.access_token);
                                if(window.location.href.includes("/resplandingnestle/")){
                                    window.location.replace("/");
                                    this.setState({modalFormScreen: ''});
                                }else{
                                    window.location.reload();
                                }
                                sendMessageToUser('Você foi cadastrado com sucesso!')
                            }
                            this.setState({loading: false})
                        }
                    })
                    .catch((erro) => {
                        // console.log(erro)
                        this.setState({loading: false})
                    })

                }
            break;
            // case "telephone-operator":
            case "forgot-password":
                    const operator_body = {
                        phone_or_email: this.state.phone_or_email
                    }
                    // console.log("ForgotPass: ", this.state)
                    // console.log("Forgot-Password: ",operator_body);
                    this.props.mutate({mutation: RESET_PASSWORD_MUTATION, variables: operator_body, errorPolicy: 'all'})
                    .then(({data, errors}) => {
                        if(errors){
                            // console.log("ERROR: ", errors)
                            let { validation } = errors[0];
                            if(!!validation){
                                if(validation.phone_or_email){
                                    sendMessageToUser('Usuário não encontrado');
                                } else if(validation.msisdn) {
                                    sendMessageToUser('O telefones já foi usado');
                                }else{
                                    sendMessageToUser('Usuário não encontrado');
                                }
                            }else{
                                sendMessageToUser('Usuário não encontrado');
                            }
                            this.setState({loading: false});
                            this.setModalScreen("login-password");
                                
                        } else {
                            // console.log("DATA: ", data)
                            if(data.resetPassword && data.resetPassword == 'ok'){
                                sendMessageToUser(`Um email foi enviado para a conta cadastrada.
                                Caso não receba, verifique também em sua caixa de spam`);
                                //sendMessageToUser('Um email foi enviado para a conta cadastrada.' + this.state.phone_or_email);
                                this.setState({loading: false});
                                this.setModalScreen("login-password");
                            }
                           
                        }
                    })              

            case "telephone-operator":
                if(this.state.modalFormScreen === "telephone-operator"){
                    const operator_body = {
                        country_id: this.state.country,
                        msisdn: `55${this.state.phone}`,
                        password: this.state.password,
                        name: this.state.name,
                        nickname: this.state.nickname,
                    }
                    this.props.mutate({mutation: REGISTER_OPERATOR_MUTATION, variables: operator_body, errorPolicy: 'all'})
                    .then(({data, errors}) => {
                        if(errors){
                            // console.log("ERROR: ", errors)
                            let { validation } = errors[0];
                            if(validation.nickname){
                                sendMessageToUser('Nome de usuário já foi usado');
                            } else if(validation.msisdn) {
                                sendMessageToUser('O telefone já foi usado');
                            }
                            this.setState({loading: false})
                        } else {
                            // console.log("DATA: ", data)
                            let { createUser } = data;

                            if(createUser){
                                // createUser.subscribed = true;
                                localStorage.setItem('UserAccount', JSON.stringify(createUser))
                                localStorage.setItem('user_token', createUser.access_token)
                                // this.props.mutate({mutation: REGISTER_PIN_MUTATION});
                                // this.setModalScreen("pin-code");
                                window.location.reload();
                                sendMessageToUser('Você foi cadastrado com sucesso!')
                            }
                            this.setState({loading: false})
                        }
                    })
                    /*
                    let registerOperator = await this.props.mutate({mutation: REGISTER_OPERATOR_MUTATION, variables: operator_body});
                    // console.log(operator_body);
                    let { createUser } = registerOperator.data;

                    if(createUser){
                        localStorage.setItem('UserAccount', JSON.stringify(createUser))
                        localStorage.setItem('user_token', createUser.access_token)
                        this.props.mutate({mutation: REGISTER_PIN_MUTATION});
                        this.setModalScreen("pin-code");
                        sendMessageToUser('Você foi cadastrado com sucesso!')
                    }
                    */
                } else {                    
                    if(!!this.state.pincode){
                        const pin_bory = {
                            pin: this.state.pincode
                        }
                        let registerOperator = await this.props.mutate({mutation: REGISTER_PIN_VALIDATION_MUTATION, variables: pin_bory});
                        switch(registerOperator.data.verifyMsisdnPin){
                            case "MSISDN ALERADY VERIFIED":
                            case "MSISDN VERIFIED":
                                window.location.reload();
                            break;
                            default:
                                sendMessageToUser('Não foi possível validar o seu PIN. Tente novamente.');
                            break;
                        }
                    }
                    this.setState({loading: false});
                }
            break;
            case "login-password":
                let login_body = {};
                let QUERY_MUTATION = null;
                if(!!this.state.provider){
                    login_body = {
                        provider: this.state.provider,
                        access_token: this.state.access_token
                    }
                    QUERY_MUTATION = SOCIAL_LOGIN_MUTATION;
                } else {
                    login_body = {
                        phone_or_email: this.isNumber(this.state.phone_or_email) ? `55${this.state.phone_or_email}`: this.state.phone_or_email,
                        password: this.state.password
                    }
                    QUERY_MUTATION = LOGIN_MUTATION;
                }
                // console.log("Submit: ", login_body);
                this.props.mutate({mutation: QUERY_MUTATION, variables: login_body, errorPolicy: 'all', fetchPolicy: 'no-cache'})
                .then(async ({data, errors}) => {
                    if(errors){
                        if (!!this.state.provider) {
                            if (!!this.state.provider && this.state.access_token) {
                                // console.log("REGISTER BY FACE/GOOGLE")
                                let body = {
                                    email: this.state.phone_or_email, 
                                    password: this.state.password,
                                    name: this.state.name,
                                    nickname: this.state.nickname,
                                    referrer: this.state.referrer,
                                    provider: this.state.provider,
                                    access_token: this.state.access_token
                                }
                                this.props.mutate({mutation: REGISTER_SOCIAL_MUTATION, variables: body, errorPolicy: 'all'}).then(({data, errors}) => {
                                    if(errors){
                                        let {validation} = errors[0].extensions;
                                        // console.log("ERRO GRAPHQL ", errors);
                                        if( !!validation.email ){
                                            sendMessageToUser('O email já está cadastrado!');


                                        } else if( !!validation.nickname ){
                                            sendMessageToUser('O nome de usuário já está cadastrado');
                                        }
                                        this.setModalScreen("finalize-registration");
                                        
                                        this.setState({loading: false})
            
                                    } else {
                                        // console.log("USUARIO CADASTRADO COM SUCESSO!!! ", data);
                                        let { createUserBySocialProvider } = data;
                                        if(createUserBySocialProvider){
                                            // console.log("VOLTOU USUARIO")
                                            // createUserBySocialProvider.subscribed = true;
                                            localStorage.setItem('UserAccount', JSON.stringify(createUserBySocialProvider))
                                            localStorage.setItem('user_token', createUserBySocialProvider.access_token)
                                            this.setState({loading: false})
                                            window.location.reload();
                                            sendMessageToUser('Você foi cadastrado com sucesso!')
                                        }
                                    }
                                })
                                .catch((err) => {
                                    // console.log("ERRO NA SOLICITACAO ", err)
                                    sendMessageToUser('Houve um erro na sua solicitação. Por favor, tente novamente')
                                    this.setState({loading: false})
                                    window.location.reload();  
                                })
            
                            }else{
                                sendMessageToUser('Certifique-se de criar uma conta primeiro.');
                            }

                            
                            
                        } else {
                            sendMessageToUser('O login de acesso é invalido!');
                        }
                        if(!!this.state.provider){
                            this.setModalScreen("finalize-registration");
                        }
                        this.setState({loading: false})
                    } else {
                        // console.log(data);
                        let { login, socialLogin } = data;
                        let content = login ? login : socialLogin;
                        //SETANDO TODOS COMO ASSINADOS NO LOGIN
                        // content.subscribed = true;

                        if(content){
                            // content.subscribed = true;
                            localStorage.setItem('UserAccount', JSON.stringify(content));
                            localStorage.setItem('user_token', content.access_token);
                            
                            let playerStatus = localStorage.getItem('playerComponent') ? JSON.parse(localStorage.getItem('playerComponent')) : undefined;
                            if(!playerStatus || !playerStatus.current){
                                let {data} = await apolloClient.query({query: CURRENT_PLAYBACK});
                                if(!!data && !!data.currentPlayback && data.currentPlayback.episode){
                                    let position = data.currentPlayback.episode.duration ?
                                        1.0 * data.currentPlayback.position/data.currentPlayback.episode.duration : 0;
                                    let player = {
                                        current: data.currentPlayback.episode,
                                        queue: [],
                                        currentTime: position
                                    }
                                    // console.log("Player data: ", player);
                                    localStorage.setItem('playerComponent', JSON.stringify(player))
                                }
                            }
                            if(window.location.href.includes("/resplanding/") || window.location.href.includes("/resplandingnestle/") ||window.location.href.includes("/register/") || !!this.state.provider){
                                window.location.replace("/");
                                this.setState({modalFormScreen: ''});
                            }else{
                                window.location.reload();
                            }
    
                            sendMessageToUser('Você foi logado com sucesso!')
                        }
                        this.setState({loading: false})
                    }

                })
                //.catch((error) => console.log("Err: ", error));       
                
            break;
        }
    }

    render() {
        return (
            <Container >
                <div id="signup" className={this.props.hideModal ? "" : "modal"}>
                {this.state.modalFormScreen == "register" ? 
                    <div className="auth-logo-cad">
                        <img src={LogoCadastro} alt="logo ola" />
                    </div>
                    :
                    <div className="auth-logo">
                        <img src={LogoDefault} alt="logo ola" />
                    </div>
                }
                    { this.state.loading &&
                    <div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center",position: "absolute", backgroundColor:"#fff",top:0,left:0,right:0,bottom:0}}>
                        <img src={LOADING} />
                    </div>
                    }
                    { this.state.modalFormScreen == "register" &&
                        <Register setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "login-password" &&
                        <LoginPassword setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "telephone-operator" &&
                        <TelephoneOperator setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "forgot-password" &&
                        <ForgotPassword setModalScreen={(screen)=> this.setModalScreen(screen)} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "pin-code" &&
                        <PinCodeCarrier setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "finalize-registration" &&
                        <FinalizeRegistration setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                </div>
            </Container>
        )
    }
}

export default compose(graphql(REGISTER_MUTATION),graphql(LOGIN_MUTATION))(ModalRestrationLogin);
