import React from 'react';

import Reset from './generic/reset'
import Base from "./base"

import Colors from './settings/colors'
import Size from './settings/sizes'
import Spacing from './settings/spacing'

const Styles = () => (
<>
  <Reset/>
  <Base/>
  <Size/>
  <Colors/>
  <Spacing/>
</>
);

export default Styles;
