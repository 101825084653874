import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ACCESS_TOKEN, USER_ACCOUNT } from '../../../constants';
import M from 'materialize-css';

import { ModalRestrationLogin } from "../../../components/ModalRestrationLogin";
import Layout from "../../../hoc/Layout/Layout";
//component
import queryString from 'query-string'


class RespChild extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        const paramsUrl = queryString.parse(props.location.search);
        if (!!paramsUrl && !!paramsUrl.convidado) {
            this.state = {
                email: paramsUrl.convidado
            }
        }
    }
    componentDidMount() {

        if (localStorage.getItem(ACCESS_TOKEN)) {
            let user = JSON.parse(localStorage.getItem(USER_ACCOUNT));
            if (!!user && user.email == this.state.email) {
                window.location.replace("/");
            } else {
                localStorage.clear();
                window.location.reload(true);
            }
        } else {
            var elems = document.getElementById('signup');
            if (elems) {
                let that = this;
                var instances = M.Modal.init(elems, {
                    onOpenStart: function () {
                        var ev = new CustomEvent('modal-entrar', { "detail": "register" });
                        document.dispatchEvent(ev);
                        var ev2 = new CustomEvent('setaEmail', { "detail": that.state.email });
                        document.dispatchEvent(ev2);
                        var ev4 = new CustomEvent('travaEmail', { "detail": true });
                        document.dispatchEvent(ev4);
                    }
                });
                var instance = M.Modal.getInstance(elems);
                instance.open();
                // this.setState({ mostraRegistro: false })
            }
        }
    }


    render() {

        const {
            seila
        } = this.state;

        return (
            <Layout>
                {!!this.state.email ? <></> : <Redirect to="/" />}

                <>AGUARDE ENQUANTO VALIDAMOS SUA ASSINATURA ...</>

                <ModalRestrationLogin />

            </Layout>

        );
    }
}

export default RespChild;
