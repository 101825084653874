import React, { Component } from "react";

// import { Container } from './styles';
import AloModal from "../../components/alos/AloModal";
import { Close } from "styled-icons/material";

export default class Modal extends Component {
  state = {
    data: []
  };
  componentDidMount() {}
    render() {
      return (
        <div id="ola-modal" className={`modal alo-modal`}>
          <AloModal />
        </div>
      );
    }
}
