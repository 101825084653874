import { createGlobalStyle } from "styled-components";

const Spacing = createGlobalStyle`
    :root {
        --spacing-default: 28px;
        --spacing-2x: 56px;

        --spacing-very-small: 3.5px;
        --spacing-small: 7px;
        --spacing-medium: 14px;
        --spacing-half-big: 28px;
        --spacing-big: 56px;
        --spacing-very-big: 112px;
        --spacing-menu:  224px;
    }
`;

export default Spacing;
