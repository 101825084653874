import React, { Component } from "react";

import {
  Container,
  HeaderWrapper,
  ContainerCont,

} from "./styles";

import M from "materialize-css";


import { Title } from "../../elements/Typography";

import EpisodeCard from "../../components/EpisodeCardComponent";
import ChannelCard from "../../components/ChannelCard/ChannelCard";

import debounce from "lodash.debounce";

import { SEARCH_QUERY } from '../../services/graphql/query';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { parseSearchQuery } from '../../common';
import LOADING from "../../assets/img/loading.gif";

class SearchResult extends Component {


  constructor(props) {
    super(props);
    
    this.state = {
      error: false,
      loading: false,
      filter: parseSearchQuery(props.location),
      page_episodes: 1,
      page_channels: 1,
      hasMoreEpisodes: true,
      hasMoreChannels: true,
    }; 
    let search = parseSearchQuery(props.location)
    if(search) this.state = {...this.state, search }
  }

  static getDerivedStateFromProps(props, state){
      let { data } = props;
      let new_state = state;
      if(!!data && data.episodes && data.episodes !== state.episodes) new_state = { ...new_state, episodes: data.episodes }
      if(!!data && data.channels && data.channels !== state.channels) new_state = { ...new_state, channels: data.channels }
      if(!!data && data.feedChannels && data.feedChannels !== state.feedChannels) new_state = { ...new_state, feedChannels: data.feedChannels }
      if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
      return new_state;
  }

  loadChannels = () => {
      if(!this.state.loading && this.state.hasMoreChannels){
        this.setState({loading: true});
        let page_channels = this.state.page_channels + 1;
        this.props.data.fetchMore({
          variables: {page_channels: page_channels },
          updateQuery: (prev, { fetchMoreResult }) => {
              if(fetchMoreResult && fetchMoreResult.channels && (fetchMoreResult.channels.data.length || (fetchMoreResult.feedChannels && fetchMoreResult.feedChannels.data.length))){
                  this.setState({page_channels: page_channels });
                  return {
                    channels: {...fetchMoreResult.channels, data: [...prev.channels.data, ...fetchMoreResult.channels.data] },
                    feedChannels: fetchMoreResult.feedChannels ? {...fetchMoreResult.feedChannels, data: [...prev.feedChannels.data, ...fetchMoreResult.feedChannels.data] } : null,
                    episodes: { ...prev.episodes },
                    // page_channels: page_channels
                  }
              } else {
                  this.setState({hasMoreChannels: false})
                  return {
                    channels: { ...prev.channels },
                    feedChannels: { ...prev.feedChannels },
                    episodes: { ...prev.episodes }
                  }
              }
          }
        })
      }
    };
    loadEpisodes = () => {
     if(!this.state.loading && this.state.hasMoreEpisodes){
       this.setState({loading: true});
       let page_episodes = this.state.page_episodes + 1;
       this.props.data.fetchMore({
         variables: {page_episodes: page_episodes },
         updateQuery: (prev, { fetchMoreResult }) => {
              if(fetchMoreResult && fetchMoreResult.episodes && fetchMoreResult.episodes.data.length){
                  this.setState({page_episodes: page_episodes });
                  // console.log("Prev: ", prev);
                  // console.log("More: ", fetchMoreResult);
                  return {
                    channels: { ...prev.channels },
                    feedChannels: fetchMoreResult.feedChannels ? { ...prev.feedChannels } : null,
                    episodes: {...fetchMoreResult.episodes, data: [...prev.episodes.data, ...fetchMoreResult.episodes.data] },
                    page_episodes: page_episodes
                  }
              } else {
                  this.setState({hasMoreEpisodes: false})
                  return {
                    channels: { ...prev.channels },
                    feedChannels: fetchMoreResult.feedChannels ? { ...prev.feedChannels } : null,
                    episodes: { ...prev.episodes }
                  }
              }
          }
        })
     }
    };

    handleScroll = debounce(() => {
        const {
          loadChannels,
          loadEpisodes,
          state: {
            error,
            loading,
            hasMoreChannels,
            hasMoreEpisodes
          },
        } = this;
          
        // console.log("scroll....");
        let el = document.querySelectorAll(".tabs");
        let instance =  M.Tabs.init(el);
        
        // Bails early if:
        // * there's an error
        // * it's already loading
        // * there's nothing left to load
        if (error || loading || (!hasMoreEpisodes && !hasMoreChannels)) return;
  
        // Checks that the page has scrolled to the bottom
        if (
          window.innerHeight + document.documentElement.scrollTop
          > document.documentElement.offsetHeight - 10
        ) {
          if(instance[0].index == 0 ){
            loadChannels();
          }else{
            loadEpisodes();
          }
          
        }else{
        }
      }, 100);

  componentDidMount() {
    let el = document.querySelectorAll(".tabs");
    let instance = M.Tabs.init(el);
    
    window.addEventListener('scroll', this.handleScroll, true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {    
    
    const {
      error,
      channels,
      episodes,
      feedChannels,
    } = this.state;

    let allChannels = !!channels && !!channels.data ? channels.data : [];
    if (!!feedChannels && !!feedChannels.data) {
      allChannels = allChannels.concat(feedChannels.data);
    }

  return (
    <Container>
      <div className="container">
      <HeaderWrapper>
        <Title typeClass="search-result_subtitle" content="Sua busca por "/>
        <Title  typeClass="search-result_title" content={decodeURI(parseSearchQuery(this.props.location))} />
      </HeaderWrapper>

      <ContainerCont  >
          <div className="row">
            <div className="col s12">
              <ul className="tabs">
                <li className="tab col s6">
                  <a  href="#canais">
                    CANAIS
                  </a>
                </li>
                <li className="tab col s6">
                  <a href="#episodios">EPISÓDIOS</a>
                </li>
              </ul>
            </div>
            {allChannels.length > 0 ? 
              <div id="canais" className="canais">
                {allChannels.map(channel => {
                  return !!channel && <ChannelCard 
                      key={channel.id}  
                      channel={channel} 
                  />;
                })}
              </div>
            : (!!channels && !!channels.data) || (!!feedChannels && feedChannels.data) ? 
              <div id="canais" className="canais">
                <li className="tab col s6" style={{textAlign: "center"}}>
                  Sem resultados
                </li>
              </div>
            :
              <div id="canais" className="canais">
              <li className="tab col s6" style={{textAlign: "center"}}>
                <img src={LOADING}/>
                </li>
              </div>
            }
            {!!episodes && !!episodes.data && episodes.data.length > 0 ? 
              <div id="episodios" className="eps">
                <div className="contentSearch">
                  {!!episodes && !!episodes.data && episodes.data.map(episode => {
                    return !!episode && <EpisodeCard 
                        key={episode.id} 
                        horizontal 
                        showDetails 
                        episode={episode} 
                    />;
                  })}
                </div>
              </div>
              : !!episodes && !!episodes.data ?
                <div id="episodios" className="eps">
                  <li className="tab col s6" style={{textAlign: "center"}}>
                     Sem resultados
                  </li>
                </div>
              :
                <div id="episodios" className="eps">
                  <li className="tab col s6" style={{textAlign: "center"}}>
                    <img src={LOADING}/>
                  </li>
                </div>
            }
          </div>
        </ContainerCont>

        {/* <ChannelAdvertising className="col s12 m4">
          banner de propaganda
        </ChannelAdvertising>
       */}
      </div>
    </Container>
    );
  }
}

const propsToOptions = (props) => {
  let consult = decodeURI(parseSearchQuery(props.location));
  return ({
      variables: 
        { 
          filter: consult,
          page_episodes: 1,
          page_channels: 1
        }
    }
  )
}

export default compose(graphql(SEARCH_QUERY, { options: propsToOptions } ))(SearchResult);
