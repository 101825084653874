import React, { Component } from "react";

import HeaderChannel from "../../components/HeaderChannel";

import EpisodeList from '../../components/episodes/EpisodeListComponent';
import AboutChannelComponent from '../../components/channels/AboutChannelComponent';

import Layout from "../../hoc/Layout/Layout";

import M from "materialize-css";
import debounce from "lodash.debounce";

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { CHANNEL_QUERY_BY_CODENAME } from '../../services/graphql/query';

class Channels extends Component {

  constructor(props) {
    super(props);
    this.state = {
      channel: { codename: props.match.params.code },
      error: false,
      loading: false,
      page_episodes: 1,
      hasMoreEpisodes: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    const el = document.querySelectorAll(".tabs");
    const instance = M.Tabs.init(el);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  loadEpisodes = () => {
    // console.log("load more...");
    if (!this.state.loading && this.state.hasMoreEpisodes) {
      this.setState({ loading: true });
      let page_episodes = this.state.page_episodes + 1;
      this.props.data.fetchMore({
        variables: { page_episodes: page_episodes },
        updateQuery: (prev, { fetchMoreResult }) => {
          // console.log("zzz curr...", this.state.episodes);
          // console.log("zzz more...", fetchMoreResult);
          if (fetchMoreResult && fetchMoreResult.channels && fetchMoreResult.channels.data[0] && fetchMoreResult.channels.data[0].episodes.data.length) {
            this.setState(
              {
                page_episodes: page_episodes,
                episodes: { ...fetchMoreResult, data: [...this.state.episodes.data, ...fetchMoreResult.channels.data[0].episodes.data] },
                hasMoreEpisodes: fetchMoreResult.channels.data[0].episodes.has_more_pages
              });
            return {
              channels: { ...prev.channels },
              // episodes: {...fetchMoreResult, data: [...prev.channels.data[0].episodes.data, ...fetchMoreResult.channels.data[0].episodes.data] },
              page_episodes: page_episodes,
            }
          } else {
            this.setState({ hasMoreEpisodes: false })
            return {
              channels: { ...prev.channels },
            }
          }
        }
      })
    }
  };

  handleScroll = debounce(() => {
    const {
      loadEpisodes,
      state: {
        error,
        loading,
        hasMoreEpisodes,
      },
    } = this;

    // console.log("scroll...");

    let el = document.querySelectorAll(".tabs");
    let instance = M.Tabs.init(el);

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (error || loading || !hasMoreEpisodes) return;

    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if (instance[0].index == 0) {
        loadEpisodes();
      }
    }
  }, 100);


  static getDerivedStateFromProps(props, state) {
    let { data } = props;
    let new_state = state;
    if (!!data && !!data.channels && data.channels.data[0] !== state.channel) new_state = { ...new_state, channel: data.channels.data[0] }
    if (!!data && !!data.channels && data.channels.data[0] !== state.channel) new_state = { ...new_state, episodes: data.channels.data[0].episodes }
    if (!!data && !!data.channels && data.channels.data[0] !== state.channel) new_state = { ...new_state, hasMoreEpisodes: data.channels.data[0].episodes.has_more_pages }
    if (!!data && data.loading !== state.loading) new_state = { ...new_state, loading: data.loading }
    return new_state;
  }

  render() {
    const { channel, episodes } = this.state;
    // console.log("THIS PROPS: ",channel)
    const metadata = this.props.data.channels;

    if (channel.name) {
      // Title
      document.title = `OLA Podcasts - ${channel.name}`;
      document.querySelector('meta[name=title]').setAttribute("content", `OLA Podcasts - ${channel.name}`);
      document.querySelector('meta[property="og:title"]').setAttribute("content", `OLA Podcasts - ${channel.name}`);
      document.querySelector('meta[property="twitter:title"]').setAttribute("content", `OLA Podcasts - ${channel.name}`);

      document.querySelector('meta[property="og:image"]').setAttribute("content", channel.thumbnail);
      document.querySelector('meta[property="twitter:image"]').setAttribute("content", channel.thumbnail);
      document.querySelector('meta[property="og:image:type"]').setAttribute("content", "image/png");
      document.querySelector('meta[property="og:image:width"]').setAttribute("content", "400");
      document.querySelector('meta[property="og:image:height"]').setAttribute("content", "400");
    }
    return (
      <Layout>
        <div className="container">
          <main className="channel-page">
            <HeaderChannel channel={channel} showrss={(episodes && episodes.data.length)} />
            <div className="row">
              <div className="col s12">
                <ul className="tabs">
                  <li id="episodes_btn" className="tab col s6">
                    <a href="#episodes">EPISÓDIOS</a>
                  </li>
                  <li id="sobre_canal" className="tab col s6">
                    <a className="" href="#about">
                      SOBRE O CANAL
                    </a>
                  </li>
                </ul>
              </div>

              <div id="episodes" className="col s12 episode-list channel-page">
                {!!channel && !!channel.id && !!episodes &&
                  <EpisodeList episodes={episodes} id={channel.id} scope="channel" />
                }
              </div>
              <div id="about" className="col s12 episode-list channel-page">
                {!!channel && !!channel.id &&
                  <AboutChannelComponent description={channel.description} />
                }
              </div>
            </div>
          </main>
        </div>
      </Layout>
    );
  }
}

const propsToOptions = (props) => {
  let { code: channel_codename } = props.match.params;
  switch (channel_codename) {
    case 'deepgrowth': window.location.href = 'https://feeds.captivate.fm/deepgrowth/'; break;
  }
  return ({
    variables:
    {
      channel_codename,
      page_episodes: 1
    }
  }
  )
}

export default compose(graphql(CHANNEL_QUERY_BY_CODENAME, { options: propsToOptions }))(Channels);

