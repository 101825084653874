import styled from "styled-components";

export const EpisodeContainer = styled.section`
  margin: var(--spacing-medium);
  width: 100%;
  height: 100%;

  .episode-image {
    width: 100%;
    border-radius: var(--radius-small);
  }
  .episode-header {
    display: flex;
    justify-content: center;
  }
  .episode-channel_name{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    letter-spacing: 0.05em;

    color: #666666
  }
  .episode-plays-time{
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    margin-top: 20px;
    .plays{
      text-align: left;
      width: 50%;
    }
    .time{
      width: 50%;
      text-align: right;
    }
  }

  .episode-description{
    font-size: 14px;
    color: #989898;
    h6{
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #666666;
    }
  }
  .comentarios{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
    .em-resposta{
      font-size: 12px;
      font-weight: normal;
      span{
        font-weight: bold;
      }
      img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .textarea{
      padding: var(--size-small);
      background-color: #f7f7f5;
      height: 150px;
      font-size: 12px;
      font-weight: normal;
      color: #666666;
      resize: none;
    }
    .auth-button{
      width: auto;
      border: 1px solid #2196F3;
      border-radius: var(--radius-small);
      padding: 5px 16px;
      font-size: 12px;
      line-height: 24px;
      color: #FFF;
      background-color: #2196F3;
    }
    .lista-comentarios{
      margin-top: var(--spacing-medium);
    }
    
  }
  .btn-floating.green {
    .icon {
      color: var(--color-seventh-darken);
    }
  }
  .btn-floating.red {
    .icon {
      color: var(--color-second);
    }
  }
`;
export const RecommendedEpisodes = styled.section`
  width: 100%;
  background: #f5f5f5;
  padding: var(--spacing-small);

  .recommended-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #666666;
  }
  .episode-card {
    margin: var(--spacing-medium) 0;
    box-shadow: none;
  }
`;

export const Comentario = styled.article`
  width: 100%;
  display:flex;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin: 20px 0;
    
    .trash-icon {
      color: #2196f3; 
      width: 20px;
      height: 20px;
      cursor: pointer
    }

    .button-row {
      height: 100%;
      align-items: center;
      flex-direction: row-reverse;
      display: flex;
      margin: 20px 10px 0 0;
    }

    .text-update {
    resize: none;
    outline: none;
    border: none;
    background-color: rgb(247, 247, 245);
    color: rgb(102, 102, 102);
    font-size: 12px;
    padding: 15px;
    height: 75px;
    }

    .btn-cancelar, .btn-edit {
      background-color: #666666;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 500;
      color: #fff;
      width: 65px;
      height: 22px;
    }

    

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-bottom: 20px;
    }
    
    .btn-salvar {
      background-color: #2196f3;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 500;
      color: #fff;
      width: 65px;
      height: 22px;
      margin-left: 10px;
    }

    .btnDelete {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }

    @media (max-width: 500px) {
      .button-row{
        flex-direction: column-reverse;
        margin-top: 10px;
      }

      .btnDelete, .btnEdit {
     margin: 4px 0;
    }
    }

    .comment-image{
      img{
        width: 80px;
        border-radius: 50%;
        margin-top: var(--spacing-medium);
        margin-left: var(--spacing-medium);
        
      }   

      @media (max-width: 500px) {
        img{
          width: 45px;
        }
      }
    }
    
    .time-ago{
      font-size: 10px;
      line-height: 12px;
    }

    .comment-body{
      display:flex;
      flex-direction:column;
      justify-content:space-around;
      width: 75%;
      margin-left: var(--spacing-small);
    
      .comment-body_title{
        display:flex;
        align-items: center;
        h4{
          font-family: Avenir-Heavy;
          font-size: 16px;
          color: #4A4A49;
          letter-spacing: -0.38px;
          margin-right:var(--spacing-very-small);
        }
        div{
           margin-left: var(--spacing-small);
          font-family: Avenir-Medium;
          font-size: 14px;
          color: #7F7F7F;
          letter-spacing: 0;
        }
        .comment-title_icon{
          margin-left: auto;
        }

        @media (max-width: 500px) {
          h4 {
            margin: 10px 5px 5px 0
          }
        }
      }
    
      .comment-body_text{
        margin: var(--spacing-small) 0;
        font-size: 12px;
      font-weight: normal;

              @media (max-width: 500px) {
                line-height: 18px;
        }
      }
    
      .comment-body_action{
          display:flex;
          align-items:center;
          position: relative;
          margin: var(--spacing-very-small) 0;
          .action-icon{
            display:flex;
            justify-content: flex-end;
            img{
              margin-right:5px;
            }
          }
          a{
            margin-right:var(--spacing-small);
          }
    
          a:last-of-type{
            margin-right:0;
          }
    
          .comment-edit::before{
            content: "";
            background: var(--color-third);
            position: absolute;
            left: -10px;
            height: 85%;
            width: 2px;

          }
    
          .comment-edit{
            padding: 0 var(--spacing-very-small);
            margin: 0 var(--spacing-small);
            position: relative;

          }
    
          .comment-chevron{
            margin-left:auto;
          }
      }
    }
`;
