import styled from "styled-components";

export const Container = styled.section`
  padding: var(--spacing-small);
  width: 100%;
  display: flex;
  flex-direction: column;
  .notification-form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    margin-top: var(--spacing-medium);

    .notification-form-title {
      font-family: Avenir-Black;
      font-size: 12px;
      color: var(--color-negative-lighten);
      letter-spacing: 0;
    }
  }
  .notification-form-description {
    width: 50%;
    height: auto;
    font-family: Avenir-Heavy;
    font-size: 12px;
    color: #4a4a49;
    letter-spacing: 2px;
  }
  .notification-form-checkbox {
    width: 10%;
    margin-right: 10px;
    height: auto;
  }
  .notification-form-button {
    margin-top: var(--spacing-medium);
    margin-left: auto;
    max-width: 318px;
  }
`;
