import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  .tagview{
    width: 60%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: var(--spacing-medium); 
    margin-left: var(--spacing-medium); 

    .tag{
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 16px;
      color: #000000;
      span{
        font-weight: normal;
        font-size: 23px;
        line-height: 16px;
        color: #2196F3;
        margin-left: var(--spacing-small); 
      }
    }
  }
  .eps{
    margin-top: var(--spacing-medium); 
    .episode-card{
      margin-bottom: var(--spacing-small); 
    }
  }
`;

