import styled, { css } from "styled-components";

export const Button = styled.button.attrs({
  type: props => (props.Type === "submit" ? "submit" : "button")
})`
  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.filled === false &&
    css`
      background: ${props => props.Color};
      color: ${props => props.Background};
      border: 1px solid ${props => props.Background};

      &:hover {
        background: none;
        opacity: 0.8;
      }
    `};
`;
