import React, { Component } from "react";

import {
  Container,
  ContainerView
} from "./ProfileOptions.styles";

import { Query } from 'react-apollo';
import ProfileQuery from './profile.graphql';

//component

import { Info } from "../../../components/ChannelInfo";
import { Profile } from "../../../components/Profile";
import { ProfileOptionBar } from "../../../components/ProfileOptionBar";
import { ChangePassword } from "../../../components/ChangePassword";
import { UserSignatures } from "../../../components/UserSignatures";

import  Layout  from "../../../hoc/Layout/Layout";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class ProfileOptions extends Component {
    constructor(props){
        super(props);
        this.state = {
            optionActive: "profile"
        }
  }
  renderContainer(profile){
    switch(this.state.optionActive){
        case "profile":
            return <Profile profile={profile}/>;
        case "changePassword":
            return <ChangePassword/>;
        case "signatures":
            return <UserSignatures />;
        case "episode":
            return <>MEUS EPISODIOS</>;  
                      
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { data } = props;

    let new_state = state;
    if (props.user && props.user !== state.user) new_state = { ...new_state, user: props.user };
    if (!!data && data.user && props.user !== state.user) new_state =  { ...new_state, user: data.user };
    if (!!data && data.loading !== state.loading) new_state = { ...new_state, loading: data.loading };
    return new_state;
  }

  selectOption = (option) =>{
      this.setState({optionActive: option})
  }



  render() {
    return (
      <Layout>
        <ContainerView>
          <ProfileOptionBar selectOption={this.selectOption} />
          <Container>
                <Query query={ProfileQuery} variables={{}}>
                  {({ loading, error, data }) => {
                      if (loading || error) {
                          return("Loading ..." );
                      }
                      return this.renderContainer(data.profile);
                  }}
              </Query>
          </Container>
        </ContainerView>
      </Layout>
    );
  }
}
