import React, { Component } from "react";
import M from "materialize-css";

//styles
import { Container, HeaderWrapper, WrapperList } from "./List.style";
import { Link } from "react-router-dom";
import { Query } from 'react-apollo';
import myLists from './List.graphql';

// components
import { Carousel } from "../../Carousel"
import { NewPlayList } from "./NewPlayList";
//elements
import { Title } from "../../../elements/Typography";
import Thumb from "./Thumb/Thumb";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

class List extends Component {
  
  render() {
    return (
      <Container>
        <HeaderWrapper>
          <Title size="1" typeClass="playlist-title" content="Minhas PlayLists" />
        </HeaderWrapper>
        <WrapperList className="wrapper-list">
          <Query query={myLists}>
          {
            ({ loading, error, data, refetch }) => {
              if (loading && !data) return <div> {`Loading: ${loading}`} </div>;
              if (error && !data) {
                if (error.networkError) {
                    // console.log("Error: ",error);
                    // console.log("data: ",data);
                    return <div> {`Error: no network`} </div>;
                }
                if (error.graphQLErrors) {
                    return <div> {`Error: ${error}`} </div>;
                }
                return <div> {`Error: ${error}`} </div>;
              }
              let{ myPlaylists } = data;
              {/* console.log("xxxx",myPlaylists.data) */}

              if(this.props.scope && this.props.scope == "addEpPlaylist"){
                  if(myPlaylists.data.length){
                    // console.log("TEM PLAYLIST ... ");
                    return <div className="conteiner-list row">
                      <NewPlayList qtdPlaylists={myPlaylists.data.length} onRefresh={() => refetch()}/>
                      {
                        myPlaylists.data.map(
                          (playList) => {
                            return (

                              <div key={`thumb-${playList.id}`} className="thumb-content col l2 m3 s4">
                                <Thumb lista={playList.episodes_with_order} indice={playList.id} props={this.props} onRefresh={() => refetch()} />
                                <div className="titlePlaylist">{playList.name}</div>
                              </div>
                            )
                          }
                        )
                      }
                    </div>
                  } else {
                    // console.log("nao TEM PLAYLIST ... ");
                    
                    return (
                      <>
                    <NewPlayList qtdPlaylists={myPlaylists.data.length} onRefresh={() => refetch()}/>
                    <div style={{minHeight: "300px",fontSize: "16px", fontWeight: "900",display: "flex",flex: 1, justifyContent: "center",alignItems: "center"}}>Desculpe, você ainda não possui uma playlist.</div>
                      </>
                    );
                  }
              }else{
                if(myPlaylists.data.length){
                  // console.log("TEM PLAYLIST 2 ... ");
                  return <div className="conteiner-list row">
                    <NewPlayList qtdPlaylists={myPlaylists.data.length} onRefresh={() => refetch()}/>
                    {
                      myPlaylists.data.map(
                        (playList) => {
                          return (

                            <div key={`thumb-${playList.id}`} className="thumb-content col l2 m3 s4">
                              <Thumb lista={playList.episodes_with_order} indice={playList.id} props={this.props} onRefresh={() => refetch()} />
                              <div className="titlePlaylist">{playList.name}</div>
                            </div>
                          )
                        }
                      )
                    }
                  </div>
                } else {
                  
                  return <NewPlayList qtdPlaylists={myPlaylists.data.length} onRefresh={() => refetch()}/>;
                }
              }
            }
          }
          </Query>
        </WrapperList>
        {/* < Carousel /> */}
      </Container>
    );
  }
}

export default List;
