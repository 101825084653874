import gql from "graphql-tag";

const LaterQuery = gql`
query{
    myPlaylists( type: "later", page: 1, limit: 1){
        data {
            name
            episodes_with_order{
                episode{
                    id
                    thumbnail(width: 300, height: 300)
                    title
                    rating
                    content
                    premium
                    original
                    external                    
                    channel{
                        id
                        thumbnail(width: 300, height: 300)
                        codename
                        flag
                    }
                    play_count
                    publishDate
                    duration
                    description
                }
            }
        }
        current_page
        has_more_pages
    }
}
`;
const LaterChannelQuery = gql`
query{
    myPlaylists( type: "later", page: 1, limit: 1){
        data {
            name
            episodes_with_order{
                episode{
                    channel{
                        id
                        thumbnail(width: 300, height: 300)
                        codename
                        main_category {
                            id
                        }
                        flag
                    }
                }
            }
        }
        current_page
        has_more_pages
    }
}
`;

export { LaterQuery, LaterChannelQuery };
