import styled from 'styled-components';

export const Header = styled.header`
  margin: 0;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto 64px auto;

  @media (max-width: 768px) {
    margin: 0 3%;
  }
`;

export const Title = styled.h1`
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

export const Instructions = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 64px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Item = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 64px;
  row-gap: 18px;
  width: calc(50% - 32px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Ilustration = styled.img`
  width: 300px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Rodape = styled.footer`
  color: #7e7e7e;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: 992px) {
    & {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
