import React, { Component } from "react";

import M from "materialize-css";

import { Title } from "../../elements/Typography";
import { Wrapper } from "../../elements/Layout";

//styles
import {
  Trends,
} from "./VerticalList.style";

//graphql
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { VerticalListQuery }  from './VerticalList.graphql';

// import {
//   Card,
//   EditorialCard,
//   //EpisodeCard,
//   VerticalCard
// } from "../../elements/Card";
import EpisodeCard from "../../components/EpisodeCardComponent";

import { ChevronRight } from "styled-icons/material";

// icons generos
import { Cotidiano, Conhecimento, Variedades, Humor, Familia, Historias, LifeStyle, Mercado, Esportes, Tecnologia } from "../../assets/icons/";

class VerticalListCarrocel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instanceGeneroCarrosel: {
        numVisible: 6,
        dist: 0,
        padding: 0,
        fullWidth: true
      }
    }
  }
  componentDidMount(){
    //EM ALTA CARROSEL
    let { mainCategories } = this.props.data;
    if(mainCategories) {
    let elementGenero = document.querySelectorAll(`#vertical-${mainCategories[0].id}`);
    this.instanceGenero = M.Carousel.init(elementGenero,this.state.instanceGeneroCarrosel);
    // console.log("INSTANCIA: ",this.instanceGenero)
    }
  }
  componentDidUpdate(){
    //EM ALTA CARROSEL
    let {mainCategories} = this.props.data;
    //let elementGenero = document.querySelectorAll(`.genero-vertical-carrosel`);
    let elementGenero = document.querySelectorAll(`#vertical-${mainCategories? mainCategories[0].id : 0}`);
    this.instanceGenero = M.Carousel.init(elementGenero,this.state.instanceGeneroCarrosel);
    // console.log("INSTANCIA: ",this.instanceGenero)
  }

  replaceTitle = (name) => {
    switch(name) {
      case "HUMOR":         return <span style={{color:"#9E673C"}}><Humor className="icon" /> Humor</span>;
      case "CONHECIMENTO":  return <span style={{color:"#D94D4C"}}><Conhecimento className="icon" /> Conhecimento</span>;
      case "COTIDIANO":     return <span style={{color:"#DF2617"}}><Cotidiano className="icon" /> Cotidiano</span>;
      case "ESPORTES":      return <span style={{color:"#65B32E"}}><Esportes className="icon" /> Esportes</span>;
      case "FAMILIA":       return <span style={{color:"#3FB6E2"}}><Familia className="icon" /> Família</span>;
      case "HISTÓRIAS":     return <span style={{color:"#7259B0"}}><Historias className="icon" /> Histórias</span>;
      case "LIFESTYLE":     return <span style={{color:"#B5A1D0"}}><LifeStyle className="icon" /> Lifestyle</span>;
      case "MERCADO":       return <span style={{color:"#00784A"}}><Mercado className="icon" /> Mercado</span>;
      case "TECNOLOGIA":    return <span style={{color:"#6EBD8F"}}><Tecnologia className="icon" /> Tecnologia</span>;
      case "VARIEDADES":    return <span style={{color:"#9E673C"}}><Variedades className="icon" /> Variedades</span>;
    }
  }
  next = () => {
    // // console.log("INDEX: ", index)
    //if(this.instanceGenero[index]){
      this.instanceGenero[0].next(1);
    //}
  }
  
  renderCarrocel(data){
    const {
      mainCategories,
      verticalsEpisodes,
      loading
    } = data;
    let {catIndex} = this.props;
    
    if (loading && !mainCategories) return false;
    return(
      <Trends className="trends">
        {
          verticalsEpisodes && 
          verticalsEpisodes.data &&
          verticalsEpisodes.data.length > 0 &&
          <>
            <Wrapper
            alignItems="center"
            justifyContent="flex-start"
            className="trends-header"
            >
              <Title size="5" typeClass="trend-title" content={this.replaceTitle(mainCategories[0].name)} />
            </Wrapper>
            <Wrapper className="trend-list genero">
              <div id={`vertical-${mainCategories[0].id}`} className="genero-vertical-carrosel carousel">
                {
                  verticalsEpisodes && 
                  verticalsEpisodes.data &&
                  verticalsEpisodes.data.length > 0 &&
                  verticalsEpisodes.data.map(
                    (episode) => {
                      return(
                        <div key={`genero-vertical-key-${mainCategories[0].id}-ep-${episode.id}`} className="content-colon carousel-item">
                          <EpisodeCard episode={episode} />
                        </div>
                      )
                    }
                  )
                }
                {
                  verticalsEpisodes && 
                  verticalsEpisodes.data && 
                  verticalsEpisodes.data.length > 3 && 
                  verticalsEpisodes.data.length < 12 &&
                  verticalsEpisodes.data.map(
                    (episode) => {
                      return(
                        <div key={`genero-vertical-key-${mainCategories[0].id}-ep-${episode.id}-repeat`} className="content-colon carousel-item">
                          <EpisodeCard episode={episode} />
                        </div>
                      )
                    }
                  )

                }
              </div>
              <a className="pagination"
                onClick={() => this.next()}
              >
                <ChevronRight className="paginationIcon" />
              </a>
            </Wrapper>
          </>
        }
      </Trends>
    )
  }
  render(){
    return this.renderCarrocel(this.props.data);
  }
};

const propsToOptions = (props) => {
  return (
    { variables: 
      {
        id: props.category.id,
      }
    }
  )
}

export default compose(graphql(VerticalListQuery, { options: propsToOptions }))(VerticalListCarrocel);
