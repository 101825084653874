import styled from "styled-components";

export const Container = styled.div`
    .content-select {
        display: flex;
        flex-direction: row;
        margin-bottom: 19px;
        select:first-child {
            margin-right: 11px;
        }
        select:last-child {
        }
        select {
            flex: 1;
            display: flex;
            line-height: 16px;
            color: #66757F;
            border: 1px solid var(--color-third);
            border-radius: 3px;
            background: var(--color-third);
            padding: var(--size-very-small);
        }
    }
    input {
        margin-bottom: 19px;
    }
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
    .auth-button {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 35px;
        padding: 0 15px;
        text-transform: uppercase;
        width: 100%;
    }
`;
