import React from "react";

import { Container, Wrapper } from "./Security.style";
import { Input } from "../../../elements/Input";
import { Button } from "../../../elements/CTA";

const Security = () => (
  <Container className="browse-container">
    <Wrapper className="mydata-form-wrap">
      <Input
        labelContent="Senha"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
    </Wrapper>
    <Wrapper lassName="mydata-form-wrap">
      <Input
        labelContent="Nova senha"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Input
        labelContent="Confirme a nova senha"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Button
        type="button"
        className="mydata-form-button"
        Background="var(--color-first)"
        Color="var(--color-zero)"
      >
        Salvar
      </Button>
    </Wrapper>
  </Container>
);

export default Security;
