import { createGlobalStyle } from "styled-components";

const Size = createGlobalStyle`
    :root {
        --radius-small: 3px;
        --radius-medium: 6px;
        --radius-big :12px;

        --size-base: 10px;
        --size-very-small: 0.75rem;
        --size-small: 1rem;
        --size-half-medium: 1.3rem;
        --size-medium: 2rem;
        --size-half-big: 2.5rem;
        --size-big: 4rem;
        --size-very-big: 5rem;
    }
    @media (max-height: 700px) {
        :root {
            --size-base: 9.8px;
        }
    }
`;

export default Size;
