import React, { Component } from "react";

import { Container, ControlerScreen } from "./ChannelOptions.styles";

//constants
import { ADMIN_USERS } from "../../../constants/users";
//component
import { Info } from "../../../components/ChannelInfo";
import { ChannelUserAccess } from "../../../components/ChannelUserAccess";
import { ChannelOptionEpisodes } from "../../../components/ChannelOptionEpisodes";
import { ChannelOptionAlos } from "../../../components/ChannelOptionAlos";
import { ChannelOptionBar } from "../../../components/ChannelOptionBar";
import { ChannelNewEpisode } from "../../../components/ChannelNewEpisode";
import Layout from "../../../hoc/Layout/Layout";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";
import { Redirect } from "react-router-dom";
export default class ChannelOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      optionActive: "info"
    };
  }
  
  componentDidMount() {

  }

  renderContainer(id) {
    switch (this.state.optionActive) {
      case "dashboard":
        return <div>minha dashboard</div>;
      case "info":
        return <Info channel_id={id} props={{ state: this.state}} selectOption={this.selectOption}/>;
      case "access":
        return <ChannelUserAccess channel_id={id} users={ADMIN_USERS} selectOption={this.selectOption}/>;
      case "episodes":
        return <ChannelOptionEpisodes channel_id={id} selectOption={this.selectOption} />;
      case "alos":
        return <ChannelOptionAlos channel_id={id} selectOption={this.selectOption}/>;
      case "new-episodes":
        return <ChannelNewEpisode channel_id={id} selectOption={this.selectOption} />
      default:
        return <div>dashboard</div>;
    }
  }

  selectOption = option => {
    this.setState({ optionActive: option });
  };

  render() {
    // console.log(this.props);
    let {id} = this.props.match.params;
    let myChannels = JSON.parse(localStorage.getItem("MY_CHANNELS"));
    if (!myChannels || myChannels.filter((channel) => {return channel.id == id}).length == 0) {
      return (<Redirect to="/"/>);
    }

    return (
      <Layout>
        <ControlerScreen>
          <ChannelOptionBar selectOption={this.selectOption} optionActive={this.state.optionActive} />
          <Container>{this.renderContainer(id)}</Container>
        </ControlerScreen>
      </Layout>
    );
  }
}
