import styled from "styled-components";
import premium from "../../assets/img/premium-icon.png";
export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--spacing-default);
    .content-header {
        display: flex;
        flex-direction: row;
        .title {
            margin: 0  10px 0 0;
            font-size: var(--size-medium);
            line-height: var(--size-medium);
            padding: 6px 0;
            font-weight: bold;
        }
        .type-category {
            height: 41px;
            font-size: var(--size-medium);
            line-height: var(--size-medium);
            padding-left: var(--size-medium);
            padding-right: var(--size-medium);
            text-transform: capitalize;
            color: var(--color-negative-darken);
        }
    }
    .content-channel {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        .column {
            min-width: 50%;
            padding-left: 12px;
            padding-right: 12px;
            display: flex;
            flex: 1;
            flex-direction: column;
            article:first-child {
                /*height: 110px;*/
                overflow: hidden;
                margin-bottom: var(--spacing-medium);
            }
            article.m8 {
                margin-top: -24px;
                height: auto;
            }
            .overlay {
                margin-top: 0;
                article {
                    min-width: 97px;
                    height: 0;
                    padding-bottom: 97px;
                }
            }
            img.contentBanner {
                height: auto;
            }
        }
        .content-link {
            display: flex;
            flex: 1;
            padding: 40px 0;
            justify-content: center;
            .verMais {
                border: 1px solid #2196F3;
                box-sizing: border-box;
                border-radius: 3px;
                padding: 15px 63px;
                color: #2196F3;
            }
        }
    }
    .content-premium {
        margin-bottom: var(--spacing-default);
        h6.title {
            background: url(${premium}) no-repeat top left;
            background-size: 18px;
            padding-left: 22px;
            color: var(--color-fifth);
            font-weight: bold;
        }
        .control-premium {
            display: flex;
            flex: 1;
            .episode-card {
                margin: 0;
                box-shadow: none;
                display: flex;
                flex: 1;
                flex-direction: column;
            }
            .episode-thumb {
                a {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }
    .content-alta {
        margin-bottom: var(--spacing-default);
        h6.title {
            font-weight: bold;
        }
        .control-alta {
            display: flex;
            flex: 1;
            .episode-card {
                display: flex;
                flex: 1;
                flex-direction: column;
                margin: 0;
                box-shadow: none;
            }
            .episode-thumb {
                a {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        flex: 1;
        flex-direction: row;
        .control-lancamentos {
            flex: 3;
            display: flex;
            flex-direction: column;
        }
        .content-propaganda {
            flex: 1;
            display: flex;
            background-color: #d1d6d9;
            margin-left: var(--spacing-default);
        }
    }
    .control-lancamentos {
        .episode-card {
            margin-left: 0;
            box-shadow: none;
            flex: 2;
            .episode-thumb {
                display: flex;
                flex: 1;
                a {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
            .card-body {
                display: flex;
                flex: 4;
                flex-direction: column;
            }
        }
    }
`;
