import styled from "styled-components";

export const NavBarContainer = styled.div`
  height: auto;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .banner-advertising {
    margin-top: var(--spacing-small);
  }

  @media (max-width: 992px) {
    width: auto;
  }
`;

export const MenuWrapper = styled.article`
  width: 100%;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 25px;
  color: #66757f;
  margin-left: var(--spacing-small);

  .menu-item {
    display: flex;
    align-items: center;
    text-align: center;
    .menu-icon {
      margin-left: var(--spacing-small);
      color: var(--color-third-darken);
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  li {
    padding: var(--spacing-medium) 0;
  }

  li.menu-item.active {
    background: #f2f2f2;
    font-weight: 700;
    font-weight: bold;
    color: #4a4a49;
    .menu-icon {
      color: #4a4a49;
    }
  }
  @media (max-width: 992px) {
    width: auto;
    margin-right: var(--spacing-small);
  }
`;
