import React, { Component } from "react";
import { Link } from "react-router-dom";

//styles
import { Container } from "./ModalSejaPremium.style.js";

//assets
import LogoDefault from "../../assets/img/ola_logo_vertical.png";

import { sendMessageToUser } from '../../common';

import apolloClient from "../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';

import IMG_HEADER from "../../assets/img/Premium/logo-premium.jpg";
import IMG_01 from "../../assets/img/Premium/img-01.jpg";
import IMG_02 from "../../assets/img/Premium/img-02.jpg";

export default class ModalSejaPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    abrePremium = () => {
        if(Math.floor(Math.random() * 10) >= 4){
            this.setState({img: IMG_01});
        }else{
            this.setState({img: IMG_02});
        }
    }
    componentDidMount(){
        document.addEventListener("abre-premium", this.abrePremium);
    }

    render() {
        return (
            <Container>
                <div id="modal-premium" className="modal modal-premium">
                <div className="modalBackground">
                    <header>
                        <img src={IMG_HEADER} />
                    </header>
                    <section>
                        <div>
                            <img src={this.state.img} />
                        </div>
                        <div className="title">
                            Podcast sem fim.
                        </div>
                        <span>Seja Premium e bote para tocar só aquelas que voce curte</span>
                        {/* <Link className="btnPremium modal-close" onClick={() => {
                                var elems = document.getElementById('modal-assinar');
                                var instances = M.Modal.init(elems, {
                                    onOpenStart: function () {
                                        var ev = new CustomEvent("abre-assinar", { 'detail': "detalhesAqui" });
                                        document.dispatchEvent(ev);
                                    }
                                });
                                var instance = M.Modal.getInstance(elems);
                                instance.open();
                            }}> */}
                        <Link className="btnPremium modal-close" to="/planos/">
                            Experimente o premium
                        </Link>
                        <Link className="btnNoPremium modal-close">
                            Não, valeu
                        </Link>
                    </section>
                    <section>

                    </section>
                </div>
                  
                </div>
            </Container>
        )
    }
}

