import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { Container } from "./styles";

import EmailConfirmation from "./EmailConfirmation";
import SignupCommit from "./SignupCommit";
import SignupOptions from "./SignupOptions";

import InputMask from 'react-input-mask';
import { Input } from "../../../../elements/Input";
import { Button } from '../../../../elements/ButtonElement';
import { Title, Text } from "../../../../elements/Typography";
import LogoDefault from "../../../../assets/img/logo-ola-colorido.svg";
import LOADING from "../../../../assets/img/loading.gif";


import { sendMessageToUser } from '../../../../common';

import { validator } from "../../../../validators/validators.js";

import { REGISTER_MUTATION } from '../../../../services/graphql/mutations';

import apolloClient from "../../../../services/apollo";
import { graphql, withApollo,Query } from 'react-apollo';

import { compose } from 'recompose';

class SignupForm extends Component {
  state = {
    componentActive: "key",
    phone_or_email: "",
    email: "",
    password: "",
    loading: false,
    errorMensage: "",
    // body: {
    //   email: "",
    //   password: ""
    // }
    value: ""
  };

  

updateState = (state: Object) => {  
  this.setState(state);
}

submitForm = async () => {
  this.setState({loading: true})
}

  handleInputChange = async e => {
    await this.setState({ [e.target.name]: e.target.value });
  };
  _handleBulletStep = () => {};
  _handleAction = (e, next) => {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    const { phone_or_email } = this.state;
    if (
      validator.isEmail(phone_or_email) ||
      validator.isPhoneNumber(phone_or_email)
    ) {
      this.setState({ componentActive: next });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    
  }
  submitForm = () => {
    const er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
    this.setState({loading: true});
    let { phone_or_email, email, name, nickname, password, errorMensage } = this.state;
    if(
      phone_or_email && 
      email && 
      name && 
      nickname && 
      password && 
      phone_or_email.length > 12 && 
      email.length > 6 && name.length > 3 && 
      nickname.length > 3 && 
      password.length > 7 && 
      password.match(/[a-z]/i) && 
      password.match(/[0-9]/i) &&
      er.test(email) &&
      validator.isPhoneNumber(`+55${phone_or_email}`)){
      console.log("SUBMITED: ")
      this.setState({loading: false, errorMensage: ""});
      //this.props.submitForm();
      let phone = this.state.phone_or_email.replace(/[^0-9]+/g,'');
      let codigo = "";
      let body = {
        email: this.state.email,
        msisdn: `55${phone}`, 
        password: this.state.password,
        name: this.state.name,
        nickname: this.state.nickname,
        referrer: this.state.referrer,
        celco_incentive: "VIVO",
        celco_incentive_code: this.props.match.params && this.props.match.params.codigo ? this.props.match.params.codigo : ""
      }
      // console.log(body)
  
      this.props.mutate({mutation: REGISTER_MUTATION, variables: body, errorPolicy: 'all'})
      .then(({data, errors}) => {
        // console.log("DATA: ", data, "ERRORS: ", errors)
        if(errors){
          let {validation} = errors[0].extensions;
          if( validation.msisdn ){
            this.setState({errorMensage: 'O telefone já está cadastrado'});
          } else if( validation.email ){
            this.setState({errorMensage: 'O email já está cadastrado!'});
          } else if( validation.name ){
            this.setState({errorMensage: 'O nome já está cadastrado'});
          } else if( validation.nickname ){
            this.setState({errorMensage: 'O nome de usuário já está cadastrado'});
          }
          this.setState({loading: false})
        } else {
          let { createUser } = data;
          if(createUser){
            // createUser.subscribed = true;
            localStorage.setItem('UserAccount', JSON.stringify(createUser))
            localStorage.setItem('user_token', createUser.access_token)
            window.location.href = window.location.origin;
            sendMessageToUser('Você foi cadastrado com sucesso!')
          }
          this.setState({loading: false})
        }
      })
      .catch((erro) => {
        // console.log(erro)
        this.setState({loading: false})
      })
    }else{
      console.log("SUBMIT DO Not finalizaeRegistration", validator.isPhoneNumber(phone_or_email));

      if(phone_or_email === "" || phone_or_email.length <= 6 || !validator.isPhoneNumber(`+55${phone_or_email}`)){
        this.setState({errorMensage: 'Você precisa preencher seu número corretamente'});
      }
      if(email === "" || email.length <= 12 || !er.test(email)){
        this.setState({errorMensage: 'Você precisa preencher seu email corretamente'});
      }
      else if( name === undefined || name.length <= 3) {
        this.setState({errorMensage: 'Você precisa preencher seu nome corretamente'});
      }
      else if(nickname === undefined || nickname.length <= 3 || nickname.split(" ").length > 1) {
        this.setState({errorMensage: 'Você precisa preencher seu nome de usuário corretamente'});
      }
      else if(password === undefined || password.length < 8 || !password.match(/[a-z]/i) || !password.match(/[0-9]/i)) {
        this.setState({errorMensage: 'Sua senha precisa ter, no mínimo, 8 caracteres e conter letras e números'});
      }
      this.setState({loading: false});
    }
  }
  render() {
    return <Container>
      <form onSubmit={this.onSubmit}>
        <div className="auth-logo">
          <img src={LogoDefault} alt="logo ola" />
        </div>
        { this.state.loading &&
        <div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center",position: "absolute", backgroundColor:"#fff",top:0,left:0,right:0,bottom:0,zIndex:999}}>
          <img src={LOADING} />
        </div>
        }
        <Text
          typeClass="auth-subtitle"
          content="A Vivo e Ola Podcasts se juntaram para te presentear com 30 dias de acesso premium ao aplicativo Ola Podcasts. Complete o cadastro abaixo e tenha acesso a milhares de conteúdos agora mesmo! E mais, fique ligado na sua caixa de mensagens (SMS) para participar de desafios e ganhar bônus de internet para navegar no aplicativo durante todo mês de fevereiro!"
        />
        <Title size="1" content="Cadastre-se já!" typeClass="auth-title" />
        {
          this.state.errorMensage !== "" &&
          <Text
            typeClass="errorMensage"
            content={this.state.errorMensage}
          />
        }
         <InputMask 
          mask="(99) 99999-9999" 
          name="phone_or_email"
          type="text"
          labelContent=""
          width="100%"
          labelClasses=""
          placeholder="Telefone*"
          borderRadius="3px"
          background="var(--color-third)"
          border="1px solid var(--color-third)"
          value={this.state.phone_or_email}
          onChange={ 
            (event) => { 
              this.setState({phone_or_email: event.target.value, errorMensage: ""}); 
              this.handleInputChange(event);
            }
          }
        />
        <Input
          name="email"
          type="text"
          labelContent=""
          width="100%"
          labelClasses=""
          placeholder="Email*"
          borderRadius="3px"
          background="var(--color-third)"
          border="1px solid var(--color-third)"
          value={this.state.email}
          onChange={ 
            (event) => { 
              this.setState({email: event.target.value, errorMensage: "" }); 
            }
          }
        />
        <Input
          name="name"
          type="text"
          labelContent=""
          width="100%"
          labelClasses=""
          placeholder="Nome Sobrenome*"
          borderRadius="3px"
          background="var(--color-third)"
          border="1px solid var(--color-third)"
          value={this.state.name}
          onChange={ 
            (event) => { 
              this.setState({name: event.target.value, errorMensage: "" }); 
            }
          }
        />
        <Input
          name="nickname"
          type="text"
          labelContent=""
          width="100%"
          labelClasses=""
          placeholder="nome de usuário*"
          borderRadius="3px"
          background="var(--color-third)"
          border="1px solid var(--color-third)"
          value={this.state.nickname}
          onChange={ 
            (event) => { 
              this.setState({nickname: event.target.value, errorMensage: "" }); 
            } 
          }
        />
        <Input
          name="password"
          type="password"
          labelContent=""
          width="100%"
          labelClasses=""
          placeholder="crie sua senha*"
          borderRadius="3px"
          background="var(--color-third)"
          border="1px solid var(--color-third)"
          value={this.state.password}
          onChange={ 
            (event) => { 
              this.setState({password: event.target.value, errorMensage: "" }); 
            } 
          }
        />
        <Button
          type="button"
          className="auth-button"
          Background="var(--color-first)"
          Color="var(--color-zero)"
          loading={this.props.loading}
          title="Criar uma Conta"
          onClick={() => this.submitForm()}
        />
      </form>
    </Container>;
  }
}

export default compose(graphql(REGISTER_MUTATION))(withRouter(SignupForm));
