import React, { Component } from "react";

//styles
import { Container, AuthForm, PlayerContainer } from "./styles";

//elements
import { Title, Text } from "../../../../elements/Typography";
import { Wrapper, Content } from "../../../../elements/Layout";

//components
import { SignupForm } from "../../../../components/Forms";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

//assets
import PlayerImage from "../../../../assets/img/player-image2.png";
import OlaFrontWeb from "../../../../assets/img/OlaFrontWeb.jpg";

export default class Signup extends Component {
  render() {
    //GOOGLE ANALITCS
    const trackingId = "UA-154124614-1"; 
    ReactGA.initialize(trackingId);
    const history = createBrowserHistory();
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
    //FIM
    return (
      <Container>
       
        <Wrapper justifyContent="space-between">
          {/* <PlayerContainer className="player-container">
            <Title
              size="3"
              content="Efetue o seu cadastro e ganhe 30 dias grátis."
              typeClass="player-container_subtitle"
            />
            <Text
              content="Efetue o seu cadastro e ganhe 30 dias grátis"
              typeClass="player-container_subtitle"
            />
            <img src={PlayerImage} alt="player img" />
          </PlayerContainer> */}

          <AuthForm className="auth">
            <SignupForm />
          </AuthForm>
        </Wrapper>
        {/* <Footer classFooter="auth-footer" direction="horizontal" /> */}
      </Container>
    );
  }
}
