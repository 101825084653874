import styled from "styled-components";

export const Container = styled.section`
  min-width: 320px;
  max-width: 534px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  color: var(--color-negative-darken);
  background: var(--color-zero);
  display: flex;
  padding: 0 27.5px 27.5px;

  .auth-logo {
    height: auto;
    width: 100% !important;
    margin: 0 !important;
    text-align: center;
    img {
      width: 200px;
    }
  }
  .auth-title {
    font-family: "Avenir-Light";
    font-size: 30px;
    color: #313131;
    line-height: 40px;
    letter-spacing: -1.086957px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .auth-subtitle {
    font-family: "Avenir-Book";
    font-size: var(--size-small);
    line-height: 20px;
    letter-spacing: -0.336px;
    text-align: center;
    margin-bottom: 20px !important;
  }

  .auth-or {
    text-align: center;
    margin: var(--spacing-very-small);
  }
  .auth-button {
    height: 45px;
    font-family: "Avenir-Medium";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.636364px;
    margin-bottom: 5px;
  }

  .auth-login {
    display: flex;
    justify-content: center;
    height: 20px;
    margin-top: 40px;
    font-family: Avenir-Medium;
    font-size: 14px;
    letter-spacing: -0.34px;

    .login-button {
      width: 34.59px;
      height: 19px;
      color: #0e53db;
      font-family: "Avenir-Medium";
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-decoration: underline;
      letter-spacing: -0.336px;
      margin-left: 5px;
    }
  }
  input {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #d1d3d7;
    background: #f6f8fc;
    margin-bottom: 8px;
  }
  .errorMensage {
    color: #FF0000;
    text-align: center;
    margin-bottom: 10px;
  }
  @media (min-width: 600px) {
    & {
      max-width: 535px;
      max-height: 660px;
    }
  }
`;

export const SignupStepWrapper = styled.form`
  .confirmation-input {
    border: none;
    border-bottom: 1px solid var(--color-negative-lighten);
    background: var(--color-zero);
    width: 36px;
    margin-right: 2px;
    border-radius: 0;
    color: var(--color-negative-lighten);
  }
`;
