import styled from "styled-components";

export const Container = styled.section`
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Header = styled.header`
    margin: 0;
    padding: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`



export const Main = styled.main`
    padding: 50px 0;
    margin: 0;
    max-width: 1080px;
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
        padding: 32px 16px;
    }
    `

export const Title = styled.h1`
    margin: 0;
    font-size: 32px;
    margin-bottom: 25px;
    
    @media (max-width: 960px) {
        font-size: 24px;
    }
`

export const Subtitle = styled.h2`
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    margin: 32px 0 18px;

    &.spaced {
        padding-left: 30px;
    }
`

export const Paragraph = styled.p`
    font-size: 14px;
    text-align: justify;

    strong {
        font-weight: 700;
    }

    &.italic{
        font-style: italic;
    }
`

export const Table = styled.table`
    margin: 0;
    width: 100%;
`

export const Thead = styled.thead`
    margin: 0;

    td {
        padding: 10px 0;
        text-align: center;
        border: 1px solid #000;
        background-color: #00000010;
    }
    
    `

export const Tbody = styled.tbody`
    margin: 0;
    
    td {
        padding: 10px 0;
        text-align: center;
        border: 1px solid #000;
        background-color: #2196F399;
    }
`

export const Ol = styled.ol`
    margin: 0;
    list-style: lower-roman !important;
    padding-left: 46px;

    li {
        list-style-type: lower-roman;
    }
`

export const Rodape = styled.div`
    color: #7E7E7E;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;

    @media (max-width: 992px) {
        & {
            padding-left:0; 
            padding-right:0; 
        }
      }`