import React, { Component } from "react";
import M from "materialize-css";

import { Close } from "styled-icons/material";
import  { BlackMask }  from "../../components/BlackMask";

export default class ModalBlackMask extends Component {
  state = {
    data: []
  };
  componentDidMount() {}
    render() {
      return (
        <div id="backmask-modal" className={`modal backmask-modal`}>
          <BlackMask />
        </div>
      );
    }
}
