import React from "react";

import { ContentBannerContainer } from "./ContentBanner.style";

export const ContentBanner = ({
  bannerImage = ""
}) => (
  <ContentBannerContainer>
    <div className="contentBanner">
      <img src={bannerImage} className="contentBanner"/>
    </div>
  </ContentBannerContainer>
);
