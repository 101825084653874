import React, { Component } from 'react';
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

import { Title } from "../../elements/Typography";
import { Tag } from "../../elements/Tag";

import { ChannelCard } from "../ChannelCard";
import {CardComponent} from "../CardComponent";

import { Container } from './Category.styles';

export default class Category extends Component {
    constructor(props){
        super(props);
    }   
    handleScroll = debounce(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop
            > document.documentElement.offsetHeight-20
        ) {   
            this.props.onScrollMore();
        }else{
            //// console.log(`NAO CHEGOU ATE OP FINAL: ${window.innerHeight + document.documentElement.scrollTop} > ${document.documentElement.offsetHeight-5}`);
        }
    }, 100);

    componentDidMount() {  
        var style = document.createElement('style');
        style.innerHTML = `
            html, body, #root {
                height: auto;
            }
        `;
        document.head.appendChild(style);
        window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    render() {
        let {
            topChannels,
            topEpisodes,
            mainCategories,
            episodes,
            latestEpisodes,
            onButtonMore,
            onScrollMore
        } = this.props;

        return(
            <Container>
                <div className="content-header">
                    <Title size="4" typeClass="title" content="Categoria"/>
                    <Tag className="type-category">{mainCategories[0].name}</Tag>
                </div>
                <div className="content-channel">
                    {
                        topChannels.data.map((channel) => 
                            <div className="column">
                                <ChannelCard 
                                    key={channel.id}  
                                    channel={channel.channel}
                                    className="boxChannel"
                                />
                            </div>
                        )
                    }
                    { topChannels.has_more_pages&&
                    <div className="content-link">
                        <Link to="#" onClick={onButtonMore} className="verMais">
                            VER MAIS CANAIS
                        </Link>
                    </div>
                    }
                </div>
                <div className="content-premium">
                    <Title size="6" typeClass="title" content="CONTEÚDO PREMIUM"/>
                    <div className="control-premium">
                        {
                            episodes.data.map((premium) => {
                                return(
                                    <CardComponent 
                                    key={premium.id} 
                                    episode={premium} />
                                )
                            }
                        )}
                    </div>
                </div>
                <div className="content-alta">
                    <Title size="6" typeClass="title" content="Em Alta"/>
                    <div className="control-alta">
                        {
                            topEpisodes.data.map((episode, index) => <CardComponent 
                                key={episode.id} 
                                episode={episode.episode} />
                            )
                        }
                    </div>
                </div>
                <div className="content-lancamentos">
                    <Title size="6" typeClass="title" content="Últimos lançamentos"/>
                    <div className="content">
                        <div className="control-lancamentos">
                            {
                                latestEpisodes.data.map((latestEpisode) => {
                                    // console.log(latestEpisode);
                                        return(
                                            <CardComponent 
                                            horizontal
                                            showDetails
                                            key={latestEpisode.id} 
                                            episode={latestEpisode} />
                                        )
                                }
                            )}
                        </div>
                        <div className="content-propaganda"></div>
                        </div>
                </div>
            </Container>
        )
    }
}
