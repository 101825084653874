import React, { Component } from "react";
import apolloClient from "../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';


//styles
import M from 'materialize-css';
import { Container } from "./ModalAddFeed.style.js";
import LogoDefault from "../../assets/img/ola_logo_vertical.png";
import { Title, Text } from "../../elements/Typography";
import { Link } from "react-router-dom";
import { Button } from '../../elements/ButtonElement';

//assets

import { AddFeed } from "./AddFeed";
import { ShowFeed } from "./ShowFeed";

import { sendMessageToUser } from '../../common';
import { CURRENT_PLAYBACK} from '../../services/graphql/query';
import { EXTERNAL_FEED_MUTATION  } from '../../services/graphql/mutations';


class ModalAddFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFormScreen: "add-feed",
            loading: false,
            searchUrl: '',
        }
        document.addEventListener("modal-addFeed", this.goToAddFeed.bind(this));
    }

    goToAddFeed = ({detail}) => {
        // console.log("Cheguei aqui: ",detail)
        this.setState({modalFormScreen: 'add-feed', searchUrl: detail})        
    }

    componentDidMount(){
        // var elems = document.querySelectorAll('.modal');
        // var instances = M.Modal.init(elems);
    }

    setModalScreen(screen){
        this.setState(state => ({ modalFormScreen: screen }))
    }
    updateState = (state: Object) => {  
        this.setState(state);
    }

    submitForm = async () => {
        this.setState({loading: true})
        switch(this.state.modalFormScreen){

            case "add-feed":
                    const operator_body = {
                        external_feed: this.state.busca
                    }
                    // console.log("INSERIR: ",operator_body);
                    this.props.mutate({mutation: EXTERNAL_FEED_MUTATION, variables: operator_body, errorPolicy: 'all'})
                    .then(({data, errors}) => {
                        if(errors){
                            // console.log("ERROR: ", errors)
                            let { validation } = errors[0].extensions;
                            let msgValid = validation.external_feed[0];
                            if(msgValid.includes('feed already in platform')){
                                const [msg, codename] = msgValid.split('||');
                                // console.log("Validacao: " + msg + "  id: " + codename)
                                window.location.href = "/channels/" + codename;
                            }
                            this.setState({loading: false});
                            this.setState({error: true});
                            this.setModalScreen("error-feed");
                            
                        } else {
                            // console.log("DATA: ", data)
                            if(data && data.addExternalFeed){
                                this.setState({loading: false});
                                this.setState({error: false});
                                this.setState({externalFeed: data.addExternalFeed});

                                this.setState({externalFeed: data.addExternalFeed});
                                window.location.href = "/externalFeed/" + data.addExternalFeed.id;

                                this.setModalScreen("show-feed");
                            }
                            // if(data.resetPassword && data.resetPassword == 'ok'){
                            //     sendMessageToUser('Um email foi enviado para: ' + this.state.phone_or_email);
                            //     this.setState({loading: false});
                            //     this.setModalScreen("login-password");
                            // }
                           
                        }
                    })              

          
                
            break;
        }
        this.setState({loading: false})
    }

    render() {
        // console.log("ESTADO ZICA", this.state);
        return (
            <Container>
                <div id="addFeed" className="modal" >
                    <div className="auth-logo">
                        <img src={LogoDefault} alt="logo ola" />
                    </div>
                    { this.state.modalFormScreen == "add-feed" &&
                        <AddFeed setModalScreen={(screen)=> this.setModalScreen(screen)} searchUrl={this.state.searchUrl} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "show-feed" &&
                        
                        <ShowFeed setModalScreen={(screen)=> this.setModalScreen(screen)} state={this.state} updateState={this.updateState} submitForm={this.submitForm} loading={this.state.loading} />
                    }
                    { this.state.modalFormScreen == "error-feed" &&
                        <>
                            <Title size="1" content="Putz!" typeClass="auth-super-title" />
                            <Title size="2" content="Não conseguimos adicionar seu canal automaticamente." typeClass="auth-title" />
                            <Title size="4" content="Mas calma, nossa equipe vai verificar e entrar em contato com você." typeClass="auth-subtitle" />
                            <Button
                                className={`btn subscribe-button button-primary`}
                                onClick={() => { this.setModalScreen('add-feed') } }
                            >
                                Voltar
                            </Button>
                            {/* <Link onClick={() => this.setModalScreen('add-feed')} >Voltar</Link> */}
                        </>
                    }
                </div>
            </Container>
        )
    }
}

export default compose(graphql(EXTERNAL_FEED_MUTATION))(ModalAddFeed);
