import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    .imgRegister{
        width: 47.17%;
        align-self: center;
        margin-bottom: 20px;
    }
    .auth-title {
        font-family: "Avenir";
        font-size: 30px;
        line-height: 42px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 7px;
        color: #000000;
    }
    @media (max-width: 800px) {
        & {
            .imgRegister{
                width: 67.17%;
                align-self: center;
                margin-bottom: 20px;
            }
            .auth-title {
                font-family: "Avenir";
                font-size: 15px;
                line-height: 15px;
                font-weight: 900;
                text-align: center;
                margin-bottom: 7px;
                color: #000000;
            }
        }
    }
    .icon {
        margin-right: var(--size-very-small);
        color: var(--color-zero);
        height: 22px;
    }
    hr {
        height: 2px;
        border: none;
        background-color: #2296f3;
        margin: 9px 0 26px 0;
    }
    input, .auth-button {
        margin-bottom: 19px;
    }
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
    .positionOr {
        color: #2296f3;
        margin-top: -39px;
        margin-bottom: 17px;
        justify-content: center;
        display: flex;
        span {
            background-color: #fafafa;
            padding: 0 7px;
            font-weight: 900;
            letter-spacing: 1px;
        }
    }
    .auth-login {
        font-family: Avenir;
        font-size: 12px;
        line-height: 16px;
        a {
            text-transform: none;
            color: #2196F3;
            margin-left: 3px;
        }
    }
    form {
        display: flex;
        flex-direction: column;
    }
`;
export const SocialContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 19px;
    align-items: center;
    justify-content: space-between;
    a:first-child {
        margin-left: 0;
        margin-right: 5px;
    }
    a:last-child {
        margin-right: 0;
        margin-left: 5px;
    }
    a {
        padding: 0 5px;
        font-size: 12px;
        min-height: 3rem;
        margin: 0 0 8px 0;
        cursor: pointer;
        background: var(--color-zero);
        color: #2196F3;
        border: 1px solid #2196F3;
        border-radius: var(--radius-small);
        text-transform: uppercase;
        font-family: "Avenir";
        display: flex;
        flex: 1;
        .icon {
            height: 35px;
            color: #2196F3;
        }
        span{
            display: flex;
            align-items: center;
        }
    }
`;
