import styled from "styled-components";

export const Container = styled.div`
width: 400px;
padding-top: 42px;
.modal-title {
    font-family: Avenir;
    font-size: 32px;
    line-height: 42px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 40px;
    color: #666;
    text-align: center;
    text-transform: uppercase;
    .campoInput {
        width: auto;
        display: flex;
        height: 35px;
        line-height: 35px;
    }
}
`;
