import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  .label-name {
    font-family: Avenir-Medium;
    font-size: 18px;
    color: #4a4a49;
  }
`;

export const Wrapper = styled.article`
  display: inline-flex;

  flex-direction: column;
  width: 50%;
  max-width: 600px;
  padding: var(--spacing-medium);
  .mydata-form-input {
    min-width: 152px;
  }
  .mydata-form-label {
    margin-top: var(--spacing-small);
  }

  .mydata-form-button {
    max-width: 152px;

    margin-left: auto;
    margin-top: var(--spacing-small);
  }
`;
