import React, { Component } from "react";

import { Header } from "../../components/Header";
import { NavBar } from "../../components/NavBar";
import { ADMIN_USERS } from "../../constants/users";
// import { Container } from './styles';

const BREADCRUMB = [
  {
    displayName: "Home",
    href: "/",
    class: "breadcrumb"
  },
  {
    displayName: "Canais",
    href: "/",
    class: "breadcrumb"
  },
  {
    displayName: "Gerenciar",
    href: "/",
    class: "breadcrumb"
  }
];

export default class ChannelAdmin extends Component {
  render() {
    return (
      <>
        <div>teste</div>
      </>
    );
  }
}
