import styled from "styled-components";

const Container = styled.div`
    min-width: 49%;
    height: 0;
    padding-bottom: 49%;
    margin: 0 1% 1% 0;
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
    .thumb_image {
        display: block;
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
        width: 100%;
        height: 100%;
    }
`;
const ThumbConteiner = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        color: #000;
        width: 20px;
        height: 20px;
        background-color: #fff;
        box-shadow: 0 0 4px #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            width: 60%;
        }
    }
    .redirectTo {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
    }
    .episodeFull {
        min-width: 100%;
        padding-bottom: 100%;
    }
    .episodeLine {
        min-width: 100%;
    }
`;

export { Container, ThumbConteiner };
