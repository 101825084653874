import React, { Component } from "react";

import { Container } from "./PlanosAssinatura.styles";
import M from "materialize-css";

import Layout from "../../../hoc/Layout/Layout";
// import VIVO from "../../../assets/img/vivo_02.png";
import VIVO from "../../../assets/img/vivo_03.jpg";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class PlanosAssinatura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plano: 'mensal'
    };   
  }

  mudaPlano = (e) => {
    // console.log("Seta: ",e.target.value);
    this.setState({'plano':e.target.value});
  }

  render() {
    return (
      <Layout>
        <Container>
            {/* <img src={VIVO} style={{width: "100%"}} /> */}
              <div className="caixa-planos">
                <div className="plano1">

                <div style={{marginTop:"-8%",marginBottom:"4%", color: "#7F7F7F",fontSize: "12px",fontWeight: "900",lineHeight: "16px"}}>Crie seu cadastro</div>
                  <div style={{color: "#0E53DB",marginBottom:"3%",fontSize: "40px",fontWeight: "900",letterSpacing: "-1.45px",	lineHeight: "26px"}}>GRÁTIS</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Pode baixar até 20 podcasts Offline</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Pode criar até 3 playlist</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Pode criar apenas 1 canal</div>
                  
                  {/* <div className="botaoAssinar"><a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLA_CC_ANUAL&returnUrl=https://" + window.location.host + "/resplanding/"}>ASSINAR</a></div> */}

                  
                </div>
                <div className="plano2">
                  
                <div style={{marginTop:"-10%",marginBottom:"4%", color: "#7F7F7F",fontSize: "12px",fontWeight: "900",lineHeight: "16px"}}>MENSAL</div>
                  <div style={{color: "#0E53DB",marginBottom:"12%",fontSize: "40px",fontWeight: "900",letterSpacing: "-1.45px",	lineHeight: "26px"}}>R$9,90</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Crie canais ilimitados</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Ouça podcasts offline ilimitados</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Crie playlists ilimitadas</div>
                  <div id="assinar_plano_btn" className="botaoAssinar"><a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLA_CC_MENSAL&returnUrl=https://" + window.location.host + "/resplanding/"}>ASSINAR</a></div>

                </div>
                <div className="plano3">
                <div style={{marginBottom:"4%", color: "#7F7F7F",fontSize: "12px",fontWeight: "900",lineHeight: "16px"}}></div>
                  <div style={{color: "#0E53DB",marginBottom:"4%",fontSize: "40px",fontWeight: "900",letterSpacing: "-1.45px",	lineHeight: "26px"}}>Operadora</div>
                  <div className="textoSimples"> 
                      <input type="radio" name="planos" 
                            value='mensal' 
                            className="radio"
                            id="ass_op_mensal"
                            checked={this.state.plano === 'mensal'} 
                            onChange={this.mudaPlano} /> MENSAL 
                      <input type="radio" name="planos" 
                            value='semanal'
                            id="ass_op_semanal"
                            className="radio"
                            checked={this.state.plano === 'semanal'} 
                            onChange={this.mudaPlano} /> SEMANAL       
                            
                  </div>
                  <div style={{color: "#0E53DB",marginBottom:"4%", marginTop:"4%", fontSize: "40px",fontWeight: "900",letterSpacing: "-1.45px",	lineHeight: "26px"}}>
                    {this.state.plano == 'mensal' ? "R$9,90" : "R$4,90"}
                  </div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Crie canais ilimitados</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Ouça podcasts offline ilimitados</div>
                  <div className="textoSimples"><i style={{color: "#65B32E"}} className="material-icons">check</i> Crie playlists ilimitadas</div>
                  <div id="assinar_plano_operadora_btn" className="botaoAssinar">
                    {this.state.plano == 'mensal' ? 
                      <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_MENSAL&id_tipo_assinatura=283&returnUrl=http://" + window.location.host + "/resplanding/"}>ASSINAR</a>
                    :
                      <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&id_tipo_assinatura=282&returnUrl=http://" + window.location.host + "/resplanding/"}>ASSINAR</a>

                    }
                  </div>
                  {/* <div className="botaoAssinante"><a href={"http://wap.toing.com.br/landing/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>JA SOU ASSINANTE</a></div> */}
                  <div id="sou_assinante_operadora_btn" className="botaoAssinante"><a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>JA SOU ASSINANTE</a></div>

                </div>
                
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center"}}> <div id="sou_assinante_tim_btn" className="botaoAssinante"><a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLA_TIM_BUNDLE&page=login&returnUrl=https://" + window.location.host + "/resplanding/"}>JA SOU ASSINANTE - TIM CARRO WIFI</a></div></div>
        </Container>
      </Layout>
    );
  }
}
