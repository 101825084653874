import styled from "styled-components";

export const Container = styled.div`
    
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
    form {
        display: flex;
        flex-direction: column;
    }
`;
