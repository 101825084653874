import React, { Component } from 'react';
import { Link } from "react-router-dom";

//graphql
import { Mutation } from 'react-apollo';
import CategoryFollowMutation from './ChannelCard.graphql';
import { FOLLOW_EXTERNAL_FEED_MUTATION  } from '../../services/graphql/mutations';

//styles
import {
    Container,
    ChannelBanner,
    ChannelCardStyle
} from "./ChannelCard.style";

import { sendMessageToUser } from '../../common';

import { Button, ButtonGroup } from "../../elements/CTA";
import { ACCESS_TOKEN, USER_ACCOUNT } from '../../constants';
import { Verified } from "styled-icons/octicons";
import { Card } from "../../elements/Card";
import { ContentBanner } from "../../elements/Banner/ContentBanner/ContentBanner";
import M from 'materialize-css';


export default class ChannelCard extends Component {
    render(){
        let { id, cover, thumbnail, name, description, codename, subscribers, following, premium, flag } = this.props.channel;
        let { scope } = this.props;
        let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
        // // console.log("CHANNEL: ",this.props.channel)
        return(
            <ChannelCardStyle className="col s12 m6 xl4 channel-card-container">
                {/*<div className="channel-card-cover" style={{backgroundImage: `url(${cover})`}}>
                <div className="channel-card-cover">
                </div>
                */}
                <div className="channel-card-content">
                    {flag != 'feed' ?
                        <Link to={`/channels/${codename}`}>
                            <Card w="97px" h="97px" cardImg={thumbnail}/>
                        </Link>
                    :
                        <Link to={`/externalfeed/${id}`}>
                            <Card w="97px" h="97px" cardImg={thumbnail}/>
                        </Link>
                    }
                    <div className="card-wrapper_text">
                        <div className="card-title_text">
                            {flag != 'feed' ?
                                <Link to={`/channels/${codename}`}>
                                    {
                                        name.length < 60
                                        ? name
                                        : `${name.substr(0,57)}...`
                                    }
                                </Link>
                             :
                                <Link to={`/externalfeed/${id}`}>
                                {
                                    name.length < 60
                                    ? name
                                    : `${name.substr(0,57)}...`
                                }
                                </Link>
                            }
                        </div>
                        <div className="card-codename_wrapper">
                            <span className={`channel-codename channel-${premium ? 'premium' : flag}`}>
                                {flag != 'feed' ?
                                    <Link to={`/channels/${codename}`}>
                                    {
                                        codename.length < 15
                                        ? codename
                                        : `${codename.substr(0,12)}...`
                                    }
                                    </Link>
                                    :
                                    <Link to={`/externalfeed/${id}`}>
                                    {
                                        name.length < 15
                                        ? name
                                        : `${name.substr(0,12)}...`
                                    }
                                    </Link>
                                 }
                            </span>
                            <div id="inscricao_channel_btn" className='card-subscribers'>
                                <Mutation 
                                    mutation={flag != 'feed' ? CategoryFollowMutation : FOLLOW_EXTERNAL_FEED_MUTATION} 
                                    variables={{ 
                                        id: id, 
                                        unfollow: following}}>
                                {postMutation => (
                                    !localStorage.getItem(ACCESS_TOKEN) ?
                                        <Button onClick={() => sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.')} className='card-subscribers_button subscribed' Color='white' Background='blue'>
                                            Inscreva-se
                                        </Button>                                        
                                    :
                                        premium && !userAccount.subscribed ?
                                            <Button onClick={() => {
                                                var elems = document.getElementById('modal-premium');
                                                var instances = M.Modal.init(elems, {
                                                    onOpenStart: function () {
                                                        var ev = new CustomEvent("abre-premium", { 'detail': "detalhesAqui" });
                                                        document.dispatchEvent(ev);
                                                    }
                                                });
                                                var instance = M.Modal.getInstance(elems);
                                                instance.open();
                                            }} className='card-subscribers_button subscribed' Color='white' Background='blue'>
                                                Inscreva-se
                                            </Button>                                           
                                        :
                                            !following ?
                                                <Button onClick={postMutation} className='card-subscribers_button' Color='white' Background='blue'>
                                                    Inscreva-se
                                                </Button>
                                            :
                                            <Button onClick={postMutation} className='card-subscribers_button subscribed' Color='white' Background='blue'>
                                                Inscrito
                                            </Button>
                                )
                                }
                                </Mutation>
                                {/*<span className="card-subscribers_text">{subscribers}</span>*/}
                            </div>     
                        </div>
                    </div>
                </div>
            </ChannelCardStyle>
        )
    }
}
