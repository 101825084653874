import gql from "graphql-tag";

const PlayListQuery = gql`
query($playlistId: Int){
    myPlaylists( id: $playlistId, page: 1, limit: 1 ){
        data {
            name
            episodes_with_order{
                episode{
                    id
                    thumbnail(width: 300, height: 300)
                    title
                    rating
                    content
                    premium
                    original
                    external   
                    channel{
                        id
                        thumbnail(width: 300, height: 300)
                        codename
                        flag
                        main_category {
                            id
                        }
                    }
                    play_count
                    publishDate
                    duration
                    description
                }
            }
        }
        current_page
        has_more_pages
    }
}
`;

const UpdatePlayListQuery = gql`
    mutation($id: Int, $type: String, $episode_id: Int!, $order: Int!){
        changeEpisodeOrderFromPlaylist(id: $id, type: $type, episode_id: $episode_id, order: $order){
            id
            name
            episodes_with_order{
                order
                episode{
                    id
                    thumbnail(width: 300, height: 300)
                    title
                    rating
                    content
                    premium
                    original
                    external   
                    channel{
                        id
                        thumbnail(width: 300, height: 300)
                        codename
                        flag
                        main_category {
                            id
                        }
                    }
                    play_count
                    publishDate
                    duration
                    description
                }
            }
        }
    }
`;

export { PlayListQuery, UpdatePlayListQuery };
