import styled from "styled-components";
export const ChannelBanner = styled.article`
  height: 30px;
  margin-top: var(--spacing-default);
  .contentBanner {
    width: 440px;
    height: 110px;
  }
`;

export const ChannelCardStyle = styled.article`
  height: 175px;
  .row .col.s12 {
      width: 75%;
  }
  .overlay {
    margin-left: 14px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .card-wrapper_text {
    
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
    /* justify-content: space-between; */
    margin-top: 5px;
    margin-left: var(--spacing-medium);
  }
  .card-title_text {
    width: 100%;
    min-height: 51px;
    font-size: var(--size-small);
    margin-bottom: var(--spacing-small);
    line-height: 20px;
    letter-spacing: 0.05em;
    font-size: 20px;
    font-style: bold;
    color: var(--color-negative);
  }
  .card-codename_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    .card-badge {
      margin-left: 5px;
      color: #93cb6f;
      width: 13px;
    }
    .channel-codename {
      font-size: 0.65em;
    }
    .card-codename_text {
      
      line-height: 12px;
      font-size: 10px;
      text-transform: uppercase;
      color: var(--color-third-darken);
    }
  }
  .card-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .card-subscribers {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    .card-subscribers_text {
      font-size: var(--size-small);
      margin-right: var(--spacing-small);
      line-height: 14px;
      letter-spacing: 0.05em;
      white-space: nowrap;
      font-size: 10px;
      padding: 0 var(--spacing-medium);
      color: var(--color-negative);
    }
    .card-subscribers_button {
      justify-content: center;
      border-radius: 3px;
      font-size: 13px;
      margin-left: var(--spacing-small);
      padding: 0 12px;
      width: 100px;
      height: 100%;
      background: #2196F3;
      color: #fff;
      line-height: 29px;
    }
    .card-subscribers_button.subscribed {
      background: var(--color-third-lighten);
      color: var(--color-third-darken)
    }
  }
  .card-vertical {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    .card-vertical_text {
      font-size: var(--size-small);
      margin-right: var(--spacing-small);
      line-height: 14px;
      letter-spacing: 0.05em;
      white-space: nowrap;
      font-size: 14px;
      padding: 0px var(--spacing-medium);
      color: var(--color-negative);
    }
    .card-vertical_logo {
      width: auto;
      justify-content: center;
      border-radius: 5px;
      padding: 0px var(--spacing-medium);
      height: 100%;
    }
  }
`;
