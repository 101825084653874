import styled from "styled-components";

const Container = styled.div`
    min-width: 49%;
    height: 0;
    padding-bottom: 49%;
    margin: 0 1% 1% 0;
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
    .thumb_image {
        display: block;
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
        width: 100%;
        height: 100%;
    }
`;
const Thumb = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    .episodeFull {
        min-width: 100%;
        padding-bottom: 100%;
    }
    .episodeLine {
        min-width: 100%;
    }
`;

export { Container, Thumb };
