import React, { Component } from "react";
import { Link } from "react-router-dom";
import { sendMessageToUser } from '../../../common';

//styles
import { Container, SocialContent } from "./ForgotPassword.style.js";

//icons
import {
    Facebook,
    Google
} from "styled-icons/boxicons-logos";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';

import LOADING from "../../../assets/img/loading.gif";

//elements
import { Title, Text } from "../../../elements/Typography";

class ForgotPassword extends Component {
    state = {
        phone_or_email: '',
        password: ''
    }

    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }

    updateInput(input){
        this.props.updateState(input)
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        this.setState({loading: true});
        let { phone_or_email, password} = this.state;
        if(!phone_or_email.length || !phone_or_email.indexOf('@') > -0 || !phone_or_email.indexOf('.') > 0 ){
            sendMessageToUser('Você deve inserir um telefone ou email!')
            this.setState({loading: false});
        } else {
            this.props.submitForm();
        }
    }
    render(){
        if(this.state.loading){
            return (
                <Container>
                    <Title size="1" content="Estamos enviando o email, por favor aguarde." typeClass="auth-title" />
                    <div className="divImg"><img src={LOADING}/></div>
                </Container>
            )
        }else{
            return(
                <Container>
                    <Title size="1" content="Digite seu email ou telefone que enviaremos um email para recadastrar sua senha." typeClass="auth-title" />
                    <Input
                        id="phone_or_email"
                        name="phone_or_email"
                        type="text"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        placeholder="Seu email ou telefone"
                        borderRadius="3px"
                        background="var(--color-third)"
                        border="1px solid var(--color-third)"
                        value={this.state.phone_or_email}
                        onChange={ (event) => { this.setState({phone_or_email: event.target.value }); this.props.updateState({phone_or_email: event.target.value}) } }
                    />

                    <Button
                        type="submit"
                        className="auth-button"
                        Background="var(--color-first)"
                        Color="var(--color-zero)"
                        loading={this.props.loading}
                        title="Enviar"
                        onClick={() => {this.setState({loading: true}); this.submitForm()}}
                    >
                        Enviar
                    </Button>
                    
                </Container>
            )
        }
    }
}

export default ForgotPassword;
