import styled from "styled-components";

export const Container = styled.div`
    /* font-weight: 600;
    display: flex;
    font-size: 0.9rem;
    width: 100%;
    padding-left: 250px;
    justify-content: flex-start;
    border-top: 1px solid #aaa;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #f9f9f9;
    margin-top: 20px;

    .links {
        li{
            list-style-type: disc;
            font-weight: 400;
            margin-left: 25px;
        }
    } */
    color: #7E7E7E;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left: 250px;

    @media (max-width: 992px) {
        & {
            padding-left:0; 
            padding-right:0; 
        }
      }

`;
