import styled from "styled-components";

export const Container = styled.section`
    .modal {
        background-color: #0e53db;
        max-width: 535px;
        max-height: 660px;
        padding: 10px 100px;
        overflow-Y: hidden;
        overflow-X: hidden;
    }
    .modalBackground {
        background-color: #0e53db;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Avenir;
        text-align: center;
    }
    header {
        margin-bottom: 10px;
    }
    .title {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
        display: flex;
        height: 6vh;
        justify-content: center;
        align-items: center;
    }
    span {
        color: #fff;
        font-weight: 100;
        font-size: 14px;
        display: flex;
        margin: 0 15% 1.5vh 15%;
    }
    .btnPremium {
        display: flex;
        background-color: #fff;
        color: #0e53db;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 25px;
        height: 50px;
        font-size: 15px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .btnNoPremium  {
        display: flex;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 25px;
        height: 50px;
        font-size: 15px;
        justify-content: center;
        align-items: center;
        color: #8ba3d3;
    }
    section {
        img{
            border-style: none;
            display: flex;
            margin: auto;
            width: 100%;
        }
    }
`;
