import React, { Component } from "react";

import {
  Container,
  Trends,
  PremiumBox,
} from "./Premium.styles";

import Banner from "../../../components/BannerComponent";
import { ListEmAlta } from "./ListEmAlta";
import { CarroselPremium } from "./CarroselPremium"

import { Title } from "../../../elements/Typography";
import { Wrapper } from "../../../elements/Layout";
import { ACCESS_TOKEN, USER_ACCOUNT } from '../../../constants';

//images
import PREMIUM_BADGE from "../../../assets/img/premiumBadge.png";
import BANNER_BOTTOM from "../../../assets/img/ola_15dias.jpg";

import Layout from "../../../hoc/Layout/Layout";


import { graphql } from 'react-apollo';
import { compose } from 'recompose';

import { TelaPremiumQuery } from './premium.graphql';
import debounce from "lodash.debounce";

import LOADING from "../../../assets/img/loading.gif";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";
import M from "materialize-css";

class Premium extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: false,
      loading: false,
      teste: null,
      page_channels: 1,
      hasMoreChannels: true,
    }
  }
  
  static getDerivedStateFromProps(props, state){
    let { data } = props;
    let new_state = state;
    if(!!data && data.channels && data.channels !== state.channels) new_state = { ...new_state, channels: data.channels }
    if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
    return new_state;
}

  abreModalCadastro = () => {
    var elems = document.getElementById('signup');
    // console.log("Elems: ",elems);
    var instances = M.Modal.init(elems, {
        onOpenStart: function () {
            var ev = new CustomEvent('modal-entrar', { "detail": "register"});
            document.dispatchEvent(ev);
      }
    });
    var instance = M.Modal.getInstance(elems);
    instance.open();
}

handleScroll = debounce(() => {
        const {
          loadChannels,
          state: {
            error,
            loading,
            hasMoreChannels,
          },
        } = this;

        // Bails early if:
        // * there's an error
        // * it's already loading
        // * there's nothing left to load
        if (error || loading || (!hasMoreChannels)) return;
  
        // Checks that the page has scrolled to the bottom
        if (
          window.innerHeight + document.documentElement.scrollTop
          > document.documentElement.offsetHeight - 10
        ) {

          loadChannels();
          
          
        }else{
        }
      }, 100);

      loadChannels = () => {
        if(!this.state.loading && this.state.hasMoreChannels){
          this.setState({loading: true});
          let page_channels = this.state.page_channels + 1;
          this.props.data.fetchMore({
            variables: {page_channels: page_channels },
            updateQuery: (prev, { fetchMoreResult }) => {
                if(fetchMoreResult && fetchMoreResult.channels && (fetchMoreResult.channels.data.length)){
                    this.setState({page_channels: page_channels });
                    return {
                      channels: {...fetchMoreResult.channels, data: [...prev.channels.data, ...fetchMoreResult.channels.data] },
                      // page_channels: page_channels
                    }
                } else {
                    this.setState({hasMoreChannels: false})
                    return {
                      channels: { ...prev.channels },
                    }
                }
            }
          })
        }
      };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

    // console.log("Minhas Props: ", this.props);
    return (
      !!this.props.data &&  !!this.props.data.episodes &&  !!this.props.data.episodes.data  && this.props.data.channels && this.props.data.channels.data ?
      <Layout>
        <Container>
          
          <div className="container">
            <PremiumBox className="premium-container">
            {!!this.props.data.top && !!this.props.data.top.data && <Banner slides={this.props.data.top.data} />}
              {/* <Banner slides={slidesFixos.data} /> */}
              <Trends className="trends">
                <Wrapper
                  alignItems="center"
                  justifyContent="flex-start"
                  className="trends-header"
                >
                  <img src={PREMIUM_BADGE} className="home-icon"/>
                  <Title size="5" typeClass="trend-title-premium" content="CONTEÚDOS ORIGINAIS" />
                </Wrapper>
                <Wrapper className="trend-list em-alta">
                
                  { 
                    !!this.props.data &&  !!this.props.data.episodes &&  !!this.props.data.episodes.data  &&
                      <CarroselPremium episodes={this.props.data.episodes.data} />
                  } 
                </Wrapper>
              </Trends>

              <Trends className="trends">
                <Wrapper
                  alignItems="center"
                  justifyContent="flex-start"
                  className="trends-header"
                >
                  {/* <TrendingUp className="home-icon" /> */}
                  <img src={PREMIUM_BADGE} className="home-icon"/>
                  <Title size="5" typeClass="trend-title-premium" content="CANAIS ORIGINAIS" />
                </Wrapper>
                <Wrapper className="trend-list em-alta trend-list-premium">
                  {
                    this.props.data && this.props.data.channels && this.props.data.channels.data &&
                    <ListEmAlta channels={this.props.data.channels.data} />
                  }
                  {/*
                    this.props.data && this.props.data.topEpisodes && this.props.data.topEpisodes.data &&
                    <CarroselEmAlta topEpisodes={this.props.data.topEpisodes.data} />
                  */}
                </Wrapper>
              </Trends>
            </PremiumBox>

{/* {(!localStorage.getItem(ACCESS_TOKEN) || !userAccount.subscribed) &&
            <Trends className="trends">
              <Wrapper className="trend-list content-banner">
              {!!this.props.data.bottom && !! this.props.data.bottom.data &&
              <div className="carousel ola-banner">
                  <a
                    className="carousel-item modal-trigger"
                    // onClick={() => {
                    //   let event = new CustomEvent('modal-entrar', { "detail": "register"});
                    //   document.dispatchEvent(event);
                    // }}
                    // href="/cadastro"
                    onClick={() => this.abreModalCadastro()}
                    style={{ width: "100%", display: "block" }}
                  >
                    <div
                      className="slide-item slide-cover"
                      style={{
                        backgroundImage: `url(${BANNER_BOTTOM})`
                      }}
                    ></div>
                  </a>
              </div>
              }
              </Wrapper>
            </Trends>
} */}

            {/* <Trends className="trends">
              <Wrapper className="trend-list content-banner">
              {!!this.props.data.bottom && !! this.props.data.bottom.data &&
              <div className="carousel ola-banner">
                  <a
                    className="carousel-item modal-trigger"
                    // onClick={() => this.openModalPremium()}
                    href="#modal-premium"
                    style={{ width: "100%", display: "block" }}
                  >
                    <div
                      className="slide-item slide-cover"
                      style={{
                        backgroundImage: `url(${this.props.data.bottom.data[Math.floor(Math.random()*this.props.data.bottom.data.length)].cover})`
                      }}
                    ></div>
                  </a>
              </div>
              }
              </Wrapper>
            </Trends> */}
          </div>
        </Container>
      </Layout>
      :
      <div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center"}}>
        <img src={LOADING} />
          {/*
          <svg svg="" className="loading" width="493px" height="420px" viewBox="0 0 493 420" style={{width:"40%"}}>
            <rect className="contentPurpleSmalGrenn" width="48" height="62" rx="10" ry="10" fill="#00AE4D" x="0" y="128"></rect>
            <rect className="contentBlue" width="48" height="201" rx="10" ry="10" fill="#1B4178" x="74" y="90"></rect>
            <rect className="contentGreen" width="48" height="151" rx="10" ry="10" fill="#00AE4D" x="148" y="140"></rect>
            <rect className="contentRed" width="48" height="252" rx="10" ry="10" fill="#ED1C24" x="222" y="90"></rect>
            <rect className="contentOrange" width="48" height="252" rx="10" ry="10" fill="#F57E20" x="296" y="90"></rect>
            <rect className="contentLightBlue" width="48" height="151" rx="10" ry="10" fill="#00AEDF" x="370" y="140"></rect>
            <rect className="contentPurple" width="48" height="62" rx="10" ry="10" fill="#C21E8E" x="444" y="128"></rect>
          </svg>
          */}
      </div>
    );
  }
}

const propsToOptions = (props) => {
  return ({ 
    variables: { 
      //premium: true,
      original: true,
      limit: 50,
      limitChannel: 48,
      page_channels: 1,
      thumbWidth: 200, 
      thumbHeight: 200,
      coverWidth: 1200, 
      coverHeight: 300,
      top: 'premium-180620',
      bottom: 'bottom',
      orderAsc: false
    },
    fetchPolicy: 'no-cache'
  })
}

export default compose(graphql(TelaPremiumQuery, { options: propsToOptions }))(Premium);
