import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Container } from './styles';
// import { Container } from './styles';
import { Settings, Headset, AddCircleOutline } from "styled-icons/material";
import { Button } from '../../elements/ButtonElement';

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { FOLLOW_EXTERNAL_FEED_MUTATION  } from '../../services/graphql/mutations';
import defaultImage from "../../assets/img/thumbDefault.jpg";
import CoverImage from "../../assets/img/coverDefault.jpg";


const SubscriberComponent = ({loading, following, followers_count, actionFollow}) => (
  <>
    <Button
      loading={loading}
      className={`btn subscribe-button ${following ? 'button-secondary' : 'button-primary'}`}
      onClick={(event) => { event.preventDefault(); actionFollow() } }
    >
      {following ? 'Inscrito' : 'Inscreva-se'}
    </Button>
    <span className="subscribers-count">{followers_count > 0 ? `${followers_count} inscritos` : `Nenhum inscrito ainda. Inscreva-se agora` }</span>
  </>
)

class HeaderFeed extends Component {

  constructor(props){
    super(props);
    this.state = {
      channel: props.channel,
      loading: false,
    }
  }

  actionFollow = async () => {
    this.setState({loading: true})
    // console.log('actionFollow feed', this.state)
    let bodyConsult = { id: this.state.feed.id }
    if(this.state.feed.following){
      bodyConsult.unfollow = true
    }else{
      bodyConsult.unfollow = false
    }
    // console.log('actionFollow ', bodyConsult)
    let followFeed = await this.props.mutate({ variables: bodyConsult });
    // console.log('followFeed ', followFeed)
    this.setState({
      feed: followFeed.data.followExternalFeed,
      loading: false
    })
  }

  static getDerivedStateFromProps(props, state){
    let { data } = props;
    let new_state = state;
    if(!!data && !!data.feed &&data.feed.data[0] !== state.feed ) new_state = { ...new_state, feed: data.feed.data[0] }
    if(props.feed !== state.feed) new_state = { ...new_state, feed: props.feed }
    if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
    return new_state;
}

  render() {
    // console.log('HeaderFeed', this.props)
    const  feed  = this.props.feed;
    feed.codename = feed.name;

  
    return (
      <>
        <div className="row channel-header">
          <div
            style={{ backgroundImage: `url(${CoverImage})` }}
            className="channel-cover col s12"
          />
          <div className=" channel-container">
            <div className="channel-card col s12 m8 l8">
              <div className="channel-thumb">
                <img
                  className="responsive-img"
                  src={feed.thumbnail?feed.thumbnail:defaultImage}
                  alt={feed.name}
                />
              </div>
              <div className="channel-info">
                <strong className="channel-title">{feed.name}</strong>
                <div className={`channel-codename channel-${feed.flag}`}>{feed.codename}</div>
                <div className="channel-subscribe hide-on-med-and-down">
                  <SubscriberComponent loading={this.state.loading} following={feed.following} followers_count={feed.followers_count} actionFollow={this.actionFollow} />
                </div>
              </div>
            </div>

  
            <div className="col s12 hide-on-large-only  subscribers">
              <SubscriberComponent loading={this.state.loading} following={feed.following} followers_count={feed.followers_count} actionFollow={this.actionFollow} />
            </div>
            {/*
            <div className="col s12 hide-on-large-only  channel-verticals">
              <div className="channel-alo"></div>
            </div>
            */}
          </div>
        </div>
        
      </>
    );
  }
}

export default compose(graphql(FOLLOW_EXTERNAL_FEED_MUTATION))(HeaderFeed);
