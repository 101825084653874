import React, { Component } from "react";

//style
import { NavBarContainer, MenuWrapper, PlayerWrapper } from "./ProfileOptionBar.style";
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserActions from "../../store/ducks/user";

import { Cog } from "styled-icons/fa-solid";
import { sendMessageToUser } from "../../common";
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { VERIFY_EMAIL_MUTATION } from '../../services/graphql/mutations';

class ProfileOptionBar extends Component{
  
  constructor(props) {
    super(props);
    this.props.getMeRequest();
    this.state = {}
  }

  static getDerivedStateFromProps(props, state) {
    let { data } = props;

    let new_state = state;
    if (props.user && props.user !== state.user) new_state = { ...new_state, user: props.user };
    if (!!data && data.user && props.user !== state.user) new_state =  { ...new_state, user: data.user };
    if (!!data && data.loading !== state.loading) new_state = { ...new_state, loading: data.loading };
    return new_state;
  }


  render(){
      let { props } = this;
      let profile = {};
      const USER_ACCOUNT = 'UserAccount';
      const userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

      if(!!this.state.user && !!this.state.user.data.profile) profile = this.state.user.data.profile
      return(
        <NavBarContainer >
            <div className="topo-configs">
              {!!profile && <img src={profile.profilePicture}/>}
              <div className="direita-config">
                <div className="title">{!!profile && profile.name}</div>
                {(!userAccount || !userAccount.subscribed) ?
                  <div className="plan">
                    <span>Plano: Free</span>
                    <span>
                      <a href="/planos">Virar premium</a>
                    </span>
                  </div> 
                  :
                  <div className="plan"> 
                    <span>Plano: Premium</span>
                  </div>
                }
                {(!!userAccount && !!userAccount.email && !userAccount.email_verified_at) &&
                  <div className="plan">
                    <span>
                    <div onClick={() => {
                                    let email = {email: userAccount.email}
                                    this.props.mutate({variables: email})
                                    .then(() => {
                                      sendMessageToUser(`Enviamos um email para ${userAccount.email}, siga os passos descritos nele para verificar sua conta.`);
                                    })
                                    .catch((error) => {
                                      sendMessageToUser('Falha ao enviar email, por favor, verifique se o mesmo já foi recebido recentemente e se não se encontra na caixa de Spam.');
                                    });
                                }}
>
                    <Link >
                      <span>Verificar Email</span>
                    </Link>
                  </div>
                    </span>
                  </div> 
                }
              </div>
            </div>
            <div className="lista">
              <div className="configs">
                  <Cog className="icon"/> Configurações
              </div>
              <div onClick={()=> props.selectOption("profile")} className="menu-item">
                <Link >
                  <span>Perfil</span>
                </Link>
              </div>
              <div onClick={()=> props.selectOption("changePassword")} className="menu-item">
                <Link >           
                  <span>Segurança</span>
                </Link>
              </div>
              <div onClick={()=> props.selectOption("signatures")}  className="menu-item">
                <Link>          
                  <span>Assinaturas</span>
                </Link>
              </div>
              {/* <div onClick={()=> props.selectOption("profile")}  className="menu-item">
                <Link>
                  <span>Dispositivos</span>
                </Link>
              </div> */}
            </div>
          
    
      </NavBarContainer>
      )
  }

}


function mapStateToProps({ user }) {
  return { user };
}

const mapDistapchToProps = dispatch =>
  bindActionCreators({ ...UserActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDistapchToProps
)(compose(graphql(VERIFY_EMAIL_MUTATION))(ProfileOptionBar));
