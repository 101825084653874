import styled from "styled-components";

export const Container = styled.section`
    .modal {
        background-color: #0e53db;
        max-width: 535px;
        max-height: 660px;
        padding: 10px 100px;
        overflow-Y: hidden;
        overflow-X: hidden;
        color: #fff;
    }
    .modalBackground {
        background-color: #0e53db;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Avenir;
        text-align: center;
    }
    header {
        margin-bottom: 30px;
    }
    .title {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 900;
        display: flex;
        height: 6vh;
        justify-content: center;
        align-items: center;
    }
    .subtitle {
        font-size: 14px;	
        font-weight: 500;	
        letter-spacing: -0.34px;	
        line-height: 19px;	
        text-align: center;
        margin-top: 25px;
        margin-bottom: 30px;
        color: #fff;
    }
    .botoes {
        flex: 1;
        display: flex;
        text-align: center;
        flex-direction: row;
        align-self: center;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    .assinar{
        border-radius: 20px;	
        background-color: #FFCC00;
        color: #0E53DB;	
        font-size: 12px;	
        font-weight: 900;	
        line-height: 16px;	
        text-align: center;
        width: 114px;	
        padding: 10px;
        margin-right: 25px;
    }
    .ja-assinado{
        border: 2px solid #FFFFFF;
        border-radius: 20px;	
        font-size: 12px;	
        font-weight: 900;	
        line-height: 16px;	
        text-align: center;
        padding: 10px;
        margin-right: 25px;
        padding-right: 25px;
        padding-left: 25px;
    }
    .close{
        margin-top: 15px;
    }
    section {
        img{
            border-style: none;
            display: flex;
            margin: auto;
            width: 100%;
        }
    }
`;
