import { call, put } from "redux-saga/effects";
import api from "../../services/api";

import TracksActions from "../ducks/tracks";

export function* getTracks(params) {
  try {
    const response = yield call(api.get, "tracks");
    yield put(TracksActions.getTracksSuccess(response.data));
  } catch (error) {
    // console.log(error);
  }
}
