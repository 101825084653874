import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--spacing-default);
  .line-tags {
    color: #039be5;
    margin-bottom: var(--spacing-default);
  }
  .containerAlta {
    display: flex;
    flex-direction: column;
    .episode-card {
      box-shadow: none;
      margin-bottom: 0;
    }
    .contentLineDirection:first-child {
      .destaque {
        .episode-card {
          margin: 0;
        }
      }
    }
    .contentLineDirection:last-child {
      .destaque {
        .episode-card {
          margin: 0 0 0 22px;
        }
      }
    }
  }
  .contentLineDirection {
    display: flex;
    flex:1;
    flex-direction: row;
    height: 100%;
  }
  .contentColunDirection {
    flex: 1;
  }
  .horizontal {
    margin: 0 2%;
    padding: var(--spacing-small);
    border-radius: var(--radius-small);
    margin-bottom: var(--spacing-small);
    box-shadow: rgba(0,0,0,0.1) 0 1px 3px;
    color: #666;
    .episode-thumb {
      width: 30%;
      height: 0;
      float: left;
      padding-bottom: 30%;
      position: relative;
      a {
        img.episode-thumb {
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
      .episode-thumb_image {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        padding: 0;
        position: absolute;
      }
    }
    .card-body {
      flex: 2 1;
      display: flex;
      flex-direction: column;
      .footer-block {
        flex: 1;
        align-items: flex-end;
      }
    }
  }

  .destaque {
    .episode-card {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .destaque:first-child {
    .episode-thumbs {
      background-color: var(--color-fourth);
    }
  }
  .destaque:last-child {
    .episode-thumbs {
      background-color: var(--color-seventh-lighten);
    }
  }
  .destaque .card-body {
    display: flex;
    flex-direction: column;
    .content-block {
      margin-bottom: 2em;
    }
  }
  @media (max-width: 800px) {
    padding-left: 0;
    padding-right: 0;
    .contentLineDirection {
      flex-wrap: wrap;
    }
    .horizontal .episode-thumb {
      padding-bottom: 40%;
      min-width: 40%;
      min-height: 0;
    }
    .horizontal .episode-thumb .episode-thumb_image {
      min-height: 100px;
      min-width: 100px;
    }
    .containerAlta .contentLineDirection:last-child .destaque .episode-card {
      margin: 0
    }
  }
`;

export const Trends = styled.section`
  width: 100%;
  & .trend-title {
    margin-top: 0;
    color: var(--color-negative);
  }
  & .trend-list{
    justify-content: start;
  }
`;
