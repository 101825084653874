import styled from "styled-components";

export const VerticalCardContainer = styled.article`
  width: 427px;
  display: flex;
  background: ${props => props.backgroundColor};
  color: ${props => props.fontColor};
  padding: var(--spacing-small);
  border-radius: var(--radius-small);
  margin-bottom: var(--spacing-small);
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;

  @keyframes example {
    from {opacity: 0;}
    to {opacity: 0.6}
  }

  .card-image_time {
    background: var(--color-negative-darken);
    color: var(--color-zero);
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    padding: 0 var(--spacing-small);
  }
  .card-image {
    margin: 0.4rem;
    position: relative;
  }
  .card-image .play-icon {
    position: absolute;
    width: 100%;
    padding: 25%;
    opacity: 0.6;
    animation-name: example;
    animation-duration: 1s;
    margin-top: var(--spacing-small);
    margin-left: var(--spacing-small);
    display: none;
  }
  .card-image:hover .play-icon {
    display: block;
  }
  .card-body {
    display: flex;
    margin-left: var(--spacing-small);
    margin-top: var(--spacing-small);
    flex-direction: column;
  }
  .card-title {
    height: 65px;
    font-style: normal;
    font-weight: 700;
    font-size: var(--size-half-medium);
    line-height: 1.4rem;
    margin-bottom: 1.3rem;
  }

  .card-channel {
    span {
      margin-right: var(--spacing-small);
    }
    font-size: var(--size-small);
    line-height: 16px;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-half-big);
  }
  .card-description {
    height: 50px;
    width: 223px;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: var(--spacing-small);
  }
  .card-link {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-decoration-line: underline;
    /* margin-bottom: var(--spacing-small); */
  }
  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.05em;
    margin-left: -7px;
    .play-count {
      display: flex;
      align-items: center;
      font-size: var(--size-very-small);
      line-height: 16px;
      .play-icon {
        height: 24px;
      }
    }
    .time-ago {
      font-size: var(--size-very-small);
    }
  }

  .card-action {
    .card-action_icon {
      height: var(--size-small);
    }
  }
`;
