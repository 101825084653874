import React, { Component } from 'react';
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/pt-br";

//graphql
import { Mutation } from 'react-apollo';
import CategoryFollowMutation from './BigCardComponent.graphql';

import { ACCESS_TOKEN } from '../../constants';
import { sendMessageToUser } from '../../common';
import M from 'materialize-css';

//styles
import {
    Container,
} from "./BigCardComponent.style";
import { Button, ButtonGroup } from "../../elements/CTA";

const USER_ACCOUNT = 'UserAccount'

export default class BigCardComponent extends Component {
    kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
            : Math.sign(num) * Math.abs(num);
    }
    datform(num) {
        Date(num)
    }

    render(){
        let { id, thumbnail, title, channel, play_count, publishDate, description, premium } = this.props.episode;
        let { scope } = this.props;
        let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
        // console.log("EPISODE: ", this.props.episode);
        return(
            <Container 
                className={`episode-card ${this.props.horizontal? 'horizontal' : ''}  ${this.props.backgroundColor? 'white-text' : ''}`}
            >
                <div className="episode-thumbs">
                    <div 
                        className="epsode-background"
                        style={{backgroundImage: `url(${channel.thumbnail})`}}
                    ></div>
                    <a href={`/episodes/${id}`} style={{backgroundImage: `url(${thumbnail})`}}>
                    </a>
                </div>
                <div className="card-body">
                    <div className="title-block">
                        <Link to={`/episodes/${id}`}>
                            <span className="title">
                                {title}
                            </span>
                        </Link>
                        <div className="lineChannel">
                            { (!scope || scope != 'channel') && <Link to={`/channels/${channel.codename}`}>
                            <span className="channel-code">
                                {channel.codename}
                            </span>
                            </Link>}
                            <Mutation 
                                mutation={CategoryFollowMutation} 
                                variables={
                                    { 
                                        channel_id: channel.id, 
                                        unfollow: channel.following
                                    }
                            }>
                                {
                                    postMutation => {
                                        if(premium){
                                            if(!localStorage.getItem(ACCESS_TOKEN) ){
                                                // // console.log("Premium deslogado");
                                                return <Button onClick={() => sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.')}  className="seguir inativo">
                                                    Inscreva-se
                                                </Button>
                                            } else if(!userAccount.subscribed){
                                                // // console.log("Premium nao assinado");
                                                return <Button onClick={() => {
                                                    var elems = document.getElementById('modal-premium');
                                                    var instances = M.Modal.init(elems, {
                                                        onOpenStart: function () {
                                                            var ev = new CustomEvent("abre-premium", { 'detail': "detalhesAqui" });
                                                            document.dispatchEvent(ev);
                                                        }
                                                    });
                                                    var instance = M.Modal.getInstance(elems);
                                                    instance.open();
                                                    }}  className="seguir ativo">
                                                    Inscreva-se
                                                </Button>
                                            } else if(!channel.following) {
                                                return <Button onClick={postMutation} className='seguir ativo'>
                                                    Inscreva-se
                                                </Button>
                                            } else {
                                                return <Button onClick={postMutation}  className="seguir inativo">
                                                    Inscrito
                                                </Button>
                                            }
                                        } else {
                                            if(!localStorage.getItem(ACCESS_TOKEN) ){
                                                // // console.log("Usuário deslogado");
                                                return <Button onClick={() => sendMessageToUser('Você precisa estar logado para se inscrever!')}  className="seguir inativo">
                                                    Inscreva-se
                                                </Button>
                                            } else if(!channel.following) {
                                                return <Button onClick={postMutation} className='seguir ativo'>
                                                    Inscreva-se
                                                </Button>
                                            } else {
                                                return <Button onClick={postMutation}  className="seguir inativo">
                                                    Inscrito
                                                </Button>
                                            }
                                        }
                                    }
                                }
                            </Mutation>
                        </div>
                    </div>
                    { !!description && this.props.showDetails && <div className="content-block">
                        <span className="description">
                        {description.length < 160
                        ? `${description}`
                        : `${description.substring(0, 160)}...`}
                        </span>
                    </div> }
                    <div className="footer-block">
                        <span className="play-count">
                            {play_count > 10 &&
                                `${this.kFormatter(play_count)}`
                            }
                        </span>
                        <span className="time-ago">
                            {moment(publishDate, "X", "pt-BR", true).fromNow()}
                        </span>
                    </div>
                </div>
            </Container>
        )
    }
}
