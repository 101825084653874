import gql from 'graphql-tag';
import { CATEGORY_TYPE } from '../types/CATEGORIES'

export const CATEGORIES_QUERY = gql`
    query mainCategories{
        ${CATEGORY_TYPE}
    }
`;

export const CATEGORIES_TO_CREATE_CHANNEL = gql`
    query Categories{
        mainCategories{
            ${CATEGORY_TYPE}
        }
        alternateCategories{
            data{
                ${CATEGORY_TYPE}
            }
        }
    }
`;
