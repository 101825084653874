import React, { Component } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/pt-br";

//styles
import { Container } from "./CardComponent.style";

export default class CardComponent extends Component {
  kFormatter(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
        : Math.sign(num) * Math.abs(num);
}
  render() {
    let {
      id,
      thumbnail,
      title,
      channel,
      play_count,
      publishDate,
      description
    } = this.props.episode;
    let { scope } = this.props;
    return (
      <Container
        className={`episode-card ${
          this.props.horizontal ? "horizontal" : ""
        }  ${this.props.backgroundColor ? "white-text" : ""}`}
        backgroundColor={this.props.backgroundColor}
      >
        <div className="episode-thumb">
          <Link to={`/episodes/${id}`}>
            <img src={thumbnail} alt={title} className="episode-thumb" />
          </Link>
        </div>
        <div className="card-body">
          <div className="title-block">
            <Link to={`/episodes/${id}`}>
              <span className="title">{title}</span>
            </Link>
            {(!scope || scope != "channel") && (
              <Link to={`/channels/${channel.id}`}>
                <span className="channel-code">{channel.name}</span>
              </Link>
            )}
          </div>
          {!!description && this.props.showDetails && (
            <div className="content-block">
              <span className="description">
                {description.length < 60
                ? `${description}`
                : `${description.substring(0, 60)}...`}
              </span>
            </div>
          )}
          <div className="footer-block">
            <span className="play-count">
            {play_count > 10 && 
            `${this.kFormatter(play_count)} plays`
            }
            </span>
            <span className="time-ago">{moment(publishDate, "X", "pt-BR", true).fromNow()}
            </span>
          </div>
        </div>
      </Container>
    );
  }
}
