import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: var(--spacing-big);
    .div1{
        background: #C7E5FC;
        width: 50%;
        font-family: Avenir;
        height: 200px;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #333333;
    }
    .imgThumb{
        margin-top: -150px;
    }
    .divBtn{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        background: #EEEEEE;
        height: 50px;
        border-radius: 0px 0px 3px 3px;
        width: 50%;
        .pular{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #66757F;
            text-align: center;
            letter-spacing: 0.05em;   
            margin-right: var(--spacing-default);         
        }
        .btnEnviar{
            margin-left: var(--spacing-default);
            .auth-button{
                width: auto !important;
                padding: 7px 24px 6px;
                line-height: 12px;
                border-radius: var(--radius-small);
                background-color: #2196F3;

            }
        }
    }
`;
