import gql from "graphql-tag";

const ADMIN_USERS_QUERY = gql`
    query($idChannel: Int!){
        channelManagers(channel_id: $idChannel){
            channel_id
            manager_type
            manager_id
            manager_email
            status
        }
        managerTypes{
            manager_type
            manager_description
        }
    }
`;

const ADMIN_USERS_INVITE_MUTATION = gql`
    mutation($idChannel: Int!, $mail: String!, $type: String!){
        inviteChannelManager(channel_id: $idChannel, email: $mail, manager_type: $type) 
    }
`;

export { ADMIN_USERS_QUERY, ADMIN_USERS_INVITE_MUTATION };
