import React from "react";
import { Link } from "react-router-dom";
import FAQ from '../../../../src/assets/pdfs/FAQ.pdf';
import { LogoOla } from "../../../assets/icons";
import * as C from './styles';

const TermosPrivacidade = () => {
    return (
        <C.Container>
            <C.Header>
                <Link to="/">
                    <LogoOla classImage="logo-image" />
                </Link>
            </C.Header>
            <C.Main>
                <C.Title>
                    TERMOS, CONDIÇÕES, POLÍTICAS DE PRIVACIDADE E DE USO DE
                    SERVIÇOS DA PLATAFORMA “OLA PODCASTS”
                </C.Title>
                <C.Paragraph>
                    Bem-vindo à “OLA PODCASTS”. Este é um serviço comercializado por “OLA
                    PODCASTS CONTEÚDO E PARTICIPAÇÕES” sociedade por ações devidamente
                    constituída e validamente existente de acordo com as Leis da República Federativa do
                    Brasil, inscrita no CNPJ/MF sob o nº 34.145.511/0001-13, com sede na Avenida das
                    Américas nº 500, Bloco 19, sala 301, Barra da Tijuca, Rio de Janeiro - RJ, CEP 22640-
                    904. O serviço usa uma Plataforma criada e desenvolvida pela “OLA PODCASTS”,
                    supracitada, para entretenimento, aprendizado, interações sociais, aquisições de produtos
                    físicos ou virtuais e programa de relacionamento, sendo certo que “OLA PODCASTS” e
                    seus parceiros, produtores de conteúdo, são titulares de todo o conteúdo da área Originais,
                    direitos autorais e de propriedade intelectual da área Originais, protegidos por Lei e com
                    utilização regulada pelos seguintes TERMOS, CONDIÇÕES E POLÍTICAS DE USO.
                    Leia atentamente os TERMOS, CONDIÇÕES E POLÍTICAS DE USO antes de acessar
                    esta Plataforma.
                    <br /><br />
                    Ao acessar ou utilizar esta Plataforma via website, mobile web, aplicativo de dispositivo
                    móvel ou outro produto ou serviço da “OLA PODCASTS” (denominados coletivamente
                    a “Plataforma”) em qualquer computador, celular, tablet, console ou outro dispositivo
                    (denominados coletivamente, “dispositivo”), você afirma e confirma que leu,
                    compreendeu, concordou com e aceitou plenamente os TERMOS, CONDIÇÕES E
                    POLÍTICAS DE USO, estando a eles sujeito, esteja ou não registrado como um usuário
                    ou assinante da “OLA PODCASTS”.
                    <br /><br />
                    A “OLA PODCASTS” poderá livremente e a seu exclusivo critério alterar os TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO a qualquer momento e sem qualquer aviso prévio,
                    por qualquer motivo, mediante publicação na Plataforma dos novos TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO vigentes, sendo necessário novo aceite pelo
                    usuário. As alterações serão comunicadas via e-mail, razão pela qual você deverá garantir
                    que o endereço eletrônico contato@olapodcasts.com.br não seja vinculado como “spam”
                    pelo seu provedor.
                    <br /><br />
                    Sua participação e utilização da Plataforma serão orientadas exclusivamente por estes
                    TERMOS, CONDIÇÕES E POLÍTICAS DE USO. Caso não concorde por qualquer
                    motivo com o presente TERMOS, CONDIÇÕES E POLÍTICAS DE USO, total ou
                    parcialmente, NÃO UTILIZE A PLATAFORMA. O uso da Plataforma será considerado
                    como seu consentimento e aceite pleno em relação aos TERMOS, CONDIÇÕES E
                    POLÍTICAS DE USO da Plataforma vigentes.
                </C.Paragraph>
                <C.Subtitle>
                    1. CONDIÇÕES GERAIS
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” oferece o serviço de disponibilização de conteúdos em áudio, na
                    forma de Podcast para streaming, a partir de sua plataforma, agregando inúmeros canais
                    de Podcasts em um mesmo lugar. Para que os nossos serviços sejam utilizados, você
                    precisa ter acesso à internet, bem como utilizar dispositivos compatíveis com a tecnologia
                    da plataforma. A “OLA PODCASTS” não se responsabiliza pelo fornecimento da sua
                    internet, pois nossos serviços são limitados apenas à plataforma da “OLA PODCASTS”.
                    <br /><br />
                    Você deve observar que o uso da “OLA PODCASTS” é pessoal, privado e intransferível,
                    sendo terminantemente proibido o seu uso para fins comerciais e lucrativos, a veiculação
                    do seu conteúdo em público e o compartilhamento de sua senha pessoal com terceiros.
                    Você deve respeitar os limites de uso do conteúdo disponibilizado pela “OLA
                    PODCASTS”, que se limitam apenas a reprodução dos Podcasts por meio da nossa
                    plataforma.
                </C.Paragraph>
                <C.Subtitle>
                    2. AVISOS E RESTRIÇÕES DE FUNCIONALIDADE
                </C.Subtitle>
                <C.Paragraph>
                    A Plataforma foi elaborada e desenvolvida utilizando materiais de diversos produtores de
                    conteúdo, tendo, ainda, como referências as experiências pessoais, profissionais e
                    exclusivas de cada produtor, não possuindo, por conseguinte, certificação ou garantia de
                    qualquer resultado, de modo que a “OLA PODCASTS” não poderá ser responsabilizada
                    pelos resultados e ganhos obtidos pelos usuários.
                </C.Paragraph>
                <C.Subtitle>
                    3. UTILIZANDO A “OLA PODCASTS”
                </C.Subtitle>
                <C.Paragraph>
                    Todos os serviços e os conteúdos “Originais” disponíveis na “OLA PODCASTS” são de
                    propriedade da “OLA PODCASTS” ou de seus licenciantes. Nós concedemos a você uma
                    permissão limitada, não exclusiva e revogável para fazer uso da plataforma “OLA
                    PODCASTS”, e uma permissão limitada, não exclusiva e revogável para fazer uso
                    pessoal e não comercial do conteúdo disponível na “OLA PODCASTS” (“Permissão”).
                    Esta permissão permanecerá em vigor, a menos que seja encerrada por você ou pela
                    “OLA PODCASTS”. Você concorda e declara que utilizará a “OLA PODCASTS” e o
                    seu conteúdo para seu uso pessoal, não comercial e que você não irá redistribuir nem
                    transferir o serviço ou os conteúdos acessados. Você declara estar plenamente ciente de
                    que o conteúdo e os aplicativos de software “OLA PODCASTS” não são vendidos nem
                    transferidos para você, e que a “OLA PODCASTS” e os seus licenciantes retêm a
                    titularidade de todas as cópias dos aplicativos de software “OLA PODCASTS” e dos
                    conteúdos Originais OLA Podcasts, mesmo após a instalação em seus computadores
                    pessoais, telefones celulares, tablets, e/ou outros dispositivos ("Dispositivos").
                    <br /><br />
                    Todas as marcas, nomes comerciais, logotipos, nomes de domínio da “OLA
                    PODCASTS” e quaisquer outras características da marca “OLA PODCASTS” são de
                    propriedade exclusiva da “OLA PODCASTS” e/ou dos seus licenciantes. Os Acordos
                    não concedem a você quaisquer direitos de usar quaisquer características da marca “OLA
                    PODCASTS” seja para uso comercial ou não comercial.
                    <br /><br />
                    Você concorda em respeitar nossas Diretrizes do Usuário e não usar a “OLA
                    PODCASTS” de qualquer forma não expressamente permitida nos TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO.
                    <br /><br />
                    Exceto pelos direitos expressamente concedidos a você nos TERMOS, CONDIÇÕES E
                    POLÍTICAS DE USO, a “OLA PODCASTS” não concede qualquer direito, título ou
                    interesse a você.
                    <br /><br />
                    Conteúdos de terceiros (por exemplo, bibliotecas de software de código aberto) incluídos
                    no “OLA PODCASTS” são disponibilizados para você consoante os termos da licença
                    aplicável de terceiros, conforme disposto no item 12.
                </C.Paragraph>
                <C.Subtitle>
                    4. OPÇÕES DE SERVIÇO
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” oferece streaming de conteúdo em áudio gratuito para todos os
                    usuários, através da área denominada Área Freemium (“Área Freemium”).
                    Contudo, nós também oferecemos o serviço pago dos planos de assinatura para acesso
                    Premium (“Acesso Premium”), a qual permitirá ao usuário o acesso sem propagandas,
                    com criação de playlists ilimitadas e consumo de episódios offline de forma ilimitada na
                    “OLA PODCASTS”.
                    <br /><br />
                    Para ter acesso ao conteúdo da “OLA PODCASTS”, independentemente da área, é
                    necessária a criação de uma conta, mediante a prestação de informações requeridas no
                    formulário de cadastro.
                </C.Paragraph>
                <C.Subtitle>
                    4.1 USUÁRIO PREMIUM
                </C.Subtitle>
                <C.Paragraph>
                    Para ser um usuário Premium, o usuário deverá assinar o plano de acesso, mediante os
                    seguintes passos:
                    <br /><br />
                    - Criar uma conta no site ou baixar e criar uma conta no aplicativo ou, se o usuário já for
                    registrado, entrar no site ou no aplicativo da “OLA PODCASTS”;
                    <br />
                    - Fornecer as informações requeridas no formulário de assinatura de forma verdadeira,
                    precisa e autêntica;
                    <br />
                    - Concordar com estes TERMOS, CONDIÇÕES E POLÍTICAS DE USO;
                    <br />
                    - Pagar o preço da assinatura através de um dos sistemas de pagamento oferecidos;
                    <br />
                    - Confirmar sua assinatura.
                    <br /><br />
                    Uma vez confirmada sua assinatura, a “OLA PODCASTS” enviará um e-mail de
                    confirmação para o endereço de e-mail fornecido no processo de registro. A partir de
                    então, a assinatura Premium estará em vigor.
                    <br /><br />
                    O usuário concorda em informar à “OLA PODCASTS”, com a maior brevidade possível,
                    qualquer alteração ou atualização nas informações fornecidas no processo de assinatura
                    Premium, inclusive qualquer modificação no seu endereço de e-mail ou nos dados
                    bancários. O usuário pode modificar estas informações a qualquer momento na sua conta.
                    <br /><br />
                    O usuário deverá informar imediatamente à “OLA PODCASTS” qualquer perda ou uso
                    não autorizado da sua conta, dos seus dados de identificação e de sua senha. A senha e os
                    dados de identificação são pessoais, e o usuário concorda em não divulgá-los. Portanto,
                    o usuário é o único responsável pelo uso de sua conta na “OLA PODCASTS”.
                </C.Paragraph>
                <C.Subtitle>
                    5. TARIFAS, FORMAS DE COBRANÇA, CONTRATAÇÃO E
                    CANCELAMENTO
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” poderá ser acessada de forma gratuita na Área Freemium. Para
                    acesso ao conteúdo gratuito, basta a criação de uma conta e o registro mediante login e
                    senha criados na “OLA PODCASTS”.
                    <br /><br />
                    Contudo, caso deseje ser um usuário Premium, o cliente poderá realizar a assinatura do
                    plano Premium da “OLA PODCASTS”, pelo pagamento de um valor periódico. A “OLA
                    PODCASTS” poderá alterar de tempos em tempos o valor da assinatura. Se isso ocorrer,
                    o Cliente será informado previamente ao período da mudança efetiva de valor, quando
                    poderá optar por continuar ou cancelar a assinatura. Se o Cliente não concordar com a
                    alteração de preço, poderá rejeitar a alteração ao realizar o cancelamento da assinatura
                    Premium antes que a alteração de preço entre em vigor. No entanto, caso não cancele a
                    assinatura, a alteração será considerada aceita.
                    <br /><br />
                    O Cliente que contratar a “OLA PODCASTS” pagará os valores seguindo as tabelas
                    abaixo, com renovações automáticas, de acordo com a origem de sua assinatura (lojas de
                    aplicativos, cartão de crédito ou operadora móvel), na periodicidade (semanal, mensal ou
                    anual) e meio de pagamento escolhidos, durante todo o período em que permanecer com
                    o serviço ativo, ainda que não acessado.
                    <br /><br />
                    <strong>
                        Lojas de Aplicativos (Google e Apple) ou cartão de crédito:
                    </strong>
                    <br /><br />
                    Período Mensal R$ 9,90/mês - Período Anual R$ 79,90/ano
                    <br /><br />
                    <strong>
                        Operadoras Móveis Tim e Vivo:
                    </strong>
                    <br /><br />
                </C.Paragraph>
                <C.Table>
                    <C.Thead>
                        <tr>
                            <td>Nome da Oferta</td>
                            <td>Preço (R$)</td>
                            <td>Frequência</td>
                        </tr>
                    </C.Thead>
                    <C.Tbody>
                        <tr>
                            <td>OLA PODCASTS</td>
                            <td>R$ 4,90</td>
                            <td>Semanal com renovação automática</td>
                        </tr>
                        <tr>
                            <td>OLA PODCASTS</td>
                            <td>R$ 9,90</td>
                            <td>Mensal com renovação automática</td>
                        </tr>
                        <tr>
                            <td>OLA PODCASTS</td>
                            <td>R$ 0,00</td>
                            <td>60 dias (Gratuidade COVID*)</td>
                        </tr>
                    </C.Tbody>
                </C.Table>
                <C.Paragraph className="italic">
                    *Sobre o valor acima definido, estão incluídos os tributos incidentes.
                </C.Paragraph>
                <C.Paragraph>
                    <br />
                    <strong>
                        Operadora Móvel Claro
                    </strong>
                    <br /><br />
                </C.Paragraph>
                <C.Table>
                    <C.Thead>
                        <tr>
                            <td>Nome da Oferta</td>
                            <td>Preço (R$)</td>
                            <td>Frequência</td>
                        </tr>
                    </C.Thead>
                    <C.Tbody>
                        <tr>
                            <td>OLA PODCASTS</td>
                            <td>R$ 3,99</td>
                            <td>Semanal com renovação automática</td>
                        </tr>
                        <tr>
                            <td>OLA PODCASTS</td>
                            <td>R$ 9,99</td>
                            <td>Mensal com renovação automática</td>
                        </tr>
                    </C.Tbody>
                </C.Table>
                <C.Paragraph className="italic">
                    *Sobre o valor acima definido, estão incluídos os tributos incidentes.
                </C.Paragraph>
                <C.Paragraph>
                    <br />
                    O Cliente que contratar a “OLA PODCASTS”, através de Cartão de Crédito e/ou pelas
                    operadoras TIM, VIVO e CLARO, terá 15 dias de gratuidade, contando da data efetiva
                    da assinatura.
                    <br /><br />
                    Ao contratar o Serviço pelas operadoras TIM, VIVO e CLARO, a cobrança da sua
                    assinatura será automática e realizada: (a) em sua conta da TIM/ VIVO/ CLARO, quando
                    você tiver uma conta de celular pós-pago ou controle, ou (b) mediante desconto de seus
                    créditos, quando você tiver uma conta de celular pré-pago.
                    <br /><br />
                    O serviço não estará́ disponível para clientes de planos empresariais, ressalvada a hipótese
                    de o Cliente efetuar o desbloqueio de sua linha corporativa para desta forma viabilizar a
                    contratação do “OLA PODCASTS”.
                    <br /><br />Os valores do serviço podem ser alterados ou variar, mediante prévia e expressa
                    comunicação aos Clientes assinantes, de acordo com a forma de contratação, ficando
                    ciente o Cliente que eventuais valores diversos do previsto no presente Termo serão
                    informados diretamente na contratação.
                    <br /><br />
                    Para contratar a oferta semanal por R$4,90 com renovação automática, o cliente deve
                    enviar uma mensagem SMS do seu Tim ou Vivo com a palavra OLA para o número LA
                    3010. Para contratar a oferta mensal por R$9,90 com renovação automática, o cliente
                    deve enviar uma mensagem SMS do seu Tim ou Vivo com a palavra OLAP para o número
                    LA 3010. Em seguida você receberá uma mensagem para confirmar a assinatura, e você
                    deverá responder à mensagem com a palavra SIM.
                    <br /><br />
                    Para contratar a oferta semanal por R$3,99 com renovação automática, o cliente deve
                    enviar uma mensagem SMS do seu Claro com a palavra OLA para o número LA 3010.
                    Para contratar a oferta mensal por R$9,99 com renovação automática, o cliente deve
                    enviar uma mensagem SMS do seu Claro com a palavra OLAP para o número LA 3010.
                    Em seguida você receberá uma mensagem para confirmar a assinatura, e você deverá
                    responder à mensagem com a palavra SIM.
                    <br /><br />
                    - O Cliente não deve possuir débito (valor vencido e não pago) junto a TIM, VIVO ou
                    CLARO no momento da contratação do Serviço.
                    <br />
                    - Para cancelar a assinatura do Serviço, o cliente TIM, VIVO ou CLARO deverá enviar
                    uma mensagem SMS com a palavra SAIR para o número 3010 ou entrar em contato com
                    a operadora por telefone na central de atendimento das mesmas. O serviço ficará vigente
                    até o final do período contratado, a partir da data de cancelamento, de acordo com a oferta
                    contratada (semanal 7 dias ou mensal 30 dias).
                    <br /><br />
                    Caso a assinatura tenha sido feita via cartão de crédito, pelo site ou landing pages, o
                    cancelamento poderá ser feito pela tela de cadastro no site www.olapodcasts.com ou no
                    aplicativo da “OLA PODCASTS”, e no item Meu Perfil, sempre respeitando as condições
                    comerciais estabelecidas quando da assinatura do plano escolhido.
                    <br /><br />
                    No entanto, você deve observar que a data de cancelamento de assinatura deverá ser
                    anterior à data de cobrança da “OLA PODCASTS” em seu cartão de crédito. Caso
                    contrário, você será cobrado por mais um mês de uso.
                    <br /><br />
                    Ao cancelar sua assinatura do plano de acesso Premium, sua conta se tornará Freemium.
                    <br /><br />
                    Se a compra tiver sido feita via App Store ou Google Play, a política de cancelamento e
                    reembolso segue os termos de cada loja. O cancelamento deve ser feito por meio das
                    configurações de sua conta nas lojas de aplicativos.
                </C.Paragraph>
                <C.Subtitle>
                    5.1. PRAZO DE ARREPENDIMENTO
                </C.Subtitle>
                <C.Paragraph>
                    Após a assinatura, você terá o prazo improrrogável de 7 (sete) dias para exercer seu direito
                    de arrependimento e solicitar o cancelamento de seu plano de acesso Premium. Note que
                    o direito de arrependimento somente poderá ser exercido uma única vez, na primeira
                    assinatura do plano de acesso Premium. Caso você realize uma nova assinatura, não será
                    possível exercer o direito de arrependimento.
                    <br /><br />
                    Para solicitar o cancelamento por arrependimento, você deverá entrar em contato
                    diretamente com o serviço de atendimento ao cliente (“SAC”), através do e-mail
                    contato@olapodcasts.com.
                    <br /><br />
                    Para maiores informações sobre assinatura através da sua operadora de celular, consultar
                    o item 5.
                </C.Paragraph>
                <C.Subtitle>
                    6. PROPRIEDADE INTELECTUAL
                </C.Subtitle>
                <C.Paragraph>
                    Toda a propriedade intelectual desta Plataforma (exceto o conteúdo gerado pelo usuário
                    que é cedido por meio da aceitação destes TERMOS, CONDIÇÕES E POLÍTICAS DE
                    USO e os conteúdos agregados na plataforma, disponíveis na área freemium da mesma)
                    é detida pela “OLA PODCASTS” e seus licenciantes, conforme o caso, que inclui
                    materiais protegidos por direitos autorais, marcas registradas ou Leis de patentes. Todas
                    as marcas registradas, marcas de serviço e nomes de marcas são detidas, registradas e/ou
                    licenciadas pela “OLA PODCASTS” e seus licenciantes, conforme o caso. Todo o
                    conteúdo da Plataforma (exceto o conteúdo gerado pelo usuário que é cedido por meio da
                    aceitação destes TERMOS, CONDIÇÕES E POLÍTICAS DE USO e os conteúdos
                    agregados na plataforma, disponíveis na área freemium da mesma), incluindo, entre
                    outros, o texto, consultoria de especialistas, conteúdo validado por profissionais,
                    software, scripts, código, designs, gráficos, fotos, sons, músicas, vídeos, imagens,
                    aplicativos, recursos interativos e outros conteúdos (“Conteúdo”), para todos os efeitos,
                    é considerado um trabalho coletivo protegido pelas Leis brasileiras aplicáveis e por Leis
                    de Direitos Autorais, sendo que terão todos os direitos reservados.
                </C.Paragraph>
                <C.Subtitle>
                    7. RESTRIÇÕES DE USO DA PLATAFORMA
                </C.Subtitle>
                <C.Paragraph>
                    Você pode utilizar o Conteúdo de titularidade da “OLA PODCASTS” ou licenciado para
                    a “OLA PODCASTS” somente para o seu uso próprio não comercial, para participar da
                    Plataforma com suas funcionalidades, para consumir, gerar, receber, avaliar,
                    compartilhar, comentar, moderar, denunciar os Conteúdos, interagir com outros usuários
                    registrados ou assinantes ou para adquirir produtos físicos ou virtuais na Plataforma
                    “OLA PODCASTS”, além de participar de promoções, concorrer a prêmios ou brindes.
                    <br /><br />
                    Você concorda em não alterar ou eliminar nenhum comunicado de propriedade de
                    materiais baixados ou impressos da Plataforma. Você concorda em não modificar, alterar,
                    copiar, traduzir, difundir, apresentar, exibir, distribuir, ajustar, reproduzir, republicar,
                    baixar, publicar, transmitir, doar, ceder ou vender nenhum Conteúdo de propriedade
                    intelectual presente na Plataforma, incluindo o conteúdo gerado pelo usuário (definido
                    abaixo), sem o consentimento prévio e por escrito da “OLA PODCASTS”, a menos que
                    seja o seu próprio conteúdo, no caso em que você (usuário) publicou de forma legal e
                    legítima na Plataforma.
                    <br /><br />
                    Você concorda em não utilizar nenhuma mineração de dados, robôs, raspagens de dados
                    ou métodos similares de coleta de dados. Nada nestes TERMOS, CONDIÇÕES E
                    POLÍTICAS DE USO deve ser interpretado como uma cessão, transferência ou concessão
                    de qualquer licença de propriedade intelectual para você.
                </C.Paragraph>
                <C.Subtitle>
                    8. COMO UTILIZAR
                </C.Subtitle>
                <C.Paragraph>
                    Ao utilizar os serviços da “OLA PODCASTS”, você se declara ciente e concorda que a
                    permissão de uso dos serviços da “OLA PODCASTS” é limitada, não exclusiva e
                    revogável, restrita ao uso pessoal e não comercial do conteúdo disponibilizado. É
                    terminantemente proibida a redistribuição ou transferência de qualquer conteúdo
                    acessado na “OLA PODCASTS”.
                    <br /><br />
                    Para utilizar a “OLA PODCASTS”, você deverá primeiramente ter acesso à internet e
                    utilizar Dispositivos compatíveis com a tecnologia da nossa plataforma.
                    <br /><br />
                    Você deverá acessar o site www.olapodcasts.com ou baixar o aplicativo da “OLA
                    PODCASTS” nas lojas de aplicativo Apple Store ou Play Store. Em seguida, realizar o
                    cadastro, mediante informação dos seus dados que serão requisitados pela plataforma.
                    Nesse momento, você deverá criar o seu login e senha. Atenção: a sua conta, login e senha
                    são pessoais e intransferíveis.
                    Após realizado o cadastro, você poderá acessar a plataforma com seu nome de usuário e
                    senha e, então, utilizar os serviços disponibilizados na “OLA PODCASTS”, de acordo
                    com a opção de cadastro feita por você (acesso Premium ou Freemium).
                    <br /><br />
                    Todas as informações fornecidas são de sua inteira responsabilidade. Você está ciente de
                    que a “OLA PODCASTS” poderá, a qualquer momento, verificar a veracidade das
                    alegações prestadas no cadastro. Caso sejam verificadas informações inconsistentes ou
                    inverídicas, seu acesso poderá ser suspenso da “OLA PODCASTS”.
                </C.Paragraph>
                <C.Subtitle>
                    9. PROTEÇÃO DO NOME DE USUÁRIO/SENHA
                </C.Subtitle>
                <C.Paragraph>
                    Você é o único responsável por quaisquer ações que possam ocorrer enquanto utilizar a
                    sua conta na “OLA PODCASTS”. Proteja o seu nome de usuário/senha e não permita
                    de modo algum que outra pessoa utilize o seu nome de usuário/senha para acessar a
                    Plataforma. O seu nome de usuário/senha é pessoal, intransferível e de sua inteira e única
                    responsabilidade, devendo, por conseguinte, serem sempre mantidos em total sigilo.
                    <br /><br />
                    A “OLA PODCASTS” não se responsabiliza por qualquer prejuízo ou dano de qualquer
                    natureza resultante do uso indevido ou não autorizado do seu nome de usuário/senha por
                    quaisquer terceiros, com ou sem o seu conhecimento.
                    <br /><br />
                    Você não compartilhará sua senha (ou, no caso de desenvolvedores, sua chave secreta),
                    ou deixará outra pessoa acessar sua conta ou praticará qualquer ato que possa
                    comprometer a segurança da sua conta. Você não fornecerá qualquer informação pessoal
                    falsa na Plataforma, nem criará um Usuário para qualquer outra pessoa além de você.
                    Você manterá suas informações de contato precisas e atualizadas, responsabilizando-se
                    integralmente perante a “OLA PODCASTS” e/ou quaisquer terceiros pelos dados
                    disponibilizados na Plataforma e pelos prejuízos a que tiver dado causa em razão de
                    divergências nos referidos dados e informações cadastrais.
                    <br /><br />
                    <strong>Atenção:</strong> todos os atos praticados mediante a utilização de seu login e senha serão de sua
                    inteira responsabilidade.
                </C.Paragraph>
                <C.Subtitle>
                    10. DIRETRIZES DO USUÁRIO
                </C.Subtitle>
                <C.Paragraph>
                    Organizamos nesta seção as principais regras e diretrizes do seu uso da “OLA
                    PODCASTS”, para garantir que sua experiência conosco seja sempre pautada no respeito
                    recíproco dos TERMOS, CONDIÇÕES E POLÍTICAS DE USO. Preste atenção, pois
                    todos os usuários deverão seguir as diretrizes abaixo. É proibido ao usuário:
                    <br /><br />
                    <C.Ol type="i" >
                        <li>
                            Copiar, reproduzir, fazer download, transferir, vender ou revender, alugar, licenciar
                            ou sublicenciar, exibir em público, dar destinação comercial e/ou lucrativa aos
                            conteúdos disponibilizados pela “OLA PODCASTS” de forma indevida, exceto se
                            obtiver prévia e expressa autorização da “OLA PODCASTS”;
                        </li>
                        <li>
                            Utilizar o conteúdo e serviços disponibilizados pela “OLA PODCASTS” fora do
                            escopo dos presentes TERMOS, CONDIÇÕES E POLÍTICAS DE USO;
                        </li>
                        <li>
                            Informar seu login e senha para terceiros, ou permitir que terceiros utilizem seu
                            acesso;
                        </li>
                        <li>
                            transferir cópias em cache de qualquer conteúdo disponibilizado pela “OLA
                            PODCASTS” de um Dispositivo para outro sem a autorização prévia e expressa da
                            “OLA PODCASTS”, independentemente do meio utilizado para tal;
                        </li>
                        <li>
                            violar qualquer direito de propriedade intelectual da “OLA PODCASTS”, seus
                            softwares, aplicativos, inventos, direitos autorais, marcas, patentes, realizar
                            engenharia reversa, descompilar, desmontar, modificar, copiar o conteúdo exclusivo,
                            em parte ou no todo, exceto mediante a prévia e expressa autorização da “OLA
                            PODCASTS”;
                        </li>
                        <li>
                            alterar a verdadeira contagem de reproduções ou de seguidores, ou qualquer
                            informação sobre o seu uso no Podcast por qualquer meio, em especial por meio de
                            processo automatizado;
                        </li>
                        <li>
                            violar direitos autorais da “OLA PODCASTS” ou de terceiros sobre os conteúdos
                            disponibilizados em nossa plataforma;
                        </li>
                        <li>
                            Violar o sistema de reprodução de comerciais e publicidades, em especial na Área
                            Freemium, sem a prévia e expressa autorização da “OLA PODCASTS”;
                        </li>
                        <li>
                            Utilizar a “OLA PODCASTS” de forma comercial, sendo vedada também a
                            comercialização de criação e disponibilização de playlists, seleções de conteúdo,
                            produção de conteúdos ou qualquer outro meio para obtenção de vantagem lucrativa
                            através da utilização dos serviços da “OLA PODCASTS”;
                        </li>
                        <li>
                            Utilizar a “OLA PODCASTS” para difamar, injuriar, caluniar, ofender, xingar,
                            causar exposição não autorizada de terceiros, prejudicar ou praticar qualquer ato
                            criminoso ou imoral na esfera de outrem;
                        </li>
                        <li>
                            Utilizar a “OLA PODCASTS” para fins publicitários, de forma gratuita ou onerosa,
                            sem a prévia e expressa autorização da “OLA PODCASTS”;
                        </li>
                        <li>
                            Vender uma conta de usuário mediante login e senha, ou qualquer conteúdo
                            produzido em função da utilização dos serviços da “OLA PODCASTS”.
                        </li>
                    </C.Ol>
                    <br />
                    Você reconhece que na prática de qualquer dos atos proibidos listados acima, seu acesso
                    a “OLA PODCASTS” poderá ser imediatamente cancelado, e a “OLA PODCASTS”
                    poderá apurar eventuais danos causados por você pela violação das diretrizes do usuário.
                    <br /><br />
                    Por isso, é importante manter sua senha e login privados, sem disponibilizá-los a terceiros,
                    pois, conforme determinado no item 9 você será integralmente responsabilizado por
                    qualquer ato realizado mediante o uso de seu login e senha, que são pessoais e
                    intransferíveis.
                </C.Paragraph>
                <C.Subtitle>
                    11. DIREITOS CONCEDIDOS À “OLA PODCASTS”
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” concede aos usuários os direitos descritos nestes TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO e, em contrapartida, o usuário concede direitos à
                    “OLA PODCASTS”.
                    <br /><br />
                    Você nos concede o direito de utilizar a plataforma para veicular informações,
                    publicidade e propaganda nossa e de nossos parceiros, através de imagens, vídeos ou
                    áudios.
                    <br /><br />
                    O usuário se declara ciente de que a “OLA PODCASTS” está autorizada a utilizar o seu
                    hardware e processador de internet, bem como o armazenamento em seu Dispositivo para
                    disponibilizar o serviço da plataforma.
                    <br /><br />
                    Todo e qualquer Conteúdo do Usuário poderá ser utilizado pela “OLA PODCASTS” para
                    publicação, distribuição, criação de conteúdos derivados, modificação, edição e tradução,
                    ao passo em que o usuário concede uma licença gratuita, não exclusiva, transferível, sub
                    licenciável, por prazo indeterminado, irrevogável, vigente em todo o território à “OLA
                    PODCASTS” sobre o Conteúdo do Usuário produzido.
                    <br /><br />
                    As comunicações entre você e a “OLA PODCASTS”, incluindo feedbacks e sugestões,
                    poderão ser utilizadas pela “OLA PODCASTS” de forma gratuita, sem nenhuma
                    contraprestação, sem comunicação prévia. Você reconhece que os feedbacks e sugestões
                    não são confidenciais.
                </C.Paragraph>
                <C.Subtitle>
                    12. CONTEÚDO GERADO PELO USUÁRIO
                </C.Subtitle>
                <C.Paragraph>
                    O “conteúdo gerado pelo usuário” abrange comunicados, materiais, informações, dados,
                    opiniões, fotos, perfis, mensagens, notas, links de websites, informações de texto,
                    músicas, vídeos, designs, gráficos, sons e quaisquer outros conteúdos que você e/ou
                    outros usuários registrados ou assinantes da Plataforma publicam ou disponibilizam na
                    Plataforma, ou através da mesma, exceto aqueles conteúdos que são de propriedade da
                    “OLA PODCASTS”.
                    <br /><br />
                    O conteúdo publicado por você poderá ser utilizado ampla e livremente, inclusive como
                    parte da metodologia pela “OLA PODCASTS”, independentemente de qualquer
                    consentimento ou autorização prévia e estará acessível aos demais usuários registrados
                    ou assinantes da Plataforma, assim como aos internautas em geral via mecanismos de
                    busca da web, não ensejando referida prática pela “OLA PODCASTS” violação a direitos
                    autorais, direitos à imagem e a privacidade e nem gerando indenização e ressarcimentos
                    por danos de qualquer natureza, inclusive após o encerramento da conta pelo usuário
                    registrado ou assinante da Plataforma.
                    <br /><br />
                    Ao utilizar a “OLA PODCASTS”, o usuário poderá postar, publicar, carregar e criar
                    conteúdo em nossa plataforma. Desta forma, o usuário poderá incluir imagens, fotos,
                    textos, mensagens, áudios, seleção de conteúdo, títulos, informações sobre conteúdos,
                    entre outros (“Conteúdo do Usuário”).
                    <br /><br />
                    Quando você faz upload de Conteúdo do Usuário, você concede à “OLA PODCASTS”
                    uma licença gratuita, não exclusiva, transferível, sub licenciável, por prazo
                    indeterminado, irrevogável, vigente em todo o território, sobre o Conteúdo de Usuário
                    produzido.
                    <br /><br />
                    Você declara estar ciente de que todo Conteúdo do Usuário produzido poderá, mas não
                    obrigatoriamente, ser monitorado, revisado, editado ou excluído pela “OLA
                    PODCASTS”. Você declara ainda que a “OLA PODCASTS” não possui nenhuma
                    ingerência ou controle prévios sobre a produção de Conteúdo do Usuário.
                    <br /><br />
                    O usuário deverá observar os TERMOS, CONDIÇÕES E POLÍTICAS DE USO e a
                    legislação vigente ao criar Conteúdo do Usuário.
                    <br /><br />
                    A “OLA PODCASTS” se reserva o direito de remover qualquer conteúdo que, ao seu
                    critério, viole os TERMOS, CONDIÇÕES E POLÍTICAS DE USO, sem notificação
                    prévia.
                    <br /><br />
                    Você é exclusivamente responsável por todos os Conteúdos do Usuário que você postar.
                    A “OLA PODCASTS” não é responsável pelo Conteúdo do Usuário, nem endossa
                    qualquer opinião contida em qualquer Conteúdo do Usuário.
                    <br /><br />
                    O USUÁRIO CONCORDA EM INDENIZAR A “OLA PODCASTS” POR
                    QUALQUER PERDAS E DANOS SOFRIDOS EM RAZÃO DO CONTEÚDO DE
                    USUÁRIO PROMOVIDO POR ELE, INCLUINDO CUSTOS E HONORÁRIOS
                    ADVOCATÍCIOS.
                </C.Paragraph>
                <C.Subtitle>
                    12.1. REGRAS DE PUBLICAÇÃO DO CONTEÚDO GERADO PELO USUÁRIO
                    E CONDUTA DO USUÁRIO
                </C.Subtitle>
                <C.Subtitle className="spaced" >
                    12.1.1. RESPONSABILIDADE
                </C.Subtitle>
                <C.Paragraph>
                    Você é o único responsável pelo seu conteúdo gerado ou publicado, por avaliações,
                    compartilhamentos, comentários, conteúdos moderados, por suas interações com outros
                    usuários e assinantes, por sua opinião e por sua atividade na Plataforma. Não tome
                    nenhuma atitude e não publique nada contrário às normas vigentes, a moral e os bons
                    costumes e, ainda, não tome nenhuma atitude e/ou publique algo que, de qualquer forma,
                    poderá expor a qualquer tipo de dano ou responsabilidade a “OLA PODCASTS” ou seus
                    usuários registrados ou não, e assinantes em geral.
                    <br /><br />
                    A “OLA PODCASTS” se reserva ao direito de excluir todo e qualquer conteúdo que, a seu
                    único e exclusivo critério, considere ofensivo ou inapropriado, inclusive mediante
                    denúncia de terceiros.
                    <br /><br />
                    Não publique ou divulgue nenhum conteúdo gerado pelo usuário ou utilize a Plataforma
                    de forma que ela viole qualquer Lei, crie responsabilidade ou promova atividades ilegais.
                    Não tome nenhuma atitude ou faça nada na Plataforma com a intenção de prejudicar,
                    desabilitar, interferir, tumultuar, danificar, desqualificar, incapacitar, sobrecarregar ou
                    limitar a funcionalidade de qualquer software ou hardware de computador, equipamentos
                    de telecomunicação ou a Plataforma. Não publique ou divulgue conteúdo gerado pelo
                    usuário que contenha vírus de software, programas ou outros códigos de informática. Não
                    drible, altere por qualquer meio ou modifique nenhuma tecnologia de segurança ou
                    software da Plataforma.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.1.2 ADEQUAÇÃO E ATENÇÃO QUANTO AO CONTEÚDO GERADO
                    PELO USUÁRIO
                </C.Subtitle>
                <C.Paragraph>
                    Atenha-se às discussões e publique apenas conteúdos, vídeos e comentários construtivos
                    na Plataforma. A menos que a Plataforma destaque e pergunte sobre, não fale sobre
                    questões não relacionadas ao tema proposto, pois você estará também colaborando com o
                    processo de aprendizado e experiência dos demais usuários da Plataforma.
                    <br /><br />
                    Acusações, exageros e insultos são proibidos. Não publique qualquer conteúdo ou um link
                    de um website que, a critério da “OLA PODCASTS”, seja ilegal, ofensivo, difamatório,
                    malicioso, fraudulento, falso, indecente, ameaçador, intimidador, odioso, abusivo,
                    obsceno, pornográfico, violento, invasivo de privacidade, publicitário, de propriedade
                    intelectual, ou preconceituoso a qualquer título que irá ameaçar ou prejudicar a segurança
                    de outros ou que seja motivo de objeção. Não publique fotos, vídeos ou textos de outras
                    pessoas sem o consentimento delas. Não persiga, intimide, abuse, ameace, assedie ou
                    prejudique outro usuário da Plataforma.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.1.3 MANTENHA E PROTEJA A SUA PRIVACIDADE
                </C.Subtitle>
                <C.Paragraph>
                    Não publique informações pessoais na Plataforma. Não colete ou solicite informações
                    pessoais de outros usuários da Plataforma ou envie mensagens não solicitadas ou outros
                    comunicados. Não colete, utilize ou publique na Plataforma as informações privadas de
                    qualquer outra pessoa sem o consentimento dela ou para quaisquer fins ilegais.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.1.4 PESSOALIDADE - ORIGINALIDADE
                </C.Subtitle>
                <C.Paragraph>
                    A Plataforma não deve ser utilizada para nenhum outro fim comercial, por essa razão não
                    publique nenhuma propaganda, solicitação ou conteúdo comercial de qualquer tipo na
                    Plataforma ou aceite pagamentos de terceiros em troca da realização de atividade
                    comercial na Plataforma. Não publique nenhum conteúdo gerado pelo usuário que
                    envolva a transmissão de “lixo eletrônico”, “correntes”, e-mail em massa não solicitado
                    ou “spamming.” Não utilize scripts automatizados para coletar informações da
                    Plataforma, ou interagir com a mesma.
                    <br /><br />
                    Não se passe por outra pessoa, incluindo – exemplificando, mas sem se limitar a--,
                    funcionários da “OLA PODCASTS”. Não se apresente com nome ou idade falsos. Sendo
                    assim, não dê uma impressão falsa de você mesmo, da sua idade ou da sua afiliação com
                    qualquer pessoa ou entidade. Não crie registros para uma conta “OLA PODCASTS” em
                    nome de outra pessoa, grupo ou entidade, não venda, transfira ou autorize o seu perfil ou
                    sua conta a terceiros. Não utilize, ou tente utilizar, a conta, o nome de usuário/senha de
                    outra pessoa. Conforme já disposto, você é responsável por manter a sua senha segura e
                    secreta, tendo em vista que a mesma é pessoal, intransferível e de sua (usuário) inteira e
                    única responsabilidade, não havendo a responsabilidade da “OLA PODCASTS” neste
                    sentido. Por essa razão, caso você use um dispositivo que outros também tenham acesso,
                    deverá sempre realizar o logout da sua conta após utilizar a Plataforma.
                    <br /><br />
                    Você é responsável por garantir que todo conteúdo que você publicar não infringe ou
                    infringirá os direitos de qualquer pessoa, incluindo direitos autorais, marcas registradas,
                    patentes, segredos comerciais, privacidade, imagem, publicidade ou outros direitos
                    pessoais e de propriedade. Você se compromete a não enviar conteúdos a menos que você
                    seja o dono ou tenha a permissão por escrito e formalizada do dono de publicar tal
                    conteúdo e garantir a “OLA PODCASTS” todas as licenças de direitos inclusos nestes
                    TERMOS, CONDIÇÕES E POLÍTICAS DE USO.
                </C.Paragraph>
                <C.Subtitle >
                    12.2. REGRAS GERAIS PARA CONTEÚDO GERADO PELO USUÁRIO
                    REGISTRADO OU ASSINANTE
                </C.Subtitle>
                <C.Subtitle className="spaced" >
                    12.2.1. O CONTEÚDO NÃO É VERIFICADO PREVIAMENTE PELA “OLA
                    PODCASTS”
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” não modera, pré-seleciona, verifica, classifica, analisa, realiza
                    aprovações ou controle editorial prévio do conteúdo gerado pelo usuário. A “OLA
                    PODCASTS” não garante que a Plataforma será livre de conteúdos que sejam
                    inadequados, ofensivos, ameaçadores, difamatórios, ilegais ou questionáveis ou
                    censuráveis. A “OLA PODCASTS” é meramente um canal passivo para distribuição
                    deste conteúdo gerado pelo usuário e não é responsável pelo conteúdo gerado pelo usuário
                    registrado ou assinante ou pela atividade dos usuários registrados ou assinantes na
                    Plataforma. A “OLA PODCASTS” se reserva o direito de remover ou negar a publicação
                    de qualquer conteúdo ou encerrar o seu cadastro por qualquer razão ou motivo que seja
                    contrário às normas vigentes, a moral e os bons costumes e/ou qualquer publicação que,
                    de qualquer forma, poderá expor a qualquer tipo de dano ou responsabilidade a “OLA
                    PODCASTS” ou seus usuários registrados ou não, e assinantes.
                    <br /><br />
                    Mesmo se a “OLA PODCASTS” vier a monitorar qualquer conteúdo gerado pelo usuário,
                    a “OLA PODCASTS” não assume qualquer responsabilidade por ação ou omissão, ou
                    tem qualquer obrigação de monitorar ou remover da Plataforma, qualquer conteúdo
                    gerado pelo usuário. O usuário é o único responsável pelo conteúdo gerado por ele
                    disponibilizado e publicado na Plataforma.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.2. ELEGIBILIDADE E REGISTRO
                </C.Subtitle>
                <C.Paragraph>
                    Para tornar-se um usuário registrado da Plataforma “OLA PODCASTS”, ou publicar
                    conteúdo gerado pelo usuário, você precisa se registrar no endereço
                    www.olapodcasts.com ou diretamente no aplicativo da Plataforma.
                    <br /><br />
                    Você concorda em fornecer informações precisas e atuais sobre você em todos os
                    formulários de registro na Plataforma.
                    <br /><br />
                    No caso de menores de 18 anos ou outras pessoas que necessitem de representação ou
                    assistência na forma da Lei, o cadastramento deverá ser realizado com o consentimento
                    específico e em destaque de pelo menos um dos pais ou pelo representante legal,
                    conforme instruções prévias constantes da tela inicial de cadastramento. Se você for
                    menor de 18 (dezoito) anos, quando for mencionado “você”, nestes TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO, será entendido que as declarações tenham sido
                    outorgadas por você e seu responsável legal. A sua conta pode ser deletada sem aviso se
                    você adulterar a sua idade, seja você mais novo ou mais velho. Os conteúdos gerados pelo
                    usuário registrado neste caso serão de integral responsabilidade dos pais ou representantes
                    legais que autorizaram a utilização da Plataforma.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.3. DIREITOS DA “OLA PODCASTS” À SUA PUBLICAÇÃO
                </C.Subtitle>
                <C.Paragraph>
                    O conteúdo gerado pelo usuário não é confidencial nem de sua propriedade exclusiva.
                    Você concede neste ato, e garante que você possui o direito de conceder à “OLA
                    PODCASTS” o direito não exclusivo, irrevogável, mundial, transferível, livre de
                    royalties e perpétuo de utilizar o seu conteúdo gerado de qualquer maneira, forma ou em
                    qualquer mídia já ou futuramente desenvolvida, inclusive as imagens, fotos, vídeos, som
                    de voz, dentre outros, para qualquer propósito ou finalidade, inclusive comercial,
                    publicitário ou qualquer outra que seja, incluindo o direito de traduzir, exibir, reproduzir,
                    modificar, editar, criar derivativos, sublicenciar, distribuir e comercializar sem nenhum
                    tipo de pagamento para você.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.4. RECLAMAÇÕES DE VIOLAÇÃO DE DIREITOS AUTORAIS
                </C.Subtitle>
                <C.Paragraph>
                    Valorizamos e respeitamos os direitos de propriedade intelectual (em especial os direitos
                    autorais), sendo essa uma condição para a sua utilização da “OLA PODCASTS”.
                    <br /><br />
                    A “OLA PODCASTS” possui canal de notificação sobre violação de direitos autorais que
                    você acredite que possa estar ocorrendo em nossa plataforma. Analisaremos todas as
                    denúncias recebidas e, caso preenchidos os requisitos de verificação de legitimidade e
                    veracidade das alegações recebidas, promoveremos a retirada do conteúdo indevido do
                    ar.
                    <br /><br />
                    Se você achar que o seu trabalho foi copiado de forma imprópria e publicado na
                    Plataforma a ponto de constituir uma violação, precisamos que nos forneça as seguintes
                    informações: (1) nome, endereço, número de telefone, endereço de e-mail e assinatura
                    eletrônica ou física do dono do direito atual ou da pessoa autorizada para atuar em seu
                    nome; (2) descrição e link do conteúdo protegido pelos direitos autorais que você afirma
                    que foi violado; (3) uma descrição sobre onde se encontra na Plataforma o material que
                    você afirma que foi violado; (4) uma declaração por escrito que você está agindo de boa-fé e acredita que o uso contestado não é autorizado pelo dono do direito autoral, pelo seu
                    representante, ou pela Lei; e (5) uma declaração redigida por você, sob pena de multa,
                    responsabilização civil pelos prejuízos e danos causados ou perjúrio, afirmando que as
                    informações acima no seu comunicado são exatas, corretas e que você é o dono do direito
                    autoral ou está autorizado a atuar em nome do dono do direito autoral que você afirma
                    que foi violado.
                    <br /><br />
                    Estes requisitos devem ser seguidos para fornecer à “OLA PODCASTS” um aviso
                    legalmente suficiente sobre a violação. Envie as reclamações de violação aos direitos
                    autorais para: denuncia@olapodcasts.com.
                    <br /><br />
                    Caso você tenha algum conteúdo retirado do ar em razão de denúncia de violação de
                    direitos autorais realizada por terceiro, e não concorde com a denúncia, poderá entrar em
                    contato com a “OLA PODCASTS” apresentando as informações e documentações que
                    atestam a legitimidade autoral por meio do nosso atendimento ao cliente –
                    denuncia@olapodcasts.com.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.5. LINKS
                </C.Subtitle>
                <C.Paragraph>
                    A Plataforma pode conter Links de websites, aplicativos ou outros produtos ou serviços
                    operados por outras empresas ("Plataformas de Terceiros"). A “OLA PODCASTS” não
                    apoia, monitora ou possui nenhum controle sobre estas Plataformas de Terceiros, que
                    possuem termos de uso e políticas de privacidade individuais e autônomas. A “OLA
                    PODCASTS” não é responsável pelo conteúdo ou pelas políticas de Plataformas de
                    Terceiros e o acesso a estas Plataformas de Terceiros é por sua exclusiva conta e risco.
                    <br /><br />
                    A “OLA PODCASTS” pode interagir com aplicativos, sites, serviços e conteúdo de
                    terceiros, inclusive mediante o redirecionamento para acesso de Podcasts em outras
                    plataformas. Estas Plataformas de Terceiros podem ter suas próprias regras, em especial
                    termos de uso, política de privacidade e regras quanto a direitos de propriedade
                    intelectual. Você reconhece que o uso de serviços e acesso a conteúdos de terceiros aqui
                    descritos serão regidos pelas regras próprias dos terceiros, e que a “OLA PODCASTS”
                    não possui nenhuma ingerência, relação ou responsabilidade pela sua relação com as
                    Plataformas de Terceiros.
                    <br /><br />
                    A “OLA PODCASTS” não se responsabiliza de forma alguma por qualquer transação
                    que você venha a realizar com qualquer Plataformas de Terceiros.
                    <br /><br />
                    A “OLA PODCASTS” não garante a compatibilidade do seu Dispositivo com as
                    Plataformas de Terceiros.
                    <br /><br />
                    Você poderá, ocasionalmente, ser informado pela “OLA PODCASTS” sobre o
                    redirecionamento para acesso a conteúdo ou serviço de terceiros.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.6. SERVIÇOS MÓVEIS
                </C.Subtitle>
                <C.Paragraph>
                    A Plataforma contém serviços e recursos que estão disponíveis para dispositivos móveis.
                    Caso o sistema de seu dispositivo seja incompatível com a versão da Plataforma, você
                    deverá acessá-la por outro meio disponível já informado nestes TERMOS, CONDIÇÕES
                    E POLÍTICAS DE USO. Os preços e as taxas normais da sua operadora são aplicáveis.
                    <br /><br />
                    Ao utilizar os serviços móveis da “OLA PODCASTS”, você concorda que iremos nos
                    comunicar com você por meios eletrônicos ao seu dispositivo móvel e que algumas
                    informações sobre o seu uso destes serviços podem ser compartilhadas conosco. Caso
                    altere ou desative o seu número de celular, você deve atualizar imediatamente as
                    informações da sua conta para garantir que não enviaremos as suas mensagens para outra
                    pessoa.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.7. INDENIZAÇÃO
                </C.Subtitle>
                <C.Paragraph>
                    Você concorda em indenizar, ressarcir, defender e isentar a “OLA PODCASTS”, suas
                    afiliadas, diretores, conselheiros, funcionários, representantes, licenciadores e
                    fornecedores de qualquer responsabilidade relacionada a reivindicações, prejuízos,
                    responsabilidades civis, despesas, danos e custos, incluindo, entre outros, honorários
                    advocatícios derivados do, ou relacionados de qualquer forma ao conteúdo gerado pelo
                    usuário, ao seu uso do Conteúdo, ao seu uso da Plataforma, à sua conduta relacionada à
                    Plataforma ou aos outros usuários da Plataforma, ou qualquer violação destes TERMOS,
                    CONDIÇÕES E POLÍTICAS DE USO, de qualquer Lei ou dos direitos de terceiros por
                    você inobservado.
                </C.Paragraph>
                <C.Subtitle className="spaced" >
                    12.2.8. PRIVACIDADE
                </C.Subtitle>
                <C.Paragraph>
                    Nossa Política de Privacidade, que é parte destes TERMOS, CONDIÇÕES E
                    POLÍTICAS DE USO, descreve a coleta e o uso das informações nesta Plataforma.
                </C.Paragraph>
                <C.Subtitle>
                    13. FUNCIONAMENTO E MODIFICAÇÕES DA PLATAFORMA
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” envidará todos os seus melhores esforços para manter a
                    plataforma em pleno funcionamento. Contudo, o usuário deve estar ciente de que, por
                    manutenções ou dificuldades técnicas, pode haver interrupções temporárias no serviço no
                    fornecimento do serviço.
                    <br /><br />
                    A “OLA PODCASTS” se reserva o direito de realizar interrupções no fornecimento do
                    serviço em razão de paradas programadas para ajustes, atualizações e melhorias do
                    sistema para, periodicamente e/ou a qualquer momento, modificar ou descontinuar,
                    temporária ou permanentemente, funções e recursos da “OLA PODCASTS”.
                    <br /><br />
                    Sempre que possível, enviaremos notificação acerca das interrupções e modificações no
                    sistema.
                    <br /><br />
                    Esteja ciente de que nenhum serviço ou conteúdo de nossa plataforma é permanente ou
                    inalterável, não sendo cabível nenhum tipo de reclamação ou indenização por conta de
                    alteração de conteúdo.
                </C.Paragraph>
                <C.Subtitle>
                    14. INTERAÇÃO COM OUTROS USUÁRIOS
                </C.Subtitle>
                <C.Paragraph>
                    Você é o único responsável por suas interações com outras pessoas, seja online ou
                    pessoalmente. A “OLA PODCASTS” não é responsável por, ou está sujeita a, qualquer
                    prejuízo ou dano resultante de qualquer interação com outros usuários da Plataforma,
                    consultores especialistas ou pessoas que você conhecer através ou em função da
                    Plataforma. Você concorda em tomar as devidas precauções em todas as interações com
                    outros usuários na Plataforma e realizar investigações necessárias antes de se encontrar
                    com qualquer pessoa. A “OLA PODCASTS” não tem qualquer obrigação de se envolver
                    em disputas entre usuários, a menos que julgue necessário na defesa de seus próprios
                    interesses.
                </C.Paragraph>
                <C.Subtitle>
                    15. PRIVACIDADE
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” valoriza a privacidade, e você terá os seus dados mantidos em
                    completo sigilo, independentemente da forma de pagamento escolhida.
                    <br /><br />
                    Ao utilizar a “OLA PODCASTS” pela primeira vez, será preciso que você faça o
                    preenchimento de um cadastro com as informações mínimas necessárias para o uso da
                    nossa plataforma, tais como nome de usuário, e-mail, idade, sexo, dados de acesso
                    através de serviços de terceiros (Facebook, Google ou Apple) e senha para a identificação
                    do Usuário. A partir de então, somente será necessário realizar seu login. Não se
                    preocupe, seus dados informados ficarão armazenados em nosso sistema. O número de
                    seu cartão de crédito e quaisquer outras informações sobre pagamento não serão
                    armazenadas.
                    <br /><br />
                    As suas informações pessoais serão armazenadas e geridas com padrões rígidos de
                    confidencialidade e segurança, e não serão repassadas a terceiros, exceto se
                    expressamente autorizado por você para os casos de contato comercial/informativos,
                    quando necessário para assegurar o fornecimento do serviço, para cobrança dos créditos
                    devidos por você perante a “OLA PODCASTS” ou por força de lei.
                    <br /><br />
                    Além dos dados informados por você, poderão ainda haver dados coletados
                    automaticamente pela “OLA PODCASTS”, tais como dados de conexão, navegação,
                    interação com os anúncios exibidos e dados gerados pelo uso da nossa plataforma.
                    <br /><br />
                    Dentre esses dados, a “OLA PODCASTS” poderá coletar informações sobre o seu
                    conteúdo favorito, histórico, playlists criadas ou seguidas (públicas e privadas) e todo
                    conteúdo acessado.
                    <br /><br />
                    Ao utilizar a “OLA PODCASTS”, você autoriza o uso de cookies. Cookies são sistemas
                    capazes de armazenar e/ou coletar informações relativas à navegação no terminal do
                    usuário (computador, tablet, etc.) e/ou à sua atividade e aos anúncios exibidos na “OLA
                    PODCASTS”, de acordo com o consentimento prévio.
                </C.Paragraph>
                <C.Subtitle>
                    16. AVISO LEGAL DE GARANTIA
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” não é responsável por qualquer conteúdo gerado pelo usuário ou
                    outro Conteúdo agregado ou postado na Plataforma, estes conteúdos são fornecidos “NO
                    ESTADO EM QUE SE ENCONTRAM” e sem garantias de qualquer tipo. Nos limites
                    máximos permitidos pela Lei, a “OLA PODCASTS” se isenta de quaisquer garantias,
                    expressas ou implícitas, incluindo, entre outros, as garantias de título, comercialização,
                    adaptação para uma finalidade específica e a não violação.
                    <br /><br />
                    A “OLA PODCASTS” não pode garantir e não promete nenhum resultado específico do
                    uso da Plataforma.
                    <br /><br />
                    A “OLA PODCASTS” não declara ou garante que a Plataforma será ininterrupta ou estará
                    livre de erros, mas assegura que eventuais defeitos serão corrigidos de forma breve No
                    limite máximo permitido por Lei, a “OLA PODCASTS” garante a qualidade da prestação
                    de serviços, referente ao uso de materiais ou Conteúdo na Plataforma em termos de
                    precisão, exatidão, adequação, utilidade e confiabilidade. Você compreende e concorda
                    que você baixa ou obtém Conteúdo por sua conta e risco, e que é o responsável pelo seu
                    uso e qualquer dano causado a você, terceiros, ao seu dispositivo móvel, sistema de
                    informática ou outro dispositivo no qual você acessa a Plataforma. A “OLA PODCASTS”
                    se reserva o direito de alterar todo e qualquer Conteúdo e outros itens utilizados ou
                    contidos na Plataforma a qualquer momento, a seu exclusivo critério garantido que o
                    usuário será informado da alteração realizada.
                </C.Paragraph>
                <C.Subtitle>
                    17. LIMITAÇÃO DE RESPONSABILIDADE
                </C.Subtitle>
                <C.Paragraph>
                    A “OLA PODCASTS” não será responsável por nenhum dano direto, especial, incidental,
                    indireto ou consequencial, incluindo quaisquer lucros ou dados perdidos resultantes do
                    uso ou da incapacidade de uso da plataforma ou pela conduta de outros usuários da
                    plataforma (seja online ou offline) e ou por qualquer conteúdo gerado pelo usuário. Você
                    assume a responsabilidade total pelo seu uso da plataforma. A única solução contra a
                    “OLA PODCASTS” para o uso da plataforma ou de qualquer conteúdo é parar de utilizar
                    a plataforma.
                    <br /><br />
                    A “OLA PODCASTS” não se responsabiliza de nenhuma forma pelo teor dos conteúdos
                    disponibilizados em nossa plataforma. O(a) criador(a) do conteúdo responde de forma
                    integral por qualquer conteúdo que viole as normas legais ou cause danos a terceiros,
                    excluindo totalmente qualquer tipo de responsabilidade da “OLA PODCASTS”.
                    <br /><br />
                    A “OLA PODCASTS” não se responsabiliza pelo funcionamento ou pelo conteúdo das
                    Plataformas de Terceiros. Ainda que sua relação com as Plataformas de Terceiros possa
                    ser regida por termos e condições distintas, seu único e exclusivo recurso, no que diz
                    respeito à “OLA PODCASTS”, para quaisquer problemas ou qualquer insatisfação com
                    as Plataformas de Terceiros ou o conteúdo dos mesmos, é desinstalar e/ou parar de usar
                    tais Plataformas de Terceiros.
                    <br /><br />
                    O usuário declara estar informado das limitações da rede da internet, que não têm
                    nenhuma ingerência da “OLA PODCASTS”. Portanto, a “OLA PODCASTS” se isenta
                    de responsabilidade por qualquer mau funcionamento do acesso à plataforma, pela
                    velocidade em que as páginas são abertas e lidas, pela velocidade de reprodução dos
                    episódios e pela inacessibilidade temporária ou permanente da plataforma.
                    <br /><br />
                    Além disto, a "OLA PODCASTS" não se responsabiliza:
                    <C.Ol type="i" >
                        <li>
                            Por eventuais danos aos Dispositivos ou à sua rede de usuários, decorrentes da
                            invasão de vírus, trojans ou demais formas de invasão e hacking durante o acesso às
                            nossas plataformas.
                        </li>
                        <li>
                            Pelo fornecimento da sua internet, pois nossos serviços são limitados apenas às
                            plataformas da “OLA PODCASTS”.
                        </li>
                        <li>
                            Por possíveis interrupções do fornecimento do serviço de nossas plataformas em
                            razão de paradas programadas para ajustes e atualizações.
                        </li>
                        <li>
                            Por possíveis alterações ou atualizações de nossas plataformas, que poderão ser
                            feitas a qualquer tempo e de modo unilateral pela “OLA PODCASTS”.
                        </li>
                    </C.Ol>
                </C.Paragraph>
                <C.Subtitle>
                    18. DISPOSIÇÕES GERAIS
                </C.Subtitle>
                <C.Paragraph>
                    Você concorda que esta Plataforma, os TERMOS, CONDIÇÕES E POLÍTICAS DE
                    USO, a Política de Privacidade e qualquer disputa entre você a “OLA PODCASTS” serão
                    regidos, em todos os aspectos, pela legislação brasileira.
                    <br /><br />
                    Você concorda que todas as disputas, reivindicações e processos jurídicos derivados
                    direta ou indiretamente, ou relacionadas a esta Plataforma (incluindo, entre outros, a
                    compra de produtos “OLA PODCASTS”) serão solucionadas individualmente, sem
                    recurso a qualquer forma de ação coletiva, e exclusivamente em tribunais brasileiros.
                    <br /><br />
                    Ao utilizar a Plataforma, você concorda em receber comunicados eletrônicos da “OLA
                    PODCASTS”. Qualquer aviso, acordo, divulgação ou outro comunicado que a “OLA
                    PODCASTS” enviar para você eletronicamente irá responder aos critérios de
                    comunicação legal, inclusive tais comunicados poderão ser enviados por escrito. Caso a
                    “OLA PODCASTS” deixe de exigir ou aplicar o desempenho rigoroso de quaisquer
                    destes TERMOS, CONDIÇÕES E POLÍTICAS DE USO, tal conduta não será
                    considerada como uma renúncia de quaisquer disposições ou direitos. Nem o curso de
                    conduta entre as partes ou a prática comercial modificarão estes TERMOS, CONDIÇÕES
                    E POLÍTICAS DE USO. A “OLA PODCASTS” pode atribuir seus direitos e deveres ao
                    amparo destes TERMOS, CONDIÇÕES E POLÍTICAS DE USO para qualquer parte, a
                    qualquer momento.
                    <br /><br />
                    Se qualquer disposição destes TERMOS, CONDIÇÕES E POLÍTICAS DE USO for
                    considerada inválida, ilegal, nula ou não aplicável, as disposições restantes destes
                    TERMOS, CONDIÇÕES E POLÍTICAS DE USO continuarão sendo aplicáveis.
                </C.Paragraph>
                <C.Subtitle>
                    19. RESCISÃO E ENCERRAMENTO DE CONTA
                </C.Subtitle>
                <C.Paragraph>
                    Os presentes TERMOS, CONDIÇÕES E POLÍTICAS DE USO terão vigência por prazo
                    indeterminado, podendo ser alterados unilateralmente pela “OLA PODCASTS” a
                    qualquer momento. Você sempre poderá ter acesso à versão atualizada e vigente dos
                    TERMOS, CONDIÇÕES E POLÍTICAS DE USO em nosso site.
                    <br /><br />
                    Os TERMOS, CONDIÇÕES E POLÍTICAS DE USO permanecerão aplicáveis durante
                    toda a utilização do “OLA PODCASTS”, até que seja encerrado por você ou por nós. No
                    entanto, você reconhece e concorda que a licença concedida por você em relação ao
                    Conteúdo do Usuário, incluindo o feedback, é irrevogável e, portanto, perdurará após a
                    expiração ou término dos TERMOS, CONDIÇÕES E POLÍTICAS DE USO, por
                    qualquer motivo.
                    <br /><br />
                    Para encerrar a sua conta na “OLA PODCASTS”, pedimos que entre em contato com o
                    nosso atendimento ao cliente, através do e-mail contato@olapodcasts.com.
                    <br /><br />
                    A “OLA PODCASTS” se reserva ao direito de encerrar a sua conta, deletar o seu perfil e
                    qualquer conteúdo gerado por você na Plataforma caso seja verificada qualquer conduta
                    contrária às descritas nestes TERMOS, CONDIÇÕES E POLÍTICAS DE USO.
                    <br /><br />
                    A “OLA PODCASTS” também se reserva o direito de bloquear usuários de certos
                    endereços de IP ou números de dispositivo considerados duvidosos, falsos ou maliciosos
                    e prevenir o acesso à Plataforma.
                    <br /><br />
                    Você compreende e concorda que seus conteúdos gerados e compartilhados em feeds de
                    atividade em outras Plataformas (ex.: Facebook, Twitter, Google, etc.) podem continuar
                    disponíveis em Plataformas de Terceiros, mesmo após a remoção do seu conteúdo ou a
                    rescisão da conta, uma vez que a “OLA PODCASTS” não se responsabiliza pela
                    manutenção e moderação dos conteúdos fora da sua Plataforma.
                    <br /><br />
                    Estes TERMOS, CONDIÇÕES E POLÍTICAS DE USO continuam em vigor mesmo
                    após o encerramento da sua conta.
                    <br /><br />
                    A “OLA PODCASTS” se reserva ao direito de suspender temporariamente e de forma
                    unilateral e a qualquer momento, desde que comunicado com antecedência aos usuários,
                    os serviços e funcionalidades da Plataforma.
                    <br /><br />
                    A “OLA PODCASTS” se reserva, também, de forma unilateral, ao direito de encerrar
                    definitivamente os serviços e funcionalidades da Plataforma, com comunicação prévia
                    aos usuários e com reversão dos valores já pagos e não utilizados em função de seu
                    encerramento, sem que haja direito à indenização pelo mesmo.
                </C.Paragraph>
                <C.Subtitle>
                    20. FALE CONOSCO
                </C.Subtitle>
                <C.Paragraph>
                    Se você tiver alguma dúvida sobre a utilização da “OLA PODCASTS”, conta e
                    pagamento, ou dos TERMOS, CONDIÇÕES E POLÍTICAS DE USO, fique à vontade
                    para entrar em contato através do nosso atendimento ao cliente, pelo e-mail
                    contato@olapodcasts.com.
                    <br /><br />
                    Muito obrigado por escolher a “OLA PODCASTS”! Esperamos que aproveitem todo o
                    conteúdo oferecido.
                    <br /><br />
                    “OLA PODCASTS” Conteúdo e Participações Ltda.
                    CNPJ 34.145.511/0001-13
                </C.Paragraph>
            </C.Main>
            <C.Rodape>
                <div className="container" >
                    OLA Podcasts conteúdo e participações • CNPJ 34.145.511/0001-13. • <a href={FAQ} target="_blank">Perguntas Frequentes</a> • <a href={'/termos-privacidade'} target="_blank">Termos, Condições e Políticas de uso</a>
                </div>
            </C.Rodape>
        </C.Container>
    )
}

export default TermosPrivacidade;