import React, { Component } from "react";

//style
import { NavBarContainer, MenuWrapper, PlayerWrapper } from "./ChannelOptionBar.style";
import { Link } from "react-router-dom";

//element
import { List } from "../../elements/List";
import { Logo } from "../../elements/Image";

//icons
import {
  HomeAlt,
  TrendingUp,
  Headphone,
  EditAlt,
  Diamond
} from "styled-icons/boxicons-regular";

import OptionImage from "../../assets/img/option.png";
import { Cog, UserCog, IdCard, MicrophoneAlt, Podcast } from "styled-icons/fa-solid";
import { Explore } from "styled-icons/material";

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionActive: this.props.optionActive
    }
  }
  componentWillReceiveProps(props){
    this.setState({
      optionActive:props.optionActive
    })
  }
  render(){
    // console.log("minhas props ", this.props);
    return(
      <NavBarContainer  >
      <MenuWrapper>
        <List>
          {/* <li onClick={()=> props.selectOption("dashboard")} className="menu-item">
            <Link >
            <Cog className="menu-icon"/>
              <span>DASHBOARD</span>
            </Link>
          </li> */}
          <li onClick={()=> this.props.selectOption("info")} className={`menu-item${this.state.optionActive=='info'?' active':''}`}>
            <Link >
              <IdCard className="menu-icon"/>
              <span>INFORMAÇÕES DO CANAL</span>
            </Link>
          </li>
          <li onClick={()=> this.props.selectOption("episodes")} className={
            `menu-item${
              this.state.optionActive=='episodes'||this.state.optionActive=='new-episodes'?
                ' active':
                ''
            }`
          }>
            <Link>
              <MicrophoneAlt className="menu-icon"/>
              <span>EPISÓDIOS</span>
            </Link>
          </li>
          <li onClick={()=> this.props.selectOption("alos")} className={`menu-item${this.state.optionActive=='alos'?' active':''}`}>
            <Link>
              <Podcast className="menu-icon"/>
              <span>ALÔS</span>
            </Link>
          </li>
          {/*
            <li onClick={()=> this.props.selectOption("access")} className={`menu-item${this.state.optionActive=='access'?' active':''}`}>
            <Link>          
              <UserCog className="menu-icon"/>
              <span>ACESSOS E USUÁRIOS</span>
            </Link>
          </li>
          */}
        </List>
      </MenuWrapper>
    </NavBarContainer>
    )
  }
}
export default NavBar;
