import styled from "styled-components";

export const Container = styled.div`
  border: 20px solid var(--color-third);
  flex: 1;
  height: auto;
  height: 130vh;
  justify-content: flex-start;
  flex-direction: column;
  .cover {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin: var(--spacing-default);
    margin-bottom: 0;
    height: 0;
    padding-bottom: 25%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-blend-mode: darken;
    span {
      color: #fff;
      margin: auto;
      font-weight: bold;
      display: flex;
      align-self: flex-end;
      align-items: flex-end;
      justify-self: flex-end;
      justify-content: flex-end;
      flex: 1;
      margin-top: 23.5%;
      margin-right: 2%;
      .icon-item {
        margin-right: 10px
      }
    }
  }
  .coverUpload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.icon-item {
  width: 22px;
}
.botao-ep {
  margin-left: 10px;
  flex: 1
}
.botaoSubmit {
  flex: 1;
  display: flex;
  align-content: center;
  margin-left: var(--spacing-default);
  margin-right: var(--spacing-default);
  margin-top: var(--spacing-default);
}
.titleLabel {
  display: flex;
  flex: 1;
  width: 100%;
}
.auth-button {
  background: #2196F3;
  color: #fff;
  border-radius: 3px;
  font-family: Avenir;
  font-size: 11.5px;
  line-height: 16px;
  margin-bottom: 15px;
  flex: 1;
}
.perfil-titulo {
  display: flex;
  flex-direction: row;
  margin: var(--spacing-default);
  .perfil {
    display: flex;
    align-items: center;
    margin-left: 80px;
    width: 180px;
    text-align: center;
    height: 180px;
    margin-top: -82px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    position: relative;
/*
    background-color: #fff;
    border: 3px solid #2196F3;
*/
    border-radius: 15px;
    display: flex;
    span {
      color: #fff;
      margin: 6px;
      background-color: #597e9c;
      background-size: cover;
      font-weight: 900;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 162px;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      /*background-color: hsla(0,0%,100%,0.70);*/
      background-blend-mode: soft-light;
    }
  }
}
.thumbUpload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  opacity: 0;
}
  .titulo-codename {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 90px;
    .titulo {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      margin-left: var(--spacing-default);
      text-align: center;
      
      input {
        width: 100%;
        /*
        border: none;
        background: #eee;
        color: #000;
        */
        border-radius: var(--radius-medium);
      }
    }
    .codename {
      width: 100%;
      text-align: left;
      margin-top: var(--spacing-small);
      margin-left: var(--spacing-default);
      color: #D1D6D9;
    }
  }

  .dropdowns {
      margin: var(--spacing-default);
      margin-bottom: 0;
      margin-top: 0;
      display: flex;
      flex-direction: row;

      .Dropdown-root {
        flex: 1;
      }
      
      .primaria {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: var(--spacing-medium);
      }
      .secundaria {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: var(--spacing-medium);
      }
      .drop {
        display: flex;
        flex: 1;
        text-align:left;
        background: #eee;
        flex-direction: column;
      }
      
  }
  input,textarea {
    width: auto;
    height: 22px;
    padding: 7px 14px;
    margin-bottom: 15px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #dbdbdb !important;
    color: #313131;
    background: #fff;
  }
  .dropdowns {
    font-family: Avenir;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    .drop {
      background-color: #fff;
      border: none;
      display: flex;
      flex: 1;
      flex-direction: row;
      text-align:left;
      padding-right: 10px;
      margin-bottom: 0;
      border-radius: 5px;
      border: 1px solid #dbdbdb;
      .Dropdown-arrow {
        margin-top: 2px;
        margin-right: 0px;
      }
    }
    .Dropdown-placeholder {
        color: #d5d5d5;
    }
    .Dropdown-placeholder.is-selected {
        font-weight: bold;
        color: #313131;
    }
    .Dropdown-menu.drop {
        flex-direction: column;
    }
    .coluna {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .coluna:first-child {
        margin-right: 10px;
    }
    .coluna:last-child {
        margin-left: 10px;
    }
  }
  .descricao {
      margin-top: var(--spacing-default);
      margin-left: var(--spacing-default);
      margin-right: var(--spacing-default);
      display: flex;
      flex-direction: column;
      /*width: 100%;*/
      .desc {
        background: #fff;
      }
      /*
      .textArea {
        background: #eee;
        border: 0;
        color: #66757F;
        padding: var(--radius-big);
      }*/
  }

  input::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal; }
  input:-moz-placeholder { color: #d5d5d5; font-weight: normal; }
  input::-moz-placeholder { color: #d5d5d5; font-weight: normal; }
  input:-ms-input-placeholder { color: #d5d5d5; font-weight: normal; }
  textarea::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal; }
  textarea:-moz-placeholder { color: #d5d5d5; font-weight: normal; }
  textarea::-moz-placeholder { color: #d5d5d5; font-weight: normal; }
  textarea:-ms-input-placeholder { color: #d5d5d5; font-weight: normal; }

  @media (max-width: 992px) {
    border: 10px solid var(--color-third);
    height: auto;
    .cover {
      margin: var(--spacing-medium);
      margin-bottom: 0;
    }
    .perfil-titulo {
      .perfil {
        margin-left: 0;
      }
    }
    .perfil-titulo, .dropdowns, .descricao, .botaoSubmit {
      margin: var(--spacing-medium);
    }
    .titulo-codename {
      .titulo, .codename {
        margin-left: 5px
      }
    }
  }
  @media (max-width: 500px) {
    .perfil-titulo {
      .perfil {
        width: 120px;
        height: 120px;
        .thumbnail {
          height: 120px;
        }
      }
    }
    .auth-button,
    .botao-ep {
      font-size: 10px;
      padding: 0 5px
    }
  }
`;
