import gql from "graphql-tag";

const NEW_PLAYLIST = gql`
    mutation($name: String!){
        createPlaylist(name: $name){
            id
            name
        }
    }
`;

export default NEW_PLAYLIST;
