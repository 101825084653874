import React, { Component } from "react";
import { Link } from "react-router-dom";

//styles
import { Container } from "./ModalVideo.style.js";  

export default class ModalVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    componentDidMount(){
        document.addEventListener("abre-premium", this.abrePremium);
    }

    render() {
        return (
            <Container>
                <div id="modal-video" className="modal modal-video">
                    <a href="javascript:void(0)" className="close">X</a>
                    <iframe id="video-play" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreenstyle={{width: "100%", height: "100%"}}></iframe>
                </div>
            </Container>
        )
    }
}

