import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: var(--size-small);
  margin-top: var(--size-small);
  color: #000000;
  width: 400px;
  padding-bottom: 50px;

.btn-cortar{
  background-color: var(--color-first);
   width: 100%;
   height: 30px;
  margin-top: var(--size-base);
  border-radius: var(--radius-small);
  margin-bottom: 15px;
  color: #FFF;
  border-radius: 3px;   
}

.crop-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.controls {
  margin: auto;
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

  .title{
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: var(--size-medium);
  }
  .img-perfil{
    margin-top: var(--size-small);
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    .contentImagePerfil {
      width: 59px;
      height: 59px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #ccc;
      margin-right: 10px;
    }
    img{
      width: 55px;
      height: 55px;
    }
    button{
      margin-left: var(--size-small);
      width: auto;
      border: 1px solid #2196F3;
      border-radius: var(--radius-small);
      padding: 5px 16px;
      font-size: 12px;
      line-height: 24px;
    }
  }
  .formulario{
    margin-top: var(--size-big);
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    display: flex;
    flex-direction: column;

    input{
      margin-bottom: 10px;
      width: auto;
      flex: 1;
      display: flex;
      padding: 9px 10px;
    
    }
    .drop{
      margin-bottom: 10px;
      background: #eee;
      border: none;
    }

    .auth-button{
      width: 50%;
      margin-top: var(--size-base);
      border-radius: var(--radius-small);
      background: #2196F3;
      color: #FFF;
      border-radius: 3px;
    }
  }
  @media (max-width: 992px) {
    width: auto;
    margin-right: var(--size-small);
    .inputy-file {
      width: 85%
    }
    .formulario {
      .auth-button {
        padding: var(--size-base);
      }
    }
  }
`;
