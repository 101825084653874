import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { ButtonGroup } from "../../elements/CTA";
import M from "materialize-css";

import { Header } from "../../components/Header";
import { NavBar } from "../../components/NavBar";
// import HorizontalPlayer from "./components/HorizontalPlayer/HorizontalPlayer";
import PlayerHorizontal from '../../components/PlayerHorizontalComponent';
import { Container } from "../../screens/Main/styles";
import ModalRestrationLogin from '../../components/ModalRestrationLogin/ModalRestrationLogin';
import Rodape from '../../components/Rodape/Rodape';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import DataLayer from "../../assets/dataLayer/dataLayer";

const PLAYER_VISIBLE = 'playerVisible';

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

class layout extends Component {
  constructor(props) {
    super(props);
    let playerVisible = localStorage.getItem(PLAYER_VISIBLE) ? JSON.parse(localStorage.getItem(PLAYER_VISIBLE)) : { visibility: true };
    this.state = {
      mostraPlayer: playerVisible.visibility
    }
  }
  componentDidMount() {
    var sidenav = document.querySelectorAll('.sidenav');
    var instancesSide = M.Sidenav.init(sidenav, { edge: 'right' });
  }
  showHorizontalPlayer = () => {
    let toggle = !this.state.mostraPlayer;
    this.setState({
      mostraPlayer: toggle
    },
      () => localStorage.setItem(PLAYER_VISIBLE, JSON.stringify({ visibility: toggle }))
    );

  }
  render() {
    //GOOGLE ANALITCS
    const trackingId = "UA-154124614-1";
    ReactGA.initialize(trackingId);
    const history = createBrowserHistory();
    ReactGA.set({ page: history.location.pathname }); // Update the user's current page
    ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
    //FIM

    const { children } = this.props;

    return (
      <>
        <DataLayer />
        <Header />
        <Container>
          <NavBar />
          {children}
        </Container>
        <PlayerHorizontal {...this.props} mostraPlayer={this.state.mostraPlayer} showHorizontalPlayer={this.showHorizontalPlayer} />
        <div id="slide-out2" className="sidenav menu-account-sidenav">
          <ModalRestrationLogin hideModal />
        </div>
        <Container className="rodape-footer"><Rodape /></Container>
      </>
    );

  }
};

export default layout;
