import gql from "graphql-tag";

export const myChennelFollowingLists = gql`
  query userFeedChannelsFollowing {
    myPlaylists(page: 1, limit: 5){
      data{
        id
        name
      }
      total
      per_page
      current_page
      has_more_pages
    }
    myChannels{
        id
        name
        thumbnail(width: 100, height: 100)
        cover(width: 250, height: 250)
    }
    userFeedChannelsFollowing {
      data {
        id
        name
        description
        flag
        thumbnail
        following
        followers_count
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
    userChannelsFollowing {
      data {
        id
        name
        codename
        main_category {
          id
        }
        description
        flag
        premium
        thumbnail(width: 400, height: 400)
        cover(width: 1200, height: 300)
        followers_count
        following
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`;
