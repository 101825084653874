import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: var(--size-small);
  margin-top: var(--size-small);
  color: #000000;
  width: 400px;
  .title{
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: var(--size-medium);
  }
  .signatures{
    border: 1px dashed #D1D6D9; 
    padding: 19px 14px;   
    border-radius: 3px;
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: var(--size-medium);
    color: #000000;
    .tipo-status{
      display: flex;
      flex-direction: row;
      font-family: Avenir;
      .tipo{
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
        line-height: 33px;
      }
      .status{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        color: #6FCF97;
      }
    }
    .data-pagamento{
      display: flex;
      flex-direction: row;
      font-family: Avenir;
      font-size: 12px;
      line-height: 16px;
      .data{
        display: flex;
        justify-content: flex-start
      }
      .pagamento{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  @media (max-width: 992px) {
    width: auto;
    margin-right: var(--size-small);
  }
`;
