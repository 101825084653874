import React, { Component } from "react";

import { Close } from "styled-icons/material";
import M from "materialize-css";
import { ACCESS_TOKEN } from '../../constants';

import { ALOS } from "../../constants/alos";

import BannerTecMundo from "../../assets/img/banners/tec-mundo_banner.svg";

export default class AloModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alos: '',
      channel: '',
      currentTimeWidth: [],
      currentPlaying: 0,
      started: false,
      fechandoModal: false
  }

    // this.elems1 = document.querySelectorAll(".modal");
    // this.instance = M.Modal.init(this.elems1, {});
  }

  componentDidMount() {
    document.addEventListener("playAlo", this.eventAlo);
    document.addEventListener("fechaModalAlo", this.eventCloseAlo);
  }

  // fetchAudio = async (uri) => {
  //   let authorization;
  //   if(localStorage && localStorage.length){
  //       let token = localStorage.getItem(ACCESS_TOKEN)
  //       if(token) authorization = `Bearer ${token}`
  //   }
  //   const requestOptions = {
  //       method: 'GET',
  //       headers: {
  //       'Authorization': authorization
  //       }
  //   };
  //   var urlHttps = uri.replace(/^http\:\/\//i, "https://");
  //   return fetch(urlHttps, requestOptions)
  //       .then(res => {
  //           if (!res.ok)
  //               throw new Error(`${res.status} = ${res.statusText}`);
  //           var reader = res.body.getReader();
  //           return reader
  //               .read()
  //               .then((result) => {
  //               return result;
  //               });
  //       })
  // }

  fetchAudio = async (uri) => {
    let authorization;
    if(localStorage && localStorage.length){
        let token = localStorage.getItem(ACCESS_TOKEN)
        if(token) authorization = `Bearer ${token}`
    }
    const requestOptions = {
        method: 'GET',
        headers: {
        'Authorization': authorization
        }
    };
    // var urlHttps = uri.replace(/^http\:\/\//i, "https://");
    return fetch(uri, requestOptions)
        .then(response => response.blob())
        .then(blob => {
          return window.URL.createObjectURL(blob);
         
      });
  }

  componentDidUpdate(){
    if(!this.state.started && !this.state.fechandoModal){
      this.widthAlo = [];
      for (let i=0; i<this.state.alos.length; i++ ) {
        this.widthAlo[i] = 0;
      }
          // // console.log("Start no did update: ");
          this.startPlayback();
      }
  }
  
  startPlayback() {
    // // console.log("Start Playback");
    this.setState({
      started: true
    })
    
    let actualAlo = this.state.alos[this.state.currentPlaying];

    this.fetchAudio(actualAlo.content)
    .then((url) => {
        // // console.log("URL: ",url);
        this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].src = url;
        this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].addEventListener("timeupdate", this.timeUpdate);
        this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].addEventListener("ended", this.finalizou);
        this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].play();
    })


  }

  eventAlo = ({detail}) => {
    this.setState({
      alos: detail,
      channel: detail[0].channel,
      fechandoModal: false,
      
    });
    // // console.log("Detalhe",detail);
  }

  eventCloseAlo = ({detail}) => {
    // // console.log("Event close Alo");  
    if (!!this.state.alos[this.state.currentPlaying]) {
      this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].pause();
      this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].removeEventListener("timeupdate", this.timeUpdate);
    }
    this.setState({
      alos: '',
      channel: '',
      currentTimeWidth: [],
      currentPlaying: 0,
      started: false,
      fechandoModal: true
    });
  }

  finalizou = ( e ) => {
      // // console.log("FINALIZOU: ",e);
       this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].removeEventListener("timeupdate", this.timeUpdate);
       this[`myAudios${this.state.alos[this.state.currentPlaying].id}`].pause();

       this.widthAlo[this.state.currentPlaying] = 100;

       let current = this.state.currentPlaying + 1;
       this.setState({
         currentPlaying: current,
         currentTimeWidth: this.widthAlo
       })
       if (current < this.state.alos.length) {
        //  // console.log("Proximooooooooo");
         this.startPlayback();
       }else{
        // // console.log("Gostaria de fechar o modal aqui");
       }
  };
  
  timeUpdate = ({ srcElement }) => {
   
    let { duration, currentTime } = srcElement;
    
    this.widthAlo[this.state.currentPlaying] = (currentTime / duration) * 100
    this.setState({
      currentTimeWidth: this.widthAlo
    });
  };

  render() {
    return (
       <>
         {!!this.state && !!this.state.alos && (
            <div>
              <div className="alo-modal_action">
                <span className="alo-modal_title">{this.state.channel.codename}</span>
                <button>
                  <Close className="close-icon modal-close" />
                </button>
              </div>
              <div
                className="alo-modal_content"
                style={{
                  backgroundImage: `url(${this.state.channel.cover}) `
                }}
              />
              <div className="alo-modal_footer">
                
                {this.state.alos.map((alo, index) => (
                  <div key={`alo-div-${alo.id}`} className="alo-modal_slider">
                    <div
                      className="alo-progress_status"
                      index={index}
                      style={{
                        width: `${this.state.currentTimeWidth[index]}%`
                      }}
                    ></div>

                    <audio
                      // ref={ref => (this.myAudios[`${alo.id}`] = ref)}
                      ref={ref => {this[`myAudios${alo.id}`] = ref}}
                      id={`aloplayer-${alo.id}`}
                    >
                      
                      <source src="" type="audio/mpeg" />
                    </audio>
                  </div>
                ))}
              </div>
            </div>
           )}
       </>
    );
  }
}
