import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; 

  .alo-wrapper {
    /* width: 80px; */
    & .trend-title {
      color: #3468E6;
      font-size: 20px;
      font-weight: 900;
      line-height: 27px;
      margin-top: 0;
    }
  }
  .alo-description {
    width: 292px;
    color: #7F7F7F;
    font-size: 12px;
    font-weight: 900;
    line-height: 17px;
  }
  .contentAlos {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .alo {
    display: flex;
    /*flex: 1;*/
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .code-name {
      width: 62px;
      margin-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8em;
      text-transform: capitalize;
      text-align: center;
      color: #373737;
      font-size: 12px;
      font-weight: 900;
      line-height: 13px;
    }
  }
  .episode-card {
    width: 163px;
    min-width: 163px;
    .dropdown-content {
      left: 0px !important;
      right: 0;
      top: -145px !important;
      height: 143px !important;
      width: auto !important;
      border-radius: 10px;
      background-color: #0E53DB;
      padding: 10px 20px;
      box-shadow: 0 10px 11px -4px rgba(14,83,219,0.3);
      transform: scaleX(1) scaleY(1) !important;
      li.divider {
        display: none;
      }
      li {
        min-height: 30px;
        display: flex;
        align-items: center;
        a {
          color: #FFFFFF !important;
          padding: 0;
          min-height: auto !important;
        }
      }
      li:hover {
        background-color: transparent !important;
      }
    }
    .contentOriginal {
      max-width: 80%;
      min-height: 20px;
      border-radius: 9px;
      background-color: #FF005A;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .iconOriginal {
      width: 17px;
      height: 17px;
      margin-left: 6px;
      margin-right: 6px;
      color: #fff;
    }
    .textOriginal {
        color: #fff;
        font-family: "Avenir";
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    }
  }
  @media (max-width: 992px) {
    .alos {
      flex-direction: column;
    }
    .alo-description {
      max-width: 100%;
      margin-bottom: 12px;
    }
  }

  .home-icon {
    color: var(--color-third-darken);
    height: var(--size-medium);
    width: 24px;
    height: 24px
  }

  .banner {
    width: 100%;
    height: 300px;
    padding-left: 130px;
    margin-top: 28px;
  }

  .em-alta-carrosel{
    /* height: 350px !important; */
    scrollbar-width: none;
    overflow-y: visible;
  }
  .progress {
    margin-top: 3px;
    margin-bottom: 0;
    background-color: #edeff1;
    .determinate {
      background-color: #ff9400;
    }
  }

  .content-top-premium-genero {
    display: flex;
  }

  .content-generos{
    display: flex;
    flex-wrap: wrap;
    flex:1;
    .content-genero {
      width: 18%;
      height: 0;
      padding-bottom: 18%;
      display: flex;
      margin: 1%;
      position: relative;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #EDEFF1;
        border-radius: 3px;
        transition: 0.25s ease-in-out;
        span {
          color: #959595;
          font-size: 11px;
          padding-top: 5.5px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
        }
        .icon {
          color: #7E7E7E;
        }
      }
      a:hover {
        .icon, span { 
          color: #fff;
        }
      }
      .cotidiano:hover {
        background-color: #E52321;
      }
      .conhecimento:hover {
        background-color: #D94D4C;
      }
      .variedades:hover {
        background-color: #F08332;
      }
      .humor:hover {
        background-color: #9E673C;
      }
      .familia:hover {
        background-color: #0EADDB;
      }
      .historias:hover {
        background-color: #5E4696;
      }
      .lifestyle:hover {
        background-color: #AD97C8;
      }
      .mercado:hover {
        background-color: #00784A;
      }
      .esportes:hover {
        background-color: #65B32E;
      }
      .tecnologia:hover {
        background-color: #6EBD8F;
      }
      .top-channel-link {
        background-position: center center;
        background-size: cover;
        border-radius: 3px;
      }
    }
    .content-genero:first-child,
    .content-genero:nth-child(6) {
      margin-left: 0;
    }
  }
  @media (max-width: 425px) {
    .content-top-premium-genero {
      display: block;
      .content-generos {
        width: 100%;
        .content-genero {
          width: 30%;
          padding-bottom: 30%;
        }
        .content-genero:nth-child(6) {
          margin-left: 1%;
        }
        .content-genero:nth-child(4),
        .content-genero:nth-child(7),
        .content-genero:nth-child(10) {
          margin-left: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .content-top-premium-genero {
      display: block;
      .content-generos {
        width: 100%;
      }
    }
  }
  @media (max-width: 800px) {
    .banner {
      height: 330px;
      padding-left: var(--spacing-small);
    }
    .em-alta-carrosel{
      height: 288px !important;
      .episode-card {
        .dropdown-content{
          top: -168px !important;
        }
      }
    }
    .vertical-list {
      .vertical-box{
        .episode-card {
          .dropdown-content {
            top: 5px !important;
          }
        } 
        .episode-card:last-child {
          .dropdown-content {
            top: -168px !important;
          }
        } 
      }
    }
  }

  .banner-wrapper {
    .banner-subtitle {
      font-family: "Avenir-Book", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 33px;
      text-transform: capitalize;
      letter-spacing: 2px;
    }
    .banner-title {
      margin-bottom: var(--spacing-small);
      font-family: "Avenir-Book", sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 44px;
    }
    .banner-read_more {
      color: #ffffff;
      font-family: "Avenir-Medium";
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      padding: 0 15px;
      width: 200px;
      text-transform: capitalize;
      letter-spacing: 1.636364px;
    }
  }
  .contentAlos {
    min-height: auto;
    .carousel-item {
      width: auto;
      height: auto;
    }
  }
  .continuar-ouvindo, .minhas-inscricoes, .em-alta, .premium, .editorial, .recommended  {
    width: 100%;
    /* min-height: 350px; */
    position: relative;
    .carousel-item {
      width: auto;
      height: auto;
    }
    .contener-editorial {
      width: 33%;
    }
    .indicators {
      display: none;
    }
    /* .em-alta-pagination {
      display: flex;
      width: 60px;
      .paginationIcon{
        width: 50%;
        margin: auto;
        color: #bcbcbc;
      }
    } */
    .continuar-ouvindo-pagination, .minhas-inscricoes-pagination, .em-alta-pagination, .premium-pagination, .recommended-pagination {
      position: absolute;
      z-index: 9;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: -1px 70x rgba(0, 0, 0, 0.17);
      top: 50%;
      margin-top: -16.5px;
      right:  -16.5px;
      box-shadow: -1px 7px 11px 0 rgba(0,0,0,0.17);
      transition: 0.15s ease-in-out;
      cursor: pointer;
    }

    .continuar-ouvindo-pagination, .minhas-inscricoes-pagination, .em-alta-pagination-prev, .premium-pagination, .recommended-pagination {
      position: absolute;
      z-index: 9;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: -1px 70x rgba(0, 0, 0, 0.17);
      top: 50%;
      margin-top: -16.5px;
      left:  -16.5px;
      box-shadow: -1px 7px 11px 0 rgba(0,0,0,0.17);
      transition: 0.15s ease-in-out;
      cursor: pointer;
    }
    .continuar-ouvindo-pagination:hover, 
    .minhas-inscricoes-pagination:hover, 
    .em-alta-pagination:hover,
    .em-alta-pagination-prev:hover,
    .premium-pagination:hover,
    .recommended-pagination:hover  {
      height: 39px;
      width: 39px;
      margin-top: -19.5px;
      right: -19.5px;
      .paginationIcon {
        color: #0E53DB;
      }
    }
    #editorial-carrosel {
      min-height: auto;
      .carousel-item {
        width: 33.3%;
        min-width: 33.3%!important;
        padding-right: 7.5px;
      }
    }
    .contener-editorial {
      display: flex;
      flex: 1;
      flex-direction: column;
      /* margin-left: 7.5px; */
      width: 100%;
      min-width: 100%;
      .editorial-card {
        padding: 0;
        min-width: 100%;
      }
    }
    @media (max-width: 768px) {
      #editorial-carrosel {
        .carousel-item {
          width: 50%;
          min-width: 50%!important;
        }
      }
      .contener-editorial {
        width: 100%;
        min-width: 100%;
        padding-right: 10px;
      }
    }
    @media (max-width: 576px) {
      #editorial-carrosel {
        .carousel-item {
          width: 100%;
          min-width: 100%!important;
        }
      }
    }
    .episode-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 7.5px;
      /* margin-left: 7.5px; */
      width: 163px;
      min-width: 163px;
      .episode-thumb {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        .episode-thumb_image {
          min-height: auto;
          position: absolute;
        }
      }
      .wrapper-block {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px 0;
        .queue-icon {
          width: 12px;
          height: 22px;
          margin-top: -2px;
          align-items: flex-start;
          justify-content: flex-end;
            .iconMenu {
              color: #000;
              font-size: 15px;
            }
        }
        .title-block {
          display: flex;
          flex: 1;
          flex-direction: column;
          .channel-link {
            display: flex;
            flex: 1;
            align-items: flex-end;
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
          }
          a {
            display: flex;
            font-size: 13px;
            font-weight: 900;
            line-height: 18px;
          }
          a:first-child {
            min-height: 54px;
          }
        }
        .content-block {
          display: flex;
          flex: 1;
          align-items: flex-end;
        }
        .footer-block {
          display: flex;
          justify-self: flex-end;
          .duration {
            display: none;
          }
        }
      }
    }
  }
  .content-banner{
    article {
      height: auto;
    }
  }
  .contentBanner {
    display: block;
    flex: 1;
    flex-direction: column;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 800px) {
    .em-alta, .premium {
      min-height: 63px;
      .carousel-item {
        min-width: 150px !important;
      }
      .episode-card {
        width: 150px;
        min-width: 150px;
        margin-left: 0;
        
        .wrapper-block {
          .title-block { 
            a:first-child {
              min-height: 63px;
            }
          }
        }
        .channel-codename {
          font-size: 0.75em;
        }
      }
      .em-alta-pagination {
        display: none;
      }
    }
  }
`;

// export const Editorials = styled.section`
//   width: 100%;
//   height: auto;
//   display: flex;
//   flex-wrap: wrap;
//   /* margin-top: var(--spacing-big); */
//   /* margin-left: var(--spacing-default); */
//   .contener-editorial {
//     display: flex;
//     padding: 0;
//   }
//   .contener-editorial:nth-child(2){
//     padding: 0 0.75rem;
//   }
//   .channel-link {
//     flex: 1
//   }
//   .editorial-card {
//     margin: 0;
//     padding: 0;
//     min-width: auto;
//     flex: 1;
//   }
//   .editorial-card:hover {
//     box-shadow: 1px 0px 5px 1px #ccc;
//   }
//   .editorial-carrosel{
//     /* display: flex; */
//     .carousel-item {
//       width: 32.33%;
//       height: auto;
//     }
//   }
  
//   @media (max-width: 800px) {
//     & {
//       .contener-editorial{
//         padding: 0 0.75rem;
//         margin-bottom: 10px;
//       }
//       .editorial-card{
//         margin-right: 0;
//         margin-left: 0;
//         padding: 0;
//         min-width: auto
//       }
//     }
//   }
// `;

export const Trends = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  /*margin-left: var(--spacing-default);*/

  & .trend-title {
    /* margin-left: var(--spacing-medium);
    color: var(--color-negative); */
    color: #313131;
    font-size: 20px;
    font-weight: 900;
    line-height: 27px;
  }
  & .ola-card_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 0 10px
  }
  & .trend-list {
    justify-content: start;
  }
  & .trends-header {
    margin-bottom: 1.168rem;
  }
`;

export const Verticals = styled.section`
  height: auto;
  margin: var(--spacing-default) 0 var(--spacing-default);
  margin-right: var(--spacing-small);;
  overflow: hidden;
  display: flex;

  .vertical-header {
    margin-bottom: var(--spacing-medium);
  }
  .vertical-list.sliderContent {
    /*width: 1424px;*/
  }
  .slick-slide {
    /*width: auto !important;*/
  }
  .slick-slider {
    width: 100%;
  }
  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .carousel {
    min-height: 950px
  }
  .indicators {
    display: none;
  }
  .vertical-title {
    color: var(--color-negative);
  }
  .content-colon {
    min-width: 31%;
  }
  .vertical-box {
    padding: var(--spacing-small);
    display: flex;
    flex: 1;
    flex-direction: column;
    .vertical-logo {
      width: auto;
      text-align: center;
      /*height: 100%;*/
      display: flex;
      /*flex: 1;*/
      align-items: center;
      justify-content: center;
      margin-bottom: var(--spacing-medium);
    }
  }
  .vertical-humor{
    width: 427;
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    .episode-card {
      background: var(--color-fifth);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
  }
  .wrapper-block {
    flex: 1;
    .title-block {
      display: flex;
      flex: 1;
      a:first-child {
        max-height: 44px;
      }
      a {
        display: flex;
        flex: 1;
      }
      .channel-link {
        align-items: flex-start;
      }
    }
  }
  .episode-card .duration .play-icon {
    color: #fff;
  }
  .vertical-mercado{
    .episode-card {
      background: var(--color-seventh-darken);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-conhecimento{
    .episode-card {
      background: var(--color-second-lighten);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-lifestyle{
    .episode-card {
      background: var(--color-sixth-lighten);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-esporte{
    .episode-card {
      background: var(--color-seventh);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-cotidiano{
    .episode-card {
      background: var(--color-second);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-familia{
    .episode-card {
      background: var(--color-first-lighten);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-esportes{
    .episode-card {
      background: var(--color-seventh);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-historias{
    .episode-card {
      background: var(--color-sixth);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .vertical-tecnologia{
    .episode-card {
      background: var(--color-seventh-lighten);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }  
  .vertical-variedades{
    .episode-card {
      background: var(--color-fourth);
      color: var(--color-zero);
      .channel-codename{
        color: var(--color-zero);
      }
    }
    width: 427;
  }
  .episode-card {
    padding: 5px;
    height: 167px;
    border-radius: var(--radius-small);
    margin-bottom: var(--size-base);
  }

  .vertical-box:hover {
    box-shadow: 1px 0px 5px 1px #ccc;
  }
  .pagination {
    display: flex;
    width: 60px;
    .paginationIcon {
      width: 50%;
      margin: auto;
      color: #bcbcbc;
    }
  }
  .pagination:hover {
    .paginationIcon {
      color: #656565;
    }
  }
  @media (max-width: 800px) {
    & {
      margin-right: 0;
      .pagination {
        width: 40px;
      }
      .carousel {
        min-height: 660px;
        .carousel-item {
          width: 100%;
        }
      }
      .episode-card.horizontal{
        height: 110px;
        .episode-thumb_image {
          min-height: 100px;
          min-width: 100px;
        }
      }
    }
  }
  @media (min-width: 801px) {
    & {
      .carousel {
        .carousel-item {
          width: 346px;
          min-width: 346px!important;
        }
      }
    }
  }
`;

export const AdvertisingBanner = styled.section`
  width: 100%;
  height: 300px;
  background: #e6e6e6;
  margin: var(--spacing-default) 0;
`;
