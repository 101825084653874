let ua = navigator.userAgent;
let contentFormat = "medium";
ua.lastIndexOf('Safari/') > 0 && ua.lastIndexOf('Chrome/') <= 0 && ua.lastIndexOf('Mobile/') <= 0 ? contentFormat = "download_medium" : contentFormat = "medium";

export const EPISODE_TYPE = `
    data{
        id
        title
        rating
        restricted
        description
        playCount: play_count
        content(format: "${contentFormat}")
        publishDate
        thumbnail(width: 400, height: 400)
        likes
        dislikes
        userLike
        userDislike
        premium
        original
        external
        channel{
            id
            name
            codename
            main_category {
                id
            }
            flag
            thumbnail
            premium
            original
        }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
`;
