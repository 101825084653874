import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import UserIconDefault from '../../../assets/img/default-avatar.png';
import { Title } from "../../../elements/Typography";

//styles
import { Container } from "../styles";
import { Comentario, EpisodeContainer, RecommendedEpisodes } from "./styles";

import { sendMessageToUser } from '../../../common';

import { ACCESS_TOKEN, USER_ACCOUNT } from '../../../constants';

//component
import EpisodeCardComponent from "../../../components/EpisodeCardComponent";
import PlayerComponent from "../../../components/PlayerComponent";

//constants
import { Button } from "../../../elements/CTA";

import { Query, graphql, useMutation } from 'react-apollo';
import { compose } from 'recompose';
import Layout from "../../../hoc/Layout/Layout";
import { COMMENT_MUTATION, DELETE_COMMENT_MUTATION, EDIT_COMMENT_MUTATION } from '../../../services/graphql/mutations';
import { EPISODE_QUERY, RECOMEND_EPISODE_QUERY } from '../../../services/graphql/query';

// Icons
import { Edit } from 'styled-icons/material/';
import { Trashcan } from "styled-icons/octicons";

import moment from "moment";
import "moment/locale/pt-br";
import Textarea from "react-textarea-autosize";
import { Play3 } from "styled-icons/icomoon/";


const Episode = (props) => {
	const { episodes } = props.data;
	const { data } = props;
	const [comentario, setComentario] = useState('')
	const [comentarios, setComentarios] = useState('');
	const [commentEdit, setCommentEdit] = useState('');
	const [qtdComentarios, setQtdComentarios] = useState(0);
	const [episode, setEpisode] = useState(null);
	const [recommendedEpisodes, setRecommendedEpisodes] = useState(null);
	const [loading, setLoading] = useState(false);
	const [editIndex, setEditIndex] = useState(-1);

	//Mutations
	const [createComment] = useMutation(COMMENT_MUTATION);
	const [deleteComment] = useMutation(DELETE_COMMENT_MUTATION);
	const [editComment] = useMutation(EDIT_COMMENT_MUTATION);

	useEffect(() => {
		if (data) {
			if (data.episodes && !data.episodes.data[0].block_comments && data.episodes.data[0].comments && data.episodes.data[0].comments.data !== comentarios && comentarios == '') {
				setComentarios(data.episodes.data[0].comments.data);
			}

			if (data.episodes && !data.episodes.data[0].block_comments && data.episodes.data[0].comments && data.episodes.data[0].comments.total !== qtdComentarios) {
				setQtdComentarios(data.episodes.data[0].comments.total);
			}

			if (data.episodes && data.episodes.data[0] !== episode) {
				setEpisode(data.episodes.data[0]);
			}

			if (data.recommendedEpisodes && data.recommendedEpisodes !== recommendedEpisodes) {
				setRecommendedEpisodes(data.recommendedEpisodes);
			}

			if (data.loading !== loading) {
				setLoading(data.loading);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const kFormatter = (num) => {
		return Math.abs(num) > 999
			? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
			: Math.sign(num) * Math.abs(num);
	};

	const renderPlayCount = (playCount) => {
		if (playCount) {
			return (
				<>
					<Play3 className="play-icon" /> {kFormatter(playCount)}
				</>
			);
		} else {
			return <></>;
			// return <>nenhum play ainda</>
		}
	};

	useEffect(() => {
		if (episodes && episodes.data && episodes.data[0].title) {
			// Title
			document.title = `OLA Podcasts - ${episodes.data[0].title}`;
			document.querySelector('meta[name=title]').setAttribute("content", `OLA Podcasts - ${episodes.data[0].title}`);
			document.querySelector('meta[property="og:title"]').setAttribute("content", `OLA Podcasts - ${episodes.data[0].title}`);
			document.querySelector('meta[property="twitter:title"]').setAttribute("content", `OLA Podcasts - ${episodes.data[0].title}`);
			document.querySelector('meta[property="og:image"]').setAttribute("content", episodes.data[0].thumbnail);
			document.querySelector('meta[property="twitter:image"]').setAttribute("content", episodes.data[0].thumbnail);
			document.querySelector('meta[property="og:image:type"]').setAttribute("content", "image/png");
			document.querySelector('meta[property="og:image:width"]').setAttribute("content", "600");
			document.querySelector('meta[property="og:image:height"]').setAttribute("content", "600");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [episodes]);


	if (episode && episode.premium && (!document.userAccount || !document.userAccount.premium) && true == false) {
		sendMessageToUser('Você precisa de uma assinatura para acessar esse conteúdo!');
		return <Redirect to={`/channels/${episode.channel.codename}`} />;
	}

	const handleComment = () => {
		let comment = { episode_id: episode.id, text: comentario }
		let cleanComments = comentarios;
		for (let i = 0; i < cleanComments.length; i++) {
			cleanComments[i].edit = false;
		}
		setComentario('');
		createComment({ variables: comment })
			.then(({ data }) => {
				let oComentario = data.createComment;
				oComentario.user.id = JSON.parse(localStorage.UserAccount).id;
				setComentarios([oComentario, ...comentarios])
				setQtdComentarios(qtdComentarios + 1)
				sendMessageToUser("Comentário criado com sucesso!");
				editIndex >= 0 && setEditIndex(editIndex + 1)
			})
			.catch((error) => {
				console.log(error.message)
				sendMessageToUser('É necessário ter um email verificado para poder comentar. Faça isso em "Minha Conta" e comece a interagir agora mesmo!');
			});
	}

	const handleDelete = (CommentId, index) => {
		let id = { id: CommentId }
		let comments = comentarios;
		comments.splice(index, 1);
		deleteComment({ variables: id })
			.then(async () => {
				sendMessageToUser('Comentário excluído com sucesso')
				setComentarios(comments);
			})
			.catch((error) => {
				sendMessageToUser('Não foi possível excluir seu comentário')
				console.log(error)
			});
	}

	const handleEdit = (commentId, episodeId, index) => {
		let comentario = { id: commentId, episode_id: episodeId, text: commentEdit }
		let comments = comentarios
		editComment({ variables: comentario })
			.then((data) => {
				comments[index] = data.data.editComment;
				setCommentEdit('');
				setEditIndex(-1)
				sendMessageToUser('Comentário editado com sucesso')
			})
			.catch((error) => {
				sendMessageToUser('Não foi possível editar seu comentário')
				console.log(error.message)
			});
	}

	return (
		<Layout {...props}>
			<Container className="episode-page container">
				{!!episode && (
					<EpisodeContainer>
						<div className="row">
							<div className="col m5 s12">
								<img
									className="episode-image"
									src={episode.thumbnail}
									alt=""
								/>
								<RecommendedEpisodes className='hide-on-med-and-down'>
									<strong className="recommended-title center-align">
										Episódios recomendados
									</strong>
									<Query query={RECOMEND_EPISODE_QUERY} variables={{
										category_id: episode.channel.main_category.id,
										epThumbWidth: 300,
										epThumbHeight: 300
									}}>
										{({ loading, error, data }) => {
											if (loading) return <div> {`Loading: ${loading}`} </div>;
											if (error) {
												if (error.networkError) {
													return <div> {`Error: no network`} </div>;
												}
												if (error.graphQLErrors) {
													return <div> {`Error: ${error}`} </div>;
												}
												return <div> {`Error: ${error}`} </div>;
											}
											if (!!data.recommendedEpisodes) {
												return data.recommendedEpisodes.data.map(
													episode => (
														<EpisodeCardComponent showDetails key={`epcardep-${episode.id}`} horizontal episode={episode} />
													)
												)
											} else {
												return false
											}
										}}
									</Query>
								</RecommendedEpisodes>
							</div>
							<div className="col m7 s12">
								<div id="channel_pgepisode_btn" className="episode-header center-align">
									<Link
										to={`/channels/${episode.channel.codename}`}
										className="channel-codename"
									>
										{episode.channel.codename}
										<span
											className={`channel-flag channel-${episode.channel.flag}`}
										></span>
									</Link>
								</div>

								<div className="episode-content">
									<h5 className="episode-title">
										{episode.title}
									</h5>
									<div className="episode-player">
										<PlayerComponent key={episode.id} episode={episode} likes_count={episode.likes} dislikes_count={episode.dislikes} scope='episode' />
									</div>

									<div className="episode-plays-time">
										<div className="plays">
											{/* { this.props.data.episodes.data[0].play_count > 10 && */}
											{/* <span className="play-count">
													{ this.renderPlayCount(episode.play_count) }
											</span> */}
											{/* } */}
										</div>
										<div className="time">
											<span className="time-ago">
												{moment(episode.publishDate, "X", "pt-BR", true).fromNow()}
											</span>
										</div>

									</div>

									<div className="episode-description">
										<h6 className="left-align episode-description_title">
											DESCRIÇÃO:
										</h6>
										<p dangerouslySetInnerHTML={{ __html: episode.description }} />
									</div>
									<div className="comentarios">
										COMENTÁRIOS {episode.block_comments ? "DESABILITADOS" : `(${comentarios.length})`}
										{/* <div className="em-resposta">
												<img src={this.props.data.episodes.data[0].channel.owner.profile.profilePicture} />
										</div> */}
										{(episode.block_comments || !localStorage.getItem(ACCESS_TOKEN) || (episode.premium && !JSON.parse(localStorage.getItem(USER_ACCOUNT)).subscribed)) ?
											<div></div>
											:
											<>
												<div className="comment-box">
													<Textarea
														style={{ background: "#f7f7f5", border: "0px", color: "#666666", fontSize: "12px", fontWeight: "normal", resize: 'none', padding: '15px' }}
														placeholder="Escreva aqui seu comentário..."
														value={comentario}
														onChange={e => setComentario(e.target.value)}
														minRows={1}
														maxRows={5}
													/>
												</div>
												<div>
													<Button
														type="submit"
														className="auth-button"
														onClick={() => handleComment()}
													>
														Comentar
													</Button>
												</div>
											</>
										}
										{!episode.block_comments &&
											<div className="lista-comentarios">
												{comentarios.map((commentClass, index) => {
													return (
														<Comentario className={commentClass} key={index}>
															<div className="comment-image">
																{
																	commentClass.user.profile.profilePicture ?
																		<img src={commentClass.user.profile.profilePicture} alt="Foto de perfil" />
																		:
																		<img src={UserIconDefault} alt="Foto de perfil" />
																}
															</div>
															<div className="comment-body">
																<div className="comment-body_title">
																	<Title size="4" content={commentClass.user.profile.name} />
																	<span style={{ FontSize: '10px' }} className="time-ago">{moment(new Date(new Date(commentClass.updatedAt).getTime() + (-180 * 60 * 1000))).fromNow(true)}</span>
																</div>
																{
																	editIndex === index ?
																		<div>
																			<textarea
																				className="text-update"
																				value={commentEdit}
																				onChange={e => setCommentEdit(e.target.value)}>
																			</textarea>
																			<div className="buttons">
																				<button className="btn-cancelar"
																					onClick={() => setEditIndex(-1)}>
																					Cancelar
																				</button>
																				<button className="btn-salvar"
																					onClick={() => handleEdit(commentClass.id, episode.id, index)
																					}>
																					Salvar
																				</button>
																			</div>
																		</div>
																		:
																		<div className="comment-body_text">
																			{commentClass.body}
																		</div>
																}
															</div>
															{
																JSON.parse(localStorage.UserAccount).id === commentClass.user.id &&
																<div className="button-row">
																	<button className="btnDelete"
																		onClick={() => handleDelete(commentClass.id, index)
																		}>
																		<Trashcan className="trash-icon" />
																	</button>
																	<button className="btnEdit"
																		onClick={() => {
																			setEditIndex(index)
																			setCommentEdit(comentarios[index].body)
																		}}>
																		<Edit className="trash-icon" />
																	</button>
																</div>
															}
														</Comentario>
													)
												})}
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					</EpisodeContainer>
				)}
			</Container>
		</Layout >
	)
}
const propsToOptions = (props) => {
	return ({
		variables:
		{
			epThumbWidth: 600,
			epThumbHeight: 600,
			episode_id: parseInt(props.match.params.id),
		}
	}
	)
}
export default compose(
	graphql(EPISODE_QUERY, { options: propsToOptions }),
	graphql(DELETE_COMMENT_MUTATION),
	graphql(COMMENT_MUTATION)
)(Episode);