// components/waveform.js
import React from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'

export default class Waveform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this)
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      barWidth: 0.1,
      cursorWidth: 1,
      backend: 'MediaElement',
      responsive: true,
      height: 80,
      waveColor: '#22243b',
      progressColor: 'purple'
    })
    this.wavesurfer.load(this.props.src)
  }
  playIt = () => {
    this.wavesurfer.playPause();
  };
  render() {
    return (
      <div className='waveform'>
        <div className='wave' onClick={this.playIt}></div>
      </div>
    )
  }
}

Waveform.defaultProps = {
  src: ""
}
