import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from 'react-apollo';
import IMAGE_DEFAULT from '../assets/img/default-avatar.png'
import { CANCEL_ASSING_MUTATION, CHILD_ACCOUNTS_QUERY, CHILD_ACCOUNT_INVITE_MUTATION, CHILD_ACCOUNT_DELETE_MUTATION } from '../services/graphql/mutations/AssinaturaMutation';

import { Modal } from './DropDownUser.style';
import M from "materialize-css";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserActions from "../store/ducks/user";
import LOGO_TIM from "../assets/img/tim.png";

import { sendMessageToUser } from '../common';
import { Activity } from "styled-icons/feather";
import { Input } from "../elements/Input";

class DropDownUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assing: true,
      loading: true,
      childAccounts: [],
      availableInvites: [{ title: 'Email 1', email: '' }, { title: 'Email 2', email: '' }, { title: 'Email 3', email: '' }, { title: 'Email 4', email: '' }],
      maxInvites: 4
    }
  }
  componentDidMount() {
    let elems = document.querySelectorAll(".dropdown-trigger");
    let instances = M.Dropdown.init(elems, {
      alignment: "right",
      inDuration: 300,
      outDuration: 225,
      constrainWidth: false,
      coverTrigger: false, // Displays dropdown below the button
      stopPropogation: true
    });

    this.initializeModal();
    this.refreshChildInvites();
  }

  initializeModal() {
    var elems = document.querySelectorAll('#modal-tim1');
    var instances = M.Modal.init(elems);
  }

  refreshChildInvites() {
    let that = this;
    if (!!document.userAccount &&
      document.userAccount.subscribed) {
      this.props.client.query({
        query: CHILD_ACCOUNTS_QUERY,
        fetchPolicy: "network-only"
      }).then(({ data, errors }) => {
        console.log("Child acc: ", data);
        if (!!data && !!data.childAccounts && data.childAccounts.length > 0) {
          that.setState({
            childAccounts: data.childAccounts,
            availableInvites: [{ title: 'Email 1', email: '' }, { title: 'Email 2', email: '' }, { title: 'Email 3', email: '' }, { title: 'Email 4', email: '' }],
            loading: false,
          });
        } else {
          that.setState({
            loading: false,
          });
        }
      }).catch((erro) => {
        that.setState({
          loading: false,
        });
      })
    }
  }

  getTextForInvite(invite) {
    return invite.email;
  }

  setTextForInvite(invite, text) {
    let updatedInvites = this.state.availableInvites;
    updatedInvites[updatedInvites.indexOf(invite)].email = text;
    this.setState({
      availableInvites: updatedInvites
    })
  }

  validaEmail = (value) => {
    if (!value) return false;
    if (value.length > 0 && value.indexOf('@') > -1 && value.indexOf('.') > -1) {
      return true;
    } else {
      return false;
    }
  }

  sendInvites() {
    let { availableInvites, childAccounts, maxInvites } = this.state;
    let invites = availableInvites.filter((item) => { return this.validaEmail(item.email) && availableInvites.indexOf(item) < (maxInvites - childAccounts.length) });
    if (invites.length == 0) {
      return;
    }

    let inviteCount = 0;
    this.setState({ loading: true });
    invites.forEach(invite => {

      if (!!document.userAccount &&
        document.userAccount.subscribed) {
        this.props.client.mutate({
          mutation: CHILD_ACCOUNT_INVITE_MUTATION,
          variables: { email: invite.email }
        }).then(() => {
          inviteCount++;
          if (inviteCount == invites.length) {
            this.refreshChildInvites();
          }
        }).catch(() => {
          inviteCount++;
          if (inviteCount == invites.length) {
            this.refreshChildInvites();
          }
        })
      }
    });
  }
  deleteInvite(email) {
    let that = this;
    this.setState(
      {
        loading: true
      });

    if (!!document.userAccount &&
      document.userAccount.subscribed) {
      this.props.client.mutate({
        mutation: CHILD_ACCOUNT_DELETE_MUTATION,
        variables: { email: email }
      }).then(({ data, errors }) => {
        console.log("Child acc: ", data);
        if (!!data && !!data.deleteChildAccount) {
          that.setState({
            childAccounts: data.deleteChildAccount,
            loading: false,
          });
        } else {
          that.setState({
            loading: false,
          });
        }
      }).catch((erro) => {
        console.log("Oops: ", erro)
        that.setState({
          loading: false,
        });
      })
    }
  }

  cancelarAssinatura = () => {
    this.props.client.mutate({
      mutation: CANCEL_ASSING_MUTATION
    }).then(({ data, errors }) => {
      if (errors) {
        sendMessageToUser('Não foi possível cancelada sua assinatura!')
      } else {
        document.userAccount = data.cancelSignature;
        localStorage.setItem('UserAccount', JSON.stringify(data.cancelSignature))
        this.props.setMeRequest(data.cancelSignature);
        window.location.replace('/');
        sendMessageToUser('Sua assinatura foi cancelada sucesso!')
      }
    }).catch((erro) => {
      sendMessageToUser('Não foi possível cancelada sua assinatura!')
    })
  }
  render() {
    const { userImage } = this.props;
    let { childAccounts, availableInvites, maxInvites, loading } = this.state;
    let avatarProfile = userImage || IMAGE_DEFAULT;
    //console.log(document.userAccount)
    if (document.userAccount) {
      return (
        <>
          {/* Modal start */}
          <Modal>
            <div id="modal-tim1" className="modal">
              <div className="modalTimCarroTitle">TIM CARRO CONECTADO</div>
              <div className="modalTimCarroText">{"Envie agora seu presente pra um amigo =).\nVocê pode editar depois seus convites."}</div>
              {
                childAccounts && childAccounts.map((child) => {
                  return (
                    <div key={`${child.child_email}`} className="modalTimCarroChild">
                      <div className="modalTimFlexRowCenter modalTimFlexSpaceBetween">
                        <div className="modalTimFlexColumn">
                          <div numberOfLines={1} className="modalTimCarroChildEmail">{child.child_email}</div>
                          {child.status == 'VERIFIED' &&
                            <div className="modalTimFlexRowCenter">
                              <div className="modalTimCarroChildGreen"></div>
                              <div className="modalTimCarroChildStatus">{"Usuário ativo"}</div>
                            </div>
                          }
                          {child.status == 'PENDING' &&
                            <div className="modalTimFlexRowCenter">
                              <div className="modalTimCarroChildOrange"></div>
                              <div className="modalTimCarroChildStatus">{"Pendente"}</div>
                            </div>
                          }
                        </div>
                        <div className="modalTimCarroChildButtonDelete" onClick={() => this.deleteInvite(child.child_email)}>
                          <div className="modalTimCarroChildButtonDeleteText">EXCLUIR</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {
                availableInvites
                  .filter((item) => { return availableInvites.indexOf(item) < (this.state.maxInvites - childAccounts.length) })
                  .map((invite) => {
                    return (
                      <div
                        key={invite.title} className="modalTimInvite">
                        <div className="modalTimInviteText">{`${invite.title}`}</div>
                        <input
                          placeholder="exemplo@email.com"
                          placeholderTextColor="#B6B6B6"
                          className="modalTimInviteTextInput"
                          value={this.getTextForInvite(invite)}
                          // onFocus={() => {
                          //     // this.loginError(false);
                          //     if (Platform.OS != "android") {
                          //         this.showFloatTextInput()
                          //     }
                          // }}
                          // error={this.state.errorActive}
                          onChange={(event) => this.setTextForInvite(invite, event.target.value)}
                        />
                      </div>
                    );
                  })
              }
              <div className="modalTimFlexRowCenter modalTimFlexEnd">
                <button className="modal-close modalTimButtonClose"><div className="modal-close modalTimButtonCloseText">Fechar</div></button>
                <button className="modal-close modalTimButtonSend"
                  onClick={() => {
                    this.sendInvites()
                  }}
                ><div className="modal-close modalTimButtonSendText">Enviar</div></button>
              </div>
            </div>
          </Modal>
          {/* Modal end */}

          <div className="contentUserImage">
            <a
              href="/#"
              className="dropdown-trigger btn-large waves-effect waves-light header-user-image"
              data-target="dropdown-profile"
              data-constrainwidth="false"
              style={{ backgroundImage: `url(${avatarProfile})` }}
            >
              <img src={avatarProfile} className="responsive-img" alt="" />
            </a>
          </div>

          <ul
            id="dropdown-profile"
            className="dropdown-profile dropdown-content row"
          >
            {/* {document.userAccount.subscribed ? "" : 
              <li className="dropdown-profile_item">
                <a className="dropdown-profile_premium " href={"http://wap.toing.com.br/landing/?origem=MADNET_PMOVIL_OLAPOC_BR&returnUrl=http://" + window.location.host + "/resplanding/"}>Seja Premium</a>
              </li>
              } */}
            {
              document.userAccount &&
              document.userAccount.subscribed &&
              document.userAccount.celco === 'TIM_BUNDLE' &&
              <div className="cardTimCarro">
                <div className="cardTimCarroHeader">
                  <img src={LOGO_TIM} className="cardTimCarroLogo" alt="" />
                  <div className="cardTimCarroTitle">TIM CARRO CONECTADO</div>
                </div>
                <div className="cardTimCarroText">Com a TIM você pode convidar mais 4 amigos sem pagar mais nada por isso.</div>
                <div className="cardTimCarroButton">
                  {
                    document.userAccount &&
                      document.userAccount.subscribed &&
                      document.userAccount.celco === 'TIM_BUNDLE' ?
                      <button href="#modal-tim1" data-target="modal-tim1" className="modal-trigger cardTimCarroButtonText">{
                        loading ?
                          "AGUARDE..."
                          :
                          `ENVIAR CONVITE ${childAccounts.length}/${maxInvites}`
                      }</button>
                      :
                      <a className="cardTimCarroButtonText" href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_TIM_BUNDLE&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>ASSOCIAR CONTA</a>
                  }
                </div>
              </div>
            }
            {/* {document.userAccount && document.userAccount.celco !== 'TIM_BUNDLE' &&
              <li className="dropdown-profile_item">
                <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>Associar Número Vivo</a>
              </li>
            }
            } */}
            {document.userAccount && document.userAccount.celco !== 'TIM_BUNDLE' &&
              <li className="dropdown-profile_item">
                <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_CLARO&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>Associar Número Claro</a>
              </li>
            }
            {document.userAccount && document.userAccount.celco !== 'TIM_BUNDLE' &&
              <li className="dropdown-profile_item">
                <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_TIM&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>Associar Número Tim</a>
              </li>
            }
            { document.userAccount && document.userAccount.celco !== 'NEO' &&
              <li className="dropdown-profile_item">
                <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLA_NEO&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}>Associar Empresa Parceira</a>
              </li>
            }
            <li className="dropdown-profile_item">
              <Link to="/account/">Minha Conta</Link>
            </li>
            <li className="dropdown-profile_item">
              <Link to="/registrations/">Minhas Inscrições </Link>
            </li>
            <li className="dropdown-profile_item">
              <Link to="/channels/create/">Criar Canal</Link>
            </li>
            <li className="dropdown-profile_item">
              <Link to="/my-channels/"> Meus Canais</Link>
            </li>
            <li className="dropdown-profile_item">
              <Link to="/playlist/later/"> Ouvir mais tarde</Link>
            </li>
            <li className="dropdown-profile_item">
              <Link to="/playlist/favorites/"> Favoritos</Link>
            </li>
            {/* <li className="dropdown-profile_item">
                <Link to="/playlists/">Playlist</Link>
              </li> */}
            <li className="dropdown-profile_item">
              <Link to="/playlists/">Minhas Playlists</Link>
            </li>
            {/* <li className="dropdown-profile_item">
              <Link to="/playlists/">Dashboard</Link>
            </li> */}
            <li className="dropdown-profile_item">
              <Link className="red-text" onClick={() => {
                this.props.setLogout();
                window.location.replace('/');
              }}>Deslogar</Link>
            </li>
            {/*console.log("userAccount: ", document.userAccount*)*/}
            {
              document.userAccount &&
              document.userAccount.subscribed &&
              document.userAccount.celco === 'CC' &&
              <li className="dropdown-profile_item">
                <Link className="cancelaAssinatura" onClick={() => this.cancelarAssinatura()}>Cancelar Assinatura</Link>
              </li>
            }
          </ul>
        </>
      );
    } else {
      return (
        <>
          <div
            className="account-item"
          >
            <a
              data-target="slide-out2"
              className="sidenav-trigger"
            >
              <i className="material-icons small">account_circle</i>
            </a>
          </div>
        </>
      )
    }
  }
}

function mapStateToProps({ }) {
  return {};
}

const mapDistapchToProps = dispatch =>
  bindActionCreators({ ...UserActions }, dispatch);

export default withApollo(connect(
  mapStateToProps,
  mapDistapchToProps
)(DropDownUser));

