import React, { Component } from "react";
//style
import { 
    Container,
    SearchIcon,
    WrapperSearch
 } from "./ChannelOptionAlos.style";

 import { connect } from 'react-redux';
 
import { Query, Mutation, graphql } from 'react-apollo';
import { compose } from 'recompose';
import { AlosQuery, DELETE_ALO } from './alos.graphql';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { Link } from "react-router-dom";


import moment from "moment";
import "moment/locale/pt-br";

const confirma = (id) => {
    return new Promise( (resolve, reject) => { confirmAlert({
      title: '',
      message: 'Tem certeza que deseja apagar o alo ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { 
              resolve(true);
          }
        },
        {
          label: 'Cancelar',
          onClick: () => { reject(false) }
        }
      ]
    }) }
    )
  }; 
 

const ChannelOptionAlos = (props) => {
  const [removeAlo] = useMutation(DELETE_ALO);
  
  // console.log('props ===> ', props)
  
  return(
    <Container  >
      <div className="title">Gerenciar Alôs</div>
      {/* <WrapperSearch>
         <SearchIcon className="search-icon" />
          <Input
            type="text"
            inputClasses="inputSearch"
            id="searchManager"
            name="searchManager"
            // onChange={e => this.handleInputChange(e)}
          />
           <Dropdown
            options={["ORDEM 1", "ORDEM 2"]}
            value="ORDENAR POR"
            menuClassName="drop"
            controlClassName="drop"
          />
         
      </WrapperSearch> */}
      <div className="eps">
          <div className=" head row">
            <div className="div-play col s4">Play</div>
            <div className="div-data col s4">Data</div>
            <div className="div-menu col s4">Ações</div>
          </div>
          {!!props.data && !!props.data.alos && !!props.data.alos.data[0]? 
            props.data.alos.data.map((alo,indice) => 
              <div key={`divAlo-${indice}`} className="ep row">
                    <div className="div-play col s4">
                      <>
                        {alo.aloCms.status == "OK" ?
                          <audio id={`alo-${indice}`} controls>
                              <source src={alo.content} type="audio/mp3" />
                          </audio>
                        :
                          <div>Conteudo sendo processado</div>
                        }
                      </>
                    </div> 

                    <div className="div-data col s4">
                      <span className="time-ago">
                        {moment(alo.publishDate, "X", "pt-BR", true).fromNow()}
                      </span>
                    
                    </div>

                    <div className="menu-gerente col s4"> 
                      <div className="div-menu">
                        
                        <a   href="#ola-modal" key={`ver_${indice}`}  
                         onClick={
                           () => {
                           
                              let alos = [];
                              alos[0] = alo;
                              var ev = new CustomEvent("playAlo", { 'detail': alos });
                              document.dispatchEvent(ev);
                          }
                        }
                        className="channel-link">Ouvir</a>
                      </div>
                      <span>
  
                        <Link key={`remove_${indice}`}
                          onClick={ 
                            async () => {
                              let confirmacao = await confirma(alo.id);
                              removeAlo({variables: { alo_id: alo.id}})
                              delete props.data.alos.data[indice]
                            }
                          }
                        >Remover</Link>
                          
                      </span>
                  </div>
              </div>
            )
          : 
          <div className="ep row">
            <div className="col s12">
              <div className="center-align">O canal ainda não possui nenhum <strong>ALO</strong> cadastrado.</div>
            </div>
          </div> 
        }
      </div>

    </Container>
  )
}

const propsToOptions = (props) => {
  return (
    { variables: 
        { 
          channel_id: props.channel_id, 
          thumbWidth: 800, 
          thumbHeight: 300
        }
    }
)
}

export default compose(graphql(AlosQuery, { options: propsToOptions}))(ChannelOptionAlos);
