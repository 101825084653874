import React, { Component } from "react";

//styles
import { Container } from "./PinCodeCarrier.style.js";

//graphql
import { Query } from 'react-apollo';
import { COUNTRY } from "../TelephoneOperator/TelephoneOperator.graphql";

//elements
import { Input } from "../../../elements/Input";
import { Button, StyledLink } from "../../../elements/CTA";

//elements
import { Title, Text } from "../../../elements/Typography";

class PinCodeCarrier extends Component {
    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }
    constructor(props){
        super(props);
        this.state = { ...props.state, loading: false };
        
    }
    updateInput(input){
        this.props.updateState(input)
    }
    render(){
        // console.log("Minhas props",this.props);
        // console.log("Meu State",this.state);
        return(
            <Container>
                <Title size="1" content="Ola! Selecione sua operadora e valide seu número" typeClass="auth-title" />
                <Input
                    name="pincode"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    inputClasses="inputPinCode"
                    placeholder="Digite seu PIN CODE"
                    borderRadius="3px"
                    background="var(--color-third)"
                    color="var(--color-first)"
                    border="1px solid var(--color-third)"
                    value={this.state.pincode}
                    onChange={ (event) => { this.setState({pincode: event.target.value }); this.updateInput({pincode: event.target.value}) }}
                />
                <Button
                    type="submit"
                    className="auth-button"
                    Background="var(--color-first)"
                    color="var(--color-zero)"
                    // onClick={() => console.log(this.state)}
                >
                    Criar uma conta
                </Button>

                <div className="auth-login">
                    <StyledLink
                        type="submit"
                        className="login-button"
                        color="var(--color-first)"
                        onClick={() => this._setModalScreen("telephone-operator")}
                    >
                        Voltar
                    </StyledLink>
                </div>
            </Container>
        )
    }
}

export default PinCodeCarrier;
