import styled from "styled-components";

export const Container = styled.section`
  padding: var(--spacing-default);
  width: 100%;
  @media (max-width: 800px) {
    padding: var(--spacing-default) 0;
  }
`;

export const ContainerCont = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  .contentOriginal {
      max-width: 80%;
      min-height: 20px;
      border-radius: 9px;
      background-color: #FF005A;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .iconOriginal {
      width: 17px;
      height: 17px;
      margin-left: 6px;
      margin-right: 6px;
      color: #fff;
    }
    .textOriginal {
        color: #fff;
        font-family: "Avenir";
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    }
  .row .col {
    float: inherit;
  }
  .tabs{
    border-bottom: 1px solid #D1D6D9;
    .indicator{
      background-color: #2196F3;
    }
    .tab {
      a {
        color: #666666;
      }
      a.active {
        color:#2196F3;
        background: transparent;
        font-weight: bold;
      }
      a.focus{
        background: transparent;
      }
    }
  }
  .eps{
    margin-left: var(--spacing-small);
    margin-top: var(--spacing-default);
    .contentSearch {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .canais{
    float: left;
    width: 100% ;
  }
  .episode-card.horizontal {
    display: flex;
    margin-bottom: 15px;
    width: 48%;
    .episode-thumb {
      width: 163px;
      min-width: 163px;
      height: 163px;
    }
  }
  @media (max-width: 800px) {
    padding-left:0;
    padding-right: 0;
    .eps {
      margin-left: 0;
    }
    .episode-card {
      margin-right: 0;
      min-width: 100%;
      .episode-thumb_image {
        min-height: 100px;
        min-width: 100px;
      }
      .episode-thumb {
        .episode-thumb_image{
          height: auto;
        }
      }
    }
    .channel-card-container {
      .channel-card-content {
        margin-left: 0
      }
    }
  }
`;



export const HeaderWrapper = styled.article`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   align-content: center;

  .search-result_subtitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;

    color: #000000;
  }
  .search-result_title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 16px;
    color: #66757F;
    margin-left: var(--spacing-small);
    
  }
`;


export const ChannelAdvertising = styled.article``;
