import { EPISODE_TYPE } from './EPISODE';
export const CHANNEL_TYPE = `
    id
    name
    codename
    main_category {
        id
    }
    description
    flag
    premium
    original
    thumbnail(width: 400, height: 400)
    cover(width: 1200, height: 300)
    episodes(limit: 10){
        ${EPISODE_TYPE}
    }
    followers_count
    following
    main_category{
    id
    name
    }
    alternate_category{
    id
    name
    }
`;
