import React from "react";

const Image = ({ classImage, srcImage, media, srcset, alt }) => (
  <picture>
    <source media={media} srcSet={srcset} />
    <img src={srcImage} className={classImage} alt={alt} />
  </picture>
);

export default Image;
