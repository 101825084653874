import styled from "styled-components";

export const Container = styled.div`
width: 100%;
min-height: 350px;
display: flex;
    .premium-carrosel {
        height: 350px !important;
    }
    .carousel-item {
        width: auto;
        height: auto;
    }
    .indicators {
        display: none;
    }
    .premium-pagination {
        display: flex;
        width: 60px;
        .paginationIcon{
            width: 50%;
            margin: auto;
            color: #bcbcbc;
        }
    }
    .premium-pagination:hover {
        .paginationIcon {
            color: #656565;
        }
    }
    .episode-card {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 7.5px;
        margin-left: 7.5px;
        width: 219px;
        min-width: 219px;
        .episode-thumb {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            .episode-thumb_image {
                min-height: auto;
                position: absolute;
            }
        }
        .wrapper-block {
            display: flex;
            flex: 1;
            flex-direction: column;
            .title-block {
                display: flex;
                flex: 1;
                flex-direction: column;
                .channel-link {
                    display: flex;
                    flex: 1;
                    align-items: flex-end;
                }
                a {
                    display: flex;
                    min-height: 44px
                }
            }
            .content-block {
                display: flex;
                flex: 1;
                align-items: flex-end;
            }
            .footer-block {
                display: flex;
                justify-self: flex-end;
            }
        }
    }
    @media (max-width: 800px) {
        min-height: 63px;
        .premium-carrosel {
            height: 288px !important;
        }
        .carousel-item {
            min-width: 150px !important;
        }
        .episode-card {
            width: 150px;
            min-width: 150px;
            margin-left: 0;
            .wrapper-block {
                .title-block { 
                    a:first-child {
                        min-height: 63px;
                    }
                }
            }
            .channel-codename {
                font-size: 0.75em;
            }
        }
        .premium-pagination {
            display: none;
        }
    }
`;
