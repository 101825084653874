import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { UserType } from '../sagas/user';
import { USER_ACCOUNT } from '../../constants';

import { sendMessageToUser } from '../../common';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getMeRequest: null,
  getMeSuccess: ["data"],
  setMeRequest: ["data"],
  setLogout: null,
});

export const UserTypes = Types;
export default Creators;

/* Initial State */
var userData: UserType;

export const INITIAL_STATE = Immutable({
  data: [],
  loading: true
});

//
const updateMe = (user: UserType) => {
  if(localStorage.length && user){
    let userLocal = localStorage.getItem(USER_ACCOUNT);
    if(userLocal){
      let userJSON = JSON.parse(userLocal);
      userJSON = { ...userJSON, ...user}
      localStorage.setItem(USER_ACCOUNT, JSON.stringify(userJSON))
    }
  }else if(user){
    let userJSON = user;
    localStorage.setItem(USER_ACCOUNT, JSON.stringify(userJSON))
  }
  let message = new CustomEvent('toastMessage', {"detail": "Seu perfil foi atualizado com sucesso!"});
  document.dispatchEvent(message)
}


/* Reducers */
export const getSuccess = (state, { data }) =>
  state.merge({ data, loading: false });

export const setMeRequestSuccess = (state, { data }) => {
  updateMe(data)
  return state.merge({data, loading: false })
}

export const logoutRequest = (state, {data}) => {
  if(localStorage.length) localStorage.clear();
  sendMessageToUser('Você foi desconectado com sucesso!');
  return state.without(data);
}
/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ME_SUCCESS]: getSuccess,
  [Types.SET_ME_REQUEST]: setMeRequestSuccess,
  [Types.SET_LOGOUT]: logoutRequest
});
