import styled from "styled-components";

export const Tag = styled.span`
  background: #eaf4ff;
  /* #eaffee;  #6dae77 */
  border-radius: 4px;
  padding: 4px 6px;
  font-family: Avenir-Medium;
  font-size: 11px;
  width: auto;
  color: #6d8aae;
  letter-spacing: 1.5px;
  text-align: center;
`;
