import React, { Component } from "react";

//style
import { Container } from "./UserSignatures.style";

import { compose } from 'recompose';
import { graphql } from 'react-apollo';

import { SignaturesTypeQuery, UserSignaturesQuery } from './signatures.graphql';

class UserSignatures extends Component {
    constructor(props){
        super(props);
        this.state = {
            signatures: '',
        }
    }
    
    render(){
        const USER_ACCOUNT = 'UserAccount';

        const userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

        // console.log("UserAcc ", userAccount);

        return(
            <Container  >
                <div className="title">
                    Assinaturas
                </div>
                {!!userAccount && !!userAccount.subscribed &&
                    <div className="signatures">
                        <div className="tipo-status">
                            <div className="tipo">Assinatura OLA PODCASTS</div>
                            <div className="status">Status: ATIVA</div>
                        </div>
                        <div className="data-pagamento">
                            <div className="data">Data expiração: {userAccount.signature_expire_at}</div>
                            <div className="pagamento">Forma de Pagamento: {!!userAccount.celco ? userAccount.celco : 'DEGUSTAÇÃO' }</div>
                            {/* {!!userAccount.celco && userAccount.celco == 'CC' && 
                                <div className="pagamento">Você tem 15 dias grátis a partir da data de assinatura antes de ser cobrado.</div>
                            } */}
                        </div>
                    </div>
                } 
                {(!userAccount || !userAccount.subscribed) &&
                    <div className="signatures">VOCÊ NÃO É ASSINANTE</div>
                }
            
            </Container>
        )
    }
}

export default compose(graphql(SignaturesTypeQuery),graphql(UserSignaturesQuery))(UserSignatures);

