import styled from "styled-components";

export const InputField = styled.input`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  height: ${props => props.height};
  color: ${props => props.color};
  border: ${props => props.border};
  border-bottom: ${props => props.borderBottom};
  border-radius: ${props => props.borderRadius};
  background: ${props => props.background};
  padding: var(--size-very-small);

  &.error {
    color: var(--color-second);
    border: 1px solid var(--color-second);
  }

  &.success {
    color: var(--color-seventh-lighten);
    border: 1px solid var(--color-seventh-lighten);
  }
`;
