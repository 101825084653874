import React from "react";

import { Container, Wrapper } from "./Notifications.styles";

//elements
import { Checkbox } from "../../../elements/Input";
import { Button } from "../../../elements/CTA";

const Notifications = () => (
  <Container>
    <div className="notification-form-row">
      <div className="notification-form-description"></div>
      <div className="notification-form-checkbox">
        <strong className="notification-form-title">Push</strong>
      </div>
      <div className="notification-form-checkbox">
        <strong className="notification-form-title">Email</strong>
      </div>
      <div className="notification-form-checkbox">
        <strong className="notification-form-title">SMS</strong>
      </div>
    </div>
    <div className="notification-form-row">
      <div className="notification-form-description">Novos podcasts</div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
    </div>
    <div className="notification-form-row">
      <div className="notification-form-description">Comentários</div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
    </div>
    <div className="notification-form-row">
      <div className="notification-form-description">Lembretes</div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
      <div className="notification-form-checkbox">
        <Checkbox name="teste" />
      </div>
    </div>
    <Button
      type="button"
      className="notification-form-button"
      Background="var(--color-first)"
      Color="var(--color-zero)"
    >
      Salvar
    </Button>
  </Container>
);

export default Notifications;
