import React, { Component } from "react";
import M from "materialize-css";
import { ChevronLeft, ChevronRight } from "styled-icons/material";
import { Link } from "react-router-dom";


export default class BannerComponent extends Component {
  state = { executed: false };

  componentDidMount() {
    var elems = document.querySelectorAll(".ola-banner");
    this.instances = M.Carousel.init(elems, {
      fullWidth: true,
      indicators: true
    });
    this.autoplay();
  }

  autoplay = () => {
    if (this.state.executed) {
      !!this.instances[0] && this.instances[0].next();
    } else {
      this.setState({ executed: true });
    }
    setTimeout(this.autoplay, 7500);
  };

  // TODO - other actions, link away, subscription ...
  action = (action, args) => {
    switch (action) {
      case 'channel':
        return `/channels/${args}`;
        break;
      case 'episode':
        return `/episodes/${args}`;
        break;
        default:
        return `/${action}/${args}`;  
    }
  }

  getUrlVideo = VIMEO_ID => {
    var video = document.getElementById('modal-video');
    var slide = document.querySelectorAll('.carousel');
    var instance = M.Modal.getInstance(video);
    let elems = document.getElementById('video-play');
    elems.src = `https://player.vimeo.com/video/${VIMEO_ID}?autoplay=1`
    window.onclick = function(event) {
      if(event.target != video && !event.target.classList.contains("slide-item")) {
        elems.src = "";
        instance.close();
      }
    }
    
  }
  getUrlAudio = (TITULO, IMAGEN_URL, AUDIO_URL) => {
    // let audio = document.getElementById('audio-play');
    var elemsAudio = document.getElementById('modal-audio');
    document.getElementById('modal-audio-titulo').innerHTML = TITULO;
    document.getElementById('modal-audio-image').src = IMAGEN_URL;
    var instance = M.Modal.getInstance(elemsAudio);
    // audio.src = AUDIO_URL
    //"https://amazingaudioplayer.com/wp-content/uploads/amazingaudioplayer/8/audios/In%20the%20Moment%20of%20Inspiration.mp3"
    var audio = new Audio(AUDIO_URL);
    audio.addEventListener("canplaythrough", event => {
      audio.play();
    });
    audio.addEventListener('ended',function(){
      if (elemsAudio) {
        instance.close();
      }
    });
    window.onclick = function(event) {
      if(event.target != elemsAudio && !audio.paused) {
        audio.pause();
        instance.close();
      }
    }
  }

  openModalPremium() {
      var elems = document.getElementById('modal-premium');
      if (elems) {
        var instance = M.Modal.getInstance(elems);
        instance.open();
      }
  }
  bannerNext = () => {
    this.instances[0].next(1);
  }
  bannerPrev = () => {
    this.instances[0].prev(1);
  }

  render() {
    let slides = this.props.slides || [];
    if(slides.length){
      
      return (
        <div className="contentOlaBanner">
          <a className="banner-pagination"
            onClick={() => this.bannerPrev()}
          >
            <ChevronLeft className="paginationIcon" />
          </a>
          <div className="carousel ola-banner">
            {slides.map(slide => {
              if (slide.action == 'subscribe') {
                return <a
                  className="carousel-item modal-trigger"
                  // onClick={() => this.openModalPremium()}
                  href="#modal-premium"
                  style={{ width: "100%", display: "block" }}
                  key={slide.id}
                >
                  
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${slide.cover})`
                    }}
                  ></div>
                </a>
              } else if (slide.action == 'audio') {
                return <a
                  className="carousel-item modal-trigger"
                  onClick={() => this.getUrlAudio(
                    slide.title,
                    slide.cover,
                    slide.description
                  )}
                  href="#modal-audio"
                  style={{ width: "100%", display: "block" }}
                  key={slide.id}
                >
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${slide.cover})`
                    }}
                  ></div>
                </a>
              } else if (slide.action == 'video') {
                return <a
                  className="carousel-item modal-trigger"
                  onClick={() => this.getUrlVideo(slide.description)}
                  href="#modal-video"
                  style={{ width: "100%", display: "block" }}
                  key={slide.id}
                >
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${slide.cover})`
                    }}
                  ></div>
                </a>
              } else if (slide.action == '' || slide.action == 'none') {
                return <div
                  className="carousel-item"
                  style={{ width: "100%", display: "block" }}
                  key={slide.id}
                >
                  
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${slide.cover})`
                    }}
                  ></div>
                </div>
              } else {
                return <Link
                  className="carousel-item"
                  to={this.action(slide.action,slide.arguments)}
                  style={{ width: "100%", display: "block" }}
                  key={slide.id}
                >
                  
                  <div
                    className="slide-item slide-cover"
                    style={{
                      backgroundImage: `url(${slide.cover})`
                    }}
                  ></div>
                </Link>
              }
            })}
          </div>
          <a className="banner-pagination"
            onClick={() => this.bannerNext()}
          >
            <ChevronRight className="paginationIcon" />
          </a>
        </div>
      );
    } else {
      return false;

    }
  }
}
