import React, { useState, useCallback, Component, Fragment } from "react";
import ReactDOM from 'react-dom'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop';
import InputMask from 'react-input-mask';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserActions from "../../store/ducks/user";



//style
import { Container } from "./Profile.style";
import { Link } from "react-router-dom";

//element
import { List } from "../../elements/List";
import { Logo } from "../../elements/Image";
import { Input } from "../../elements/Input";
import { Button, StyledLink } from "../../elements/CTA";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import getCroppedImg from './cropImage'

import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation, graphql } from 'react-apollo';
import { compose } from 'recompose';
import {GeoQuery,City} from './localizacao.graphql';
import { PROFILE_MUTATION } from '../../services/graphql/mutations'


import Thumb from "../../assets/img/op.jpg";
import { async } from "q";

const minZoom = 0.4

const stateOptions = (props) => {
     if(!!props && !!props.data && !!props.data.state ){
        
        let stateOpt = [];
        let qtdEstados = props.data.state.length;
        for (let i=0; i < qtdEstados; i++) {
            stateOpt = stateOpt.concat({ value: props.data.state[i].id, label: props.data.state[i].name });
        }
        return stateOpt;
    }
    return [];
}
const stateValue = (props) => {
    if(!!props && !!props.data && !!props.data.state ){
       let qtdEstados = props.data.state.length;
       if(props.profile.profile.state_id > 0){
            for (let i=0; i < qtdEstados; i++) {
                if(props.data.state[i].id == props.profile.profile.state_id){
                    return {value: props.data.state[i].id, label: props.data.state[i].name};
                }
            }
       }
   }
   return {value: 'ESTADO', label: 'ESTADO'};
 }
 const cityOptions = (data) => {
    if(!!data && !!data.city ){
        let qtdCidades = data.city.length;
        let cityOpt = [];
        for (let i=0; i < qtdCidades; i++) {
            cityOpt = cityOpt.concat({ value: data.city[i].id, label: data.city[i].name });
        }
        return cityOpt;
   }
   return [];
}
const cityValue = (data,props) => {
    if(!!data && !!data.city ){
       let qtdCidades = data.city.length;
       if(props.profile.profile.city_id > 0){
            for (let i=0; i < qtdCidades; i++) {
                if(data.city[i].id == props.profile.profile.city_id){
                    return {value: data.city[i].id, label: data.city[i].name};
                }
            }
       }
   }
   return {value: 'CIDADE', label: 'CIDADE'};
 }



class Profile extends Component {
    constructor(props){
        super(props);
        this.state = {
            imageSrc: null,
            imagePreview: null,
            crop: { x: 0, y: 0 },
            zoom: minZoom,
            aspect: 1 / 1,
            croppedAreaPixels: null,
            croppedImage: null,
            name: props.profile.profile.name,
            vieBirth: props.profile.profile.birth,
            birth: props.profile.profile.birth,
            estado: '',
            cidade: '',
            jaCroppada: false,
        }
    }
    componentDidMount(){
        this.getDataFormat(this.state.birth);
    }
      onCropChange = crop => {
        this.setState({ crop })
      }
    
      onCropComplete = (croppedArea, croppedAreaPixels) => {
        // // console.log(croppedArea, croppedAreaPixels)
        this.setState({ croppedAreaPixels })
      }

      onZoomChange = zoom => {
        this.setState({ zoom })
      }

      selecionaEstado = estado => {
        this.setState({ estado: estado })
      }
      selecionaCidade = cidade => {
        this.setState({ cidade: cidade })
      }

      onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            
          const imageDataUrl = await readFile(e.target.files[0])

          this.setState({
            imagemAntigaSrc: imageDataUrl,
            imageSrc: imageDataUrl,
            imagePreview: imageDataUrl,
            crop: { x: 0, y: 0 },
            zoom: 1,
          })
        }
      }
      showCroppedImage = async () => {
          
        const croppedImage = await getCroppedImg(
          this.state.imageSrc,
          this.state.croppedAreaPixels
        )
        this.setState({ croppedImage })
        this.setState({ imageSrc: '' })
        this.setState({ jaCroppada: true })
      }

    onSubmit = async () => {
        let profile = {
            name: this.state.name, 
            birth: this.setDataFormat(this.state.vieBirth), 
            city: this.state.cidade.value , 
            state: this.state.estado.value
        };

        if(this.state.jaCroppada){
            
            profile.profilePicture = this.state.croppedImage;
            let profileEnviar = Object.entries(profile).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            // console.log("Enviar: ",profileEnviar);
            this.setState({})
            let profileConsult = await this.props.mutate({variables: profileEnviar});
            // console.log(profileConsult)
            this.props.setMeRequest(profileConsult.data.editUser);

        }else if(this.state.imageSrc){
            await this.showCroppedImage()
            .then(
                async () => {
                    profile.profilePicture = this.state.croppedImage;
                    let profileEnviar = Object.entries(profile).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
                    // console.log("Enviar: ",profileEnviar);
                    this.setState({})
                    let profileConsult = await this.props.mutate({variables: profileEnviar});
                    // console.log(profileConsult)
                    this.props.setMeRequest(profileConsult.data.editUser);
                }
            )
        } else {
            let profileEnviar = Object.entries(profile).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            // console.log("Enviar: ",profileEnviar);
            this.setState({})
            let profileConsult = await this.props.mutate({variables: profileEnviar});
            this.props.setMeRequest(profileConsult.data.editUser);
        }
    }
    getDataFormat = (data) => {
        // console.log("DATA: ", data)
        if(data){
            var dateBr = data.substr(0, 10).split('-').reverse().join('/');
            this.setState({ vieBirth: dateBr })
            return dateBr;
        }
    }
    setDataFormat = (data) => {
        // console.log("DATA: ", data)
        var dateUs = data.substr(0, 10).split('/').reverse().join('-');
        this.setState({ birth: dateUs })
        return dateUs;
    }

  render(){
      // console.log("Estado: ",this.state);
  return(
    <Container>
        {
            this.state.imageSrc && (
                <div className="cropador">
                    <Fragment>
                        <div className="crop-container">
                            <Cropper
                                image={this.state.imageSrc}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                            />
                        </div>
                        <div className="controls">
                            <Slider
                                value={this.state.zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => this.onZoomChange(zoom)}
                                classes={{ container: 'slider' }}
                            />
                        </div>
                        <Button
                            onClick={this.showCroppedImage}
                            variant="contained"
                            color="primary"
                            className="btn-cortar"
                            // className="auth-button"
                        >
                            Cortar Imagem
                        </Button> 
                    </Fragment>
                </div>
            )
        } 
        
        <div className="title">
            Perfil
        </div>
        <span>Imagem</span>
        <div className="img-perfil">
            <div className='contentImagePerfil'>
                <img src={this.state.imagePreview || this.props.profile.profile.profilePicture}/>
            </div>
            <input type="file"  className="auth-button inputy-file"  onChange={this.onFileChange} />
        </div>
        <div className="formulario">
            Você
            <Input
                type="text"
                placeholder="Seu Nome"
                value={this.state.name}
                id="name"
                name="name"
                font-size="11.5px"
                font-color="#000"
                background="#EEEEEE"
                border="1px solid var(--color-third)"
                onChange={e => this.setState({ name: e.target.value })}
            />
            <InputMask
                type="text"
                placeholder='Data nascimento(Ano-Mes-Dia) ex: 1987-12-21'
                value={this.state.vieBirth}
                id="birth"
                name="birth"
                style={{
                    fontColor:"#000",
                    background:"#EEEEEE",
                    border:"1px solid var(--color-third)"
                }}
                mask="99/99/9999" 
                onChange={e => this.getDataFormat(e.target.value)}
            />
            <Dropdown 
                id="pais" 
                name="pais" 
                options={[{value: 'BR', label: 'BRASIL'}]} 
                value={{value: 'BR', label: 'BRASIL'}} 
                menuClassName="drop" 
                controlClassName="drop pais" 
            />

            <Dropdown 
                options={stateOptions(this.props)}
                value={this.state.estado || stateValue(this.props)}
                menuClassName="drop" 
                controlClassName="drop estado" 
                onChange={(selected) => this.selecionaEstado(selected)}
            />
            {
                this.state.estado && (
                    <Query query={City} variables={{state: this.state.estado.value}}>
                        {
                            ({ loading, error, data }) => {
                                if (loading || error) {
                                    return("Loading ..." );
                                }
                                return (                            
                                    <Dropdown 
                                        options={cityOptions(data)}
                                        value={this.state.cidade || cityValue(data,this.props)}
                                        menuClassName="drop" 
                                        controlClassName="drop cidade" 
                                        onChange={(selected) => this.selecionaCidade(selected)}
                                    />
                                );
                            }
                        }
                    </Query>
                )
            } 
            {
                !this.state.estado && this.props.profile.profile.state_id &&  (
                    <Query query={City} variables={{state: this.props.profile.profile.state_id}}>
                        {
                            ({ loading, error, data }) => {
                                if (loading || error) {
                                    return("Loading ..." );
                                }
                                return (                            
                                    <Dropdown 
                                        options={cityOptions(data)}
                                        value={this.state.cidade || cityValue(data,this.props)}
                                        menuClassName="drop" 
                                        controlClassName="drop cidade" 
                                        onChange={(selected) => this.selecionaCidade(selected)}
                                    />
                                );
                            }
                        }
                    </Query>
                )
            } 
            <Button 
                type="submit"
                className="auth-button"
                onClick={() => this.onSubmit()}
            >
                Atualizar Perfil
            </Button>
        </div>
    </Container>
  )}
}
const propsToOptions = (props) => {
  
    return ({ 
        variables: 
          { 
            state: props.profile.profile.state_id, 
            city: props.profile.profile.city_id,
            
          }
      }
    )
}
function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)

  })
}

function mapStateToProps({ user }) {
    return { user };
  }
  
  const mapDistapchToProps = dispatch =>
    bindActionCreators({ ...UserActions }, dispatch);


export default connect(
    mapStateToProps,
    mapDistapchToProps
)(compose(graphql(GeoQuery, { options: propsToOptions }), graphql(PROFILE_MUTATION))(Profile));

