import gql from "graphql-tag";

const VerticalQuery = gql`

  query Channels($code: String!, $mainCategory: Int!, $thumbWidth: Int, $thumbHeight: Int, $coverWidth: Int, $coverHeight: Int) {
    mainCategories(id: $mainCategory){
        id
         name
        top_episodes{
            order
            episode{
                id
                title
                rating
                duration
                description
                playCount: play_count
                publishDate
                thumbnail(width: $thumbWidth, height: $thumbHeight)
                channel{
                    id
                    codename
                 flag
                }
            
            }
        
        }
    }
    slides(code: $code){
        data{
            id
            action
            arguments
            cover(width: $coverWidth, height: $coverHeight)
        }
        total
    }
}`;

export { VerticalQuery };
