import React, { Component } from "react";
import M from "materialize-css";

import { MdAdd } from "react-icons/md";
import { Card } from "../../../../elements/Card";
//elements
import { Title, Text } from "../../../../elements/Typography";
import { Button } from "../../../../elements/ButtonElement";
import { Input } from "../../../../elements/Input";

import { sendMessageToUser } from '../../../../common';
import { USER_ACCOUNT } from '../../../../constants';

import NEW_PLAYLIST from './NewPlayList.graphql';
import apolloClient from "../../../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';

//styles
import {
    Container,
    Trends,
  } from "./NewPlayList.styles";

class NewPlayList extends Component {
    constructor(props){
        super(props);
        this.state = {
            playlist: null,
            qtdPlaylists: props.qtdPlaylists,
            naoPodeCriar: false,
        }
    }
    componentDidMount(){
        let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

        if(this.state.qtdPlaylists >= 3 && !userAccount.subscribed){
            this.setState({naoPodeCriar: true});
        }
        const options = {
            onOpenStart: () => {
            // console.log("Open Start");
            },
            onOpenEnd: () => {
            // console.log("Open End");
            },
            onCloseStart: () => {
            // console.log("Close Start");
            },
            onCloseEnd: () => {
            // console.log("Close End");
            },
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: false,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
    }
    componentDidUpdate(){
        // console.log("Atualizei props", this.props)
        let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

        if(this.props.qtdPlaylists != this.state.qtdPlaylists){
            this.setState({qtdPlaylists: this.props.qtdPlaylists})
            if(this.props.qtdPlaylists >= 3 && !userAccount.subscribed){
                this.setState({naoPodeCriar: true})
            }else{
                this.setState({naoPodeCriar: false})
            }
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        var instance = M.Modal.getInstance(this.Modal);
        if(this.state.playlist!== null && this.state.playlist.length > 3){
            let body = {
                name: this.state.playlist
            }
            this.props.mutate({mutation: NEW_PLAYLIST, variables: body, errorPolicy: 'all'})
            .then(({register, errors}) => {
                if(errors){
                    // console.log(errors)
                } else {
                    this.setState({playlist: '' });
                    sendMessageToUser("Playlist adicionada com sucesso");
                    this.props.onRefresh();
                    instance.close();
                }
            })
        }
    }
    render(){
        // console.log("State:", this.state);
        return(
            <Card
                cardClass="playlist-card col l2 m3 s4"
                w="15%"
            >
                {this.state.naoPodeCriar ?
                    <a id="create_playlist_btn" href="/planos" className="modal-trigger addMore" onClick={() => sendMessageToUser('Somente usuarios assinados podem criar mais de 3 playlists.')}>
                        <MdAdd size={48} />
                    </a>
                :
                    <a id="create_playlist_btn" href="#newPlaylist" className="modal-trigger addMore">
                        <MdAdd size={48} />
                    </a>
                 }
                <Container
                    ref={Modal => {
                        this.Modal = Modal;
                    }}
                    id="newPlaylist" className="modal modal-new-playlist" style={{width:"400px"}}>
                    <div className="modal-content">
                        <Title size="1" content="Adicionar uma nova playlist" typeClass="modal-title" />
                        <form id="create_playlist_btn" onSubmit={this.onSubmit}>
                            <Input
                                name="playlist"
                                type="text"
                                labelContent=""
                                inputClasses="campoInput"
                                labelClasses=""
                                placeholder="Nome da Playlist"
                                borderRadius="3px"
                                background="var(--color-third)"
                                border="1px solid var(--color-third)"
                                value={this.state.playlist || ""}
                                onChange={ (event) => { this.setState({playlist: event.target.value }); /*this.updateInput({phone_or_email: event.target.value})*/ } }
                            />
                            <Button
                                type="submit"
                                className="auth-button "
                                Background="var(--color-first)"
                                color="var(--color-zero)"
                                //onClick={() => this.submitForm() className="modal-close" }
                            >
                                Salvar Playlist
                            </Button>
                        </form>
                    </div>
                </Container>
            </Card>
            
        )
    }
}

export default compose(graphql(NEW_PLAYLIST))(NewPlayList);
