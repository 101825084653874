import styled from "styled-components";

export const HorizontalEpisodeCardContainer = styled.article`


  .container-episode-h {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 125px;    
    margin-right: var(--spacing-default);
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 13px;
  }

  .episode-card_image {
    width: 113px;
    height: 113px;
    border-radius: 3px; 
    background: url(image.png);
    position: relative;
    .card-image_icon {
      color: var(--color-zero);
    }
    
  }

  .title{
    display: flex;
    flex-direction: column;
    align-content: space-around;
    margin-left: var(--spacing-small);
    position: relative;
    padding-bottom: 12px;
    
  }

  .play-icon {
    height: 15px;
    color: #666;
    margin-right: var(--spacing-small);
  }
  .play-icon2 {
    height: 23px;
    color: #666;
    margin-right: var(--spacing-small);
  }

  .social{
    display: flex;
    width:100%;
    flex-direction: row;
    position: absolute;
    bottom: 13px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 12px;        
    letter-spacing: 0.05em;
    color: #666666;
    .cont-social-1{
      display: flex;
      
      justify-content: space-between;
      width: 45%;
    }
    .time-ago {
      display: block;
      width: 53%;
      text-align: right;
      padding-right: 2%;
    }

  }

 

  .episode-title {   
    width: 100%;
    height: 33px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }
 
  .card-image_time {    
    align-self: flex-end;
    background: var(--color-negative-darken);
    color: var(--color-zero);
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    padding: 0 var(--spacing-small);
  }

  .episode-info {
    width: 74%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;      
    color: #666666;
    .ver-mais{
      font-weight: bold;
      text-decoration-line: underline;
      margin-left: var(--spacing-small);
    }
  }
`;
