import React, { Component } from "react";

//styles
import { Container } from "./TelephoneOperator.style.js";

//graphql
import { Query } from 'react-apollo';
import { COUNTRY } from "./TelephoneOperator.graphql"

//elements
import { Input } from "../../../elements/Input";
import { Button, StyledLink } from "../../../elements/CTA";

//elements
import { Title, Text } from "../../../elements/Typography";

import { sendMessageToUser } from '../../../common';

class TelephoneOperator extends Component {
    constructor(props){
        super(props);
        this.state = {
            country: '',
            phone: '',
            operator: '',
            name: '',
            nickname: '',
            password: ''
        }
    }

    componentWillReceiveProps(props){
        if(!!props.state && props.state.emailToken && !this.state.jaEntrou){
            this.setState({phone: props.state.emailToken});
            this.props.updateState({phone: props.state.emailToken})

            this.setState({jaEntrou: true});
        }
    }

    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }
    updateInput(input){
        this.props.updateState(input)
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }
    submitForm = () => {
        //// console.log(this.state)
        let { country, operator, phone, name, nickname, password} = this.state
        if(country=== undefined || country=='') {
            sendMessageToUser('Você precisa selecionar um país para continuar!')
        } else if(operator=== undefined || operator==''){
            sendMessageToUser('Você precisa selecionar uma operadora para continuar!')
        } else if(phone=== undefined || phone.length < 11 ){
            sendMessageToUser('Você precisa preencher seu telefone com ddd corretamente!')
        } else if(name=== undefined || name.length <= 3 ){
            sendMessageToUser('Você precisa preencher seu nome corretamente')
        } else if(nickname=== undefined || nickname.length <= 3 ){
            sendMessageToUser('Você precisa preencher seu nome de usuário corretamente')
        } else if(password === undefined || password.length < 8 || !password.match(/[a-z]/i) || !password.match(/[0-9]/i)){
            sendMessageToUser('Sua senha precisa ter, no mínimo, 8 caracteres e conter letras e números')
        } else {
            this.props.submitForm();
        }
    }
    render(){
        let that = this;
        return(
            <Container>
                <Title size="1" content="Ola! Selecione sua operadora e valide seu número" typeClass="auth-title" />
                <form onSubmit={this.onSubmit}>
                    <div className="content-select">
                        <Query query={COUNTRY}>
                        {/* pollInterval={30000} fetchPolicy="network-only"> */}
                        {
                            ({ loading, error, data }) => {
                                if (loading && !data) return <div> {`Loading: ${loading}`} </div>;
                                if (error && !data) {
                                    if (error.networkError) {
                                        return <div> {`Error: no network`} </div>;
                                    }
                                    if (error.graphQLErrors) {
                                        return <div> {`Error: ${error}`} </div>;
                                    }
                                    return <div> {`Error: ${error}`} </div>;
                                }
                                if (!this.state.country) {
                                    this.setState(
                                        {
                                            country: data.country[0].id,
                                            operator: 'vivo',
                                            // phone: !this.props.state.emailToken ? '' : this.props.state.emailToken
                                    })
                                }
                                return (
                                    <select 
                                        id="country"
                                        name="country" 
                                        value={data.country[0].id}
                                        onChange={(event) => { 
                                            this.setState({country: event.target.value }); 
                                            this.updateInput({country: event.target.value}); 
                                        }} 
                                    >
                                        <option value="">País</option>
                                        {data.country.map(country => {
                                            if (this.state.country==country.id) {
                                                return (<option selected key={country.id} value={country.id}>{country.name}</option>);
                                            }
                                            return (<option key={country.id} value={country.id}>{country.name}</option>);
                                        })}
                                    </select>
                                )
                            }
                        }
                        </Query>
                        <select 
                            id="operator" 
                            name="operator" 
                            onChange={(event) => { 
                                this.setState({operator: event.target.value }); 
                                this.updateInput({operator: event.target.value}); 
                        }}>
                            <option>Operadora</option>
                            <option value="vivo" selected>Vivo</option>
                            <option value="claro">Claro</option>
                        </select>
                    </div>
                        <Input
                            name="phone"
                            labelContent=""
                            width="100%"
                            labelClasses=""
                            inputClasses="inputPhone"
                            placeholder="Telefone com DDD"
                            borderRadius="3px"
                            background="var(--color-third)"
                            color="var(--color-first)"
                            border="1px solid var(--color-third)"
                            value={this.state.phone}
                            onChange={ (event) => { this.setState({phone: event.target.value }); this.updateInput({phone: event.target.value}) } }
                        />
                    <Input
                        name="name"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        inputClasses="inputPhone"
                        placeholder="Nome Sobrenome*"
                        borderRadius="3px"
                        background="var(--color-third)"
                        color="var(--color-first)"
                        border="1px solid var(--color-third)"
                        value={this.state.name}
                        onChange={ (event) => { this.setState({name: event.target.value }); this.updateInput({name: event.target.value}) }}
                    />
                    <Input
                        name="nickname"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        inputClasses="inputPhone"
                        placeholder="Nome de usuário*"
                        borderRadius="3px"
                        background="var(--color-third)"
                        color="var(--color-first)"
                        border="1px solid var(--color-third)"
                        value={this.state.nickname}
                        onChange={ (event) => { this.setState({nickname: event.target.value }); this.updateInput({nickname: event.target.value}) }}
                    />
                    <Input
                        name="password"
                        type="password"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        inputClasses="inputPass"
                        placeholder="Digite sua senha"
                        borderRadius="3px"
                        background="var(--color-third)"
                        color="var(--color-first)"
                        border="1px solid var(--color-third)"
                        value={this.state.password}
                        onChange={ (event) => { this.setState({password: event.target.value }); this.updateInput({password: event.target.value}) }}
                    />
                    <Button
                        type="submit"
                        className="auth-button"
                        Background="var(--color-first)"
                        color="var(--color-zero)"
                        onClick={() => {this.setState({loading: true}); this.submitForm()}}

                        //onClick={() => this._setModalScreen("pin-code")}
                    >
                        Continuar
                    </Button>
                </form>
                <div className="auth-login">
                    <StyledLink
                        type="submit"
                        to="#"
                        className="login-button"
                        color="var(--color-first)"
                        onClick={() => this._setModalScreen("register")}
                    >
                        Voltar
                    </StyledLink>
                </div>
            </Container>
        )
    }
}

export default TelephoneOperator;
