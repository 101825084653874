import React, { Component } from "react";

// import { Container } from './styles';

import EpisodeCardComponent from "../EpisodeCardComponent";

export default class Cards extends Component {
  render() {
    const { episodes, sectionTitle, icon } = this.props;
    const CustomIcon = `${icon.displayName}`;
    return (
      <section className="ola-cards">
        <h3 className="ola-card_title ">
          <CustomIcon /> {sectionTitle}
        </h3>
        <div className="ola-cards_list ">
          {episodes.map(episode => (
            <EpisodeCardComponent key={episode.id} episode={episode} />
          ))}
        </div>
      </section>
    );
  }
}
