import React, { Component } from "react";

import HeaderFeed from "../../../components/HeaderFeed";

import EpisodeList from '../../../components/episodes/EpisodeListComponent';
import AboutChannelComponent from '../../../components/channels/AboutChannelComponent';

import Layout from "../../../hoc/Layout/Layout";

import M from "materialize-css";
import debounce from "lodash.debounce";

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { BUSCA_FEED_EPISODES_RSS  } from '../../../services/graphql/query';
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

class ExternalFeed extends Component {

  constructor(props){
    super(props);
    this.state = {
      feed: { id: props.match.params.id },
      error: false,
      loading: false,
      page_episodes: 1,
      hasMoreEpisodes: true,
    }; 
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    const el = document.querySelectorAll(".tabs");
    const instance = M.Tabs.init(el);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  loadEpisodes = () => {
    // console.log("load more...");
    if(!this.state.loading && this.state.hasMoreEpisodes){
      this.setState({loading: true});
      let page_episodes = this.state.page_episodes + 1;
      this.props.data.fetchMore({
        variables: {page_episodes: page_episodes },
        updateQuery: (prev, { fetchMoreResult }) => {
          // console.log("zzz curr...", this.state.episodes);
          // console.log("zzz more...", fetchMoreResult);
          if(fetchMoreResult && fetchMoreResult.feedChannels && fetchMoreResult.feedChannels.data[0] && fetchMoreResult.feedChannels.data[0].episodes.data.length){
              this.setState(
                {
                  page_episodes: page_episodes,
                  episodes: {...fetchMoreResult, data: [...this.state.episodes.data, ...fetchMoreResult.feedChannels.data[0].episodes.data] },
                  hasMoreEpisodes: fetchMoreResult.feedChannels.data[0].episodes.has_more_pages
                });
              return {
                feedChannels: { ...prev.feedChannels },
                // episodes: {...fetchMoreResult, data: [...prev.feedChannels.data[0].episodes.data, ...fetchMoreResult.feedChannels.data[0].episodes.data] },
                  page_episodes: page_episodes,
              }
          } else {
              this.setState({hasMoreEpisodes: false})
              return {
                feedChannels: { ...prev.feedChannels },
              }
          }
         }
       })
    }
  };

  handleScroll = debounce(() => {
    const {
      loadEpisodes,
      state: {
        error,
        loading,
        hasMoreEpisodes,
      },
    } = this;

    // console.log("scroll...");

    let el = document.querySelectorAll(".tabs");
    let instance =  M.Tabs.init(el);
    
    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (error || loading || !hasMoreEpisodes) return;

    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      if(instance[0].index == 0 ){
        loadEpisodes();
      }  
    }
  }, 100);


  static getDerivedStateFromProps(props, state){
    let { data } = props;
    let new_state = state;
    if(!!data && !!data.feedChannels &&data.feedChannels.data[0] !== state.channel ) new_state = { ...new_state, channel: data.feedChannels.data[0] }
    if(!!data && !!data.feedChannels &&data.feedChannels.data[0] !== state.channel ) new_state = { ...new_state, episodes: data.feedChannels.data[0].episodes }
    if(!!data && !!data.feedChannels &&data.feedChannels.data[0] !== state.channel ) new_state = { ...new_state, hasMoreEpisodes: data.feedChannels.data[0].episodes.has_more_pages }
    if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
    return new_state;
}

  render() {
    if(!!this.props && !!this.props.data && !!this.props.data.feedChannels && !!this.props.data.feedChannels.data){

   
    const  feed  = this.props.data.feedChannels.data[0];
    feed.codename = feed.name;
    // console.log(this.props)

    return (
      <Layout>
        <div className="container">
          <main className="channel-page">
            <HeaderFeed feed={feed} />
            <div className="row">
              <div className="col s12">
                <ul className="tabs">
                  <li className="tab col s6">
                  <a href="#episodes">EPISÓDIOS</a>
                  </li>
                  <li className="tab col s6">
                    <a className="" href="#about">
                      SOBRE O CANAL
                    </a>
                  </li>
                </ul>
              </div>
              <div id="episodes" className="col s12 episode-list channel-page">
                <EpisodeList episodes={this.state.episodes} id={feed.id} scope="externalFeed"/>
              </div>
              <div id="about" className="col s12 episode-list channel-page">
                <AboutChannelComponent description={feed.description} /> 
              </div>
            </div>
          </main>
        </div>
      </Layout>
    );
    }else{
        return "Carregando ..."
    }
  }
}

const propsToOptions = (props) => {
  return ({
      variables: 
        { 
          id: props.match.params.id,
          page_episodes: 1
        }
    }
  )
}

export default compose(graphql(BUSCA_FEED_EPISODES_RSS, { options: propsToOptions } ))(ExternalFeed);

