import styled from "styled-components";
/*
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    
    .boxContent:first-child {
        margin-right: 17px;
    }
    .boxContent:last-child {
        margin-left: 17px;
    }

    .boxContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 33px;
        font-family: Avenir;
        background-color: #FFF;
        border-radius: 3px;
        span {
            font-size: 24px;
            line-height: 33px;
            font-weight: 700;
            margin-bottom: 26px;
            margin-right: 26px;
        }
        input {
            background: #EEEEEE;
            width: auto;
            height: 22px;
            padding: 7px 14px;
            margin-bottom: 15px;
            font-family: Avenir;
            font-size: 11.5px;
            
        }

        .dropdowns{
            font-family: Avenir;
            font-size: 11.5px;
            display: flex;
            flex-direction: column;
            
            .secundaria{
                margin-top: var(--spacing-small);
            }
            .drop{
                background: #eee;
                border: none;
                display: flex;
                flex: 1;
                flex-direction: column;
                text-align:left;
                margin-bottom: 8px;
            }
        }
        .contentButtom {
            display: flex;
            flex: 1;
            align-items: flex-end;
            justify-content: flex-end;
            .auth-button{
                margin-top: var(--spacing-big);
                width: 50%;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: flex-end;
                align-content: flex-end;
                font-family: Avenir;
                font-size: 11.5px;
            }
        }
    }
    
    .div2{
        display: flex;
        
        background-color: #FFF;
        width: 40%;
        
        border-radius: 3px;
        
    }
    .div3{
        display: flex;
        width: 10%;
    }
    .auth-button {
        width: auto !important;
        padding: 7px 24px 6px;
        line-height: 12px;
        border-radius: var(--radius-small);
        background-color: #2196F3;
    }

    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
`;
*/
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    
    .boxContent:first-child {
        margin-right: 17px;
    }
    .boxContent:last-child {
        margin-left: 17px;
    }

    .boxContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 33px;
        font-family: Avenir;
        border-radius: 3px;
        h2 {
            font-size: 30px;
            font-weight: 900;
            max-width: 70%;
            margin-bottom: 35px;
            color: #2196F3;
        }
        span {
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            margin-bottom: 26px;
            margin-right: 26px;
            max-width: 60%;
            color: #3a3a3a;
        }
        label {
            color: #3a3a3a;
        }
        input,textarea {
            width: auto;
            height: 22px;
            padding: 7px 14px;
            margin-bottom: 15px;
            font-family: Avenir;
            font-size: 14px;
            font-weight: bold;
            border-radius: 5px;
            border: 1px solid #dbdbdb;
            color: #313131;
            background: #fff;
        }
        textarea.description{
            height: 70px;
        }

        .dropdowns{
            font-family: Avenir;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            .drop{
                background-color: #fff;
                border: none;
                display: flex;
                flex: 1;
                flex-direction: row;
                text-align:left;
                padding-right: 10px;
                margin-bottom: 0;
                border-radius: 5px;
                border: 1px solid #dbdbdb;
                .Dropdown-arrow {
                    margin-top: 2px;
                    margin-right: 0px;
                }
            }
            .Dropdown-placeholder {
                color: #d5d5d5;
            }
            .Dropdown-placeholder.is-selected {
                font-weight: bold;
                color: #313131;
            }
            .Dropdown-menu.drop {
                flex-direction: column;
            }
            .coluna {
                display: flex;
                flex: 1;
                flex-direction: column;
            }
            .coluna:first-child {
                margin-right: 10px;
            }
            .coluna:last-child {
                margin-left: 10px;
            }
        }
        .contentButtom {
            display: flex;
            flex: 1;
            align-items: flex-end;
            justify-content: flex-end;
            .auth-button{
                margin-top: 24px;
                width: 50%;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: flex-end;
                align-content: flex-end;
                font-family: Avenir;
                font-size: 11.5px;
                font-weight: 900;
                border-radius: 8px !important;
            }
        }
    }
    
    .div2{
        display: flex;
        
        background-color: #FFF;
        width: 40%;
        
        border-radius: 3px;
        
    }
    .div3{
        display: flex;
        width: 10%;
    }
    .auth-button {
        width: auto !important;
        padding: 7px 24px 6px;
        line-height: 12px;
        border-radius: var(--radius-small);
        background-color: #2196F3;
    }

    @media (max-width: 800px) {
        flex: 1;
        flex-direction: column;
        .boxContent:first-child {
            align-items: center;
            text-align: center;
            h2 {
                max-width: 90%;
            }
            span {
                max-width: 85%;
                margin-right: 0
            }
        }
        .boxContent {
            margin: 0 !important;
            padding: 5px;
        }
    }

    input::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal }
    input:-moz-placeholder { color: #d5d5d5; font-weight: normal }
    input::-moz-placeholder { color: #d5d5d5; font-weight: normal }
    input:-ms-input-placeholder { color: #d5d5d5; font-weight: normal }
    textarea::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal }
    textarea:-moz-placeholder { color: #d5d5d5; font-weight: normal }
    textarea::-moz-placeholder { color: #d5d5d5; font-weight: normal }
    textarea:-ms-input-placeholder { color: #d5d5d5; font-weight: normal }
`;
