import React, { Component } from "react";
import apolloClient from "../../../services/apollo";
import { graphql, Query, Mutation } from 'react-apollo';
import { compose } from 'recompose';
import { Link } from "react-router-dom";
import { sendMessageToUser } from '../../../common';
import validator from 'validator';

//styles
import { Container, SocialContent } from "./AddFeed.style.js";

//elements
import { Input } from "../../../elements/Input";
import { Button } from '../../../elements/ButtonElement';
import LOADING from "../../../assets/img/loading.gif";
import { Title, Text } from "../../../elements/Typography";

import { FeedCard } from '../../../components/FeedCard';



import { BUSCA_FEED_RSS } from '../../../services/graphql/query';

class AddFeed extends Component {
    state = {
        url: '',
        busca: '',
        page: 1,
        limit: 10,
        fazBusca: false,
        feeds: [],
    }

    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }

    updateInput(input){
        this.props.updateState(input)
    }
    
    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        let { busca } = this.state;
        if(!busca.length || !validator.isURL(busca)){
            sendMessageToUser('Você deve inserir uma url!')
        } else {
            this.setState({loading: true}); 
            this.props.submitForm();
        }
    }

    handleOnInputChange = (event) => {
        
        this.setState({busca: event.target.value, getUrl: true }); 
        this.props.updateState({busca: event.target.value});
        let url = false;
        if(event.target.value.length >= 3){

            if(event.target.value.includes("www") || event.target.value.includes("htt")) url = true;
                
            this.setState({fazBusca: true }); 
            if(url){
                // console.log("Busca por url");
                this.setState({fazBusca: false }); 
                this.setState({url: event.target.value }); 
                this.props.updateState({url: event.target.value});
            }else{
                // console.log("Busca por nome");
            }            
        }else{
            this.setState({fazBusca: false }); 
        }
    };

    renderResult(feeds){
        // console.log("props ",this.props);
        if (this.state.loading) {
            this.setState({
                loading: false,
                hasMore: feeds.has_more_pages,
                page: feeds.current_page,
            });
        }
        
        return (
          <>
              {!!feeds && !!feeds.data && feeds.data.length > 0 && feeds.data.map(feed => {
                 
                    return(
                        
                        <FeedCard feed={feed}/>
                        
                    );
                })}
          </>
        );
    }


    render(){
         // console.log("STATE: ",this.state," PROPS: ",this.props);
        const {
            page,
            limit,
        } = this.state;

        if(this.state.loading){
            return (
                <Container>
                    <Title size="1" content="Verificando URL" typeClass="auth-title" />
                    <div className="divImg"><img src={LOADING}/></div>
                </Container>
            )
        }else{
            return(
                <Container>
                    <Title size="1" content="Ola, adicione aqui seu podcast favorito." typeClass="auth-title" />

                    <Input
                        name="busca"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        inputClasses="inputSearch"
                        placeholder="BUSCAR POR NOME ou ADICIONAR POR URL"
                        borderRadius="3px"
                        background="var(--color-third)"
                        border="1px solid var(--color-third)"
                        value={
                            this.state.busca.length < 1 && this.props.searchUrl.indexOf('http')  > 0 ? 
                            `${this.props.searchUrl}${this.state.busca}`
                            : 
                            this.props.searchUrl.indexOf('www') > 0 ? 
                                `${this.props.searchUrl}${this.state.busca}` 
                            : 
                                this.state.busca
                        }
                        onChange={ (event) => { this.handleOnInputChange(event) } }
                    />

                    {this.state && this.state.fazBusca &&
                        <div className="resultadoBusca">
                            <Query query={BUSCA_FEED_RSS} variables={{filter: this.state.busca, page: page, limit: limit }}>
                                {({ loading, error, data }) => {
                                    if (loading) {
                                        return("Buscando ..." );
                                    }else if(error){
                                        // console.log("Error",error);
                                    }
                                    // console.log("RETORNO: ",data);
                                    
                                    return this.renderResult(data.feedChannels);;
                                }}
                            </Query>
                        </div>
                    }
                    {this.state.url &&
                        <Button
                            type="submit"
                            className="auth-button"
                            Background="var(--color-first)"
                            Color="var(--color-zero)"
                            loading={this.props.loading}
                            title="Adicionar"
                            onClick={() => {this.submitForm()}}
                        >
                            Adicionar
                        </Button> 
                    }
                </Container>
            )
        }
    }
}

export default AddFeed;
