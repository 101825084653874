import React from 'react';
import FAQ from '../../../../src/assets/pdfs/FAQ.pdf';
import { Header } from '../../../components/Header';
import * as C from './styles';

const ExcluirConta = () => {
  return (
    <>
      {/* <C.Header>
        <Link to='/'>
          <LogoOla classImage='logo-image' />
        </Link>
      </C.Header> */}
      <Header />
      <C.Main>
        <C.Title>Como excluir conta de usuário</C.Title>

        <C.Instructions>
          <C.Item>
            <C.Column>
              <h2>1. Acesse o Menu</h2>
              <p>
                {' '}
                Acesse o Menu do aplicativo, clicando no botão que fica
                localizado no canto superior direito da tela;
              </p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo1.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>2. Acessar a tela de edição de perfil</h2>
              <p>
                Clique na opção 'Editar perfil', localizado abaixo da imagem de
                perfil do App;
              </p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo2.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>3. Clicar em 'Quero apagar minha conta'</h2>
              <p>
                Clique na opção 'Quero apagar minha conta, que se encontra
                abaixo do formulário de edição do perfil';
              </p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo3.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>4. Confirme o desejo de excluir sua conta</h2>
              <p>
                Caso queira mesmo excluir sua conta, selecione a opção 'Sim,
                quero deletar minha conta';
              </p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo4.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>5. Digite sua senha</h2>
              <p>Digite sua senha para confirmar a exclusão da conta;</p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo5.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>6. Aviso de confirmação de exclusão de conta</h2>
              <p>
                Você será direcionado para a home, com um aviso de que sua conta
                foi excluída com sucesso;
              </p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo6.png' />
          </C.Item>
          <C.Item>
            <C.Column>
              <h2>7. Aplicativo deslogado</h2>
              <p>Pronto, sua conta foi excluída e você está deslogado.</p>
            </C.Column>
            <C.Ilustration src='/delete-account/passo7.png' />
          </C.Item>
        </C.Instructions>
      </C.Main>
      <C.Rodape>
        <div className='container'>
          OLA Podcasts conteúdo e participações • CNPJ 34.145.511/0001-13. •{' '}
          <a href={FAQ} target='_blank'>
            Perguntas Frequentes
          </a>{' '}
          •{' '}
          <a href={'/termos-privacidade'} target='_blank'>
            Termos, Condições e Políticas de uso
          </a>
        </div>
      </C.Rodape>
    </>
  );
};

export default ExcluirConta;
