import React, { Component } from "react";
import M from 'materialize-css';
import { Link } from "react-router-dom";

//styles
import { Container } from "./ModalAssinar.style.js";

//assets
import LogoDefault from "../../assets/img/ola_logo_vertical.png";
import { ACCESS_TOKEN } from '../../constants';
import { sendMessageToUser } from '../../common';

import apolloClient from "../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';

import IMG_HEADER from "../../assets/img/Premium/logo-premium.jpg";


export default class ModalAssinar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    abreAssinar = () => {
        // if(Math.floor(Math.random() * 10) >= 4){
        //     this.setState({img: IMG_01});
        // }else{
        //     this.setState({img: IMG_02});
        // }
        
    }
    componentDidMount(){
        document.addEventListener("abre-assinar", this.abreAssinar);
    }

    render() {
        return (
            <Container>
                <div id="modal-assinar" className="modal modal-assinar">
                <div className="modalBackground">
                    <header>
                        <img src={IMG_HEADER} />
                    </header>
                    <section>
                        <div className="title">
                             Assine agora o premium <br />da OLA podcasts.
                        </div>
                        <div className="subtitle">
                            O acesso é rápido, fácil e o primeiro passo para <br />encontrar podcasts de alta qualidade.
                        </div>
                        <div className="botoes">
                            <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&returnUrl=http://" + window.location.host + "/resplanding/"}><div className="assinar">ASSINAR</div></a>
                            <a href={"https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://" + window.location.host + "/resplanding/"}><div className="ja-assinado">JÁ SOU ASSINADO</div></a>
                        </div>
                        <div>
                            {!localStorage.getItem(ACCESS_TOKEN) &&
                                <Link onClick={() => {
                                            let event = new CustomEvent('modal-entrar', { "detail": "login"});
                                            document.dispatchEvent(event);
                                        }}><div>Ja tem uma conta ? <font color="#FFCC00">Entrar</font></div>
                                </Link>
                            }
                            <Link className="modal-close"><div className="close"><font color="#FFCC00">NÃO, VALEU</font></div></Link>
                        </div>
                    </section>
                    <section>

                    </section>
                </div>
                  
                </div>
            </Container>
        )
    }
}

