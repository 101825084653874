import gql from 'graphql-tag';

const EpisodesQuery = gql`
  query Episodes($channel_id: Int!, $thumbWidth: Int!, $thumbHeight: Int!) {
    episodes(channel_id: $channel_id) {
      data {
        id
        title
        rating
        restricted
        duration
        description
        duration
        external
        content
        episode_status_id
        episode_status_message
        thumbnail(width: $thumbWidth, height: $thumbHeight)
        channel {
          codename
        }
        play_count
        publishDate
      }
    }
  }
`;

const DELETE_EPISODE = gql`
  mutation DeleteEpisode($episode_id: Int!) {
    deleteEpisode(id: $episode_id)
  }
`;

export { EpisodesQuery, DELETE_EPISODE };
