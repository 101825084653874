import React, { useEffect } from "react";

import Styles from "./styles";
import Routes from "./routes";

import { ApolloProvider } from "react-apollo";

import apolloClient from "./services/apollo";

import { Provider } from "react-redux";
import store from "./store";

import Modal from "./components/Modal";
import ModalBlackMask from "./components/ModalBlackMask";

import { PlayerComponent } from './modules/player';

function App() {

  let player = new PlayerComponent();

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Routes />
        <Styles />
        <Modal />
        <ModalBlackMask />          
      </ApolloProvider>
    </Provider>
  );
}

export default App;
