import gql from "graphql-tag";

const TagViewQuery = gql`

query TagView($tag: String!, $page: Int!, $limit: Int!, $thumbWidth: Int!, $thumbHeight: Int!) {
    episodes(
        tag: $tag
        page: $page
        limit: $limit
    ) {
        data {
            id
            title
            description
            thumbnail(width: $thumbWidth, height: $thumbHeight)
            channel {
                codename
            }
            playCount: play_count
            publishDate
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`;

export default TagViewQuery;
