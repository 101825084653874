import React, { Component, useEffect } from "react";

import {
  Container
} from "./Message.styles";

import Layout from "../../../hoc/Layout/Layout";
import { Logo, Image } from "../../../elements/Image";
import { ButtonGroup } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';
import { Link } from "react-router-dom";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionActive: "profile"
    }
  }
  render() {
    return (
      <Layout>
        <div className="container">
          <Container>
            <Logo classImage="logo-image" />
            {!!this.props && !!this.props.pagina && this.props.pagina == "channel" ?
              <>
                <div className="titulo"> Desculpe </div>
                <div className="subtitulo">Não encontramos esta página</div>
              </>
              :
              <>
                <div className="titulo"> Não perca os novos podcasts </div>
                <div className="subtitulo">Faça login para ver as atualizações dos seus canais favoritos do Ola Podcasts</div>
                <div className="botoes">
                  <ButtonGroup>
                    <a
                      id="cadastro_btn"
                      className="modal-trigger"
                      href="#signup"
                      onClick={() => {
                        let event = new CustomEvent('modal-entrar', { "detail": "register" });
                        document.dispatchEvent(event);
                      }}
                    >
                      Cadastro
                    </a>
                    <Link data-target="signup" className="modal-trigger" to="/signin"
                      onClick={() => {
                        let event = new CustomEvent('modal-entrar', { "detail": "login" });
                        document.dispatchEvent(event);
                      }}
                    >Login
                    </Link>
                  </ButtonGroup>
                </div>
              </>
            }
          </Container>
        </div>
      </Layout>
    );
  }
}
