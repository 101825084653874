import React from "react";

import { VerticalCardContainer } from "./VerticalCard.style";
import { Card } from "../index";
import { Link } from "react-router-dom";
import { Play } from "styled-icons/boxicons-regular";

import { MoreVert } from "styled-icons/material";

import Verified from "../../../assets/img/verificado.png";

export const VerticalCard = ({
  cardImg = "",
  episodePlayCount = "",
  timeAgo = "",
  backgroundColor = "",
  fontColor = ""
}) => (
  <VerticalCardContainer
    backgroundColor={backgroundColor}
    fontColor={fontColor}
    className="adjustContentCard"
  >
    <div className="card-image">
      <Card
        cardClass="editorial-image"
        cardImg={cardImg}
        w=" 157px"
        h="100%"
        borderRadius="5px"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Play className="play-icon" />
        <span className="card-image_time">1:43:20</span>
      </Card>
    </div>
    <div className="card-body">
      <div className="card-title">
        Empreendedor 56 - Empreendendo com chocolate nas veias{" "}
      </div>
      <div className="card-channel">
        {" "}
        <span>NERDCAST</span>
        <img src={Verified} alt="" />
      </div>
      {/*}
      <div className="card-description">
        Neste podcast: Conheça a história da Kopenhagen e a Brasil Cacau contada
        por uma das pessoas por trás da operação.
      </div>
      <Link className="card-link">Ver mais</Link>
      {*/}
      <div className="card-info">
        <span className="play-count">
          <Play className="play-icon" />
          {episodePlayCount} plays
        </span>
        <span className="time-ago">há {timeAgo} </span>
      </div>
    </div>
    <div className="card-action ">
      <MoreVert className="card-action_icon" />
    </div>
  </VerticalCardContainer>
);
