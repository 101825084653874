import React, { Component } from "react";

//graphql
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { HOME_QUERY, HOME_QUERY_ALL_PLAYBACKS } from '../../../services/graphql/query';

//styles

import { ACCESS_TOKEN } from '../../../constants';

//component
import Browse from "../../../components/Browse";
import Layout from "../../../hoc/Layout/Layout"
import LOADING from "../../../assets/img/loading.gif";

import queryString from 'query-string'
import M from 'materialize-css';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mostraPremium: false
    };
    const paramsUrl = queryString.parse(props.location.search);
    if (!!paramsUrl && !!paramsUrl.modalPremium && paramsUrl.modalPremium == 'app') {
      this.state = {
        mostraPremium: true
      }
    }
  }
  componentDidUpdate() {
    if (this.state.mostraPremium) {
      // console.log("MOSTRA MODAL PREMIUM");
      var elems = document.getElementById('modal-assinar');
      if (elems) {
        var instances = M.Modal.init(elems, {
          onOpenStart: function () {
            var ev = new CustomEvent("abre-assinar", { 'detail': "detalhesAqui" });
            document.dispatchEvent(ev);
          }
        });
        var instance = M.Modal.getInstance(elems);
        instance.open();
        this.setState({ mostraPremium: false })
      }
    }
  }
  async handlePodcast(id) {
    // console.log(id);
  }

  renderHome(data) {
    const {
      tracks,
      getCurrentTrack,
      getCurrentPodcast,
      player
    } = this.props;
    const {
      alos,
      homeTrendsEpisodes,
      mainCategories,
      topChannels,
      recommendedEpisodes,
      homeFeaturedAlos,
      editorialList,
      top,
      bottom,
      premium,
      original,
      allPlaybacks,
      userAllEpisodesFollowing
    } = data;
    if (data.loading) {
      return (
        <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
          <img src={LOADING} />
          {/*
          <svg svg="" className="loading" width="493px" height="420px" viewBox="0 0 493 420" style={{width:"40%"}}>
            <rect className="contentPurpleSmalGrenn" width="48" height="62" rx="10" ry="10" fill="#00AE4D" x="0" y="128"></rect>
            <rect className="contentBlue" width="48" height="201" rx="10" ry="10" fill="#1B4178" x="74" y="90"></rect>
            <rect className="contentGreen" width="48" height="151" rx="10" ry="10" fill="#00AE4D" x="148" y="140"></rect>
            <rect className="contentRed" width="48" height="252" rx="10" ry="10" fill="#ED1C24" x="222" y="90"></rect>
            <rect className="contentOrange" width="48" height="252" rx="10" ry="10" fill="#F57E20" x="296" y="90"></rect>
            <rect className="contentLightBlue" width="48" height="151" rx="10" ry="10" fill="#00AEDF" x="370" y="140"></rect>
            <rect className="contentPurple" width="48" height="62" rx="10" ry="10" fill="#C21E8E" x="444" y="128"></rect>
          </svg>
          */}
        </div>
      )
    }
    else if (data) {
      return (
        <Layout>
          <Browse
            trends={homeTrendsEpisodes}
            mainCategories={mainCategories}
            topChannels={topChannels}
            recommendedEpisodes={recommendedEpisodes}
            tracks={tracks}
            player={player}
            getCurrentTrack={getCurrentTrack}
            getCurrentPodcast={getCurrentPodcast}
            handlePodcast={this.handlePodcast}
            homeFeaturedAlos={homeFeaturedAlos}
            editorialList={editorialList}
            top={top}
            bottom={bottom}
            alos={alos}
            premium={premium}
            original={original}
            allPlaybacks={allPlaybacks}
            userAllEpisodesFollowing={userAllEpisodesFollowing}
          />
        </Layout>
      );
    } else {
      return (
        <div>Putz. Algo deu erro e não sei o que é. Faz assim: não se desespere. Só recarrege a página. Se não funcionar, aí pode se desesperar.</div>
      )
    }
  }

  render() {
    return this.renderHome(this.props.data);
  }
}
const propsToOptions = (props) => {
  return (
    {
      variables:
      {
        thumbWidth: 300,
        thumbHeight: 300,
        aloWidth: 900,
        aloHeight: 300,
        top: localStorage.getItem(ACCESS_TOKEN) ? "617b5eaf-9a61-4801-a297-64e6d1cc9aa0" : '9b03af46-cff0-462e-9ca1-1994765c69a9',
        //top: 'home-180620',
        bottom: 'bottom',
      },
      fetchPolicy: 'no-cache'
    }
  )
}
let USE_QUERY = {}
if (localStorage.getItem(ACCESS_TOKEN)) {
  USE_QUERY = HOME_QUERY_ALL_PLAYBACKS;
} else {
  USE_QUERY = HOME_QUERY;
}

export default compose(graphql(USE_QUERY, { options: propsToOptions }))(Main);
