import gql from "graphql-tag";

const GeoQuery = gql`

query Geo{
    state(country: "BR"){
        id
        name
    }
}`;

const City = gql`

query City($state: Int!){
    city(country: "BR", state: $state){
        id
        name
    }
}`;

const EditProfileMutation = gql`

mutation EditProfile($name: String, $birth: String, $city: Int, $state: Int, $profilePicture: Upload){
  editUser(
    name: $name
    birth: $birth
    city_id: $city
    state_id: $state
    profilePicture: $profilePicture
  ) {
    profile{
      name
      birth
      country_id
      state_id
      city_id
      profilePicture
    }
  }

}`;

export { GeoQuery, EditProfileMutation, City };
