import user1 from "../assets/img/alexandre.svg";
import user2 from "../assets/img/jony.svg";
export const ADMIN_USERS = [
  {
    profileImage: user1,
    displayName: "Alexandre Ottoni",
    status: "ATIVO",
    roles: [
      {
        id: 1,
        name: "Administrador"
      },
      {
        id: 2,
        name: "Proprietário"
      }
    ],
    email: "jovemnerd@olapodcasts.com"
  },
  {
    profileImage: user2,
    displayName: "Jony Ive",
    status: "ATIVO",
    roles: [
      {
        id: 1,
        name: "Administrador"
      },
      {
        id: 2,
        name: "Proprietário"
      }
    ],
    email: "jonyive@olapodcasts.com"
  }
];
