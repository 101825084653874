import gql from 'graphql-tag';

export const BANNER_QUERY = gql`
    query Slides($banner_id: String!){
        slides(banner_id: $banner_id){
            data {
                id
                title
                description
                action
                arguments
                hide_title
                hide_description
                visibility
                hint_position
                publishDate
                cover(width: 600, height: 300)
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`
