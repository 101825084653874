import { all, takeLatest } from "redux-saga/effects";

//ducks
import { PodcastsTypes } from "../ducks/podcasts";
import { TracksTypes } from "../ducks/tracks";
import { UserTypes } from '../ducks/user';
//sagas
import { getPodcasts } from "./podcasts";
import { getTracks } from "./tracks";
import { getMe } from './user';

export default function* rootSaga() {
  return yield all([
    //podcasts
    takeLatest(PodcastsTypes.GET_PODCASTS_REQUEST, getPodcasts),

    //track
    takeLatest(TracksTypes.GET_TRACKS_REQUEST, getTracks),

    //user
    takeLatest(UserTypes.GET_ME_REQUEST, getMe)
  ]);
}
