import React, { Component } from "react";

import { Title } from "../../elements/Typography";
import { Wrapper } from "../../elements/Layout";

//styles
import {
  Container,
  Trends
} from "./VerticalList.style";

import EpisodeCard from "../../components/EpisodeCardComponent";

//graphql
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { VerticalListQuery }  from './VerticalList.graphql';

// icons generos
import { ReactComponent as Cotidiano } from "../../assets/icons/cotidiano.svg";
import { ReactComponent as Conhecimento } from "../../assets/icons/conhecimento.svg";
import { ReactComponent as Variedades } from "../../assets/icons/variedades.svg";
import { ReactComponent as Humor } from "../../assets/icons/humor.svg";
import { ReactComponent as Familia } from "../../assets/icons/familia.svg";
import { ReactComponent as Historias } from "../../assets/icons/historias.svg";
import { ReactComponent as LifeStyle } from "../../assets/icons/lifestyle.svg";
import { ReactComponent as Mercado } from "../../assets/icons/mercado.svg";
import { ReactComponent as Esportes } from "../../assets/icons/esportes.svg";
import { ReactComponent as Tecnologia } from "../../assets/icons/tecnologia.svg";

class VerticalPage extends Component {
  replaceTitle = (name) => {
    switch(name) {
      case "HUMOR":         return <span style={{color:"#9E673C"}}><Humor className="icon" /> Humor</span>;
      case "CONHECIMENTO":  return <span style={{color:"#D94D4C"}}><Conhecimento className="icon" /> Conhecimento</span>;
      case "COTIDIANO":     return <span style={{color:"#DF2617"}}><Cotidiano className="icon" /> Cotidiano</span>;
      case "ESPORTES":      return <span style={{color:"#65B32E"}}><Esportes className="icon" /> Esportes</span>;
      case "FAMILIA":       return <span style={{color:"#3FB6E2"}}><Familia className="icon" /> Família</span>;
      case "HISTORIAS":     return <span style={{color:"#7259B0"}}><Historias className="icon" /> Histórias</span>;
      case "LIFESTYLE":     return <span style={{color:"#B5A1D0"}}><LifeStyle className="icon" /> Lifestyle</span>;
      case "MERCADO":       return <span style={{color:"#00784A"}}><Mercado className="icon" /> Mercado</span>;
      case "TECNOLOGIA":    return <span style={{color:"#6EBD8F"}}><Tecnologia className="icon" /> Tecnologia</span>;
      case "VARIEDADES":    return <span style={{color:"#9E673C"}}><Variedades className="icon" /> Variedades</span>;
      default: return;
    }
  }
  renderPage(data){
    const {
      mainCategories,
      verticalsEpisodes,
      loading
    } = data;
    if (loading && !mainCategories) return false;
    return(
      <Trends className="trends">
        <Wrapper
        alignItems="center"
        justifyContent="flex-start"
        className="trends-header"
        >
          <Title size="5" typeClass="trend-title" content={this.replaceTitle(mainCategories[0].name)} />
        </Wrapper>
        <Wrapper className="trend-list genero">
          <div className="genero-vertical">
            {
              verticalsEpisodes && verticalsEpisodes.data &&
              verticalsEpisodes.data.map(
                (episode) => {
                  return(
                    <div key={`genero-vertical-list-key-${mainCategories[0].id}-ep-${episode.id}`} className="content-colon">
                      <EpisodeCard episode={episode} />
                    </div>
                  )
                }
              )
            }
          </div>
        </Wrapper>
      </Trends>
    )
  }
  render(){
    const {
      mainCategories,
      loading
    } = this.props.data;
    if (loading && !mainCategories) return <div class="progress">
      <div class="indeterminate"></div>
    </div>;
    return(
      <Container>
        { this.renderPage(this.props.data) }
      </Container>
    )
  }
};

const propsToOptions = (props) => {
  return (
    { variables: 
      {
        id: props.code, 
      }
    }
  )
}

export default compose(graphql(VerticalListQuery, { options: propsToOptions }))(VerticalPage);
