import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

const DataLayerAlternativo = () => {
    var array = [];
    const objElements = {
        home: '#home_btn',
        login: '#login_btn',
        search: "#search",
        channelTitle: '.channel-title',
        verticais: '#verticais_btn',
        inscricao: '#inscricao_btn',
        original: '#original_btn',
        favoritos: '#favoritos_btn',
        play: '#play_btn',
        play_forward: '#play_forward_btn',
        play_next: '#play_next_btn',
        share: '#share_btn',
        channel: '#pod_channel',
        episode: '#pod_episode',
        episodes: '#episodes_btn',
        sobre_canal: '#sobre_canal a',
        add_fila: '#adicionar_fila a',
        channel_pgepisode: '#channel_pgepisode_btn a',
        favoritar: '#favoritar_btn span',
        like: '#like_btn span',
        dislike: '#dislike_btn span',
        share_pgepisode: '#share_pgepisode_btn',
        share_facebook: '#share_facebook_btn',
        share_twitter: '#share_twitter_btn',
        share_link: '#share_link_btn',
        share_code: '#share_code_btn',
        cadastro: '#cadastro_btn',
        inscricao_channel: '#inscricao_channel_btn button',
        ass_plano: '#assinar_plano_btn a',
        ass_plano_operadora: '#assinar_plano_operadora_btn a',
        assinante_tim: '#sou_assinante_tim_btn a',
        assinante_operadora: '#sou_assinante_operadora a',
        ass_op_semanal: '#ass_op_semanal',
        ass_op_mensal: '#ass_op_mensal',
    }
    const searchObj = async (key) => {
        let tempElement = await document.querySelector(key);
        if (
            tempElement &&
            tempElement !== null &&
            tempElement !== undefined
        ) {
            return true;
        }
        return false;
    }
    useEffect(() => {
        const fetchData = async () => {
            // var array = [];
            for (const key in objElements) {
                const returnObj = await searchObj(objElements[key]);
                if (returnObj) {
                    array.push(key);
                }
            }
            generateDalayer();
        }
        fetchData()
    }, []);

    function generateDalayer() {
        if (array.indexOf('home') !== -1) var home = document.querySelector('#home_btn').textContent;
        if (array.indexOf('login') !== -1) var login = document.querySelector('#login_btn').textContent;
        if (array.indexOf('search') !== -1) var search = document.querySelector("#search").value;
        if (array.indexOf('channelTitle') !== -1) var channelTitle = document.querySelector('.channel-title').textContent;
        if (array.indexOf('verticais') !== -1) var verticais = document.querySelector('#verticais_btn').textContent;
        if (array.indexOf('inscricao') !== -1) var inscricao = document.querySelector('#inscricao_btn').textContent;
        if (array.indexOf('original') !== -1) var original = document.querySelector('#original_btn').textContent;
        if (array.indexOf('favoritos') !== -1) var favoritos = document.querySelector('#favoritos_btn').textContent;
        if (array.indexOf('play') !== -1) var play = document.querySelector('#play_btn').textContent;
        if (array.indexOf('play_forward') !== -1) var play_forward = document.querySelector('#play_forward_btn').textContent;
        if (array.indexOf('play_next') !== -1) var play_next = document.querySelector('#play_next_btn').textContent;
        if (array.indexOf('share') !== -1) var share = document.querySelector('#share_btn').textContent;
        if (array.indexOf('channel') !== -1) var channel = document.querySelector('#pod_channel').text;
        if (array.indexOf('episode') !== -1) var episode = document.querySelector('#pod_episode').text;
        if (array.indexOf('episodes') !== -1) var episodes = document.querySelector('#episodes_btn').textContent;
        if (array.indexOf('sobre_canal') !== -1) var sobre_canal = document.querySelector('#sobre_canal a').text;
        if (array.indexOf('add_fila') !== -1) var add_fila = document.querySelector('#adicionar_fila a').text;
        if (array.indexOf('channel_pgepisode') !== -1) var channel_pgepisode = document.querySelector('#channel_pgepisode_btn a').textContent;
        if (array.indexOf('favoritar') !== -1) var favoritar = document.querySelector('#favoritar_btn span').textContent;
        if (array.indexOf('like') !== -1) var like = document.querySelector('#like_btn span').textContent;
        if (array.indexOf('dislike') !== -1) var dislike = document.querySelector('#dislike_btn span').textContent;
        if (array.indexOf('share_pgepisode') !== -1) var share_pgepisode = document.querySelector('#share_pgepisode_btn').textContent;
        if (array.indexOf('share_facebook') !== -1) var share_facebook = document.querySelector('#share_facebook_btn').textContent;
        if (array.indexOf('share_twitter') !== -1) var share_twitter = document.querySelector('#share_twitter_btn').textContent;
        if (array.indexOf('share_link') !== -1) var share_link = document.querySelector('#share_link_btn').textContent;
        if (array.indexOf('share_code') !== -1) var share_code = document.querySelector('#share_code_btn').textContent;
        if (array.indexOf('cadastro') !== -1) var cadastro = document.querySelector('#cadastro_btn').textContent;
        if (array.indexOf('inscricao_channel') !== -1) var inscricao_channel = document.querySelector('#inscricao_channel_btn button').textContent;
        if (array.indexOf('ass_plano') !== -1) var ass_plano = document.querySelector('#assinar_plano_btn a').text;
        if (array.indexOf('ass_plano_operadora') !== -1) var ass_plano_operadora = document.querySelector('#assinar_plano_operadora_btn a').text;
        if (array.indexOf('assinante_tim') !== -1) var assinante_tim = document.querySelector('#sou_assinante_tim_btn a').text;
        if (array.indexOf('assinante_operadora') !== -1) var assinante_operadora = document.querySelector('#sou_assinante_operadora a').textContent;
        if (array.indexOf('ass_op_semanal') !== -1) var ass_op_semanal = document.querySelector('#ass_op_semanal').value;
        if (array.indexOf('ass_op_mensal') !== -1) var ass_op_mensal = document.querySelector('#ass_op_mensal').value;

        const tagManagerArgs = {
            dataLayer: {
                'home_btn': home,
                'login_btn': login,
                'search_btn': search,
                'channel_title': channelTitle,
                'verticais_btn': verticais,
                'inscricao_btn': inscricao,
                'original_btn': original,
                'favoritos_btn': favoritos,
                'play_btn': play,
                'play_forward_btn': play_forward,
                'play_next_btn': play_next,
                'share_btn': share,
                'pod_channel': channel,
                'pod_episode': episode,
                'episodes_btn': episodes,
                'sobre_canal_btn': sobre_canal,
                'adicionar_fila': add_fila,
                'channel_pgepisode_btn': channel_pgepisode,
                'favoritar_btn': favoritar,
                'like_btn': like,
                'dislike_btn': dislike,
                'share_pgepisodes_btn': share_pgepisode,
                'share_facebook_btn': share_facebook,
                'share_twitter_btn': share_twitter,
                'share_link_btn': share_link,
                'share_code_btn': share_code,
                'cadastro_btn': cadastro,
                'inscricao_channel_btn button': inscricao_channel,
                'ass_plano_btn': ass_plano,
                'ass_plano_operadora_btn': ass_plano_operadora,
                'sou_assinante_tim_btn': assinante_tim,
                'sou_assinante_operadora': assinante_operadora,
                'ass_op_semanal': ass_op_semanal,
                'ass_op_mensal': ass_op_mensal,
                event: 'olaPodcastsGTMDATALAYER',
            }
        }
        TagManager.dataLayer(tagManagerArgs);
    }
    return (
        <></>
    )
}

export default DataLayerAlternativo