import styled from "styled-components";

export const Container = styled.section`
    .modal {
        /* width: 100vw;
        height: 50vw; */
        max-width: calc(177.778vh); 
        height: calc(30.7vw);
        background-color: #fff;
        overflow-Y: hidden;
        overflow-X: hidden;
        .close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background-color: #2196F3;
            color: #fff;
            font-family: Arial;
            font-weight: bold;
            border-radius: 0.25rem;
        }
    }
`;
