import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  width: 100%;

  .alos{
    margin-left: var(--spacing-default);
  }
  .trend-title{
    margin-left: var(--spacing-default);
  }
`;

export const PremiumBanner = styled.article`
  display: flex !important;
  height: 310px;
  width: 100% ;
  justify-content: flex-start;
  align-items: center;
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpelkAUQvzQjkZVBZJOkRba7jjNW5unybMCpO9iUxsoTYVgYx9ug");
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 5px solid #979797;  */
  .destacado-img-top{
       margin-left: var(--spacing-big); 
      /* margin-left: 15%; */
      display: flex;
      width: 155px;
      height: 155px;
      background: #000000;
  }
  .destacado-corpo-top{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: var(--spacing-default);    
    width: 100%;
    color: #FFFFFF;
    /* border: 5px solid #979797; */
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
      .exclusivo{      
        font-style: italic;
        font-size: 15px;   
        margin-top: var(--spacing-small);             
      }
      .titulo{
        font-weight: 500;
        font-size: 24px;
        margin-top: var(--spacing-small);
      }
      .channel-name{
        font-size: 10px;
        letter-spacing: 0.05em;
        margin-top: var(--spacing-small);
      }
      .btn-ouvir{
        background: #FFD600;
        width: 130px;
        text-align: center;
        border-radius: 3px;
        font-size: 14px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.9);
      }
    }
`;
export const Trends = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  margin-left: var(--spacing-default);
  
  & .trend-title {
    margin-left: var(--spacing-medium);
    color: var(--color-negative);
  }
  & .trend-list{
    justify-content: start;
    flex-wrap: wrap;
    .episode-card {
      margin-bottom: var(--spacing-default);
      
    .contentOriginal {
      max-width: 80%;
      min-height: 20px;
      border-radius: 9px;
      background-color: #FF005A;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .iconOriginal {
      width: 17px;
      height: 17px;
      margin-left: 6px;
      margin-right: 6px;
      color: #fff;
    }
    .textOriginal {
        color: #fff;
        font-family: "Avenir";
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
    }
    }
  }
`;
export const PremiumBox = styled.section`
    .premium-container {
      padding-left:250px
    }
    .roleta{
      margin-top: var(--spacing-default);
    }
`;
export const Verticals = styled.section`
  width: 100%;
  height: auto;
  margin: var(--spacing-default) var(--spacing-default) var(--spacing-default);
  margin-left: var(--spacing-default);

  .vertical-header {
    margin-bottom: var(--spacing-medium);
  }
  .vertical-list.sliderContent {
    width: 1424px;
  }
  .slick-slide {
    width: auto !important;
  }
  .slick-slider {
    width: 100%;
  }
  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .vertical-title {
    color: var(--color-negative);
  }

  .vertical-box {
    width: auto !important;
    display: flex;
    flex-direction: column;

    padding: var(--spacing-small);
    margin-right: var(--spacing-half-big);
    .vertical-logo {
      width: auto;
      text-align: center;
      height: 100%;
      margin-bottom: var(--spacing-medium);
    }
  }
  .vertical-box:hover {
    box-shadow: 1px 0px 5px 1px #ccc;
  }
`;
