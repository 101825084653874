import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from 'materialize-css';

import { Container } from "./Identify.style.js";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';


//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { CATEGORIES_TO_CREATE_CHANNEL } from '../../../services/graphql/query';


//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';
import { sendMessageToUser } from '../../../common';

//elements
import { Title, Text } from "../../../elements/Typography";

class Identify extends Component {
    constructor(props){
        super(props);
        this.state = { 
            ...props.state, 
            loading: false,
            name: '',
            codename: '',
            description: '',
            primary_selected: null,
            secondary_selected: null,
            category_primary: null,
            category_secondary: null,
            mainCategories: null,
            alternateCategories: null,
        };
    }
    componentDidMount(){
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems, {});
    }

    static getDerivedStateFromProps(props, state) {
        let { data } = props;    
        let new_state = state;
        if (data.mainCategories && data.mainCategories !== state.mainCategories) new_state = { ...new_state, mainCategories: data.mainCategories };
        if (data.alternateCategories && data.alternateCategories.data !== state.alternateCategories) new_state = { ...new_state, alternateCategories: data.alternateCategories.data };
        if (!!data && data.mainCategories && props.mainCategories !== state.mainCategories) new_state =  { ...new_state, user: data.user };
        if (!!data && data.loading !== state.loading) new_state = { ...new_state, loading: data.loading };
        return new_state;
    }

    submitForm = () => {
        this.setState({loading: true});
        let { name, codename, description, primary_selected, secondary_selected} = this.state;
        if(name && codename && codename.length < 16 && description && primary_selected){
            this.props.submitForm();
        }else{
            if(name === undefined || name.length < 3) {
                sendMessageToUser('Você precisa preencher o nome do canal corretamente')
            } else if(codename === undefined || codename.length < 3 || codename.length > 15 ) {
                if(codename.length > 15){
                    sendMessageToUser('O codenome do canal deve conter no máximo 15 caracteres');
                } else {
                    sendMessageToUser('Você precisa preencher o codenome do canal corretamente');
                }
            } else if(description === undefined || description.length < 3){
                sendMessageToUser('Você precisa preencher a descrição do canal corretamente');
            } else if(primary_selected === null || primary_selected) {
                sendMessageToUser('Você precisa selecionar a categoria primaria do canal');
            }
        }
    }

    render(){
        return(
            <Container>
                <div className="boxContent">
                    {/*<span>Connect-se com seu público. Qual o nome e sobre o quê seu canal vai tratar?</span>*/}
                    <h2>Sua Jornada como criador de conteúdo começa agora</h2>
                    <span>Seguir sua paixão criativa, interagir com o público e compartilhar sus histórias começa com a criação do seu canal.</span>
                </div>
                <div className="boxContent">
                <Input
                        type="text"
                        labelContent="Crie o nome do canal *"
                        placeholder="Adicione o nome do canal"
                        id="name"
                        name="name"
                        font-size="11.5px"
                        background="#EEEEEE"
                        border="1px solid var(--color-third)"
                        value={this.state.name}
                        onChange={ (event) => { this.setState({name: event.target.value }); this.props.updateState({name: event.target.value}) } }
                    />
                    <Input
                        type="text"
                        labelContent="Codinome *"
                        placeholder="Adicione o codiname do canal"
                        id="codename"
                        name="codename"
                        inputClasses="tooltipped"
                        font-size="11.5px"
                        background="#EEEEEE"
                        border="1px solid var(--color-third)"
                        value={this.state.codename}
                        dataPosition="top"
                        dataTooltip="O CODENAME aparecerá nas páginas que não são do seu canal"
                        dataHtml="true"
                        onChange={ (event) => { this.setState({codename: event.target.value }); this.props.updateState({codename: event.target.value}) } }
                    />
                    <label className="active" for="vertical">Descrição *</label>
                    <textarea
                        type="textarea"
                        placeholder="Digite aqui uma breve descrição do seu canal, Exemplo: Lorem ipsun dolor semt log..."
                        id="description"
                        className="description"
                        name="description"
                        font-size="11.5px"
                        rows="5" 
                        value={this.state.description}
                        onChange={ (event) => { this.setState({description: event.target.value }); this.props.updateState({description: event.target.value}) } }
                    />
                    <div className="dropdowns">
                        <div className="primaria coluna">
                            <label className="active" for="vertical">Vertical *</label>
                            <Dropdown 
                                options={this.state.mainCategories || []} 
                                //placeholder='VERTICAL (categoria pincipal)' 
                                placeholder='Escolher' 
                                menuClassName="drop" 
                                controlClassName="drop"
                                value={this.state.primary_selected}
                                onChange={ (event) => { this.setState({primary_selected: event, category_primary: event.value }); this.props.updateState({category_primary: event.value}) } }
                            />
                        </div>
                        <div className="secundaria coluna">
                            <label className="active" for="categoria">Categoria secundaria</label>
                            <Dropdown 
                                options={this.state.alternateCategories || []} 
                                //placeholder='CATEGORIA SECUNDARIA (opcional)' 
                                placeholder='Escolher' 
                                menuClassName="drop" 
                                controlClassName="drop" 
                                value={this.state.secondary_selected}
                                onChange={ (event) => { this.setState({secondary_selected: event, category_secondary: event.value }); this.props.updateState({category_secondary: event.value}) } }
                            />
                        </div>
                    </div>
                    <div className="contentButtom">
                        <Button
                            type="submit"
                            className="auth-button"
                            onClick={() => this.submitForm()}
                        >
                            Criar Canal
                            {/* CONTINUAR */}
                        </Button> 
                    </div>
                </div>
            
            </Container>
        )
    }

}


export default compose(graphql(CATEGORIES_TO_CREATE_CHANNEL))(Identify);
