import gql from "graphql-tag";

const ProfileQuery = gql`

query Profile{
    profile{
        id
        email
        phone
        access_token
        premium
        signature_expire_at
        email_verified_at
        msisdn_verified_at
        expires_in
        refresh_token
        profile{
            name
            nickname
            gender
            birth
            country_id
            state_id
            city_id
            profilePicture(width: 300, height: 300)
        }
    }
}`;

export default ProfileQuery;
