import React, { Component } from "react";

import 'react-dropdown/style.css';

//graphql
import { Query } from 'react-apollo';
import {FavoritosQuery, FavoritosCannhelQuery} from './Favoritos.graphql';

//component
import Layout from "../../../hoc/Layout/Layout";

//styles
import { Container } from "./Favoritos.styles";
import EpisodeCard from "../../../components/EpisodeCardComponent";
import { ThumbEpisode } from "../../../components/ThumbEpisode";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class Favoritos extends Component {
    
    render() {
        return (
            <Layout>
                <div className="container">
                    <div className="contentTitle">
                        <h4>Favoritos</h4>
                    </div>
                    <Container>

                        <div className="colunLeft">
                            <Query query={ FavoritosCannhelQuery } fetchPolicy="no-cache" errorPolicy="all">
                            {
                                ({ loading, error, data }) => {
                                    if (loading && !data) return <div> {`Loading: ${loading}`} </div>;
                                    if (error) {
                                    if (error.networkError) {
                                        return <div></div>;
                                    }
                                    if (error.graphQLErrors) {
                                        return <div></div>;
                                    }
                                    return <div></div>;
                                    }

                                    if(data.myPlaylists.data[0].episodes_with_order.length >= 1){
                                        return <ThumbEpisode data={data}/>;
                                    }else{
                                        return "";
                                    }
                                }
                            }
                            </Query>
    
                            
                            {/* <Button
                                type="submit"
                                className="play-all"
                                Background="var(--color-first)"
                                Color="var(--color-zero)"
                            >
                                TOCAR TODOS
                            </Button> */}
                        </div>
                        <div className="colunRight">
                            {/*
                            <div className="contentDropdown">
                            <Dropdown options={['AAAA','BBBBB']} value='ORDENAR POR' menuClassName="drop" controlClassName="drop" />
                            </div>
                            */}
                            <Query query={ FavoritosQuery } fetchPolicy="no-cache" errorPolicy="all">
                            {
                                ({ loading, error, data }) => {
                                    if (loading && !data) return <div> {`Loading: ${loading}`} </div>;
                                    if (error) {
                                    if (error.networkError) {
                                        return <div> {`Error: no network`} </div>;
                                    }
                                    if (error.graphQLErrors) {
                                        return <div> {`Error: ${error}`} </div>;
                                    }
                                    return <div> {`Error: ${error}`} </div>;
                                    }
                                    
                                    if(data.myPlaylists.data[0].episodes_with_order.length >= 1){
                                        return data.myPlaylists.data[0].episodes_with_order.map((episode,index) => 
                                            <div className="contentEpisode">
                                                <div className="contentIndice">{index+1}</div>
                                                <EpisodeCard horizontal showDetails key={episode.id} episode={episode.episode} />
                                            </div>
                                        )
                                    }else{
                                        return "Você não tem episódios favoritados."
                                    }
                                }
                            }
                            </Query>
                        </div>
                    </Container>
                </div>
            </Layout>
        )
    }
}
