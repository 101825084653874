import React from 'react';

import EpisodeCard from '../EpisodeCardComponent';

const EpisodeListComponent = ({episodes,id,scope}) => {
    
    if(episodes === undefined){
        return <>carregando...</>
    } else if(episodes && episodes.data.length){
        return(
            <div>
            {episodes.data.map( episode => <EpisodeCard key={episode.id} episode={episode}  horizontal scope={scope} showDetails /> )}
            </div>
        )
    }else{
        return( 
            <p>
                <div>Este canal ainda nao possui episódios.</div>
                {/* Para adicionar seus episódios vá em: Perfil > Meus Canais > Administrar Canal<br/>
                <div style={{display: 'flex', alignItems: 'flex-end', paddingTop: '10px'}}>
                     <div>No canto superior esquerdo clique em Episódios ou clique em:</div>
                     <div style={{marginLeft: '10px'}}><a href={`/channel/${id}/episode/new`} style={{width: "170px"}} className="button-primary btn subscribe-button button-primary">Adicionar Episódio</a></div>
                </div> */}
            </p>
        )
    }
}

export default EpisodeListComponent;
