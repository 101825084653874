import React, { Component , useState } from 'react';
import { ACCESS_TOKEN } from '../../constants';
import { sendMessageToUser } from '../../common';
import { Query, Mutation, graphql } from 'react-apollo';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import M from 'materialize-css';

import apolloClient from "../../services/apollo";

import { NOTIFY_PLAYBACK } from '../../services/graphql/mutations';
import { NEXT_EPS_TO_PLAY } from '../../services/graphql/query';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

// import Hls from 'hls.js';
const Hls = window.Hls;

type Episode = {
    id:ID,
    title:String,
    description?:String,
    content:String,
    play_count:Integer,
    publishDate:Integer,
    thumbnail?:String,
    channel:{
        id?:ID,
        name:String,
        codename:String,
        thumbnail: String,
        cover: String,
        flag:String,
        premium:Boolean,
        original: Boolean,
    }
}

const PLAYER_COMPONENT = 'playerComponent';
const USER_ACCOUNT = 'UserAccount';
const PLAYER_UPDATE = new Event('playerupdate');


let userAgent = window.navigator.userAgent;
let VIDEO_OR_AUDIO = 'video';

if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
     VIDEO_OR_AUDIO = 'audio';
}

export class PlayerComponent{

    notificaBack = ($start,$end) => {

        // console.log("Notificando Play ["  + this.current.id + "] - Start: " + $start + " - End: " + $end);
        if (!isNaN(this.current.id)) {
            apolloClient.mutate({
                mutation: NOTIFY_PLAYBACK, 
                variables: {
                    episode_id: this.current.id, 
                    start: $start, 
                    end: $end,
                    source: "web"
                }
            });
        }
    }

    
    fetchAudio = async (uri) => {
        let authorization;
        if(localStorage && localStorage.length){
            let token = localStorage.getItem(ACCESS_TOKEN)
            if(token) authorization = `Bearer ${token}`
        }
        const requestOptions = {
            method: 'GET',
            headers: {
            'Authorization': authorization
            }
        };
        var urlHttps = uri.replace(/^http\:\/\//i, "https://");
        return fetch(urlHttps, requestOptions)
            .then(res => {
            if (!res.ok)
                throw new Error(`${res.status} = ${res.statusText}`);
            var reader = res.body.getReader();
            return reader
                .read()
                .then((result) => {
                return result;
                });
            })
    }

    constructor(){
        const [state, setState] = useState({})
        this.state = state;
        this.setState = setState;
        this.playerFromCache();
    }

    playerFromCache = () => {
        if(localStorage.length){
            let player = localStorage.getItem(PLAYER_COMPONENT)
            if(player){
                // console.log('local player ', JSON.parse(player) )
                let playerJSON = JSON.parse(player);
                document.player = {
                    ...this, 
                    ...playerJSON,
                }

                if(!!playerJSON && playerJSON.current) this.setCurrent(playerJSON.current);
                if(!!playerJSON && playerJSON.queue) this.setQueue(playerJSON.queue);
                if(!!playerJSON && playerJSON.currentTime){
                    // console.log("Tentando setar variavel de tempo no currentTime: ",playerJSON.currentTime);
                    this.setCurrentTimeFromCache(playerJSON.currentTime);
                    this.setInitialTime(playerJSON.currentTime);
                    this.setCurrentProgressFromCache(playerJSON.currentTime);
                } 

            }else{
                this.createEmptyPlayerOnCache()
            }
        } else {
            this.createEmptyPlayerOnCache()
        }
    }

    updatePlayerToCache = () => {
        let player = {
            current: this.getCurrent(),
            queue: this.getQueue()
        }
        // console.log('updatePlayerToCache ', player)
        localStorage.setItem(PLAYER_COMPONENT, JSON.stringify(player))
    }

    renderSecondsInTime = (secondsRaw) =>{
        let minutes = parseInt(secondsRaw/60);
        let seconds = Math.round(secondsRaw%60);
        let minutesRender = minutes < 10 ? `0${minutes}` : minutes;
        let secondsRender = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutesRender}:${secondsRender}`;
    }

    createEmptyPlayerOnCache(){
        let player = {
            current: this.current,
            queue: this.queue
        };

        localStorage.setItem(PLAYER_COMPONENT, JSON.stringify(player) )
        document.player = { 
            ...this,
            ...player, 
        };
    }
    verificaIdade = (ep) => {
        return new Promise( 
            (resolve, reject) => { confirmAlert({
                title: 'Alerta!',
                message: 'Voce precisa ser maior de 18 anos para ouvir "'+ ep.title +'". Voce tem mais que 18 anos ?',
                buttons: [
                    {
                    label: 'SIM',
                    onClick: () => { 
                        resolve(true);
                    }
                    },
                    {
                    label: 'NÅO',
                    onClick: () => { reject(false) }
                    }
                ]
                // customUI: ({ onClose }) => {
                //     return (
                //       <div className='custom-ui'>
                //         <h1>Are you sure?</h1>
                //         <p>You want to delete this file?</p>
                //         <button onClick={() => {
                //             reject(false);
                //             onClose();
                //         }}>No</button>
                //         <button
                //           onClick={() => {
                //             resolve(true);
                //             onClose();
                //           }}
                //         >
                //           Yes, Delete it!
                //         </button>
                //       </div>
                //     );
                // }
            }) 
        })
      }; 

    eventUpdateLabel:String = 'playerupdate';

    current:Episode;

    queue:Episode[];

    currentIndex:Int;

    autoContinue:Boolean = true;

    isPlaying:Boolean = false;

    initialTime:Int;

    notifyTime:Int;

    currentTime:Int;

    currentFromCacheTime:Int;

    currentDuration:Int;

    currentProgress:Float;

    onProgress;

    getCurrentProgress:Float = () =>{
        return this.currentProgress
    }

    getAudioDuration:Float = () =>{
        return document.audio.duration;
    }
    getCurrentProgressFromCache:Float = () =>{
        return  this.currentFromCacheTime;
    }

    setCurrentProgress:void = (value) => {
        if(this.current){
            //this.currentTime = value;
            if(
                !!document.audio &&
                !!document.audio.duration && 
                document.audio.duration != 'NaN'
            ){
                document.audio.currentTime = value*document.audio.duration
            }else if(!!document.audio && !!document.audio.currentTime){
                document.audio.currentTime = value;
            }
        }
    }
    setCurrentProgressFromCache:void = (value) => {
        if(this.current){
            this.currentFromCacheTime = value;
            //document.audio.currentTime = value*document.audio.duration
        }
    }
    setCurrentTimeFromCache:void = (value) => {
        if(this.current){
            // console.log("Setando current progress: ",value);
            this.currentTime = value;
        }
    }
    setCurrentAudioTime:void = (value) => {
        if(this.current){
            // console.log("Setando current progress: ",value);
            if(
                !!document.audio &&
                !!document.audio.duration && 
                document.audio.duration != 'NaN'
            ){
                document.audio.currentTime = value
            }else if(!!document.audio && !!document.audio.currentTime){
                document.audio.currentTime = value;
            }
        }
    }
    setInitialTime:void = (value) => {
        console.log("Setando posicao inicial: " + value);
        this.initialTime = value;
    }

    getCurrentTime:Int = () =>{
        return document.audio.currentTime;
    }

    getIsPlaying:Boolena = () =>{
        return this.isPlaying;
    }

    getCurrentDuration = () => {
        return this.currentDuration;
    }

    getCurrentDurationRendered = () => {
        
        if(this.currentDuration){
            // // console.log("getCurrent....: ",this.renderSecondsInTime(this.currentDuration));
            return this.renderSecondsInTime(this.currentDuration)
        }
    }

    abreModalLogin = () => {
        var elems = document.getElementById('signup');
        // console.log("Elems: ",elems);
        var instances = M.Modal.init(elems, {
            onOpenStart: function () {
                var ev = new CustomEvent('modal-entrar', { "detail": "login"});
                document.dispatchEvent(ev);
          }
        });
        var instance = M.Modal.getInstance(elems);
        instance.open();
    }

    getIndex:Int = () => {
        return this.currentIndex;
    }

    getQueue:Episode[] = () => {
        return this.queue
    }

    getCurrent:Episode = () => {
        return this.current;
    }

    setCurrent:Episode = async (episode:Episode) => {
        
        if(episode.content){
          
            let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
            // // console.log("Verificando episodio: ",episode);
            // // console.log("Verificando conta: ",userAccount);

            if(episode.premium && !localStorage.getItem(ACCESS_TOKEN) ){
                // console.log("Premium deslogado")
                this.pause();
                sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.')
                this.abreModalLogin();
                return false;
            }else if(episode.premium && !userAccount.subscribed){
                // console.log("Premium nao assinado");

                var elems = document.getElementById('modal-premium');
                var instances = M.Modal.init(elems, {
                    onOpenStart: function () {
                        var ev = new CustomEvent("abre-premium", { 'detail': "detalhesAqui" });
                        document.dispatchEvent(ev);
                    }
                });
                try {
                    var instance = M.Modal.getInstance(elems);
                    instance.open();
                } catch (e) {
                    // console.log("Unable to open premium dialog: ", e)
                }

                return;
            }else{
                if(episode.rating == "18" & this.current != episode){
                    // console.log("Verifica 18 anos")
                    this.pause();
                    // let maior = await this.verificaIdade(episode);
                    // if(!maior){
                    //     return false;
                    // }
                }
                // console.log("Passei dos 18")
                let episodeOnQueue = this.findEpisodeOnQueue(episode);
                if(episodeOnQueue && episodeOnQueue.index){
                    this.currentIndex = episodeOnQueue.index;  
                }else{
                    // this.addToTopQueue(episode);
                } 
                this.loadContent(episode.content);
                this.current = episode; 
                // this.removeFromQueue(episode)
                this.updatePlayerToCache();
                document.dispatchEvent(PLAYER_UPDATE);
                return this.current;
            }
        }
    }

    setQueue:Episode[] = (queue:Episode[]) => {
        if(queue){
            this.queue = queue;
            document.dispatchEvent(PLAYER_UPDATE);
        }
    }

    loadContent = (uri) => {
        
    }

    onEnd = (callback) => {
        // console.log("Terminei de ouvir !");
        if(this.current)this.removeFromQueue(this.current)
        
        if(this.autoContinue) this.next();

        document.dispatchEvent(PLAYER_UPDATE);
    }


    playHls = (uri, type) => {
        try{

            document.audio = document.createElement(VIDEO_OR_AUDIO);
            document.audio.type = "application/x-mpegURL";
            var config = {
                xhrSetup: function(xhr, url) {
                    // xhr.withCredentials = true;
                    let authorization, token;
                    if(localStorage && localStorage.length){
                        token = localStorage.getItem(ACCESS_TOKEN)
                        if(token) authorization = `Bearer ${token}`
                    };
                    if(token)
                        xhr.setRequestHeader('Authorization', authorization);
                }
            }
            document.audio.originalSource = uri;
            document.hls = new Hls(config);
            document.hls.attachMedia(document.audio);
            document.hls.overrideNative = true ;
            document.hls.nativeVideoTracks = false ;
            document.hls.nativeAudioTracks = false ;
            document.hls.nativeTextTracks = false ;
            
            document.hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                document.hls.loadSource(uri);
            });
            document.audio.addEventListener( "ended", this.onEnd );
            document.hls.on(Hls.Events.ERROR, function(event, data){
                // console.log("Erro Player ",event);
                if(data.response && data.response.code == 403) {
                    if (!localStorage.getItem(ACCESS_TOKEN)) {
                        sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.')
                    } else {
                        // console.log("Premium nao assinado");

                        var elems = document.getElementById('modal-premium');
                        var instances = M.Modal.init(elems, {
                            onOpenStart: function () {
                                var ev = new CustomEvent("abre-premium", { 'detail': "detalhesAqui" });
                                document.dispatchEvent(ev);
                            }
                        });
                        var instance = M.Modal.getInstance(elems);
                        instance.open();
                        document.dispatchEvent(PLAYER_UPDATE);
                        this.isPlaying = false;
                        if(document.audio) document.audio.pause();  
                    }
                }
            })
           
            const progress = this.inProgress;
            const currentDuration = this.currentDuration;

            document.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                // // console.log("manifest loaded, found " + data.levels.length + " quality level");
                document.audio.addEventListener( 'timeupdate', this.inProgress )
                // console.log("Aqui estou tentando colocar evento de fim 3");
                
                document.audio.addEventListener( "durationchange", (event) => this.currentDuration = document.audio.duration )
                document.audio.ontimeupdate = () =>  { progress() }
                document.audio.currentTime = this.currentTime;
                currentDuration = document.audio.duration;
                
                this.isPlaying = true;
            });
        } catch(e) {
            // console.log('error hls => ', e)
        }
    }

    play = (audioUri, external) => {

        if(this.current && this.current.content && (!document.audio || this.current.content != document.audio.originalSource) ){

            if(!this.currentTime){
                // console.log("Setando inicio do play em 0 e notificacao em 20s");
                this.initialTime = 0;
            }

            this.notifyTime = 20;
            
            // console.log("START: ", this.currentTime);
            // console.log("INITIAL TIME: ", this.initialTime);

            let uriRaw = audioUri || this.current.content;
            let ext = external || this.current.external;
            const progress = this.inProgress;
            const currentDuration = this.currentDuration;
            console.log("uriRaw: ",uriRaw);
            if(uriRaw.indexOf('.mp3') > 0 || uriRaw.indexOf('.m4a') > 0 ){
                if (ext || 1==1) {
                    document.audio = document.createElement(VIDEO_OR_AUDIO);
                    document.audio.src = uriRaw;
                    if(!ext){
                        document.audio.src = document.audio.src + "?Authorization=" + localStorage.getItem(ACCESS_TOKEN);
                    }
                    document.audio.type = "application/x-mpegURL";
                    // TODO - fix callbacks
                    document.audio.originalSource = uriRaw; // faltava setar o original source por isso nao conduzia a barra
                    document.audio.addEventListener( 'timeupdate', this.inProgress );
                    // console.log("Aqui estou tentando colocar evento de fim 2");
                    document.audio.addEventListener( "ended", this.onEnd );
                    document.audio.addEventListener( "durationchange", (event) => this.currentDuration = document.audio.duration );
                    document.audio.ontimeupdate = () =>  { progress() }
                    this.currentDuration = document.audio.duration;
                    // console.log("URL: ",document.audio.src);
                    if(!!this.initialTime && this.initialTime > 0){
                        document.audio.currentTime = this.initialTime;
                    }
                    document.audio.play();
                    this.isPlaying = true;
                } else {
                    this.fetchAudio(uriRaw)
                    .then((response) => {
                        
                        var blob = new Blob([response.value], { type: "application/x-mpegURL" });
                        var url = window.URL.createObjectURL(blob)
                        document.audio = document.createElement(VIDEO_OR_AUDIO);
                        document.audio.src = url + "?Authorization=" + localStorage.getItem(ACCESS_TOKEN);
                        document.audio.originalSource = audioUri || this.current.content;
                        document.audio.type = "application/x-mpegURL";
                        document.audio.addEventListener( 'timeupdate', this.inProgress )
                        // console.log("Aqui estou tentando colocar evento de fim 1");
                        document.audio.addEventListener( "ended", this.onEnd );
                        
                        document.audio.addEventListener( "durationchange", (event) => this.currentDuration = document.audio.duration )
                        document.audio.ontimeupdate = () =>  { progress() }
                        this.currentDuration = document.audio.duration;
                        if(!!this.initialTime && this.initialTime > 0){
                            document.audio.currentTime = this.initialTime;
                        }
                        document.audio.play();
                        this.isPlaying = true;
                    })
                    .catch((error) => console.log('error player ', error))
                } 
            }else if(uriRaw.indexOf('.m3u8') > 0){
                this.playHls(uriRaw);
                
                if(this.getCurrentProgressFromCache() > 0){
                    // // console.log("Tentando setar o Progress: " + this.getCurrentProgressFromCache() + "Temos o duration ? " + document.audio.duration );
                    this.setCurrentProgress(this.getCurrentProgressFromCache());
                    this.setCurrentProgressFromCache(0);
                }

                if(!!this.initialTime && this.initialTime > 0){
                    document.audio.currentTime = this.initialTime;
                }
                document.audio.play();
                this.isPlaying = true;
            }else{
                
                sendMessageToUser('Episódio inválido')
            }

        } else if(document.audio) {
            document.audio.play();
            this.isPlaying = true;
        }
        document.dispatchEvent(PLAYER_UPDATE);
    }

    pause = () => {
        this.isPlaying = false;
        document.dispatchEvent(PLAYER_UPDATE);
        if(document.audio) document.audio.pause();  
    }



    inProgress = () => {
        if(!document.audio.paused){
 
            this.currentTime = document.audio.currentTime;
            this.currentProgress = document.audio.currentTime/document.audio.duration;
            // console.log("Current Time: " + document.audio.currentTime + " Duracao: " + document.audio.duration + " Current Progress (C/D): " + this.currentProgress);
            // // console.log("Current Progress: " + this.currentTime + " | Tempo Inicio: " + this.initialTime + " | tempoAteNotificar: " + Math.round((this.initialTime + this.notifyTime) -  this.currentTime));

            if(((this.currentTime - this.initialTime) >= this.notifyTime)){
                // console.log("Passaram "+  this.notifyTime +"s : I " + this.initialTime + " - F " + this.currentTime);

                //AQUI EU FACO O MUTATION PARA NOTIFICAR
                this.notificaBack(Math.floor(this.initialTime),Math.floor(this.currentTime));

                this.initialTime = this.currentTime;
                // console.log("Mudando InitialTime para"  + this.initialTime);
            }
            
            document.dispatchEvent(PLAYER_UPDATE);
            if(this.onProgress) this.onProgress();
        }
    }
    updatePlayer = () => {
        document.dispatchEvent(PLAYER_UPDATE);
    }
    setProgress = (callback) => {
        if(callback) this.onProgress = callback
    }

    findEpisodeOnQueue = (episode:Episode) => {
        if(this.queue && this.queue.length){
            let resp = this.queue.findIndex((element,index) => {

                if(element.id == episode.id){
                    return { 
                        episode,
                        index
                    }
                }
            })
            if(resp < 0){
                return false;
            }else{
                return {
                    episode,
                    index: resp
                }
            }
        }
    }

    getNext = () => {
        if(this.queue && this.current && this.queue.length){
            let episodeOnQueue = this.findEpisodeOnQueue(this.current);


            if( episodeOnQueue && (episodeOnQueue.index < (this.queue.length - 1)) && this.queue.length > 1 ){
                return this.queue[episodeOnQueue.index+1]
            } else if(this.queue && this.queue.length && !episodeOnQueue){
                return this.queue[0]
            }
        }
    }

    getPrev = () => {
        if(this.queue && this.current && this.queue.length){
            let episodeOnQueue = this.findEpisodeOnQueue(this.current);
            if( episodeOnQueue && episodeOnQueue.index > 0 ){
                return this.queue[episodeOnQueue.index-1]
            }
        }
    }

    getNextFromChannel = async () => {
        let ep = this.current;

        console.log("Get Next From Channel")
        console.log(ep)

         apolloClient.query({
            query: NEXT_EPS_TO_PLAY, 
            variables: {
                id:ep.id, 
            }
        }).then((data) => {
            console.log(data.data.nextEpisodesToPlay);

            if(!!data && !!data.data && !!data.data.nextEpisodesToPlay){
                    //JA TA OUVINDO O ULTIMO, BUSCA O PENULTIMO
                    let newEp = data.data.nextEpisodesToPlay[0];
                    this.pause();

                    this.setCurrent(newEp).then(() => { 
                        this.setInitialTime(0);

                        this.play();
                        document.dispatchEvent(PLAYER_UPDATE);
                    });   
                    
                    for(let i=1; i<data.data.nextEpisodesToPlay.length; i++){
                        this.addToEndQueue(data.data.nextEpisodesToPlay[i]);
                    }
                
            }else{
                console.log("NOPE SEM NOVOS");

            }
             
            return data;
        });
        
        console.log("Aqui de alguma forma tenho q buscar um  outro episodio do canal deste episodio q esta tocando.");
    }

    next = async () => {
        if(this.queue && this.current && this.queue.length){
            let episodeOnQueue = this.findEpisodeOnQueue(this.current);
            if( episodeOnQueue && episodeOnQueue.index < (this.queue.length - 1) ){
                this.pause();
                await this.setCurrent(this.queue[episodeOnQueue.index+1])
                this.play();
                document.dispatchEvent(PLAYER_UPDATE);
            }else if(this.queue && this.queue.length){
                this.pause();
                await this.setCurrent(this.queue[0]);
                this.play();
                document.dispatchEvent(PLAYER_UPDATE);
            }
        }else{
            this.getNextFromChannel();
        }
    }

    prev = async () => {
        if(this.queue && this.current && this.queue.length){
            let episodeOnQueue = this.findEpisodeOnQueue(this.current);
            if( episodeOnQueue && episodeOnQueue.index > 0 ){
                // console.log("Colocando Anterior");
                this.pause();
                await this.setCurrent(this.queue[episodeOnQueue.index-1])
                this.play();
                document.dispatchEvent(PLAYER_UPDATE);
            }
        }
    }

    addToEndQueue = (episode:Episode) => {
        if(episode.content){
            if(this.queue && this.queue.length){
                let filter = this.queue.filter( ep => ep.id == episode.id)
                if(filter.length == 0) this.queue.push(episode);
            }else{
                this.queue = [episode];
            }
            this.updatePlayerToCache();
            document.dispatchEvent(PLAYER_UPDATE);
            // console.log("Adicionando ep no final da lista,",this.queue);
        }
    }

    addToTopQueue = (episode:Episode) => {
        if(episode.content){
            if(this.queue && this.queue.length){
                let filter = this.queue.filter( ep => ep.id == episode.id)
                if(filter.length == 0) this.queue.unshift(episode);
            }else{
                this.queue = [episode];
            }
            this.updatePlayerToCache();
            document.dispatchEvent(PLAYER_UPDATE);
            // console.log("Adicionando ep no top da lista,",this.queue);
        }
    }

    removeFromQueue = (episode:Episode) => {
        if(this.queue && this.queue.length){
            
            let newQueue = this.queue.filter(item => item.id !== episode.id)
            this.queue = newQueue;
        }
        this.updatePlayerToCache();
        document.dispatchEvent(PLAYER_UPDATE);
    }

}
