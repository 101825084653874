import gql from "graphql-tag";

const EditorialQuery = gql`

  query EditorialQuery($id: Int!, $coverWidth: Int!, $coverHeight: Int!) {
     editorial(id: $id) {
          id
          olho
          titulo
          subtitulo
          texto
          autor
          action
          arguments
          cover(width: $coverWidth, height: $coverHeight)
      }
    }`;

export default EditorialQuery;
