import styled from "styled-components";
export const Container = styled.article`
  width: auto;
  margin: 0 2%;
  background: ${props => props.backgroundColor};
  padding: var(--spacing-small);
  border-radius: var(--radius-small);
  margin-bottom: var(--spacing-small);
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  color: #666;
  
  .whiteColor {
    color: #fff;
  }

  .episode-thumbs {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    display: flex;
    position: relative;
    a {
      position: absolute;
      width: 80%;
      height: 80%;
      display: flex;
      margin: 10%;
      background-position: center;
      background-size: cover;
      overflow: hidden;
    }
    a:before {
      content: '';
      display: inline-block;
      height: 100%; 
      vertical-align: middle;
      margin-right: -0.25em;
    }
  }
  .epsode-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-size: cover;
    background-position: center;
  }
  .card-body {
    margin-left: var(--spacing-small);
    margin-top: var(--spacing-small);
  }
  .title-block {
    display: flex;
    min-height: 65px;
    font-style: normal;
    font-weight: 700;
    font-size: var(--size-half-medium);
    line-height: 1.4rem;
    span {
      margin-bottom: 1.3rem;
      display: block;
    }
  }
  .seguir {
    border-radius: 3px;
    width: 9rem;
    color: #fff;
    margin-left: 10px;
  }
  .ativo {
    background: #2196F3;
  }
  .inativo {
    background: rgba(102, 117, 127, 0.3);
  }
  .lineChannel {
    display: flex;
    flex-direction: row;
    .channel-code {
      margin-top: 0.7rem;
    }
  }

  @keyframes example {
    from {opacity: 0;}
    to {opacity: 0.6}
  }
`;
