import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Container } from './styles';
// import { Container } from './styles';
import { Settings, Headset, AddCircleOutline } from "styled-icons/material";
import { Button } from '../../elements/ButtonElement';
import { ACCESS_TOKEN, USER_ACCOUNT } from '../../constants';
import M from 'materialize-css';
import { sendMessageToUser } from '../../common';

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { FOLLOW_CHANNEL  } from '../../services/graphql/mutations';
import defaultImage from "../../assets/img/thumbDefault.jpg";
import CoverImage from "../../assets/img/coverDefault.jpg";


import CONHECIMENTO from "../../assets/img/Verticais/conhecimento_cover.jpg";
import COTIDIANO from "../../assets/img/Verticais/cotidiano_cover.jpg";
import ESPORTES from "../../assets/img/Verticais/esporte_cover.jpg";
import FAMILIA from "../../assets/img/Verticais/familia_cover.jpg";
import HISTORIAS from "../../assets/img/Verticais/historia_cover.jpg";
import HUMOR from "../../assets/img/Verticais/humor_cover.jpg";
import LIFESTYLE from "../../assets/img/Verticais/lifestyle_cover.jpg";
import MERCADO from "../../assets/img/Verticais/mercado_cover.jpg";
import TECNOLOGIA from "../../assets/img/Verticais/tecnologia_cover.jpg";
import VARIEDADES from "../../assets/img/Verticais/variedades_cover.jpg";

//icons
import { Rss } from "styled-icons/boxicons-regular";

const coverCategory = (name) => {
  switch(name) {
    case "HUMOR":
      return HUMOR;
    case "CONHECIMENTO":
      return CONHECIMENTO;
    case "COTIDIANO":
      return COTIDIANO;
    case "ESPORTES":
      return ESPORTES;
    case "FAMILIA":
      return FAMILIA;
    case "HISTORIAS":
      return HISTORIAS;
    case "LIFESTYLE":
      return LIFESTYLE;
    case "MERCADO":
      return MERCADO;
    case "TECNOLOGIA":
      return TECNOLOGIA;
    case "VARIEDADES":
      return VARIEDADES;
    default:
      return CoverImage;
  }
}


const SubscriberComponent = ({loading, following, followers_count, actionFollow, logged, premium, subscribed}) => (
  <>
    {!logged ?
      <Button
        className={`btn subscribe-button button-secondary`}
        onClick={(event) => sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.') }
      >
        Inscreva-se
    </Button>
    :
      premium && !subscribed ?
        <Button
          className={`btn subscribe-button button-secondary`}
          onClick={(event) => {
            var elems = document.getElementById('modal-premium');
            var instances = M.Modal.init(elems, {
                onOpenStart: function () {
                    var ev = new CustomEvent("abre-premium", { 'detail': "detalhesAqui" });
                    document.dispatchEvent(ev);
                }
            });
            var instance = M.Modal.getInstance(elems);
            instance.open();
          } }
        >
            Inscreva-se
        </Button>
      :
        <Button
          loading={loading}
          className={`btn subscribe-button ${following ? 'button-secondary' : 'button-primary'}`}
          onClick={(event) => { event.preventDefault(); actionFollow() } }
        >
          {following ? 'Inscrito' : 'Inscreva-se'}
        </Button>
    }
    <span className="subscribers-count">{followers_count > 0 ? `${followers_count} inscritos` : `Nenhum inscrito ainda. Inscreva-se agora` }</span>

  </>
)
class HeaderChannel extends Component {

  constructor(props){
    super(props);
    this.state = {
      channel: props.channel,
      loading: false,
    }
  }

  actionFollow = async () => {
    this.setState({loading: true})
    // console.log('actionFollow channel', this.state)
    let bodyConsult = { channel_id: this.state.channel.id }
    if(this.state.channel.following){
      bodyConsult.unfollow = true
    }else{
      bodyConsult.unfollow = false
    }
    // console.log('actionFollow ', bodyConsult)
    let followChannel = await this.props.mutate({ variables: bodyConsult });
    // console.log('followChannel ', followChannel)
    this.setState({
      channel: followChannel.data.followChannel,
      loading: false
    })
  }

  static getDerivedStateFromProps(props, state){
      let { data } = props;
      let new_state = state;
      if(!!data && !!data.channels &&data.channels.data[0] !== state.channel ) new_state = { ...new_state, channel: data.channels.data[0] }
      if(props.channel !== state.channel) new_state = { ...new_state, channel: props.channel }
      if(!!data && data.loading !==  state.loading) new_state =  { ...new_state, loading: data.loading }
      return new_state;
  }

  getUrlCompartilhar = () => {
    const baseUri = window._env_.API_URL;
    // console.log("FEED: ",baseUri )
    return `${baseUri}feed/${this.state.channel.codename}`
  }
  copyToClipboard = (str) => {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    sendMessageToUser("Link para compartilhamento copiado com sucesso.");    
  };

  render() {
    // console.log('HeaderChannel ', this.props)
    let {
      name,
      codename,
      description,
      subscribers,
      vertical,
      site,
      flag,
      thumbnail,
      cover,
      main_category,
      alternate_category,
      isAdmin,
      followers_count,
      following,
      premium,
      original

    } = this.state.channel;
    let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

    return (
      <>
        <div className="row channel-header">
          <div
            style={{ backgroundImage: `url(${cover?cover:main_category?coverCategory(main_category.name):CoverImage})` }}
            className="channel-cover col s12"
          />
          <div className=" channel-container">
            <div className="channel-card col s12 m8 l8">
              <div className="channel-thumb">
                <img
                  className="responsive-img"
                  src={thumbnail?thumbnail:defaultImage}
                  alt={name}
                />
              </div>
              <div className="channel-info">
                <strong className="channel-title">{name}</strong>
                {
                  premium || !!flag ?
                  <div className={`channel-codename channel-${premium ? 'premium' : flag}`}>{codename}</div>
                  :
                  <div className={`channel-codename channel-`}>{codename}
                  { !!this.props.showrss &&
                  <button className="rss-icon-channel " onClick={() => this.copyToClipboard(this.getUrlCompartilhar())}>
                    {/* <Rss className="admin-icon" /> */}
                    <svg width="15px" height="15px" viewBox="0 0 30 30" version="1.1" style={{marginLeft:"5px"}}>
                        <title>Shape</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Assinar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Assinar-Pacotes-Cartões" transform="translate(-755.000000, -423.000000)" fill="#F08332" fill-rule="nonzero">
                                <path d="M780.108711,423 L759.891289,423 C757.194102,423 755,425.194102 755,427.891289 L755,448.108652 C755,450.805898 757.194102,453 759.891289,453 L780.108652,453 C782.805898,453 785,450.805898 785,448.108711 L785,427.891289 C785,425.194102 782.805898,423 780.108711,423 Z M762.173926,448.760859 C760.555625,448.760859 759.239141,447.444375 759.239141,445.826074 C759.239141,444.207773 760.555625,442.891289 762.173926,442.891289 C763.792227,442.891289 765.108711,444.207773 765.108711,445.826074 C765.108711,447.444375 763.792227,448.760859 762.173926,448.760859 Z M770,447.782637 C768.921113,447.782637 768.043496,446.90502 768.043496,445.826133 C768.043496,442.589766 765.410586,439.956563 762.173926,439.956563 C761.095039,439.956563 760.217422,439.078945 760.217422,438.000059 C760.217422,436.921172 761.095039,436.043555 762.173926,436.043555 C767.568359,436.043555 771.956504,440.432051 771.956504,445.826133 C771.956504,446.904961 771.078887,447.782637 770,447.782637 Z M777.826074,447.782637 C776.747187,447.782637 775.86957,446.90502 775.86957,445.826133 C775.86957,438.274219 769.725488,432.130488 762.173926,432.130488 C761.095039,432.130488 760.217422,431.252871 760.217422,430.173984 C760.217422,429.095098 761.095039,428.21748 762.173926,428.21748 C771.88332,428.21748 779.782637,436.116797 779.782637,445.826191 C779.782637,446.904961 778.904961,447.782637 777.826074,447.782637 Z" id="Shape"/>
                            </g>
                        </g>
                    </svg>
                  </button>
                  }
                  </div>
                }
                <div id="inscricao_channel_btn" className="channel-subscribe hide-on-med-and-down">
                  <SubscriberComponent loading={this.state.loading} following={following} followers_count={followers_count} actionFollow={this.actionFollow} logged={localStorage.getItem(ACCESS_TOKEN)} premium={premium} subscribed={!!userAccount ? userAccount.subscribed : false} />
                </div>
              </div>
            </div>
            {/*
            <div className="col s12 m4 channel-verticals hide-on-med-and-down">
              <div className="channel-alo"></div>
            </div>
            */}
            <div className="col s12 hide-on-large-only  subscribers">
              <SubscriberComponent loading={this.state.loading} following={following} followers_count={followers_count} actionFollow={this.actionFollow} logged={localStorage.getItem(ACCESS_TOKEN)} premium={premium} subscribed={!!userAccount ? userAccount.subscribed : false}/>
            </div>
            {/*
            <div className="col s12 hide-on-large-only  channel-verticals">
              <div className="channel-alo"></div>
            </div>
            */}
          </div>
        </div>
        {!!isAdmin && (
          <>
            <div className="row hide-on-med-and-down">
              <div className="s12 channel-is_admin">
                <button className="btn-flat waves-effect waves-light blue-text text-darken-2 admin-button  admin-button_config">
                  <Settings className="admin-icon" /> Administrar Canal
                </button>
                <button className="btn waves-effect waves-light blue admin-button ">
                  Gravar Alô
                </button>
                <button className="btn waves-effect waves-light blue  admin-button">
                  Adicionar Episódio
                </button>
              </div>
            </div>
            <div className="hide-on-large-only settings">
              <div className="fixed-action-btn">
                <Link className="btn-floating btn-large btn-flat white blue-text admin-button_settings">
                  <Settings className="admin-icon--large" />
                </Link>
                <ul>
                  <li>
                    <Link className="btn-floating blue">
                      <AddCircleOutline className="admin-icon" />
                    </Link>
                  </li>
                  <li>
                    <Link className="btn-floating blue">
                      <Headset className="admin-icon" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default compose(graphql(FOLLOW_CHANNEL))(HeaderChannel);
