import styled from "styled-components";

export const Container = styled.section`
    .modal {
        max-width: 535px;
        max-height: 660px;
        padding: 39px;
    }
    .auth-logo {
        height: auto;
        width: 100%;
        display: flex;
        margin: 0 auto 51px;
        img {
            width: 129px;
        }
        align-items: center;
        justify-content: center;
    }
    .auth-logo-cad {
        height: auto;
        width: 100%;
        display: flex;
        margin: 0 auto 20px;
        img {
            width: 129px;
        }
        align-items: center;
        justify-content: center;
    }
    .auth-title {
        font-family: "Avenir";
        font-size: 30px;
        line-height: 42px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 40px;
    }
    .auth-subtitle {
        font-family: "Avenir-Book";
        font-size: var(--size-small);
        line-height: 20px;
        -webkit-letter-spacing: -0.336px;
        -moz-letter-spacing: -0.336px;
        -ms-letter-spacing: -0.336px;
        letter-spacing: -0.336px;
        text-align: center;
        margin-bottom: 45px;
    }
    .auth-selected-phone {
        font-family: Avenir;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 14px;
    }
    input {
        height: 40px;
        border-radius: 3px;
        background: #EEEEEE;
        margin-bottom: 15px;
        width: auto !important;
    }
    .auth-button {
        border: none;
        border-radius: 3px;
        outline: none;
        height: 3rem;
        width: auto;
        font-size: 12px;
        margin: 0 0 8px 0;
        padding: 0 5px;
        box-shadow: none;
        box-sizing: content-box;
        -webkit-transition: box-shadow 0.3s,border 0.3s;
        transition: box-shadow 0.3s,border 0.3s;
    }
    .auth-login, .auth-login-back {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .login-button {
        background-color: #fff;
        border: none;
    }
    @media (max-width: 800px) {
        & {
            .modal {
                top: 1em !important;
                padding: 20px 39px;
                border-radius: 5px;
            }
            .auth-title {
                font-family: "Avenir";
                font-size: 13px;
                line-height: 13px;
                font-weight: 900;
                text-align: center;
                margin-bottom: 7px;
                color: var(--color-first);
            }
            h5{
                margin-top: 7px;
            }
            .auth-selected-phone {
                margin-bottom: 7px;
            }
            a {
                font-size: 10px !important;
                text-align: center;
                background: transparent !important;
                border: transparent;
            }
            a .icon {
                height: 22px !important;
            }
            .auth-logo{
                margin-bottom: 7px;
                img {
                    width: 3.5em;
                }
            }
            .auth-logo-cad{
                margin-bottom: 7px;
                img {
                    width: 5.5em;
                }
            }
            h5 {
                font-size: 0.8rem;
            }
            input {
                height: 24px !important;
                margin-bottom: 5px !important
            }
            button {
                height: 24px !important;
                margin-bottom: 5px !important
            }
        }
    }
`;
