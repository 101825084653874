import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import Waveform from './Waveform';

//styles
import { Container } from './UploadBar.styles';
import { sendMessageToUser } from '../../common';

//elements
import { Input } from '../../elements/Input';
import { Button } from '../../elements/CTA';

const listTypeMedia = [
  'audio/1d-interleaved-parityfec',
  'audio/32kadpcm',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/aac',
  'audio/ac3',
  'audio/AMR',
  'audio/AMR-WB',
  'audio/amr-wb+',
  'audio/aptx',
  'audio/asc',
  'audio/ATRAC-ADVANCED-LOSSLESS',
  'audio/ATRAC-X',
  'audio/ATRAC3',
  'audio/basic',
  'audio/BV16',
  'audio/BV32',
  'audio/clearmode',
  'audio/CN',
  'audio/DAT12',
  'audio/dls',
  'audio/dsr-es201108',
  'audio/dsr-es202050',
  'audio/dsr-es202211',
  'audio/dsr-es202212',
  'audio/DV',
  'audio/DVI4',
  'audio/eac3',
  'audio/encaprtp',
  'audio/EVRC',
  'audio/EVRC-QCP',
  'audio/EVRC0',
  'audio/EVRC1',
  'audio/EVRCB',
  'audio/EVRCB0',
  'audio/EVRCB1',
  'audio/EVRCNW',
  'audio/EVRCNW0',
  'audio/EVRCNW1',
  'audio/EVRCWB',
  'audio/EVRCWB0',
  'audio/EVRCWB1',
  'audio/EVS',
  'audio/example',
  'audio/flexfec',
  'audio/fwdred',
  'audio/G711-0',
  'audio/G719',
  'audio/G7221',
  'audio/G722',
  'audio/G723',
  'audio/G726-16',
  'audio/G726-24',
  'audio/G726-32',
  'audio/G726-40',
  'audio/G728',
  'audio/G729',
  'audio/G729D',
  'audio/G729E',
  'audio/GSM',
  'audio/GSM-EFR',
  'audio/GSM-HR-08',
  'audio/iLBC',
  'audio/ip-mr_v2.5',
  'audio/L8',
  'audio/L16',
  'audio/L20',
  'audio/L24',
  'audio/LPC',
  'audio/MELP',
  'audio/MELP600',
  'audio/MELP1200',
  'audio/MELP2400',
  'audio/mobile-xmf',
  'audio/MPA',
  'audio/mp2',
  'audio/mp3',
  'audio/mp4',
  'audio/MP4A-LATM',
  'audio/mpa-robust',
  'audio/mpeg',
  'audio/mpeg4-generic',
  'audio/ogg',
  'audio/opus',
  'audio/PCMA',
  'audio/PCMA-WB',
  'audio/PCMU',
  'audio/PCMU-WB',
  'audio/prs.sid',
  'audio/raptorfec',
  'audio/RED',
  'audio/rtp-enc-aescm128',
  'audio/rtploopback',
  'audio/rtp-midi',
  'audio/rtx',
  'audio/SMV',
  'audio/SMV0',
  'audio/SMV-QCP',
  'audio/sp-midi',
  'audio/speex',
  'audio/t140c',
  'audio/t38',
  'audio/telephone-event',
  'audio/TETRA_ACELP',
  'audio/tone',
  'audio/UEMCLIP',
  'audio/ulpfec',
  'audio/usac',
  'audio/VDVI',
  'audio/VMR-WB',
  'audio/vnd.3gpp.iufp',
  'audio/vnd.4SB',
  'audio/vnd.audiokoz',
  'audio/vnd.CELP',
  'audio/vnd.cisco.nse',
  'audio/vnd.cmles.radio-events',
  'audio/vnd.cns.anp1',
  'audio/vnd.cns.inf1',
  'audio/vnd.dece.audio',
  'audio/vnd.digital-winds',
  'audio/vnd.dlna.adts',
  'audio/vnd.dolby.heaac.1',
  'audio/vnd.dolby.heaac.2',
  'audio/vnd.dolby.mlp',
  'audio/vnd.dolby.mps',
  'audio/vnd.dolby.pl2',
  'audio/vnd.dolby.pl2x',
  'audio/vnd.dolby.pl2z',
  'audio/vnd.dolby.pulse.1',
  'audio/vnd.dra',
  'audio/vnd.dts',
  'audio/vnd.dts.hd',
  'audio/vnd.dts.uhd',
  'audio/vnd.dvb.file',
  'audio/vnd.everad.plj',
  'audio/vnd.hns.audio',
  'audio/vnd.lucent.voice',
  'audio/vnd.ms-playready.media.pya',
  'audio/vnd.nokia.mobile-xmf',
  'audio/vnd.nortel.vbk',
  'audio/vnd.nuera.ecelp4800',
  'audio/vnd.nuera.ecelp7470',
  'audio/vnd.nuera.ecelp9600',
  'audio/vnd.octel.sbc',
  'audio/vnd.presonus.multitrack',
  'audio/qcelp	audio/vnd.qcelp',
  'audio/vnd.rhetorex.32kadpcm',
  'audio/vnd.rip',
  'audio/vnd.sealedmedia.softseal.mpeg',
  'audio/vnd.vmx.cvsd',
  'audio/vorbis',
  'audio/vorbis-config',
];
const contains = (arr, element) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === element) {
      return true;
    }
  }
  return false;
};

export default class UploadBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      semArquivo: true,
      midia: null,
      loading: false,
    };
  }

  selecionaArquivo = arquivo => {
    // console.log('Arquivo: ', arquivo);
    if (contains(listTypeMedia, arquivo.type)) {
      let audioDuration = 0;
      const audio = document.createElement('audio');
      audio.src = URL.createObjectURL(arquivo);

      audio.onloadedmetadata = () => {
        audioDuration = audio.duration; // duração do áudio em segundos
      };

      // Verifica tamanho e duração do podcast
      if (audioDuration < 10800 && arquivo.size < 209715200) {
        this.setState({ semArquivo: false });
        this.setState({ midia: arquivo });
        this.props.updateState({ midia: arquivo });
      } else {
        sendMessageToUser(
          'O arquivo deve ter no máximo 200MB de tamanho e até 3 horas de duração.',
        );
      }
    } else {
      sendMessageToUser(
        'O arquivo não é um arquivo de audio válido para nossa plataforma.',
      );
    }
  };

  render() {
    if (!this.state.semArquivo) {
      // console.log("FILE: ",this.state.midia.name)
    }
    return (
      <Container>
        {this.props.porcent == null ? (
          <div className='drag-and-drop'>
            <input
              type='file'
              className='midiaupload'
              onChange={e => {
                this.selecionaArquivo(e.target.files[0]);
              }}
            />
            {this.state.semArquivo ? (
              <>
                <span>Selecione ou arraste seu arquivo de áudio aqui</span>
                <Button>Selecionar um arquivo</Button>
              </>
            ) : (
              <>
                <span className='comArquivo'>
                  Arquivo selecionado: <strong>{this.state.midia.name}</strong>
                </span>
                <Button>Clique aqui para trocar o arquivo</Button>
              </>
            )}
          </div>
        ) : !this.props.audioEdit ? (
          <div className='contentProgress'>
            <span className='contentPorcent'>{this.props.porcent}%</span>
            <div
              className='barProgress'
              style={{ width: `${this.props.porcent}%` }}
            ></div>
          </div>
        ) : (
          <div className='grafico'>
            <Waveform
              src={'https://reelcrafter-east.s3.amazonaws.com/aux/test.m4a'}
            />
          </div>
        )}
      </Container>
    );
  }
}
