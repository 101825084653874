import gql from "graphql-tag";

const myLists = gql`
    query{
        myPlaylists(page: 1, limit: 11){
            data{
                id
                name
                episodes_with_order{
                    episode{
                        id
                        thumbnail(width:200, height: 200)
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`;

export default myLists;
