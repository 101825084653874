import React, { Component } from "react";

//styles
import { Container } from "./styles";

//components
import { SignupForm } from "../../../../components/Forms";

import OlaFrontWeb from "../../../../assets/img/OlaFrontWeb.jpg";

class Signin extends Component {
  render() {
    return (
      <Container>
        <SignupForm></SignupForm>
      </Container>
    );
  }
}

export default Signin;
