import gql from 'graphql-tag';
import { CHANNEL_TYPE } from '../types/CHANNEL';

export const CREATE_CHANNEL = gql`
    mutation CreateChannel(
        $codename: String!,
        $name: String!,
        $thumbnail: Upload,
        $cover: Upload,
        $main_category_id: Int!,
        $alternate_category_id: Int,
        $description: String!
    ){
        createChannel(
            codename: $codename,
            name: $name,
            thumbnail: $thumbnail,
            cover: $cover,
            main_category_id: $main_category_id,
            alternate_category_id: $alternate_category_id,
            description: $description
        ){
            ${CHANNEL_TYPE}
        }
    }
`;

export const UPDATE_THUMB_CHANNEL = gql`
    mutation UpdateThumb(
        $channel_id: Int!,
        $thumbnail: Upload
    ){
        editChannel(
            channel_id: $channel_id,
            thumbnail: $thumbnail
        ){
            ${CHANNEL_TYPE}
        }
    }
`;
export const UPDATE_COVER_CHANNEL = gql`
    mutation UpdateCover(
        $channel_id: Int!,
        $cover: Upload
    ){
        editChannel(
            channel_id: $channel_id,
            cover: $cover
        ){
            ${CHANNEL_TYPE}
        }
    }
`;

export const FOLLOW_CHANNEL = gql`
    mutation FollowChannel(
        $channel_id: Int!,
        $unfollow: Boolean
    ){
        followChannel(
            channel_id: $channel_id,
            unfollow: $unfollow
        ){
            ${CHANNEL_TYPE}
        }
    }
`; 

export const NEW_ALO_CHANNEL = gql`
    mutation($id: Int!, $file: Upload){
        createAlo(channel_id:$id, content: $file){
            id
            content
            publishDate
        }
    }
`; 
