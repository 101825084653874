import { createGlobalStyle } from "styled-components";

import "rc-slider/assets/index.css";

import AvenirRegular from "../../assets/fonts/Avenir-Book.ttf";
import AvenirMedium from "../../assets/fonts/Avenir-Medium.ttf";
import AvenirLight from "../../assets/fonts/Avenir-Light.ttf";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Base = createGlobalStyle`


@font-face {
  font-family:"Avenir-Book";
  src: url(${AvenirRegular}) format('truetype');
}
@font-face {
    font-family:"Avenir-Medium";
    src: url(${AvenirMedium}) format('truetype');
}

@font-face {
    font-family:"Avenir-Light";
    src: url(${AvenirLight}) format('truetype');
}
  body {
    font-family:"Avenir-Book";
    text-rendering: optimizeLegibility !important;
   -webkit-font-smoothing: antialiased !important;   
  }

  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
    height: auto;
    width: 100%;
    font-family: "Avenir-Book", sans-serif;
    overflow-x: hidden;
    display: flex;
    flex: 1;
  }

  button {
    background: none;
    border: 0;
    color: inherit;
}
ul,li{
  list-style-type: none;
}

  input {
      font-family: inherit;
      font-size: inherit;
    }
`;

export default Base;
