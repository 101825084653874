import styled from "styled-components";

// export const Container = styled.section`
//   height: 100%;
//   margin-left: 0 !important;
//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;

//   .conteiner-episode {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     margin-left: var(--spacing-default);
//   }

//   .episode-card {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: var(--spacing-default);
//     .episode-thumb {
//       width: 100%;
//       height: 0;
//       padding-bottom:100%;
//       margin: 0;
//       position: relative;
//     }
    
//     .wrapper-block {
//       .title-block {
//         a {
//           display: flex;
//           min-height: 44px;
//         }
//       }
//     }
//     .card-body {
//       display: flex;
//       flex: 1;
//       flex-direction: column;
//       .footer-block {
//         flex: 1;
//         align-items: flex-end;
//       }
//     }
//   }
//   .imgColor {
//     opacity: 0.3;
//   }
//   .imgGray {
//     -webkit-transition: all 0.8s linear;
//     -moz-transition: all 0.8s linear;
//     transition: all 0.8s linear;
//     filter: 'url("data:image/svg+xml;utf8,<svg xmlns="\'http://www.w3.org/2000/svg\'"><filter id="\'grayscale\'"><feColorMatrix type="\'matrix\'" values="\'0.3333" 0.3333="" 0="" 1="" 0\'=""></feColorMatrix></filter></svg>#grayscale")';
//     filter: gray; /* IE6-9 */
//     -webkit-filter: grayscale(100%);
//   }
//   .vertical_1 {
//     .episode-card {
//       background-color: var(--color-fifth);
//     }
//   }
//   .vertical_2 {
//     .episode-card {
//       background-color: var(--color-fourth);
//     }
//   }
//   .vertical_3 {
//     .episode-card {
//       background-color: var(--color-sixth-lighten);
//     }
//   }
//   .vertical_4 {
//     .episode-card {
//       background-color: var(--color-seventh);
//     }
//   }
//   .vertical_5 {
//     .episode-card {
//       background-color: var(--color-second);
//     }
//   }
//   .vertical_6 {
//     .episode-card {
//       background-color: var(--color-seventh-darken);
//     }
//   }
//   .vertical_7 {
//     .episode-card {
//       background-color: var(--color-second-lighten);
//     }
//   }
//   .vertical_8 {
//     .episode-card {
//       background-color: var(--color-sixth);
//     }
//   }
//   .vertical_9 {
//     .episode-card {
//       background-color: var(--color-first-lighten);
//     }
//   }
//   .vertical_10 {
//     .episode-card {
//       background-color: var(--color-seventh-lighten);
//     }
//   }
//   .episode-card {
//     max-height: 350px;
//     .wrapper-block,
//     .channel-codename {
//       color: #fff;
//     }
//   }
  
//   .wrapper-content {
//       display: flex;
//       flex-direction: column;
//       flex: 1;
//       align-items: flex-start;
//   }
//   .vertical-list {
//       flex-direction: column;
//       margin: 0 var(--spacing-default);
//   }
//   .vertical-box {
//     margin-bottom: var(--spacing-default);
//   }
//   .adjustContentCard {
//         width: 29.33%;
//         margin: 0 2%;
//         display: flex;
//         flex-direction: column;
//         position: relative;
//   }
//   .editorial-image {
//       width: auto;
//       height: 293px;
//   }
//   .vertical-logo {
//     width:70px;
//     a, div {
//       text-align: center;
//       display: block;
//       margin-bottom: 10px;
//     }
//   }
//   .card-action  {
//       position: absolute;
//       top: 15px;
//       right: 15px;
//   }
//   .horizontal {
//     .episode-thumb a {
//       width: 169px;
//       height: 167.86px;
//     }
//     .card-body {
//       width: 207px;
//     }
//     .title {
//       font-size: 14px;
//       line-height: 16px;
//       margin-bottom: 0.5rem;
//     }
//     .channel-code {
//       font-size: 10px;
//       line-height: 16px;
//       letter-spacing: 0.05em;
//       margin-bottom: 0.5rem;
//     }
//     .description {
//       font-size: 11px;
//       line-height: 14px;
//     }
//   }
//   @media (max-width: 800px) {
//     .vertical-list{
//       margin: 0;
//     }
//   }
// `;

// export const Trends = styled.section`
//   width: 100%;
//   margin-top: var(--spacing-default);
//   margin-left: var(--spacing-default);
  
//   & .trend-title {
//     margin-left: var(--spacing-medium);
//     color: var(--color-negative);
//   }
//   & .trend-list{
//     justify-content: start;
//   }
//   @media (max-width: 800px) {
//     margin: 0;
//   }
// `;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  .genero  {
    width: 100%;
    /* min-height: 350px; */
    position: relative;
    .genero-vertical {
      display: flex;
      flex-wrap: wrap;
    }
    .carousel-item {
      width: auto;
      height: auto;
      visibility: visible;
    }
    .contener-editorial {
      width: 33%;
    }
    .indicators {
      display: none;
    }
    /* .em-alta-pagination {
      display: flex;
      width: 60px;
      .paginationIcon{
        width: 50%;
        margin: auto;
        color: #bcbcbc;
      }
    } */
    .pagination {
      position: absolute;
      z-index: 9;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: -1px 70x rgba(0, 0, 0, 0.17);
      top: 50%;
      margin-top: -16.5px;
      right:  -16.5px;
      box-shadow: -1px 7px 11px 0 rgba(0,0,0,0.17);
      transition: 0.15s ease-in-out;
    }
    .pagination:hover {
      height: 39px;
      width: 39px;
      margin-top: -19.5px;
      right: -19.5px;
      .paginationIcon {
        color: #0E53DB;
      }
    }
    #editorial-carrosel {
      min-height: auto;
      .carousel-item {
        width: 33%;
        min-width: 33%!important;
        padding-right: 7.5px;
      }
    }
    .contener-editorial {
      display: flex;
      flex: 1;
      flex-direction: column;
      /* margin-left: 7.5px; */
      width: 100%;
      min-width: 100%;
      .editorial-card {
        padding: 0;
        min-width: 100%;
      }
    }
    @media (max-width: 768px) {
      #editorial-carrosel {
        .carousel-item {
          width: 50%;
          min-width: 50%!important;
        }
      }
      .contener-editorial {
        width: 100%;
        min-width: 100%;
        padding-right: 10px;
      }
    }
    @media (max-width: 576px) {
      #editorial-carrosel {
        .carousel-item {
          width: 100%;
          min-width: 100%!important;
        }
      }
    }
    .episode-card {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 7.5px;
      /* margin-left: 7.5px; */
      width: 163px;
      min-width: 163px;
      .episode-thumb {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        .episode-thumb_image {
          min-height: auto;
          position: absolute;
        }
      }
      .contentOriginal {
        max-width: 80%;
        min-height: 20px;
        border-radius: 9px;
        background-color: #FF005A;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .iconOriginal {
        width: 17px;
        height: 17px;
        margin-left: 6px;
        margin-right: 6px;
        color: #ffffff;
      }
      .textOriginal {
        color: #ffffff;
        font-family: Avenir;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 20px;
        text-align: center;
      }
      .wrapper-block {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px 0;
        .queue-icon {
          width: 12px;
          height: 22px;
          margin-top: -2px;
          align-items: flex-start;
          justify-content: flex-end;
            .iconMenu {
              color: #000;
              font-size: 15px;
            }
        }
        .title-block {
          display: flex;
          flex: 1;
          flex-direction: column;
          .channel-link {
            display: flex;
            flex: 1;
            align-items: flex-end;
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
          }
          a {
            display: flex;
            font-size: 13px;
            font-weight: 900;
            line-height: 18px;
          }
          a:first-child {
            min-height: 54px;
          }
        }
        .content-block {
          display: flex;
          flex: 1;
          align-items: flex-end;
        }
        .footer-block {
          display: flex;
          justify-self: flex-end;
          .duration {
            display: none;
          }
        }
      }
    }
  }
`;
export const Trends = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  /*margin-left: var(--spacing-default);*/

  & .trend-title {
    /* margin-left: var(--spacing-medium);
    color: var(--color-negative); */
    color: #3468E6;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
  }
  & .ola-card_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 0 10px
  }
  & .trend-list {
    justify-content: start;
  }
  & .trends-header {
    margin-bottom: 1.168rem;
  }
`;
