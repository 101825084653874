import styled from "styled-components";

export const Container = styled.div`
width: 100%;
min-height: 350px;
display: flex;
flex: 1;
flex-wrap: wrap;
z-index: 0;
.channel-card-container{
    width: 33%;
    height: auto;
    .channel-card-content {
        margin: 0 10px 80px 10px;
    }
}

    @media (max-width: 800px) {
        min-height: 63px;
        .premium-em-alta {
            height: 288px !important;
        }
        .carousel-item {
            min-width: 150px !important;
        }
        .episode-card {
            width: 150px;
            min-width: 150px;
            margin-left: 0;
            .wrapper-block {
                .title-block { 
                    a:first-child {
                        min-height: 63px;
                    }
                }
            }
            .channel-codename {
                font-size: 0.75em;
            }
        }
        .premium-em-alta-pagination {
            display: none;
        }
    }
`;
