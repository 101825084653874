import React, { Component } from "react";
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { sendMessageToUser } from '../common';
import { Play3, Clock } from "styled-icons/icomoon/";
import { Spinner } from "styled-icons/fa-solid/";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//external libs
import Slider, {Range} from "rc-slider";
import Sound from "react-sound";
import defaultImage from "../assets/img/thumbDefault.jpg";
import loadingPlayer from "../assets/img/loadingPlayer.gif";

import {
  PlayArrow,
  Pause,
  FastForward,
  FastRewind,
  Forward30,
  Replay30,
  Subject,
  ThumbUpAlt,
  ThumbDown,
  Star,
  ChatBubble,
  Share
} from "styled-icons/material";

import moment, { duration } from "moment";

import { LIKE_UNLIKE_MUTATION, DISLIKE_UNDISLIKE_MUTATION, FAVORITE_EPISODE_MUTATION, UNFAVORITE_EPISODE_MUTATION } from '../services/graphql/mutations';


export default class PlayerComponent extends Component {
  renderDuration(inicio,fim){
    // let duration = new Date(1000 * seconds).toISOString().substr(11, 8).replace(/^[0:]*/g,'');
    let secondsRaw = fim - inicio;
    let minutes = parseInt(secondsRaw/60);
    let seconds = Math.round(secondsRaw%60);
    let minutesRender = minutes < 10 ? `0${minutes}` : minutes;
    let secondsRender = seconds < 10 ? `0${seconds}` : seconds;
    // // console.log("Minutos: ",minutesRender)
    if(isNaN(minutesRender)){
      return "";
    }
    return(
        `- ${minutesRender}:${secondsRender}`
      );
  }

  constructor(props){
    super(props);
    this.state = {
      firstPlay: true,
      isPlaying: false,
      liked: false,
      favorited: false,
      duration: 0,
      episode: props.episode,
      queue: [],
      currentPosition: 0,
      currentTime: `00:00`,
      currentTimeOriginal: 0,
      showCompartilhar: false,
      showOrderList: false
    };

    if(props.episode){
      this.audioFile = new Audio(props.episode.content);
      this.audioFile.addEventListener('loadeddata', () => {
        this.setState({ duration: this.audioFile.duration })
      })
      this.state = { ...this.state, duration: this.audioFile.duration }
    } else if(!!document.player && !!document.player.getCurrent()) {
      this.state = { ...this.state, duration: document.player.getCurrentDuration() }
    }
    if(props.episode)
      document.player.setProgress(this.getCurrentTime)
    if(document.player.getCurrent())
        this.state = { ...this.state, episode: document.player.getCurrent() }
    if(document.player.getQueue())
        this.state = { ...this.state, queue: document.player.getQueue() }
  }  

  static getDerivedStateFromProps(props, state) {
      let new_state  = state;
      if(document.player.getCurrent() !== state.episode) new_state = { ...new_state, episode: document.player.getCurrent() }
      if(document.player.getQueue() !== state.queue) new_state = { ...new_state, queue: document.player.getQueue() }
  }

  componentDidMount(){
      document.addEventListener('playerupdate', this.updatePlayer);
  }

  handleClickInside = () => this.setState({ clickedOutside: false });

  updatePlayer = () => {
      if( document.audio && !!document.player.getCurrent() && this.state.firstPlay ){
          document.audio.addEventListener('loadeddata', () => {
            this.setState({ duration: document.audio.duration })
          })
      }
      if(document.audio && !document.audio.paused !== this.state.isPlaying ) this.setState({ isPlaying: !document.audio.paused });

      if(document.player.getCurrent() !== this.state.episode) this.setState({ episode: document.player.getCurrent() });
      if(document.player.getQueue() !== this.state.queue) this.setState({ queue: document.player.getQueue() });

      this.getCurrentTime();
  }

  togglePlay = () => {
    if(this.state.isPlaying){
      document.player.pause();
    } else if(this.state.firstPlay && this.state.episode && !!this.state.episode.content){
      document.player.setCurrent(this.state.episode)
      document.player.play();
      this.setState({firstPlay:false})
    }else if(!!this.state.episode && !!this.state.episode.content){
      document.player.play();
    }
    if(!!this.state.episode && !!this.state.episode.content) this.setState({isPlaying: !this.state.isPlaying})
  }

  next = () => {
    if(this.state.isPlaying){
      document.player.pause();
    }
    if(this.props.next) this.props.next();
    if(document.player && document.player.next) document.player.next();
  }

  prev = () => {
    if(this.props.prev) this.props.prev()
    if(document.player && document.player.prev) document.player.prev();
  }

  play = () => {
    if(this.props.play) this.props.play()
  }

  favorite = () => {
    if(this.props.favorite) this.props.favorite()
  }

  like = () => {
    if(this.props.like) this.props.like()
  }

  dislike = () => {
    if(this.props.dislike) this.props.dislike()
  }

  comment = () => {
    if(this.props.comment) this.props.comment()
  }

  getDuration = () => {
    if(this.props.episode && this.audioFile){
      this.setState({duration: this.props.episode.duration})
    }else if(!!document.player && !!document.player.getCurrent()) {
      this.setState({duration: document.player.getDuration()})
    }
  }

  getCurrentTime = () => {
    if(this.state.episode && document.audio && this.state.episode.content == document.audio.originalSource ){
      this.setState({currentTime: document.player.renderSecondsInTime(document.player.getCurrentTime() ), currentPosition: document.player.getCurrentProgress()*1000 })
      this.setState({currentTimeOriginal: document.player.getCurrentTime()})
    }else{
      this.setState({currentTime: '00:00'})
    }
  }

  changingSlider = (value) => {
    document.player.setCurrentProgress(value/1000);
    this.setState({currentPosition: value})
    // // console.log("SetInitialTime: ", document.audio.currentTime);
    document.player.setInitialTime(document.audio.currentTime);
  }

  getCodeEmbed = () => {
    if(this.state.episode){
      const codigo = "<iframe width='672' height='280' src='https://www.olapodcasts.com/embed/?codename="+this.state.episode.channel.codename+"' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
      return codigo;
    }
  }

  getUrlCompartilhar = () => {
    if(this.state.episode){
      // console.log("Ep compartilhar: ", this.state.episode)
      let urlCompartilhar = "https://share.olapodcasts.com/?id=" + this.state.episode.id;
      // console.log("Url Compartilhar: " + urlCompartilhar)
      return urlCompartilhar;
      return window.location.protocol + "//" + window.location.hostname + "/episodes/" + this.state.episode.id;
      // // console.log("Url para compartilhar: ",urlCompartilhar);
    }
  }
  copyToClipboard = (str) => {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    sendMessageToUser("Link para compartilhamento copiado com sucesso.");    
  };

  checkContext = () => {
      return this.props.match && this.props.match.path.indexOf('episodes') > -1 && this.props.match.params.id && document.player.getCurrent() && document.player.getCurrent().id == this.props.match.params.id || !document.player.getCurrent();
  }

  hideShow = () => {
    // // console.log("Pegatudo: ", document.querySelectorAll('.compartilhamento'));
    if(this.state.showCompartilhar){
      for (let el of document.querySelectorAll('.compartilhamento')) el.style.visibility = 'hidden';
      this.setState({showCompartilhar: false});
    }else{
      for (let el of document.querySelectorAll('.compartilhamento')) el.style.visibility = 'visible';
      this.setState({showCompartilhar: true});
    }
  };

  hide = () => {
    this.setState({showCompartilhar: false});
  }

  avancar = () => { 
    if(!!document.player && !!document.audio && !!document.audio.currentTime){ 
      // console.log("Tempo: ",document.player.getCurrentTime());
      // console.log("duracao: ",document.audio.duration);
      
      let novoTempo = (document.player.getCurrentTime() + 30) / document.audio.duration;
      document.player.setCurrentProgress(novoTempo);
      if(!this.state.isPlaying){
        document.player.play();
        this.setState({isPlaying:true})
      }

    }
  }
  retroceder = () => { 
    if(!!document.player && !!document.audio && !!document.audio.currentTime){ 
      // console.log("Tempo: ",document.player.getCurrentTime());
      let novoTempo = (document.player.getCurrentTime() - 30) / document.audio.duration;
      document.player.setCurrentProgress(novoTempo);
      if(!this.state.isPlaying){
        document.player.play();
        this.setState({isPlaying:true})
      }
    }
  }
  orderPlayList = (result) => {
    //this.state.queue
    let {destination, source} = result;
    let {queue} = this.state;
    const [reorderedItem] = queue.splice(source.index, 1);
    queue.splice(destination.index, 0, reorderedItem);
    
    console.log(queue)
    this.setState({queue: queue})
  }
  render() {
    
    if(this.state.episode){
      // // console.log("StatePlayer: ",this.state.episode.channel);
    }
    if(!this.checkContext() && this.props.mostraPlayer){
        document.body.style.paddingBottom = "90px";
    } else {
      document.body.style.paddingBottom = "0";
    }
    return (
      <div className={`player horizontal-player ${this.checkContext() ? 'off' : ''} ${this.props.mostraPlayer ? 'show' : 'hidden'}`}>
        <div className="positionPlayer">
          {
            this.props.mostraPlayer?
              <a onClick={() => this.props.showHorizontalPlayer()}>Fechar</a>
            :
              <a onClick={() => this.props.showHorizontalPlayer()}>Abrir</a>
          }
        </div>
        <div className="player-thumb">
            {!!this.state.episode && !!this.state.episode.thumbnail && 
                <img src={this.state.episode.thumbnail} alt={this.state.episode.title} />
            }
        </div>
        <div className="player-controller">
          <button onClick={this.prev} className={`player-prev-button ${document.player.getPrev() ? '' : 'disabled'}`} >
            <i className="material-icons">fast_rewind</i>
          </button>
          <button onClick={this.retroceder} className={`player-prev-button ${!!document.audio && !!document.audio.duration ? '' : 'disabled'}`} >
                  <i className="material-icons">replay_30</i>
          </button>
          {!this.state.isPlaying ? (         
                <button id="play_btn" onClick={this.togglePlay} className='player-play-button'>
                  <i className="material-icons">play_arrow</i>
                </button>
             
          ) : (
            this.state.currentPosition > 0 ?
              <button onClick={this.togglePlay} className="player-play-button">
                  <i className="material-icons">pause</i>
              </button>
             :
             <button className='player-play-button'>
                 <Spinner className="carregando" style={{margin: '0.65em'}}/>
            </button>     
          )}

          <button onClick={this.avancar} id="play_forward_btn" className={`player-next-button ${!!document.audio && !!document.audio.duration ? '' : 'disabled'}`} >
                  <i className="material-icons">forward_30</i>
          </button>
          <button onClick={this.next} id="play_next_btn" className={`player-next-button ${document.player.getNext() ? '' : 'disabled'}`} >
            <i className="material-icons">fast_forward</i>
          </button>
        </div>
        <div className="player-timeline">
            <div id="pod_channel" className="player-channel channel-codename">
                {!!this.state.episode && !!this.state.episode.channel && !!this.state.episode.channel.codename && <Link to={this.state.episode.channel.flag == 'feed' ? `/externalfeed/${this.state.episode.channel.id}` : `/channels/${this.state.episode.channel.codename}`}>{this.state.episode.channel.codename}</Link>}
            </div>
            <div id="pod_episode" className="player-epispode-title">
                <span>
                    {!!this.state.episode && !!this.state.episode.channel && <Link to={this.state.episode.channel.flag == 'feed' ? `/externalfeed/${this.state.episode.channel.id}` : `/episodes/${this.state.episode.id}`}>{this.state.episode.title}</Link>}
                </span>
            </div>
            <div className={`episode-player_slider`}>
            {/* {window.navigator.userAgent.match(/iPhone/i) || window.navigator.userAgent.match(/iPad/i)  ?
              <div></div>
             : */}
              <>
                <Slider
                    railStyle={{ background: "#ECF0F1", boderRadius: 10 }}
                    trackStyle={{ background: "#2196f3" }}
                    handleStyle={{ background: "#2196f3", border: "#2196f3" }}
                    volume={50}
                    value={this.state.currentPosition}
                    max={1000}
                    onChange={this.changingSlider}
                />
            
              
                <div className="player-time">
                    <div className="current-time">{this.state.currentTime}</div>
                    {/* <div className="duration">{ document.player.getCurrentDurationRendered() }</div> */}
                    {/* <div className="duration">{ document.player.getCurrentDurationRendered() || this.state.episode ? this.renderDuration(this.state.episode.duration) : "" }</div> */}
                    <div className="duration">{ this.state.episode ? this.renderDuration(this.state.currentTimeOriginal , this.state.episode.duration) : "" }</div>
                    
                </div>
              </>
            {/* } */}
            </div>  
        </div>

        <div className="player-toolbuttons">



          {/* <div className="button-wrapper">
            <button className={`player-toolbutton waves-effect waves-light player-actions_button ${!!this.state.episode && this.state.episode.userLike ? 'green' : ''}`}>
                <i className="material-icons">thumb_up</i>
            </button>
          </div>
          <div className="button-wrapper">
            <button className={`player-toolbutton waves-effect waves-light player-actions_button ${!!this.state.episode && this.state.episode.userLike ? 'green' : ''}`}>
                <i className="material-icons">thumb_down</i>
            </button>
          </div> */}
          {/* { this.props.scope != 'episode' && <div className="button-wrapper">
            <button className={`player-toolbutton waves-effect waves-light player-actions_button ${!!this.state.episode && this.state.episode.favorite ? 'active' : ''}`}>
                <i className="material-icons">star</i>
            </button>
          </div>} */}

          {/* <div className="button-wrapper">
            <button className="player-toolbutton waves-effect waves-light player-actions_button">
                <i className="material-icons">chat_bubble</i>
            </button>
          </div> */}

          
            {/* <div className="button-wrapper">
              <Link to={!!this.state.episode && `/episodes/${this.state.episode.id}`} className="player-toolbutton waves-effect waves-light player-actions_button">
                <i className="material-icons">subject</i>
              </Link>
            </div> */}
          
              <div className="button-wrapper compartilhamento" style={{visibility: "hidden"}}>
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getUrlCompartilhar())}`}  target="_blank" title="Compartilhar no Facebook" >
                      <i className="fa fa-facebook-official fa-2x" style={{ color: "blue" }} aria-hidden="true"></i>
                  </a>
              </div>
              <div className="button-wrapper compartilhamento" style={{visibility: "hidden"}}>
                  <a href={`http://twitter.com/share/?text=OLA Podcasts&url=${encodeURIComponent(this.getUrlCompartilhar())}`}   target="_blank" title="Tweetar" >
                      <i className="fa fa-twitter fa-2x" style={{ color: "#00ACED" }} aria-hidden="true"></i>
                  </a>
              </div>         
              <div className="button-wrapper compartilhamento" style={{visibility: "hidden"}}>
                      <i className="fa fa-link fa-2x"  aria-hidden="true"  onClick={() => this.copyToClipboard(this.getUrlCompartilhar())}></i>                  
              </div>     
              <div className="button-wrapper compartilhamento" style={{visibility: "hidden"}}>
              <a href="#" title="Copiar Código"  onClick={() => this.copyToClipboard(this.getCodeEmbed())}>
                 <i className="fa fa-code fa-2x" style={{color: "black"}} aria-hidden="true" ></i>
                </a>
              </div>    
             
          
          <div className="button-wrapper">
            <button id="share_btn" className="player-toolbutton waves-effect waves-light player-actions_button" onClick={() => {this.state.episode.channel.flag == 'feed' ? this.hide() : this.hideShow()}}>
                <i className="material-icons">share</i>
            </button>
          </div>
          <div className="button-wrapper">
              <div className={`playlist-button ${ this.state.queue && this.state.queue.length ? '' : 'disabled' }`}>
                <span 
                  style={{cursor:"pointer"}}
                  onClick={()=>this.setState({showOrderList: true})}
                >
                    <i className="material-icons">playlist_play</i>
                </span>
              </div>
          </div>
        </div>
        <DragDropContext onDragEnd={this.orderPlayList}>
          <Droppable droppableId="playlistDrag">
            {
              (provided) => (
                <ul 
                onMouseLeave={() => {
                  document.addEventListener("click", this.setState({showOrderList: false}))
                }}
                id='playlist' 
                  className={`dropdownContentList${this.state.showOrderList?" active":""}`} {...provided.groppableProps} ref={provided.innerRef}>
                    {!!this.state.queue && !!this.state.queue.length &&
                        this.state.queue.map( (item, index) => (
                          <Draggable key={index} draggableId={"drag-player-"+index} index={index}>
                            {
                              (provided) => (
                                <li key={`playlist-item ${item.id}`} className="playlist-item" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                    <div style={styles.moveItem}>
                                      <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24">
                                          <path
                                              fill="currentColor"
                                              d="M3,15H21V13H3V15M3,19H21V17H3V19M3,11H21V9H3V11M3,5V7H21V5H3Z"
                                          />
                                      </svg>
                                    </div>
                                    <Link to="#" 
                                      style={styles.constentLink}
                                      onClick={
                                        ()=> { 
                                          document.player.pause();
                                          document.player.setCurrent(item);
                                          document.player.play(); 
                                          this.setState({showOrderList: false})
                                        } 
                                      }>

                                        <img src={item.thumbnail || defaultImage} alt={item.title} />
                                        <div className="content">
                                          <span className="episode-title" style={styles.episodeTitle}>
                                              {item.title}
                                          </span>
                                          {!!item && !!item.channel && !!item.channel.codename &&
                                            <div className="channel-codename" style={styles.channelCodename}>
                                              {item.channel.codename}
                                            </div>
                                          }
                                        </div>
                                    </Link>
                                    <div className="removerFila" style={styles.removeFila}>
                                        <Link to="#" onClick={()=> { document.player.removeFromQueue(item); }}>
                                          <i className="fa fa-times " style={{ color: "#000000", fontSize:"1.33em" }} aria-hidden="true"></i>
                                        </Link>
                                    </div>
                                </li>
                              )
                            }
                          </Draggable>
                        )
                      )
                    }
                    {provided.placeholder}
                </ul>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

const styles = {
  dropdownContent: {
    backgroundColor: "#fff",
    margin: 0,
    display: "none",
    minWidth: "320px",
    overflowY: "auto",
    opacity: 0,
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
  listItem:{
    padding: 0
  },
  constentLink: {
    padding: "0"
  },
  episodeTitle: {
    fontSize: "0.8em",
    lineHeight: "0.8em",
    fontWeight: "600",
    color: "#212121"
  },
  channelCodename: {
    fontSize: "0.6em",
    lineHeight: "0.7em",
    marginBottom: 0
  },
  removeFila: {
    paddingTop: 0,
    paddingLeft: "5px"
  },
  moveItem: {
    paddingRight: "5px",
    display: "flex",
    alignItems: "center"
  }
}