import gql from "graphql-tag";

const COUNTRY = gql`
    query{
        country{
            id
            name
            population
            lat
            long
        }
    }
`;
export {
    COUNTRY
}
