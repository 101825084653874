import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  background: hsl(0, 0%, 98%);
  color: #000;
  flex-direction: column;
  .titulo{
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3rem;
  }
  .subtitulo{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
  }
  .botoes{
    margin-top: var(--spacing-default);
  }
  .logo-image{
    /* width: 50%; */
  }
`;
