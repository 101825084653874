import gql from "graphql-tag";
// import {EPISODE_TYPE} from '../../../services/graphql/types/EPISODE';

const EmAltaQuery = gql`
  query homeTrendsEpisodes($limit: Int, $page: Int){
      homeTrendsEpisodes(limit: $limit, page: $page){
            data{
                id
                title
                rating
                description
                playCount: play_count
                duration
                content
                publishDate
                thumbnail(width: 400, height: 400)
                premium
                original
                external
                tags
                channel{
                    id
                    name
                    codename
                    flag
                    thumbnail
                    premium
                    original
                    following
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
      }
  }
# query{
#     topEpisodes(main_category_id: 1, page: 1, limit: 10){
#         data{
#             episode{
#                 id
#                 thumbnail(width:300, height: 300)
#                 title
#                 description
#                 play_count
#                 publishDate
#                 tags
#                 premium
#                 duration
#                 external
#                 channel{
#                     id
#                     codename
#                     flag
#                     thumbnail(width:400, height: 400)
#                     following
#                 }
#             }
#         }
#         current_page
#         has_more_pages
#     }
# }
`;
export default EmAltaQuery;
