import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  width: 100%;
  .content-banner{
    article {
      height: auto;
    }
  }
  .contentBanner {
    display: block;
    flex: 1;
    flex-direction: column;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .home-icon {
    color: var(--color-third-darken);
    height: var(--size-medium);
    width: 24px;
    height: 24px
  }
  .em-alta {
    .episode-card{
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 7.5px;
      margin-left: 7.5px;
      .episode-thumb {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        .episode-thumb_image {
          min-height: auto;
          position: absolute;
        }
      }
      .contentOriginal {
        max-width: 80%;
        min-height: 20px;
        border-radius: 9px;
        background-color: #FF005A;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .iconOriginal {
        width: 17px;
        height: 17px;
        margin-left: 6px;
        margin-right: 6px;
        color: #fff;
      }
      .textOriginal {
          color: #fff;
          font-family: "Avenir";
          font-size: 10px;
          font-weight: 900;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
      }
      
      .wrapper-block {
        display: flex;
        flex: 1;
        flex-direction: column;
        .title-block {
          display: flex;
          flex: 1;
          flex-direction: column;
          a {
            display: flex;
            min-height: 44px;
          }
          .channel-link {
            display: flex;
            flex: 1;
            align-items: flex-start;
          }
        }
        .content-block {
          display: flex;
          flex: 1;
          align-items: flex-end;
        }
        .footer-block {
          display: flex;
          justify-self: flex-end;
        }
      }
    }
  }
  .trend-title {
    margin-left: var(--spacing-medium);
    color: var(--color-negative);
  }
  .trend-title-premium{
    margin-left: var(--spacing-medium);
    color: #FDD302;
    font-weight: bold;
  }
`;

export const PremiumBanner = styled.article`
  display: flex !important;
  height: 310px;
  width: 100% ;
  justify-content: flex-start;
  align-items: center;
  img {
    flex:1
  }
`;
export const Trends = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  /*margin-left: var(--spacing-default);*/

  & .trend-title {
    margin-left: var(--spacing-medium);
    color: var(--color-negative);
  }
  & .ola-card_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 0 10px
  }
  & .trend-list {
    justify-content: start;
  }
  & .trends-header {
    margin-bottom: 1.168rem;
  }
`;
export const PremiumBox = styled.section`
    .premium-container {
      padding-left:250px
    }
    .roleta{
      margin-top: var(--spacing-default);
    }
    @media (max-width: 992px) {
      .trend-list-premium {
        .channel-card-container {
          .channel-card-content {
            margin: 0 20px 20px 0;
            flex-direction: column;
            .card-wrapper_text {
              margin-left: 0;
              .card-title_text{
                font-size: 14px;
                min-height: 60
              }
            }
            .card-codename_wrapper{ 
              .card-subscribers {
                display: none
              }
            }
          }
        }
      }
    }
`;
export const Verticals = styled.section`
  width: 98%;
  height: auto;
  margin: var(--spacing-default) 0 var(--spacing-default);
  margin-right: 2%;
  overflow: hidden;

  .vertical-header {
    margin-bottom: var(--spacing-medium);
  }
  .vertical-list.sliderContent {
    /*width: 1424px;*/
  }
  .slick-slide {
    /*width: auto !important;*/
  }
  .slick-slider {
    width: 100%;
  }
  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .vertical-title {
    color: var(--color-negative);
  }
  .content-colon {
    display: flex;
    flex: 1;
    min-width: 31.8%;
    margin-right: 1%;
    margin-left: 1%
  }
  .vertical-box {
    padding: var(--spacing-small);
    display: flex;
    flex: 1;
    flex-direction: column;
    .vertical-logo {
      width: auto;
      text-align: center;
      /*height: 100%;*/
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--spacing-medium);
    }
  }
  .vertical-mercado{
    background: var(--color-seventh-darken);
    width: 427;
  }
  .vertical-conhecimento{
    background: var(--color-second-lighten);
    width: 427;
  }
  .vertical-humor{
    width: 427;
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    .episode-card:first-child {
      background: var(--color-fifth);
      color: var(--color-zero);
    }
  }
  .wrapper-block {
    flex: 1;
    .title-block {
      display: flex;
      flex: 1;
      a:first-child {
        max-height: 44px;
      }
      a {
        display: flex;
        flex: 1;
      }
      .channel-link {
        align-items: flex-start;
      }
    }
  }
  .vertical-lifestyle{
    .episode-card:first-child {
      background: var(--color-sixth-lighten);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-esporte{
    .episode-card:first-child {
      background: var(--color-seventh);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-cotidiano{
    .episode-card:first-child {
      background: var(--color-second);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-familia{
    .episode-card:first-child {
      background: var(--color-first-lighten);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-esportes{
    .episode-card:first-child {
      background: var(--color-seventh);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-historias{
    .episode-card:first-child {
      background: var(--color-sixth);
      color: var(--color-zero);
    }
    width: 427;
  }
  .vertical-tecnologia{
    .episode-card:first-child {
      background: var(--color-sixth);
      color: var(--color-zero);
    }
    width: 427;
  }  
  .vertical-variedades{
    .episode-card:first-child {
      background: var(--color-fourth);
      color: var(--color-zero);
    }
    width: 427;
  }
  .episode-card {
    padding: 5px;
    height: 167px;
    flex-direction: row;
    border-radius: var(--radius-small);
    margin: 0 0 var(--size-base) 0;
  }

  .vertical-box:hover {
    box-shadow: 1px 0px 5px 1px #ccc;
  }
`;
