import React from "react";

import { Container } from "../styles";

//component
import { Header } from "../../../components/Header";
import { NavBar } from "../../../components/NavBar";
import Layout from "../../../hoc/Layout/Layout"

//components
import { List } from "../../../components/Playlist";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

const Playlists = () => (
  <Layout>
    <List />
  </Layout>
);

export default Playlists;
