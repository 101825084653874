import styled from "styled-components";

export const Container = styled.main`
  background: var(--color-first);
  height: 100%;
  width: 100%;
  color: var(--color-zero);
`;

export const AuthForm = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  color: var(--color-negative-darken);
  background: var(--color-zero);
  display: flex;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-small);

  .auth-logo {
    height: 35px;
    width: auto;
    margin-bottom: var(--spacing-half-big);
  }

  @media (min-width: 800px) {
    & {
      max-width: 535px;
      max-height: 660px;
    }
  }
`;
