import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getPodcastsRequest: null,
  getPodcastsSuccess: ["data"],

  getCurrentPodcastRequest: ["id"] || null,
  getCurrentPodcastSuccess: ["data"]
});

export const PodcastsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  loading: true
});

/* Reducers */
export const getSuccess = (state, { data }) =>
  state.merge({ data, loading: false });

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PODCASTS_SUCCESS]: getSuccess
});
