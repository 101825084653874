import React, { Component } from "react";

import { EpisodeCardContainer } from "./EpisodeCard.style";

import { Card } from "../index";
import { Play } from "styled-icons/boxicons-regular";
import { Verified } from "styled-icons/octicons";
import { MoreVert } from "styled-icons/material";

export const EpisodeCard = ({
  cardImageEpisode = "",
  cardTitle = "",
  cardImageChannel = "",
  channelName = "",
  episodePlayCount = "",
  timeAgo = ""
}) => (
  <EpisodeCardContainer>
    <div className="episode-card_image">
      <Card
        cardClass="editorial-image"
        cardImg={cardImageEpisode}
        w="100%"
        h="100%"
        borderRadius="3px"
      >
        <Play className="play-icon" />
      </Card>
        
        
    </div>
    <div className="episode-card-body">
      <strong className="episode-title"> {cardTitle}</strong>
      <div className="episode-channel_wrapper">
        <span className="channel-name">{channelName}</span>
        <Verified className="channel-type" />
      </div>
      <div className="episode-info_wrapper">
      <span className="play-count">
          {episodePlayCount} plays
        </span>
        
        
        <span className="time-ago">há {timeAgo} </span>
      </div>
    </div>
  </EpisodeCardContainer>
);


export default class EpisodeComponent extends Component{
  
  constructor(props){
    super(props);  
  }

  render(){
    // channel é do tipo objeto {name, flag}
    // engagement é do tipo objeto { likes, dislikes, comments }
    let { thumb, title, channel, play_count, time_ago, description, engagement } = this.props.episode;
    return(
      <div className={"episode-card " + this.props.horizontal ? 'episode-horizontal' : ''}>
        <img src={`${thumb}`}  alt={`${title}`}/>
        <div>{title}</div>
        {/* <div>{channel}</div> */}
        {this.props.showDetails && <div>{description}</div>}
        {/* {this.props.showDetails && <div>{engagement}</div>} */}
        <div>{play_count}</div>
        <div>{time_ago}</div>
      </div>
    )
  }
}

// import Episode from '../EpisodeComponent';
// <Episode episode={} horizontal showDetails />
