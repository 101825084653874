
import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";

import apolloClient from "../../services/apollo";

import { CHECK_PASSWORD } from "../../services/graphql/query";

//styles
import { Container } from "./ModalRestritoIdade.style";
import ImagemRestito from "../../assets/img/Modal18Mais.png";

export default class ModalSejaPremium extends Component {
    constructor(props){
        super(props)
        this.state = {
            errorSubmit: false,
            modalSenha: "",
            episode: {}
        }
        document.addEventListener("modal-restrito-idade", this.goToVerificaIdade);
    }
    goToVerificaIdade = async ({detail}) => {
        this.setState({episode: detail})
    }
    async onsubmit(){
        // console.log("EPISODE: ", this.state.episode);
        // if(this.state.modalSenha !== ""){
        //     apolloClient.query({
        //         query: CHECK_PASSWORD, 
        //         variables: {
        //             password: this.state.modalSenha, 
        //         }
        //     }).then(async (result) => {
        //         let {checkPassword} = result.data;
        //         // console.log("RESULT: ", result)
        //         if(checkPassword){
        //             var elems = document.getElementById('modal-restrito-idade');
        //             var instance = M.Modal.getInstance(elems);
        //             document.player.pause();
        //             let current = await document.player.setCurrent(this.state.episode);
        //             document.player.setInitialTime(0);
        //             // console.log("Eh aqui mesmo")
        //             if(current) document.player.play();
        //             instance.close()
        //         } else {
        //             this.setState({errorSubmit: true})
        //         }
        //     })
        // } else {
        //     this.setState({errorSubmit: true})
        // }
        var elems = document.getElementById('modal-restrito-idade');
        var instance = M.Modal.getInstance(elems);
        document.player.pause();
        let current = await document.player.setCurrent(this.state.episode);
        document.player.setInitialTime(0);
        if(current) document.player.play();
        instance.close()
    }
    render(){
        return(
            <Container>
                <div id="modal-restrito-idade" className="modal modal-restrito-idade">
                    <div className="imageContainer">
                        <img src={ImagemRestito} alt="" />
                    </div>
                    <h2 className="title">Não recomendado para menores de 18 anos</h2>
                    <p className="description">Olá, informamos que este conteúdo não é 
                    recomendado para menores de 18 anos. Não se preocupe, se você ainda não tem essa idade,
                     aproveite todos os outros conteúdos livres da plataforma. 
                     Se você tem mais de 18 anos, clique em acessar e aproveite. :)</p>
                    {/* <div className="controlFrom">
                        <label className={this.state.errorSubmit ? "labelError" : "label"}>Senha</label>
                        <input 
                            type="password"
                            className={this.state.errorSubmit ? "inputError" : "input"} 
                            placeholder="Digite sua senha aqui"
                            value={this.state.modalSenha}
                            onFocus={()=>this.setState({errorSubmit: false})}
                            onChange={event => this.setState({modalSenha: event.target.value})}
                        />
                        {this.state.errorSubmit &&
                            <span className="labelError">A senha informada é invalida</span>
                        }
                    </div> */}
                    <div className="controlerBottom">
                        <button className="btnClose modal-close">FECHAR</button>
                        <button className="btnSubmit" onClick={()=>this.onsubmit()}>ACESSAR</button>
                    </div>
                </div>
            </Container>
        )
    }
}
