import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ACCESS_TOKEN,USER_ACCOUNT } from '../../../constants';
import M from 'materialize-css';

import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { ModalRestrationLogin } from "../../../components/ModalRestrationLogin";
import Layout from "../../../hoc/Layout/Layout";
//component
import queryString from 'query-string'

class RespLandingNestle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            userAccount: JSON.parse(localStorage.getItem(USER_ACCOUNT))
        };   

        const paramsUrl = queryString.parse(props.location.search);
        if(!!paramsUrl && !!paramsUrl.accessToken){
            this.state = {
                paramsUrl
            }
        }
    }
    componentDidMount() {
        console.log("State:", queryString.parse(this.props.location.search));
        const paramsUrl = queryString.parse(this.props.location.search);

        let email = paramsUrl.email;
        let params = {accessToken: encodeURIComponent(this.state.paramsUrl.accessToken)};
        let referrer = null;
        if (!!this.state.paramsUrl && !!this.state.paramsUrl.codename) {
            params.accessToken = params.accessToken.concat('|'+encodeURIComponent(this.state.paramsUrl.codename));
            referrer = encodeURIComponent(this.state.paramsUrl.codename);
        }
        if(localStorage.getItem(ACCESS_TOKEN)){
            localStorage.clear();
            window.location.reload(true);
        }
        var elems = document.getElementById('signup');
                      // console.log("Elems: ",elems);
        var instances = M.Modal.init(elems, {
            onOpenStart: function () {
                var ev = new CustomEvent('modal-entrar', { "detail": "register"});
                document.dispatchEvent(ev);
                var ev2 = new CustomEvent('setaEmail', { "detail": email});
                document.dispatchEvent(ev2);
                var ev4 = new CustomEvent('travaEmail', { "detail": true});
                document.dispatchEvent(ev4);
        }
        });
        var instance = M.Modal.getInstance(elems);
        instance.open();
      


    }
  
      
    render() {
        
        const {
            seila
        } = this.state;
        
        return (
            <Layout>
                {!!this.state.paramsUrl ? <></> : <Redirect to="/"/> }
            
               <>AGUARDE ENQUANTO ATUALIZAMOS SUA CONTA ...</>

               <ModalRestrationLogin />

            </Layout>

        );
    }
}

export default compose()(RespLandingNestle);
