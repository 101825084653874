import React, { Component } from "react";
import styled from "styled-components";

//component
import Layout from "../../../hoc/Layout/Layout";
import { VerticalList, VerticalPage } from "../../../components/VerticalList/";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class Vertical extends Component {
  render() {
    let { code } = this.props.match.params;
    return (
      <Layout>
        <Container>
          <div className="container">
            {
            code ?
            <VerticalPage code={code}/>
            : 
            <VerticalList/>
            }
          </div>
        </Container>
      </Layout>
    );
  }
}

export const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; 
`;