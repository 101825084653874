import React, { Component } from "react";
import { Link } from "react-router-dom";

//styles
import { Container } from "./ModalAudio.style.js";

//assets
import LogoDefault from "../../assets/img/ola_logo_vertical.png";

import { sendMessageToUser } from '../../common';

import apolloClient from "../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';

import IMG_HEADER from "../../assets/img/Premium/logo-premium.jpg";
import IMG_01 from "../../assets/img/Premium/img-01.jpg";
import IMG_02 from "../../assets/img/Premium/img-02.jpg";

export default class ModalAudio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }
    abrePremium = () => {
        if(Math.floor(Math.random() * 10) >= 4){
            this.setState({img: IMG_01});
        }else{
            this.setState({img: IMG_02});
        }
    }
    componentDidMount(){
        document.addEventListener("abre-premium", this.abrePremium);
    }

    render() {
        return (
            <Container>
                <div id="modal-audio" className="modal modal-audio">
                    <a href="javascript:void(0)" className="close">X</a>
                    <h5 id="modal-audio-titulo" className="trend-title"></h5>
                    <img id="modal-audio-image" src=""/>
                </div>
            </Container>
        )
    }
}

