import styled from "styled-components";

export const ButtonGroupContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0;
  border-radius: 5px;

  a {
    text-align: center;
  }
  a:first-of-type {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background: var(--color-first);
    color: var(--color-zero);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid var(--color-first);
    border-right: 1px solid #fff;
    text-transform: capitalize;
    font-family: "Avenir-Book";
  }
  a:last-of-type {
    padding: 10px 20px;
    margin-left: -1px;
    font-size: 14px;
    cursor: pointer;
    background: var(--color-zero);
    color: var(--color-first);
    border: 1px solid var(--color-first);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-transform: capitalize;
    font-family: "Avenir-Book";
  }
`;
