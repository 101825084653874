import gql from "graphql-tag";


const CHANGE_PASSWORD_MUTATION = gql`

mutation ChangePassword($current_password: String, $new_password: String){
  changePassword(current_password: $current_password, new_password: $new_password)
}`;

export { CHANGE_PASSWORD_MUTATION };
