import styled from "styled-components";

export const Modal = styled.div`
    .modalTimCarroContent {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    
    .modalTimCarroTitle {
      height: 37px;
      color: #282828;
      font-family: Avenir;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 37px;
      text-align: center;
    }
    
    .modalTimCarroText {
      height: 52px;
      color: #282828;
      font-family: Avenir;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      margin-bottom: 14px;
    }
    
    .modalTimCarroChild {
      display: flex;
      flex-direction: row;
      height: 54px;
      border-radius: 5px;
      background-color: #F1F1F4;
      padding-left: 15px;
      padding: ;
      padding-right: 15px;
      margin-top: 2px;
    }
    
    .modalTimCarroChildEmail {
      height: 21px;
      color: #282828;
      font-family: Mulish;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
    }
    
    .modalTimFlexRowCenter {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .modalTimFlexSpaceBetween {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .modalTimFlexEnd {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
    }

    .modalTimFlexColumn {
      display: flex;
      flex-direction: column;
    }
    
    .modalTimCarroChildGreen {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: #14BF7F;
    }
    
    .modalTimCarroChildStatus {
      height: 21px;
      color: #5F5F5F;
      font-family: Mulish;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 4px;
    }
    
    .modalTimCarroChildOrange {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: #FF9E00;
    }
    
    .modalTimCarroChildButtonDelete {
      height: 24px;
      border-radius: 20.5px;
      background-color: #0E53DB;
      align-items: center;
      justify-content: center;
      display: flex;
      padding-left: 5px;
      padding-right: 5px;
    }
    
    .modalTimCarroChildButtonDeleteText {
      height: 16px;
      color: #FFFFFF;
      font-family: Avenir;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
    }
    
    .modalTimInvite {
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 2px;
      margin-bottom: 2px;
      flex-direction: column;
      margin-top: 14px;
      margin-bottom: 10px;
    }
    
    .modalTimInviteText {
      height: 21px;
      color: #282828;
      font-family: Avenir;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
    
    .modalTimInviteTextInput {
      color: #313131;
      font-family: "Avenir";
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 8px;
      border-bottom-width: 1px;
      border-bottom-color: #B6B6B6;
      background-color: transparent;
      border-bottom-style: solid;
    }

    .modalTimButtonClose {
      box-sizing: border-box;
      height: 41px;
      width: 117px;
      border: 1px solid #0E53DB;
      border-radius: 25.5px;
      margin-bottom: 10px;
    }

    .modalTimButtonCloseText {
      height: 27px;
      color: #0E53DB;
      font-family: Avenir;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 27px;
      text-align: center;
    }

    .modalTimButtonSend {
      height: 40px;
      width: 116px;
      border-radius: 25.5px;
      background-color: #0E53DB;
      margin-left: 10px;
      margin-bottom: 10px;

    }
    
    .modalTimButtonSendText {
      height: 27px;
      color: #FFFFFF;
      font-family: Avenir;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 27px;
      text-align: center;
    }
`;
