import { combineReducers } from "redux";

import { reducer as podcasts } from "./podcasts";
import { reducer as tracks } from "./tracks";
import { reducer as player } from "./player";
import { reducer as user } from './user';

export default combineReducers({
  podcasts,
  tracks,
  player,
  user
});
