import React, { Component } from "react";

import {
  Container,
  Trends,
} from "./Registrations.styles";

import AloComponent from "../../../components/alos/AloComponent";
import { Title } from "../../../elements/Typography";
import { Wrapper } from "../../../elements/Layout";

//component
import EpisodeCard from '../../../components/EpisodeCardComponent';
import ChannelCard from "../../../components/ChannelCard/ChannelCard";
import Layout from "../../../hoc/Layout/Layout";

import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { USER_CHANNELS_FOLLOWING } from '../../../services/graphql/query';

import debounce from "lodash.debounce";
import M from "materialize-css";
class Registrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      page_episodes: 1,
      page_channels: 1,
      hasMoreEpisodes: true,
      hasMoreChannels: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { data } = props;
    let new_state = state;
    if (!!data && data.userAllEpisodesFollowing && data.userAllEpisodesFollowing !== state.userAllEpisodesFollowing) new_state = { ...new_state, userAllEpisodesFollowing: data.userAllEpisodesFollowing }
    if (!!data && data.userChannelsFollowing && data.userChannelsFollowing !== state.userChannelsFollowing) new_state = { ...new_state, userChannelsFollowing: data.userChannelsFollowing }
    if (!!data && data.userFeedChannelsFollowing && data.userFeedChannelsFollowing !== state.userFeedChannelsFollowing) new_state = { ...new_state, userFeedChannelsFollowing: data.userFeedChannelsFollowing }
    if (!!data && data.loading !== state.loading) new_state = { ...new_state, loading: data.loading }
    return new_state;
  }

  loadChannels = () => {
    if (!this.state.loading && this.state.hasMoreChannels) {
      this.setState({ loading: true });
      let page_channels = this.state.page_channels + 1;
      this.props.data.fetchMore({
        variables: { page_channels: page_channels },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult && fetchMoreResult.userChannelsFollowing && (fetchMoreResult.userChannelsFollowing.data.length || (fetchMoreResult.userFeedChannelsFollowing && fetchMoreResult.userFeedChannelsFollowing.data.length))) {
            this.setState({ page_channels: page_channels });
            return {
              userChannelsFollowing: { ...fetchMoreResult.userChannelsFollowing, data: [...prev.userChannelsFollowing.data, ...fetchMoreResult.userChannelsFollowing.data] },
              userFeedChannelsFollowing: fetchMoreResult.userFeedChannelsFollowing ? { ...fetchMoreResult.userFeedChannelsFollowing, data: [...prev.userFeedChannelsFollowing.data, ...fetchMoreResult.userFeedChannelsFollowing.data] } : null,
              userAllEpisodesFollowing: { ...prev.userAllEpisodesFollowing },
              // page_channels: page_channels
            }
          } else {
            this.setState({ hasMoreChannels: false })
            return {
              userChannelsFollowing: { ...prev.userChannelsFollowing },
              userFeedChannelsFollowing: { ...prev.userFeedChannelsFollowing },
              userAllEpisodesFollowing: { ...prev.userAllEpisodesFollowing }
            }
          }
        }
      })
    }
  };
  loadEpisodes = () => {
    if (!this.state.loading && this.state.hasMoreEpisodes) {
      this.setState({ loading: true });
      let page_episodes = this.state.page_episodes + 1;
      this.props.data.fetchMore({
        variables: { page_episodes: page_episodes },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult && fetchMoreResult.userAllEpisodesFollowing && fetchMoreResult.userAllEpisodesFollowing.data.length) {
            this.setState({ page_episodes: page_episodes });
            // console.log("Prev: ", prev);
            // console.log("More: ", fetchMoreResult);
            return {
              userChannelsFollowing: { ...prev.userChannelsFollowing },
              userFeedChannelsFollowing: fetchMoreResult.userFeedChannelsFollowing ? { ...prev.userFeedChannelsFollowing } : null,
              userAllEpisodesFollowing: { ...fetchMoreResult.userAllEpisodesFollowing, data: [...prev.userAllEpisodesFollowing.data, ...fetchMoreResult.userAllEpisodesFollowing.data] },
              page_episodes: page_episodes
            }
          } else {
            this.setState({ hasMoreEpisodes: false })
            return {
              userChannelsFollowing: { ...prev.userChannelsFollowing },
              userFeedChannelsFollowing: fetchMoreResult.userFeedChannelsFollowing ? { ...prev.userFeedChannelsFollowing } : null,
              userAllEpisodesFollowing: { ...prev.userAllEpisodesFollowing }
            }
          }
        }
      })
    }
  };

  handleScroll = debounce(() => {
    const {
      loadChannels,
      loadEpisodes,
      state: {
        error,
        loading,
        hasMoreChannels,
        hasMoreEpisodes
      },
    } = this;

    // console.log("scroll....");
    let el = document.querySelectorAll(".tabs");
    let instance = M.Tabs.init(el);

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    if (error || loading || (!hasMoreEpisodes && !hasMoreChannels)) return;

    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop
      > document.documentElement.offsetHeight - 10
    ) {
      if (instance[0].index == 0) {
        loadEpisodes();
      } else {
        loadChannels();
      }

    } else {
    }
  }, 100);


  componentDidMount() {
    const el = document.querySelectorAll(".tabs");
    const instance = M.Tabs.init(el, {});
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    // // console.log("Props: ", this.props);
    // // console.log("State: ", this.state);
    return (
      <Layout>
        <Container>

          {
          !!this.props.data && !!this.props.data.userChannelsFollowing && 
          !!this.props.data.userChannelsFollowing.data &&
            <div className="alos">
              {
                this.props.data.userChannelsFollowing.data.map(
                  (channel) => {
                    if(!!channel.alos && !!channel.alos[0]){
                      return <AloComponent key={`aloCOmp-${channel.id}`} id={channel.id} channel={channel} />
                    }
                  }
                )
              }
            </div>
          }
          <main className="channel-page">

            <Title size="3" typeClass="trend-title" content="Minhas Inscrições" />

            <div className="row">
              <div className="col s12">
                <ul className="tabs">
                  <li className="tab col s6">
                    <a href="#episodes">EPISÓDIOS</a>
                  </li>
                  <li className="tab col s6">
                    <a className="" href="#channels">
                      CANAIS
                        </a>
                  </li>
                </ul>
              </div>

              <div id="episodes" className="col s12 episode-list channel-page">
                <Trends>
                  <Wrapper className="trend-list"  >
                    {
                      !!this.state.userAllEpisodesFollowing && !!this.state.userAllEpisodesFollowing.data &&
                      this.state.userAllEpisodesFollowing.data.map(
                        (episode) => {
                          let scope = isNaN(episode.id) ? "externalFeed" : "";
                          return <EpisodeCard key={`epcard2-${episode.id}`} episode={episode} scope={scope} />
                        }
                      )
                    }
                  </Wrapper>
                </Trends>
              </div>
              <div id="channels" className="col s12 episode-list channel-page">
                <Trends>
                  <Wrapper className="trend-list"  >
                    {
                    !!this.state.userChannelsFollowing && !!this.state.userChannelsFollowing.data && 
                      this.state.userChannelsFollowing.data.map(
                        (channel) => <ChannelCard key={`chcard-${channel.id}`} channel={channel} />
                      )
                    }
                    {
                    !!this.state.userFeedChannelsFollowing && !!this.state.userFeedChannelsFollowing.data &&
                        this.state.userFeedChannelsFollowing.data.map(
                          (channel) => <ChannelCard key={`chcard2-${channel.id}`} channel={channel} scope="externalFeed" />
                        )
                    }
                  </Wrapper>
                </Trends>
              </div>
            </div>
          </main>
        </Container>
      </Layout>
    );
  }
}

const propsToOptions = (props) => {

  return ({
    variables:
    {
      page_episodes: 1,
      page_channels: 1
    },
    fetchPolicy: "no-cache"
  }
  )
}

export default compose(graphql(USER_CHANNELS_FOLLOWING, { options: propsToOptions }))(Registrations);
