export function parseSearchQuery(location){
    if(!!location && !!location.search && !!location.search.split('q=').length){
        let searchQuery = location.search.split('q=')[1];
        if(!!searchQuery){
            let queryRaw = searchQuery.split("+")
            return queryRaw.join(" ")
        }
    }
}

export function sendMessageToUser(message){
    let messageEvent = new CustomEvent('toastMessage', {"detail": message });
    document.dispatchEvent(messageEvent)
}
