import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    /*height: 100%;*/
    .bread {
        padding: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        img {
            max-width: 41px;
            max-height: 41px;
            width :100%;
            height: auto;
            margin-right: var(--size-base);
        }
        span {
            margin-right: var(--spacing-small);
        }
    }
    .content {
        background-color: var(--color-third);
        padding: 20px;
        flex: 1;
        .collapsible {
            border: none;
            margin: 0;
            box-shadow: none;
            .active {
                .status-dropdown {
                    transform: rotate(90deg);
                }
            }
        }
    }
    
    .contentSendFile, .contentInfoEpsode {
        background-color: var(--color-zero);
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        .collapsible-header {
            font-weight: 700;
            position: relative;
            .status-dropdown {
                height: 22px;
                position: absolute;
                right: 10px;
                color: #D1D6D9;
            }
        }
    }
    .contentSendFile {
        margin-bottom: 20px;
    }
    .contentInfoEpsode {
        min-height: 50px;
    }



    .contentSucess {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #6FCF97;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        .icon {
            height: 160px;
            color: #fff;
            margin-bottom:70px;
        }
        span {
            font-size: 24px;
            line-height: 33px;
            text-align: center;
            color: #fff;
            display: flex;
            margin: 0 10%;
        }
    }
`;
