import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ACCESS_TOKEN } from '../../../constants';
import M from 'materialize-css';

import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { VERIFY_ACCESSTOKEN_MUTATION, ASSOCIATE_NEO_USER_MUTATION } from '../../../services/graphql/mutations';
import { ModalRestrationLogin } from "../../../components/ModalRestrationLogin";
import Layout from "../../../hoc/Layout/Layout";
//component
import queryString from 'query-string'


class RespLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };   

        const paramsUrl = queryString.parse(props.location.search);
        if(!!paramsUrl && !!paramsUrl.accessToken){
            this.state = {
                paramsUrl
            }
        }
    }
    componentDidMount() {
 
        let params = {accessToken: encodeURIComponent(this.state.paramsUrl.accessToken)};
        let referrer = null;
        if (!!this.state.paramsUrl && !!this.state.paramsUrl.codename) {
            params.accessToken = params.accessToken.concat('|'+encodeURIComponent(this.state.paramsUrl.codename));
            referrer = encodeURIComponent(this.state.paramsUrl.codename);
        }
        if (this.state.paramsUrl && this.state.paramsUrl.subscriber_id) {
            this.props.mutate({
                mutation: ASSOCIATE_NEO_USER_MUTATION,
                variables: { subscriber_id: this.state.paramsUrl.subscriber_id }
            }).then(({ data, errors }) => {
                // console.log("data: ", data)
                this.setState({loading: false});
                let user = data.verifyAccessToken;
                localStorage.setItem('UserAccount', JSON.stringify(user));
                window.location.replace("/");
            })
        }
        // console.log('Params: ', params);
        this.props.mutate({variables: params, errorPolicy: 'all'})
        .then(({data,errors}) => {
            if(errors){
                let { validation } = errors[0].extensions;
                let erro = validation.accessToken[0];
                var arrValid = erro.split("|");
                switch (arrValid[0]) {
                    case 'LOGIN':
                     
                      this.setState({loading: false});
                      if(localStorage.getItem(ACCESS_TOKEN)){
                        localStorage.clear();
                        window.location.reload(true);
                      }
                      var elems = document.getElementById('signup');
                      // console.log("Elems: ",elems);
                      var instances = M.Modal.init(elems, {
                          onOpenStart: function () {
                              var ev = new CustomEvent('modal-entrar', { "detail": "login"});
                              document.dispatchEvent(ev);
                              var ev2 = new CustomEvent('setaEmail', { "detail": arrValid[1]});
                              document.dispatchEvent(ev2);
                              var ev4 = new CustomEvent('travaEmail', { "detail": true});
                              document.dispatchEvent(ev4);
                        }
                      });
                      var instance = M.Modal.getInstance(elems);
                      instance.open();

                      break;
                    case 'CADASTRO':

                        this.setState({loading: false});
                        if(localStorage.getItem(ACCESS_TOKEN)){
                            localStorage.clear();
                            window.location.reload(true);
                        }
                        var elems = document.getElementById('signup');
                        // console.log("Elems: ",elems);
                        var instances = M.Modal.init(elems, {
                            onOpenStart: function () {
                                var ev = new CustomEvent('modal-entrar', { "detail": arrValid[1].indexOf('@') >= 0 ? "register" : "telephone-operator"});
                                document.dispatchEvent(ev);
                                var ev2 = new CustomEvent('setaEmail', { "detail": arrValid[1]});
                                document.dispatchEvent(ev2);
                                if (!!referrer) {
                                    var ev3 = new CustomEvent('setaReferrer', { "detail": referrer});
                                    document.dispatchEvent(ev3);
                                }
                                var ev4 = new CustomEvent('travaEmail', { "detail": true});
                                document.dispatchEvent(ev4);
                            }
                        });
                        var instance = M.Modal.getInstance(elems);
                        instance.open();
                      break;

                    default:
                    this.setState({loading: false});
                    window.location.replace("/");
                  }

            }else{
                // console.log("data: ", data)
                this.setState({loading: false});
                let user = data.verifyAccessToken;
                localStorage.setItem('UserAccount', JSON.stringify(user));
                window.location.replace("/");
            }
        })
        .catch((error) => {
            // console.log("error: ", error);
            
        });

    }
  
      
    render() {
        
        const {
            seila
        } = this.state;
        
        return (
            <Layout>
                {!!this.state.paramsUrl ? <></> : <Redirect to="/"/> }
            
               <>AGUARDE ENQUANTO VALIDAMOS SUA ASSINATURA ...</>

               <ModalRestrationLogin />

            </Layout>

        );
    }
}

export default compose(graphql(VERIFY_ACCESSTOKEN_MUTATION))(RespLanding);
