import React, { Component } from "react";

// import { Container } from './styles';
import VerticalComponent from "./VerticalComponent";

import M from "materialize-css";

export default class Verticals extends Component {
  componentDidMount() {
    // var elems = document.querySelectorAll(".carousel");
    // var instances = M.Carousel.init(elems, {
    //   indicators: true
    // });
  }
  render() {
    const { verticals } = this.props;

    return (
      <section className="verticals">
        <h3 className="vertical-title section-title ">
          <span>Verticais</span>
          <small className="section-subtitle">
            Os assuntos que você mais curte em um só lugar
          </small>
        </h3>
        <div className="vertical-lists">
          {verticals.map( (vertical, index) => (
            <VerticalComponent key={index} vertical={vertical} />
          ))}
        </div>
      </section>
    );
  }
}
