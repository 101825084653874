import styled from "styled-components";

export const Container = styled.article`
  width: auto;
  margin: 0 2%;
  background: ${props => props.backgroundColor};
  padding: var(--spacing-small);
  border-radius: var(--radius-small);
  margin-bottom: var(--spacing-small);
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  color: #666;
  
  .whiteColor {
    color: #fff;
  }

  .episode-thumb {
    margin: 0.4rem;
    position: relative;
    border-radius: 5px;
    a {
      width: 18.46rem;
      height: 18.46rem;
      display: flex;
      position: relative;
      img {
        width: 100%;
        margin: 0;
      }
    }
  }
  .episode-card:hover .episode-thumb-hover {
    left: 0;
    right: 0;
    position: absolute;
}

  .card-body {
    margin-left: var(--spacing-small);
    margin-top: var(--spacing-small);
  }
  .title-block {
    margin-top: var(--spacing-default);
    display: flex;
    min-height: 65px;
    font-style: normal;
    font-weight: 700;
    font-size: var(--size-half-medium);
    line-height: 1.4rem;
    span {
      margin-bottom: 1.3rem;
      display: block;
    }
  }

  @keyframes example {
    from {opacity: 0;}
    to {opacity: 0.6}
  }
`;
