import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactMic } from 'react-mic';

//styles
import { Container } from "./MyChannelLine.styles";

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { NEW_ALO_CHANNEL } from "../../services/graphql/mutations/ChannelMutations";


//icon
import { Cog, EllipsisV, VolumeUp, Save, WindowClose } from "styled-icons/fa-solid";


import defaultImage from "../../assets/img/thumbDefault.jpg";
import iconGren from "../../assets/img/verificado.png";
import iconGold from "../../assets/img/premium-icon.png";
import iconSignal from "../../assets/img/signal.png";
import { sendMessageToUser } from '../../common';

//import moment from "moment";
//import "moment/locale/pt-br";
//import { MediaRecord } from "styled-icons/typicons";

class MyChannelLine extends Component {
    constructor(props){
        super(props);
        this.state = {
            recordedBlob: null,
            blob: null,
            isRecording: false,
            newAlo: false,
            playAlo: false,
            actionAlo: false,
            timerAlo: 31,
            timerPlay: 31,
            file: null
        }
    }

    getNavetation = () => {
        let ua = navigator.userAgent;
//        let msie = false;
//        let ff = false;
//        let chrome = false;
//        let safari = false;
 
        //Javascript Browser Detection - Internet Explorer
        if (/MSIE (\d+\.\d+);/.test(ua)) //test for MSIE x.x; True or False
        {
//            let msie = (/MSIE (\d+\.\d+);/.test(ua)); //True or False
//            let ieversion = new Number(RegExp.$1); //gets browser version
            //alert("ie: " + msie + ' version:' + ieversion);
            return 'msie';
        }
 
        //Javascript Browser Detection - FireFox
        if (/Firefox[/\s](\d+\.\d+)/.test(navigator.ua))//test for Firefox/x.x or Firefox x.x
        {
//            let ff = (/Firefox[/\s](\d+\.\d+)/.test(navigator.ua)); //True or False
//            let ffversion = new Number(RegExp.$1) //gets browser version
            //alert("FF: " + ff + ' version:' + ieversion);
            return 'firefox';
        }
 
        //Javascript Browser Detection - Chrome
        if (ua.lastIndexOf('Chrome/') > 0) {
//            let version = ua.substr(ua.lastIndexOf('Chrome/') + 7, 2);
//            chrome = true;
            //alert("chrome " + version);
            return 'chrome';
        }
 
        //Javascript Browser Detection - Safari
        if (ua.lastIndexOf('Safari/') > 0) {
//            let version = ua.substr(ua.lastIndexOf('Safari/') + 7, 2);
//            safari = true;
            //alert("Safari " + version);
            return 'safari';
        }
 
        //Javascript Browser Detection - Android
        if (ua.indexOf("Android") >= 0) {
            let androidversion = parseFloat(ua.slice(ua.indexOf("Android") + 8));
            if (androidversion < 2.3) {
                // do whatever
                alert("This older version of Android has some issues with CSS");
            }
            return 'android';
        }
 
        //Javascript Browser Detection - Mobile
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(ua)) {
 
            // Check if the orientation has changed 90 degrees or -90 degrees... or 0
            window.addEventListener("orientationchange", function () {
                alert(window.orientation);
            });
        }
    }
    
    kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
            : Math.sign(num) * Math.abs(num);
    }
    gravandoAlo = () => {
        console.log('Gravando Alo');
        if (!this.state.isRecording) {
            console.log("isRecording: true",navigator)
            if (navigator.mediaDevices) {
                navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false
                }).then(() => {
                    this.setState({newAlo: true, isRecording: true},
                    this.closeGravandoAlo());
                })
                .catch(
                    console.log("ERROR: Gravar Alo")
                )
            } else {
                navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false
                }).then(() => {
                    this.setState({newAlo: true, isRecording: true},
                    this.closeGravandoAlo());
                })
                .catch(
                    console.log("ERROR: Gravar Alo")
                )
            }
        } else if (!this.state.playAlo){
            this.setState({timerPlay: 32 - this.state.timerAlo ,timerAlo: 0})
        } else {
            this.setState({timerPlay: 0})
        }
    }
    closeGravandoAlo = () => {
        console.log('closeGravandoAlo');
        if(this.state.timerAlo > 1){
            console.log('closeGravandoAlo timerAlo: ' + this.state.timerAlo);
            this.setState((state, props) => ({
                timerAlo: state.timerAlo -1
            }));
            setTimeout( () => this.closeGravandoAlo(), 1000 )
        } else {
            this.setState({
                newAlo: false,
                timerAlo: 31
            })
            console.log('closeGravandoAlo timerAlo: ' + this.state.timerAlo);
        }
    }
    closePlayAlo = () => {
        console.log('closePlayAlo');
        if(this.state.timerPlay > 1){
            this.setState((state, props) => ({
                timerPlay: state.timerPlay -1
            }));
            setTimeout( () => this.closePlayAlo(), 1000 )
        } else {
            //this.stopRecording();
            this.setState({
                playAlo: false,
                timerPlay: 31,
                isRecording: false,
                actionAlo: true
            })
        }
    }

    render(){
        const { blobURL, blob, newAlo, playAlo, actionAlo, isRecording } = this.state;
        let {
            id,
            name,
            codename,
            description,
            flag,
            premium,
            followers_count,
            episodes,
            thumbnail,
        } = this.props.episode;
      
//        let { scope } = this.props;
        const dataURLtoFile = (dataurl, filename) => {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
        const onData = (recordedBlob) => {
            // console.log('chunk of real-time data is: ', recordedBlob);
            //// console.log('ONDATA CALL IS BEING CALLED! ', recordedBlob);
        }
        const onStop = (recordedBlob) => {
            // console.log('recordedBlob is: ', recordedBlob);
        }

        const onStart = () => {
            // console.log('You can tap into the onStart callback');
        }

        const onSave = (recordedBlob) => {

            console.log('recordedBlob é: ', recordedBlob)
            console.log("props :", this.props);
                        
            this.setState({ 
                blobURL : recordedBlob.blobURL,
                blob: recordedBlob.blob,
                playAlo: true
            }, () => {
                var audio = document.getElementById('audio');
                audio.play();
            },this.closePlayAlo());

        }

        const saveAlo = () => {
            let that = this;
            let reader = new FileReader();
            reader.addEventListener("loadend", function() {
                // console.log("props :", that.props)
                var base64FileData = reader.result;
                let file_alo = dataURLtoFile(base64FileData, "alo.webm")
                // console.log("LoadEnd now", file_alo);
                that.props.mutate({
                    mutation: NEW_ALO_CHANNEL, variables: {
                        id: that.props.episode.id,
                        file: file_alo
                    } 
                });
            })
            reader.readAsDataURL(blob);
            sendMessageToUser('Alo foi cadastrado com sucesso!');
            this.setState({actionAlo: false});
        }
        console.log("render isRecording: "+isRecording);
        console.log("render newAlo: "+newAlo);
        return(
            <Container>
                {isRecording&&
                <ReactMic
                    record={newAlo}
                    className="sound-wave"
                    onStop={onStop}
                    onStart={onStart}
                    onSave={onSave}
                    onData={onData}
                    strokeColor="#FF4081"
                    backgroundColor="#ffffff"
                    mimeType="audio/wav"
                />
                }
                <div style={{display:"flex",width: "100%", alignItems: "center"}}>
                    <div className="contentImage">
                        <div className="image-position">
                            <span style={{
                                backgroundImage: `url(${thumbnail?thumbnail:defaultImage})`
                            }}></span>
                        </div>
                    </div>
                    <div className="contentEpisode">
                        <div className="title-episode">
                            {name}
                            <span>{codename} <img src={!premium?iconGren:iconGold} alt=""/></span>
                        </div>
                        <div className="infor-episode">{followers_count} inscritos</div>
                        <div className="infor-episode">{episodes.total} episódios</div>
                    </div>
                    {
                        newAlo?
                        <div className="contentActions">
                            <Link onClick={this.gravandoAlo} className="action-gravando-alo">
                                <img src={iconSignal} className="icon" alt=""/> Gravando alô...
                                <span>{this.state.timerAlo}</span>
                            </Link>
                            <Link className="action-more">
                                <EllipsisV className="icon" />
                            </Link>
                        </div>
                        :
                        playAlo?
                        <div className="contentActions">
                            <audio id="audio" src={blobURL} controls type="audio/mp3"  preload="auto"/>
                            <Link onClick={this.gravandoAlo} className="action-gravando-alo play">
                                <VolumeUp className="icon" /> Play alô
                                <span>{this.state.timerPlay}</span>
                            </Link>
                            <Link className="action-more">
                                <EllipsisV className="icon" />
                            </Link>
                        </div>
                        :
                        actionAlo?
                        <div className="contentActions">
                            <Link onClick={() => saveAlo()} className="action-midia-alo">
                                <Save className="icon" /> SALVAR ALÔ
                            </Link>
                            <Link onClick={() => this.setState({actionAlo: false})} className="action-midia-alo play">
                                <WindowClose className="icon" /> CANCELAR
                            </Link>
                            <Link className="action-more">
                                <EllipsisV className="icon" />
                            </Link>
                        </div>
                        :
                        this.getNavetation() != 'safari'?
                        <div className="contentActions">
                            <Link to={`/channeloption/${id}`} className="action-admin">
                                <Cog className="icon" />ADMINISTRAR CANAL
                            </Link>
                            <Link onClick={this.gravandoAlo} className="action-alo">
                                GRAVAR ALÔ
                            </Link>
                            <Link to="#" className="action-more">
                                <EllipsisV className="icon" />
                            </Link>
                        </div>
                        :
                        <div className="contentActions">
                            <Link to={`/channeloption/${id}`} className="action-admin">
                                <Cog className="icon" />ADMINISTRAR CANAL
                            </Link>
                        </div>
                    }
                </div>
            </Container>
        )
    }
}
export default compose(
    graphql(NEW_ALO_CHANNEL),
)(MyChannelLine);
