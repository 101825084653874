export const validator = {
  handleInputIsEmailOrPhone: e => {
    if (
      validator.isPhoneNumber(e.target.value) ||
      validator.isEmail(e.target.value)
    ) {
      e.target.classList.add("success");
      e.target.classList.remove("error");
    } else {
      e.target.classList.add("error");
      e.target.classList.remove("success");
    }
  },

  isEmail: value => {
    const reg = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    if (!reg.test(value)) return false;
    else return true;
  },

  isPhoneNumber: value => {
    const reg = /(?:\+?55\s?)?(?:\(?\d{2}\)?[-\s]?)?\d{4,5}[-\s]?\d{4}/g;
    if (!reg.test(value)) return false;
    else return true;
  }
};
