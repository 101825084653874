import styled from "styled-components";

export const Container = styled.section`
    .modal {
        width: 1240px;
        height: 385px;
        padding: 10px  20px;
        background-color: #fff;
        overflow-Y: hidden;
        overflow-X: hidden;
        .close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            background-color: #2196F3;
            color: #fff;
            font-family: Arial;
            font-weight: bold;
            border-radius: 0.25rem;
        }
        .trend-title {
            text-align: center;
            font-weight: bold;
        }
        #modal-audio-image {
            width: 100%;
            height: auto;
        }
    }
`;
