import styled from "styled-components";

export const Container = styled.section`
    .modal {
        background-color: #2B2B2B;
        max-width: 535px;
        max-height: 660px;
        padding: 10px 30px;
        overflow-Y: hidden;
        overflow-X: hidden;
    }
    .modalBackground {
        background-color: #0e53db;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: Avenir;
        text-align: center;
    }
    .imageContainer {
        padding-top: 10px;
        align-items: center;
        margin-bottom: 15px;
        text-align: center;
    }
    .title {
        font-size: 20px;
        line-height: 27px;
        color: #FF003E;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
    }
    .description {
        color: #DBDBDB;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 25px;
    }
    .controlFrom {
        display: flex;
        flex-direction: column;
    }
    label {
        color: #FFFFFF;
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        margin-left: 2.5px;
        margin-bottom: 5px;
        display: flex;
    }
    .labelError {
        color: #FF0000;
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        margin-left: 2.5px;
        margin-bottom:5px;
    }
    .input {
        border-radius: 7px !important;
        background-color: #FFFFFF;
        padding: 14px 11px !important;
        width: auto !important;
        flex: 1;
    }
    .inputError {
        border-color: #FF0000;
        border-width: 1px;
        border-radius: 7px;
        background-color: #FFFFFF;
        padding: 14px 11px;
    }
    .controlerBottom {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 21px 0
    }
    .btnClose {
        border: 1px solid #fff;
        border-radius: 22.5px;
        padding: 11.25px 22px;
        margin: 0 5px;
        font-size: 12px;
        color: #EDEFF1;
        font-weight: 700;
    }
    .btnSubmit {
        background-color: #214EE3;
        border-radius: 22.5px;
        padding: 11.25px 22px;
        margin: 0 5px;
        font-size: 12px;
        color: #EDEFF1;
        font-weight: 700;
    }
`;
