import gql from "graphql-tag";

const VerticalListQuery = gql`
    query GetVerticalList($id: Int, $pageVerticalsEpisodes: Int){
        mainCategories(id: $id){
            id
            name
        }
        verticalsEpisodes(main_category_id: $id, page: $pageVerticalsEpisodes, limit: 21){
            data{
                id
                title
                rating
                restricted
                duration
                description
                play_count
                publishDate
                premium
                original
                content
                external
                thumbnail(width:200,height:200)
                favorited
                channel{
                    id
                    name
                    codename
                    description
                    flag
                    premium
                    original
                    thumbnail(width:200,height:200)
                    cover
                    followers_count
                    main_category{
                        id
                        name
                    }
                }
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }       
    }
`;

const CategoryVerticalQuery = gql`
    query {
        mainCategories{
            id
            name
        }
    }
`;

export { CategoryVerticalQuery ,VerticalListQuery };
