import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir;
  background-color: #F2F2F2;
  
  .caixa-planos{
    width: 100%;
    height: auto;
    flex: 1;
    ${'' /* min-height: 451px; */}
    border-radius: 3px;
    display: flex;
    background-color: #FFF;
    ${'' /* margin-top: -12%; */}
    @media (max-width: 1024px) {
    & {
      margin-top: 0;
      flex-direction: column;
    }
  }

  }
  .plano1{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div:first-child{
      margin-top: 5% !important
    }
  }
  .plano2{
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div:first-child{
      margin-top: 5% !important
    }
  }
  .plano3{
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div:first-child{
      margin-top: 5% !important;
    }
    margin-bottom: 5%

  }
  .textoSimples{
    color: #4A4A49;	
    font-size: 14px;	
    font-weight: 500;	
    letter-spacing: -0.34px;	
    line-height: 24px;
  }
  .textoSimples .checked{ 
    color: #000;	
    font-size: 14px;	
    font-weight: 500;	
    letter-spacing: -0.34px;	
    line-height: 24px;
  }
  .radio{
    position: relative;
    opacity: 1;
    pointer-events: auto;
    margin-left: 20px;
  }
  .radio input:checked {
    background-color: #000;
  }
  .botaoAssinar{
    border-radius: 22.5px;	
    background-color: #0E53DB;
    color: #FFFFFF;	
    font-size: 13px;
    font-weight: 900;	
    line-height: 18px;	
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 6%;
    width: 45%;
    text-align: center;
  }
  .botaoAssinante{
    border-radius: 22.5px;	
    background-color: #FFF;
    color: #0E53DB;	
    font-size: 13px;
    font-weight: 900;	
    line-height: 18px;	
    border: 2px solid #0E53DB;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-top: 1%;
    width: 45%;
    text-align: center;
  }
`;

