import gql from "graphql-tag";

const AlosQuery = gql`

  query Alos($channel_id: Int!) {
    alos(channel_id: $channel_id){
        data{
          id
          content(format: "download_medium")
          publishDate
          channel{
              codename
              main_category {
                id
              }
              cover
          }
          aloCms{
            id_job
            alo_id
            status
            description
          }
        }
    }
}`;


const DELETE_ALO = gql`

    mutation DeleteAlo($alo_id: Int!){
      deleteAlo(id: $alo_id)
    
  }`;

export { AlosQuery, DELETE_ALO };
