import React from "react";

//style
import { Link } from "react-router-dom";
import { Container } from "./Rodape.style";

import FAQ from "../../../src/assets/pdfs/FAQ.pdf";

const Rodape = () => {

  return (
    <Container>
      {/* <div className="container" >
        OLA PODCASTS CONTEÚDO E PARTICIPAÇÕES • CNPJ  34.145.511/0001-13.
        <ul className="links">
         <li><a href={FAQ} target="_blank">Perguntas Frequentes</a></li>
          <li>Termos, Condições e Políticas de uso</a></li>
        </ul>
      </div> */}
      <div className="container" >
        OLA Podcasts conteúdo e participações • CNPJ 34.145.511/0001-13. • <a href={FAQ} target="_blank">Perguntas Frequentes</a> • <Link to="/termos-privacidade" target="_blank">Termos, Condições e Políticas de uso</Link>
      </div>
    </Container>
  )
};

export default Rodape;
