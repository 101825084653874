import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
`;

export const EditorialBanner = styled.article`
  /*height: 300px;*/
  width: 100% ;
  /* background: #eee; */
  /*margin-left: var(--spacing-default);*/
  
`;
export const EditorialBox = styled.section`
    .editorial-container {
      /* padding-left:250px */
    }
    .content-editorial{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: var(--spacing-medium);
      width: 100%;
      .materia{
        width: 100%;
        /*margin-left: var(--spacing-medium);*/
        word-wrap: break-word;
        
      }
      .imagem{
        display: flex;

        float:left;
        margin-right: var(--spacing-medium);
        
      }
    }
    .content-banner{
      width: 100%;
      text-align: center;
    }
    .contentBanner {
      width: 100%;
      height: auto;
    }

    .roleta{
      margin-top: var(--spacing-default);
    }


    .editorial-title {
      /*margin-left: var(--spacing-default);*/
      margin-top: var(--spacing-medium);
      width: 100%;
    }
    .editorial-data{
      
      /*margin-left: var(--spacing-default);*/
      margin-top: var(--spacing-medium);
      width: 100%;
    }
    .editorial-image {
      margin-top: var(--spacing-small);
      margin-left: var(--spacing-default);
      max-width: 240px;
      max-height: 240px;
    }
    .editorial-description {
      margin-top: var(--spacing-small);
      /*margin-left: var(--spacing-default);
      margin-right: var(--spacing-default);*/
      white-space: pre-wrap;
      
    }
    .editorial-category {
      display: flex;
      align-items: flex-start;
      margin-top: var(--spacing-medium);
      /*margin-left: var(--spacing-default);*/
      width: 100%;
    }
    
`;
