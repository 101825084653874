import React from "react";

import { Container, Wrapper } from "./Mydata.style";
import { Input } from "../../../elements/Input";
import { Button } from "../../../elements/CTA";

const MyData = () => (
  <Container className="browse-container">
    <Wrapper className="mydata-form-wrap">
      <Input
        labelContent="Nome"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Input
        labelContent="Username"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder="@username"
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
    </Wrapper>
    <Wrapper lassName="mydata-form-wrap">
      <Input
        labelContent="Data de Nascimento"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Input
        labelContent="Genêro"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder="@username"
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Input
        labelContent="Telefone"
        inputClasses="mydata-form-input"
        labelClasses="mydata-form-label"
        placeholder=""
        borderRadius="3px"
        background="var(--color-third)"
        color="var(-color-third-darken)"
        border="1px solid var(--color-third)"
      />
      <Button
        type="button"
        className="mydata-form-button"
        Background="var(--color-first)"
        Color="var(--color-zero)"
      >
        Salvar
      </Button>
    </Wrapper>
  </Container>
);

export default MyData;
