import gql from "graphql-tag";
export const CANCEL_ASSING_MUTATION = gql`
    mutation {
        cancelSignature {
            id
            email
            phone
            access_token
            premium
            subscribed
            signature_expire_at
            celco
            email_verified_at
            msisdn_verified_at
            expires_in
            refresh_token
            profile {
                name
                nickname
                gender
                birth
                country_id
                state_id
                city_id
                profilePicture(width: 200, height: 200) 
            }
        }
    }
`;

export const CHILD_ACCOUNTS_QUERY = gql`
   query ChildAccounts {
    childAccounts {
      child_email,
      status
    }
  }
`;

export const CHILD_ACCOUNT_DELETE_MUTATION = gql`
  mutation DeleteChildAccount($email: String!) {
    deleteChildAccount(email: $email) {
      child_email
      status
    }
  }
`;

export const CHILD_ACCOUNT_INVITE_MUTATION = gql`
  mutation InviteChildAccount($email: String!) {
    inviteChildAccount(email: $email)
  }
`;