import gql from "graphql-tag";
import { USER_TYPE } from './types/USER';
export * from './mutations/ChannelMutations';

export const LOGIN_MUTATION = gql`
    mutation login($phone_or_email: String!, $password: String!){
        login(
            phone_or_email: $phone_or_email,
            password: $password
        ){
            ${USER_TYPE}
        }
    }
`;

export const EXTERNAL_FEED_MUTATION = gql`
    mutation addExternalFeed($external_feed: String!){
        addExternalFeed(external_feed: $external_feed){
            id
            name
            description
            flag
            thumbnail
            followers_count
            following
            episodes(page: 1, limit: 50){
                current_page
                total
                has_more_pages
                data{
                    title
                    description
                    thumbnail
                }
            }
            followers_count
        }
    }
`;

export const FOLLOW_EXTERNAL_FEED_MUTATION = gql`
    mutation  followExternalFeed($id: String!, $unfollow: Boolean){
        followExternalFeed(external_feed_id: $id, unfollow: $unfollow){
            id
            name
            description
            flag
            thumbnail
            followers_count
            following
            episodes(page: 1, limit: 50){
                current_page
                total
                has_more_pages
                data{
                    title
                    description
                    thumbnail
                }
            }
            followers_count
        }
    }
`;

export const VERIFY_EMAIL_MUTATION = gql`
    mutation resendEmail($email: String!){
        resendEmail(email: $email)
    }
`;

export const COMMENT_MUTATION = gql`
    mutation createComment($episode_id: Int!, $text: String!){
        createComment(episode_id: $episode_id, text: $text){
            id
            body
            user{
                id
                profile{
                    name
                    profilePicture
                }
            }
            userLiked
            userDisliked
            amountLikes
            amountDislikes
            amountReplies
            updatedAt
        }
    }
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($id: String!) {
    deleteComment(id: $id)
  }
`;

export const EDIT_COMMENT_MUTATION = gql`
  mutation editComment($id: String!, $episode_id: Int!, $text: String!) {
    editComment(id: $id, episode_id: $episode_id, text: $text) {
        id
        body
        user{
            id
            profile{
                name
                profilePicture
            }
        }
        userLiked
        userDisliked
        amountLikes
        amountDislikes
        amountReplies
        updatedAt
    }
}`;

export const LIKE_UNLIKE_MUTATION = gql`
    mutation($id: Int!, $unlike: Boolean){
        likeEpisode(id: $id, unlike: $unlike){
            id
            likes
            dislikes
            userLike
            userDislike
        }
    }
`;
export const DISLIKE_UNDISLIKE_MUTATION = gql`
    mutation($id: Int!, $undislike: Boolean){
        dislikeEpisode(id: $id, undislike: $undislike){
            id
            likes
            dislikes
            userLike
            userDislike
        }
    }
`;

export const FAVORITE_EPISODE_MUTATION = gql`
    mutation($episode_id: Int!, $type: String!){
        addToPlaylist(episode_id: $episode_id, type: $type){
            id
            name
            episodes_with_order{
                order
                episode{
                    id
                    title
                }
            }
        }
    }
`;

export const ADD_TO_PLAYLIST_MUTATION = gql`
    mutation($id: Int, $episode_id: Int!, $type: String){
        addToPlaylist(id: $id, episode_id: $episode_id, type: $type){
            id
            name
            episodes_with_order{
                order
                episode{
                    id
                    title
                }
            }
        }
    }
`;

export const CREATE_COMPLAINT_MUTATION = gql`
    mutation($type_id: Int!, $subtype_id: Int, $object_type: String!, $object_id: String!, $claimed_url: String ){
        createComplaint(type_id: $type_id, subtype_id: $subtype_id, object_type: $object_type, object_id: $object_id, claimed_url: $claimed_url){
            id
            complainer{
                email
            }
            claimed_type
            claimed_url
            active
            created_at
        }
        
    }
`;

export const UNFAVORITE_EPISODE_MUTATION = gql`
    mutation($episode_id: Int!, $type: String!){
        removeFromPlaylist(episode_id: $episode_id, type: $type){
            id
            name
            episodes_with_order{
                order
                episode{
                    id
                    title
                }
            }
        }
    }
`;

export const REMOVE_FROM_PLAYLIST_MUTATION = gql`
    mutation($id: Int, $episode_id: Int!, $type: String){
        removeFromPlaylist(id: $id, episode_id: $episode_id, type: $type){
            id
            name
            episodes_with_order{
                order
                episode{
                    id
                    title
                }
            }
        }
    }
`;

export const REGISTER_OPERATOR_MUTATION = gql`
    mutation createUser(
        $country_id: Int,
        $msisdn: String,
        $password: String!,
        $name: String!,
        $nickname: String!
    ){
        createUser(
            country_id: $country_id,
            msisdn: $msisdn,
            password: $password,
            name: $name,
            nickname: $nickname
        ){
            ${USER_TYPE}
        }
    }
`;

export const REGISTER_PIN_MUTATION = gql`
    mutation{
        sendMsisdnPinValidate
    }
`;
export const REGISTER_PIN_VALIDATION_MUTATION = gql`
    mutation verifyMsisdnPin(
        $pin: Int!
    ){
        verifyMsisdnPin(pin: $pin)
    }
`;

export const REGISTER_MUTATION = gql`
    mutation createUser(
        $email: String,
        $msisdn: String,
        $password: String!,
        $name: String!,
        $nickname: String!,
        $referrer: String,
        $celco_incentive: String,
        $celco_incentive_code: String
    ){
        createUser(
            email: $email,
            msisdn: $msisdn,
            password: $password,
            name: $name,
            nickname: $nickname,
            referrer: $referrer,
            celco_incentive: $celco_incentive,
            celco_incentive_code: $celco_incentive_code
        ){
            ${USER_TYPE}
        }
    }
`;

export const CREATE_EPISODE_MUTATION = gql`
mutation CreateEpisode($channel_id: Int!, $title: String!, $description: String!, $tags: String, $monetized: Boolean, $restricted: Boolean, $rating: String, $block_comments: Boolean, $block_likes: Boolean, $thumbnail: Upload, $content: Upload, $publish_date: Int){
    createEpisode(
        channel_id: $channel_id
        title: $title
        description: $description
        tags: $tags
        monetized: $monetized
        restricted: $restricted
        rating: $rating
        block_comments: $block_comments
        block_likes: $block_likes
        thumbnail: $thumbnail
        content: $content
        publish_date: $publish_date
  ) {
        id
        title
        tags
        description
        publishDate    
  }

}`;

export const PROFILE_MUTATION = gql`
mutation EditProfile($name: String, $birth: String, $city: Int, $state: Int, $profilePicture: Upload){
  editUser(
    name: $name
    birth: $birth
    city_id: $city
    state_id: $state
    profilePicture: $profilePicture
  ) {
    ${USER_TYPE}
  }

}`;
export const VERIFY_ACCESSTOKEN_MUTATION = gql`
    mutation verifyAccessToken(
        $accessToken: String!
    ){
        verifyAccessToken(accessToken: $accessToken){
            ${USER_TYPE}
        }
    }
`;

export const ASSOCIATE_NEO_USER_MUTATION = gql`
    mutation associateNeoUser(
        $subscriber_id: String!
    ){
        associateNeoUser(subscriber_id: $subscriber_id){
            ${USER_TYPE}
        }
    }
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation  resetPassword(
        $phone_or_email: String!
    ){
        resetPassword(phone_or_email: $phone_or_email)
    }
`;




export const SOCIAL_LOGIN_MUTATION = gql`
    mutation socialLogin(
            $provider: String!, 
            $access_token: String!
    ){
        socialLogin(
            provider: $provider,
            access_token: $access_token
        ){
            ${USER_TYPE}
        }
    }
`;


export const NOTIFY_PLAYBACK = gql`
    mutation notifyPlayback(
            $episode_id: Int!, 
            $playlist_id: Int, 
            $start: Int!, 
            $end: Int!,
            $source: String
        ){
            notifyPlayback(
                episode_id: $episode_id, 
                playlist_id: $playlist_id, 
                start: $start, 
                end: $end,
                source: $source
            )
        }
`;

export const REGISTER_SOCIAL_MUTATION = gql`
    mutation createUserBySocialProvider(
            $provider: String!, 
            $access_token: String!,
            $email: String!, 
            $password: String,
            $name: String!,
            $nickname: String!,
            $referrer: String
    ){
        createUserBySocialProvider(
            provider: $provider,
            access_token: $access_token,
            email: $email,
            password: $password,
            name: $name,
            nickname: $nickname,
            referrer: $referrer
        ){
            ${USER_TYPE}
        }
    }
`;
