import styled from "styled-components";

export const Container = styled.div`
    .resultadoBusca{

    }
    .feed{
        flex: 1;
        display: flex;
        margin-bottom: 19px;

    }
    .name-desc{
        button{
            width: 35%;
            height: 33px;
        }
        .btn{
            width: 35%;
            height: 33px;
        }
    }
    .img-feed{
        margin-right: 15px;
        display: flex;
        img{
            width:  100px; 
            height: 100px;
        }
    }

    input {
        margin-bottom: 19px;
    }
    .divImg{
        width: 100%;
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
`;

export const SocialContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 19px;
    align-items: center;
    justify-content: center;
    a:first-child {
        margin-left: 0;
        margin-right: auto;
    }
    a:last-child {
        margin-right: 0;
        margin-left: uto;
    }
    a {
        padding: 0px 10px;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;
        background: var(--color-zero);
        color: #2196F3;
        border: 1px solid #2196F3;
        border-radius: var(--radius-small);
        text-transform: uppercase;
        font-family: "Avenir";
        .icon {
            height: 35px;
            color: #2196F3;
        }
    }
`;
