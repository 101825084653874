import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--spacing-default);
    .trend-title {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 700;
    }
    @media (max-width: 992px) {
        h3 {
            font-size: 1.92rem;
        }
        padding: 10px;
    }
`;
