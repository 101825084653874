import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";
import { Times } from "styled-icons/fa-solid";

import { Container, ThumbConteiner } from "./Thumb.styles";

import ThumbDefault from "../../../../assets/img/thumbDefault.jpg";
import { sendMessageToUser } from '../../../../common';

import { ADD_TO_PLAYLIST_MUTATION } from '../../../../services/graphql/mutations';

import REMOVE_PLAYLIST from './Thumb.graphql';
import apolloClient from "../../../../services/apollo";
import { graphql, Query } from 'react-apollo';
import { compose } from 'recompose';
import RemovePlaylist from "../RemovePlaylist/RemovePlaylist";
import { redirectTo } from "@reach/router";

export class GetThumbChannel extends Component{
    render() {
        let {
            id,
            thumbnail
        } = this.props.episode;
        let size = this.props.size;
        return(
            <Container className={size&&`${size}`}>
                <a
                    className="thumb_image"
                    style={{
                    backgroundImage: `url(${thumbnail})`
                    }}
                >
                </a>
            </Container>
        )
    }
}

class Thumb extends Component {    
    onDelete = () => {
        let body = {
            id: this.props.indice
        }
        this.props.mutate({mutation: REMOVE_PLAYLIST, variables: body, errorPolicy: 'all'})
        .then(({register, errors}) => {
            if(errors){
                // console.log(errors)
            } else {
                sendMessageToUser("Playlist removida com sucesso");
                this.props.onRefresh();
            }
        })
    }
    addEpisodePlaylist = () => {
        // console.log("Episodio: ",this.props.props.episode);
        // console.log("playlist escolhida: ",this.props.indice);

        apolloClient.mutate({
            mutation: ADD_TO_PLAYLIST_MUTATION, 
            variables: {
                id: this.props.indice,
                episode_id: this.props.props.episode.id, 
            }
        })
        .then((data) =>  {
            // console.log("ADD PLAYLIST: ",data)
            this.props.onRefresh();
            sendMessageToUser("Episodio inserido na playlist corretamente !");
        });
        
    }
    render(){
        // console.log("LISTA: ",this.props);
        let { lista } = this.props;
        let channelsArray = [];
        let size = "episodeFull";
        let thumbs = [];
        if(this.props && this.props.props && this.props.props.scope){
            let scope = this.props.props.scope;
        }else{
            let scope = "";
        }
        if(lista){
            lista.map((list) => {
                if (channelsArray.length < 4) {
                    if(channelsArray.indexOf(list.episode) && list.episode.thumbnail ){
                        channelsArray = channelsArray.concat(list.episode)
                    }
                }
            });

            switch(channelsArray.length){
                case 2:
                    size="episodeLine"
                break;
                case 3:
                    size="episodeLine"
                break;
                case 4:
                    size=""
                break;
            }

            if(channelsArray.length < 1){
                if(!this.props.props || !this.props.props.scope || this.props.props.scope != 'addEpPlaylist'){
                    // console.log("Nao eh escopo de adicionar episodios => length < 1");
                    return <ThumbConteiner>
                        {/*
                        <Link className="close" onClick={() => this.onDelete()}>
                            <Times className="icon"/>
                        </Link>
                        */}
                        <a href={`#RemovePlaylist_${this.props.indice}`} className="modal-trigger close">
                            <Times className="icon"/>
                        </a>
                        <RemovePlaylist indice={this.props.indice} onRefresh={() => this.props.onRefresh()}/>
                        <GetThumbChannel key={0} 
                            episode={{
                                id: 0,
                                thumbnail: ThumbDefault
                            }} 
                            size={size} 
                            onClick={() => window.location.replace(`/playlists/${this.props.indice}`)}
                            />
                    </ThumbConteiner>
                }else{
                    // console.log("Eh escopo de adicionar episodios => length < 1");
                    return <ThumbConteiner onClick={() => this.addEpisodePlaylist()}>
                                <GetThumbChannel key={0} 
                                    episode={{
                                        id: 0,
                                        thumbnail: ThumbDefault
                                    }} 
                                    size={size} />
                            </ThumbConteiner>
                }
            }else{
                // console.log("Varios episodios na playlist  => length > 1");
                channelsArray.map((episode,index) => {
                    // console.log("EPISODE: ", episode)
                    if(channelsArray.length == 3 && index > 0) { size="" }
                    thumbs = thumbs.concat(<GetThumbChannel key={episode.id} episode={episode} size={size} />);
                });
            }

            if(!this.props.props || !this.props.props.scope || this.props.props.scope != 'addEpPlaylist'){
                // console.log("Nao eh escopo de adicionar episodios");
                return <ThumbConteiner>
                    <a href={`#RemovePlaylist_${this.props.indice}`} className="modal-trigger close">
                        <Times className="icon"/>
                    </a>
                    <RemovePlaylist indice={this.props.indice} onRefresh={() => this.props.onRefresh()}/>
                    <a className="redirectTo" onClick={() => window.location.replace(`/playlists/${this.props.indice}`)}>
                        {thumbs.map((thumb) => thumb)}
                    </a>
                </ThumbConteiner>
            }else{
                // console.log("Eh escopo de adicionar episodios: ",console.log(this.scope));
                return <ThumbConteiner onClick={() => this.addEpisodePlaylist()}>                  
                    {thumbs.map((thumb) => thumb)}
                </ThumbConteiner>
            }
        }
    } 
}
export default compose(graphql(REMOVE_PLAYLIST))(Thumb);
