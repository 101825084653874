import styled from "styled-components";
import ImgGrafico from "../../../src/assets/img/grafico.png";

export const Container = styled.section`
    .drag-and-drop {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 21px;
        border: 2px dashed #D1D6D9;
        align-items: center;
        justify-content: center;
        position: relative;

        .midiaupload{
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        span {
            margin-bottom: 10px;
        }
        button {
            background: #2196F3;
            border-radius: var(--radius-small);
            color: #fff;
            width: auto;
            height: 40px;
            margin-left: var(--radius-big);
            line-height: var(--radius-big);
            padding: var(--spacing-small) var(--spacing-default)
        }
        .comArquivo {
            strong {
                font-weight: 900;
            }
        }
    }
    .contentProgress {
        display: flex;
        flex: 1;
        height: 72px;
        background-color: #D1D6D9;
        border-radius: 3px;
        position: relative;
        .barProgress {
            display:flex;
            background-color: #6FCF97;
            border-radius: 3px;
        }
        .contentPorcent {
            font-size: 22px;
            color: #fff;
            position: absolute;
            top: 50%;
            width: 100%;
            height: 32px;
            margin-top: -16px;
            text-align: center;
        }
    }
    .grafico {
        flex: 1;
        height: 81px;
        background-color: var(--color-third-lighten);
        border-radius: 3px;
    }
`;
