import React, { Component } from "react";
//import { Query } from 'react-apollo';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';

//style
import { Link } from "react-router-dom";

//element
import { ModalAddFeed } from "../ModalAddFeed";
import { ACCESS_TOKEN } from '../../constants';

import { myChennelFollowingLists } from "./Navbar.graphql";

//icons
import { Home, Genero, Inscricoes, Premium, Favoritos, PlayList, ChevromDown, ChevromUp } from "../../assets/icons/"
import { Originais } from "../../assets/img/tabs/"

import { ReactComponent as LogoOla } from "../../assets/img/logo-ola-horizontal.svg";

import defaultImage from "../../assets/img/thumbDefault.jpg";

class NavBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      inscricao: false,
      userFeedChannels: []
    }
  }
  renderMyScription(){
    const {loading, userFeedChannelsFollowing, userChannelsFollowing} = this.props.data;
    let userFeedChannels = []
    if(userChannelsFollowing && userChannelsFollowing.data) {
      userChannelsFollowing.data.map(
        channel => {
          userFeedChannels.push(channel);
        }
      )
    }
    if(userFeedChannelsFollowing && userFeedChannelsFollowing.data){
      userFeedChannelsFollowing.data.map(
        channel => {
          userFeedChannels.push(channel);
        }
      )
    }
    return(
      <ul>
        <li className="menu-item item-title">
          <span>Inscrições</span>
        </li>
        { 
          userFeedChannels.map(
            (channel, index) => {
              return (
                <li key={`thumb-${channel.id}`} className={`menu-item ${index >= 5 && !this.state.inscricao ? 'ocultos': ''}`}>
                  {
                  Number.isInteger(channel.id) ?
                  <Link to={`/channels/${channel.codename}`}>
                    <img src={channel.thumbnail?channel.thumbnail:defaultImage} className="menu-icon"/>
                    <span>{channel.name}</span>
                  </Link>
                  :
                  <Link to={`/externalFeed/${channel.id}`}>
                    <img src={channel.thumbnail?channel.thumbnail:defaultImage} className="menu-icon"/>
                    <span>{channel.name}</span>
                  </Link>
                  }
                </li>
              )
            }
          )
        }
        {
          userFeedChannels.length >= 5 &&
          <li className={`menu-item`}>
              {!this.state.inscricao ?
                <Link onClick={() => this.setState({inscricao:!this.state.inscricao})}>
                  <ChevromDown className="menu-icon"/>
                  <span>Mostrar mais {userFeedChannels.length - 5}</span>
                </Link>
                  :
                <Link onClick={() => this.setState({inscricao:!this.state.inscricao})}>
                  <ChevromUp className="menu-icon"/>
                  <span>Mostrar menos {userFeedChannels.length - 5}</span>
                </Link>
              }
          </li>
        }
      </ul>
    )
  }
  render(){
    const {loading, myPlaylists, myChannels, userFeedChannelsFollowing, userChannelsFollowing} = this.props.data;
    const { pathname } = window.location;
    if (!!myChannels && myChannels.length > 0) {
      localStorage.setItem("MY_CHANNELS", JSON.stringify(myChannels));
    }
    //const [inscricao, setInscricao] = useState(0);
    return(
      <>
        <section className="navbar ola-menu">
          <article className="navbar_menu">
            <ul>
              <li className="menu-item--no_padding logo-ola">
                <Link to="/">
                  {/* <Logo classImage="menu-image" /> */}
                  <LogoOla classImage="logo-image" />
                </Link>
              </li>
              <li className={`menu-item ${pathname == '/' && 'active'}`}>
                <Link to="/">
                  <Home className="menu-icon" />
                  <span id="home_btn" >Home</span>
                </Link>
              </li>
              <li className={`menu-item ${pathname.indexOf('verticals') >-1 && 'active'}`}>
                <Link to="/verticals">
                  <Genero className="menu-icon" />
                  <span id="verticais_btn" >Verticais</span>
                </Link>
              </li>

              {/* <li className={`menu-item ${pathname.indexOf('em-alta') >-1 && 'active'}`}>
                <Link to="/em-alta">
                  <TrendingUp className="menu-icon" />
                  <span>Em Alta</span>
                </Link>
              </li> */}
              <li className={`menu-item ${pathname.indexOf('registrations') >-1 && 'active'}`}>
                <Link to="/registrations">
                  <Inscricoes className="menu-icon" />
                  <span id="inscricao_btn" >Inscrição</span>
                </Link>
              </li>
              {/* <li className={`menu-item ${pathname.indexOf('premium') >-1 && 'active'}`}>
                <Link to="/premium">
                  <Premium className="menu-icon" />
                  <span>Premium</span>
                </Link>
              </li> */}
              <li className={`menu-item ${pathname.indexOf('originais') >-1 && 'active'}`}>
                <Link to="/originais">
                  <Originais className="menu-icon" style={{marginLeft: "13px", marginRight: "13px"}} />
                  <span id="original_btn" >Originais</span>
                </Link>
              </li>
              <li className={`menu-item ${pathname.indexOf('playlist/favorites') >-1 && 'active'}`}>
                <Link to="/playlist/favorites">
                  <Favoritos className="menu-icon" />
                  <span id="favoritos_btn" >Favoritos</span>
                </Link>
              </li>
              {/* 
              {localStorage.getItem(ACCESS_TOKEN) && 
                <li className={`menu-item ${pathname.indexOf('externalFeed/add') >-1 && 'active'}`}>
                  <Link to="#" data-target="addFeed" className="modal-trigger"  onClick={() => {
                  let event = new CustomEvent('modal-addFeed', { "detail": "add-feed"});
                  document.dispatchEvent(event);
                  }}>
                    <Rss className="menu-icon" />
                    <span>Agregar Canal</span>
                  </Link>
                </li>
              }
              */}
            </ul>

            {localStorage.getItem(ACCESS_TOKEN) && !loading &&
              <ul>
                <li className="menu-item item-title">
                  <span>Meus Canais</span>
                </li>
                {
                  myChannels && myChannels.length &&
                  myChannels.map(
                    (channel) => {
                      return (
                        <li key={`thumb-${channel.id}`} className="menu-item">
                          <Link to={`/channeloption/${channel.id}`}>
                            <img src={channel.thumbnail?channel.thumbnail:defaultImage} className="menu-icon"/>
                            <span>{channel.name}</span>
                          </Link>
                        </li>
                      )
                    }
                  )
                }
              </ul>
            }
            {
              // INSCRICOES
              userChannelsFollowing && userChannelsFollowing.data &&
              this.renderMyScription(userFeedChannelsFollowing, userChannelsFollowing)
            }


            {
              localStorage.getItem(ACCESS_TOKEN) && 
              !loading &&
              myPlaylists && myPlaylists.data &&
              <ul>
                <li className="menu-item item-title">
                  <span>Minhas Playlists</span>
                </li>
                {
                  myPlaylists.data.map(
                    (playList) => {
                      return (
                        <li key={`thumb-${playList.id}`} className="menu-item">
                          <Link to={`/playlists/${playList.id}`}>
                            <PlayList className="menu-icon"/>
                            <span>{playList.name}</span>
                          </Link>
                        </li>
                      )
                    }
                  )
                }
              </ul>
            }
          </article>
        </section>
        <ModalAddFeed />
      </>
    )
  }
};

export default compose(graphql(myChennelFollowingLists))(NavBar);
//export default NavBar;
