import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

//external libs
import Sound from "react-sound";

import NerdcastImg from "../../assets/img/nerdcast.png";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  loadTrack: null,
  playTrack: null,
  pauseTrack: null,
  setPosition: ["position"],
  volumePositionHandler: ["volumePosition"],
  setMute: ["mute"],
  setEpisode: ["episode"],
  setPlaylist: ["playlist"]
});

export const PlayerTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  position: 16,
  episode: {
    id: 1,
    title: "Empreendedor 56 - Empreendendo com chocolate nas veias",
    thumbnail: NerdcastImg,
    content: 'https://s3-sa-east-1.amazonaws.com/gonative/1.mp3',
    duration: 184,
    channel: {
      name: "NerdCast",
    },
  },
  playlist: [
    {
      id: 1,
      title: "Empreendedor 56 - Empreendendo com chocolate nas veias",
      thumbnail: NerdcastImg,
      content: 'https://s3-sa-east-1.amazonaws.com/gonative/1.mp3',
      duration: 184,
      channel: {
        name: "NerdCast",
      },
    },
    {
      id: 2,
      title: "Faixa 2",
      thumbnail: NerdcastImg,
      content: 'https://s3-sa-east-1.amazonaws.com/gonative/2.mp3',
      duration: 155,
      channel: {
        name: "Canal 4",
      },
    },
    {
      id: 3,
      title: "Faixa 3",
      thumbnail: NerdcastImg,
      content: 'https://s3-sa-east-1.amazonaws.com/gonative/3.mp3',
      duration: 203,
      channel: {
        name: "Canal 3",
      },
    }
  ],
  statusSong: Sound.status.PAUSED,
  volumePosition: 100,
  mute: false
});

/* Reducers */
export const getSuccess = state =>
  state.merge({
    ...state,
    currentTrack: "https://s3-sa-east-1.amazonaws.com/gonative/1.mp3",
    statusSong: Sound.status.PLAYING
  });
export const playTrackSuccess = state =>
  state.merge({
    ...state,
    statusSong: Sound.status.PLAYING
  });

export const pauseTrackSuccess = state =>
  state.merge({
    ...state,
    statusSong: Sound.status.PAUSED
  });

export const setPositionSuccess = (state, action) =>
  state.merge({
    ...state,
    position: action.position,
  });

export const volumePositionHandlerSuccess = (state, action) =>
  state.merge({
    ...state,
    volumePosition: action.volumePosition,
    mute: false
  });

export const setMuteSuccess = (state, action) =>
  state.merge({
    ...state,
    mute: action.mute
  });

export const setEpisodeSuccess = (state, action) =>
  state.merge({
    ...state,
    episode: action.episode
  });

export const setPlaylistSuccess = (state, action) =>
  state.merge({
    ...state,
    playlist: action.playlist
  });

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOAD_TRACK]: getSuccess,
  [Types.PLAY_TRACK]: playTrackSuccess,
  [Types.PAUSE_TRACK]: pauseTrackSuccess,
  [Types.SET_POSITION]: setPositionSuccess,
  [Types.VOLUME_POSITION_HANDLER]: volumePositionHandlerSuccess,
  [Types.SET_MUTE]: setMuteSuccess,
  [Types.SET_EPISODE]: setEpisodeSuccess,
  [Types.SET_PLAYLIST]: setPlaylistSuccess
});
