import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  width: 100%;
  @media (max-width: 992px) {
  }
`;
export const ControlerScreen = styled.section`
  width: 100%;
  display: flex;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
