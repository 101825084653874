import React, { Component } from "react";
import { sendMessageToUser } from '../common';
//external libs
import Slider, {Range} from "rc-slider";
import Sound from "react-sound";
import { ACCESS_TOKEN, USER_ACCOUNT } from '../constants';
import { Link } from "react-router-dom";
import M from 'materialize-css';

import {
  PlayArrow,
  Pause,
  FastForward,
  FastRewind,
  Forward30,
  Replay30,
  Subject,
  ThumbUpAlt,
  ThumbDown,
  Star,
  ChatBubble,
  Share
} from "styled-icons/material";

import DropDownShare from './DropDownShare';
import { Mutation } from 'react-apollo';
import { LIKE_UNLIKE_MUTATION, DISLIKE_UNDISLIKE_MUTATION, FAVORITE_EPISODE_MUTATION, UNFAVORITE_EPISODE_MUTATION } from '../services/graphql/mutations';

const PLAYER_UPDATE = new Event('playerupdate');

export default class PlayerComponent extends Component {
  
   constructor(props){
    super(props);
    this.state = {
      firstPlay: true,
      isPlaying: false,
      liked: false,
      favirited: false,
      duration: 0,
      episode: props.episode,
      currentPosition: 0,
      currentTime: `00:00`,
      currentTimeOriginal: 0,
      logado: false,
      assinado: false,
      favoritado: false,
      temProximo: false,
      temAnterior: false,
      proximo: false,
      anterior: false,
    };
    

   
    if(props.episode){
      this.audioFile = new Audio(props.episode.content);
      this.audioFile.addEventListener('loadeddata', () => {
        this.setState({ duration: this.audioFile.duration })
      })
      this.state = { ...this.state, duration: this.audioFile.duration }

      if(document.player.getCurrent() && (document.player.getCurrent().id == this.state.episode.id) && (!this.state.isPlaying && document.player.getIsPlaying()) ){
        this.state.isPlaying = true;
      }


      if (this.props.episode.favorited) this.state.favoritado = this.props.episode.favorited;

    } else if(!!document.player && !!document.player.getCurrent()) {
      this.state = { ...this.state, duration: document.player.getCurrentDuration() }
    }
    if(props.episode) {
        document.player.setProgress(this.getCurrentTime);
    }
      
      if(localStorage.getItem(ACCESS_TOKEN)){
        this.state.logado = true;
      }
      let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
      if(userAccount && userAccount.subscribed){
        this.state.assinado = userAccount.subscribed;
      }

  }

  renderDuration(inicio,fim){
    // let duration = new Date(1000 * seconds).toISOString().substr(11, 8).replace(/^[0:]*/g,'');
    let secondsRaw = fim - inicio;
    let minutes = parseInt(secondsRaw/60);
    let seconds = Math.round(secondsRaw%60);
    let minutesRender = minutes < 10 ? `0${minutes}` : minutes;
    let secondsRender = seconds < 10 ? `0${seconds}` : seconds;
    return(
        `- ${minutesRender}:${secondsRender}`
      );
  }
  
  togglePlay = () => {

    if(this.state.isPlaying){
      document.player.pause();
      this.setState({isPlaying:false})
    } else if(this.state.firstPlay && this.state.episode && !!this.state.episode.content){
      if(document.player.getIsPlaying()){
        document.player.pause();
      }
      let trocouEp = !!this.state.episode && document.player.current && this.state.episode.id != document.player.current.id ? true : false;

      document.player.setCurrent(this.state.episode)
      // console.log("EH NAO PAREI NOS 18")
      console.log("TroqueiEP: ",trocouEp);
      console.log(document.player);
      if(trocouEp){
        document.player.setCurrentProgress(0);
        document.player.setCurrentAudioTime(0);
        document.player.setInitialTime(0);
        document.player.setProgress(0);
        document.player.updatePlayer();
      }
      document.player.play();

        this.setState({firstPlay:false})
    }else if(!!this.state.episode.content){     
      document.player.play();
    }
    if(!!this.state.episode.content) this.setState({isPlaying: !this.state.isPlaying})
  }

  next = () => {
    if(this.state.isPlaying){
      document.player.pause();
    }
    if(this.props.next) this.props.next();
    if(document.player && document.player.next) document.player.next();
  }

  prev = () => {
    if(this.props.prev) this.props.prev()
    if(document.player && document.player.prev) document.player.prev();
  }

  avancar = () => { 
    if(!!document.player && !!document.audio.duration){ 
      // console.log("Tempo: ",document.player.getCurrentTime());
      // console.log("duracao: ",document.audio.duration);
      
      let novoTempo = (document.player.getCurrentTime() + 30) / document.audio.duration;
      document.player.setCurrentProgress(novoTempo);
      if(!this.state.isPlaying){
        document.player.play();
        this.setState({isPlaying:true})
      }

    }
  }
  retroceder = () => { 
    if(document.player){ 
      // console.log("Tempo: ",document.player.getCurrentTime());
      let novoTempo = (document.player.getCurrentTime() - 30) / document.audio.duration;
      document.player.setCurrentProgress(novoTempo);
      if(!this.state.isPlaying){
        document.player.play();
        this.setState({isPlaying:true})
      }
    }
  }

  abreModalLogin = () => {
    var elems = document.getElementById('signup');
    // console.log("Elems: ",elems);
    var instances = M.Modal.init(elems, {
        onOpenStart: function () {
            var ev = new CustomEvent('modal-entrar', { "detail": "login"});
            document.dispatchEvent(ev);
      }
    });
    var instance = M.Modal.getInstance(elems);
    instance.open();
}

componentWillReceiveProps(){
  // console.log("Recebendo novas props;", this.props.episode);
  // console.log("Recebendo novas estad;", this.state.episode);
  if(this.state.episode != this.props.episode){
    this.setState({
      episode: this.props.episode,
      isPlaying: false,
      currentPosition: 0,
      currentTime: `00:00`,
      currentTimeOriginal: 0,
    });
  }
  this.getQueuePosition(this.props.episode);

}
componentDidMount(){
  // console.log("Montando pagina: ", this.state.episode);
  this.getQueuePosition(this.state.episode);
}



  getQueuePosition = (episode) => {
    let queue = document.player.getQueue();
    // console.log("Queue: ",queue)
    if(queue && queue.length){
        let positionQueue = document.player.findEpisodeOnQueue(episode);

        if(positionQueue && positionQueue.index >= 0){
          //esta na fila:
          // console.log("Posicao: ",positionQueue.index);
          if(!!queue[positionQueue.index - 1]){
            // console.log("TEM ANTERIOR ");
            this.setState({temAnterior: true, anterior: queue[positionQueue.index - 1].id});
          }
          if(!!queue[positionQueue.index + 1]){
            // console.log("TEM PROXIMO ");
            this.setState({temProximo: true, proximo: queue[positionQueue.index + 1].id});
          }          
        }else{
          //n esta na queue entao tem apenas proximo
          // console.log("TEM PROXIMO 222");
          this.setState({temProximo: true, proximo: queue[0].id});
        }
    }
  }

  play = () => {
    if(this.props.play) this.props.play()
  }

  favorite = () => {
    if(this.props.favorite) this.props.favorite()
  }

  like = () => {
    if(this.props.like) this.props.like()
  }

  dislike = () => {
    if(this.props.dislike) this.props.dislike()
  }

  comment = () => {
    if(this.props.comment) this.props.comment()
  }

  getDuration = () => {
    if(this.props.episode && this.audioFile){
      this.setState({duration: this.props.episode.duration})
    }else if(!!document.player && !!document.player.getCurrent()) {
      this.setState({duration: document.player.getDuration()})
    }
  }

  getCurrentTime = () => {
    if(this.state.episode && document.audio && this.state.episode.content == document.audio.originalSource ){
      
      this.setState({currentTime: document.player.renderSecondsInTime(document.player.getCurrentTime() ), currentPosition: document.player.getCurrentProgress()*1000 })
    }else{
      this.setState({currentTime: '00:00'})
    }
  }

  changingSlider = (value) => {
    document.player.setCurrentProgress(value/1000);
    this.setState({currentPosition: value})
    // // console.log("SetInitialTime: ", document.audio.currentTime);
    document.player.setInitialTime(document.audio.currentTime);
  }

  setmutation = (postMutation) => {
    alert("Mutacao monstro");
    return () => postMutation;
  }


  render() {
    return (
      <div className="episode-player">
        <div className="episode-player_controls">
          <button>
            {this.state.temAnterior ?
              <a href={`/episodes/${this.state.anterior}`}> 
                  <FastRewind className="episode-player_icon"/>
              </a>
            :
              <FastRewind className="episode-player_icon disabled" />
            }
          </button>
          {!!document.audio && !!document.audio.duration ? 
            <button><Replay30 className="episode-player_icon"  onClick={() => this.retroceder() }/></button>
            :
            <button><Replay30 className="episode-player_icon disabled" /></button>
          }

          {!this.state.isPlaying ? (
            <button>
              <PlayArrow 
                className="episode-player_icon play-pause" 
                onClick={() => this.togglePlay() }
              />
            </button>
          ) : (
            <button>
              <Pause 
                className="episode-player_icon play-pause" 
                onClick={() => this.togglePlay() }
              />
            </button>
          )}
          {!!document.audio && !!document.audio.duration ? 
            <button ><Forward30 className="episode-player_icon"  onClick={() => this.avancar() }/></button>
            :
            <button ><Forward30 className="episode-player_icon disabled" /></button>
          }
          <button >
           {this.state.temProximo ?
              <a href={`/episodes/${this.state.proximo}`}> 
                  <FastForward className="episode-player_icon"/>
              </a>
            :
              <FastForward className="episode-player_icon disabled" />
            }
          </button>
        </div>
        {window.navigator.userAgent.match(/iPhoneXXXXXXXXX/i) || window.navigator.userAgent.match(/iPadXXXXXXXX/i)  ?
          <div></div>
        :
          <div className="episode-player_slider">
            <Slider
              railStyle={{ background: "#ECF0F1", boderRadius: 10 }}
              trackStyle={{ background: "#2196f3" }}
              handleStyle={{ background: "#2196f3", border: "#2196f3" }}
              volume={50}
              value={this.state.currentPosition}
              max={1000}
              onChange={this.changingSlider}
            />
            <div className="episode-player_time">
              {/* <div className="current-time">{this.state.currentTime}</div>
              <div className="duration">{this.props.episode && this.audioFile ? document.player.renderSecondsInTime(this.audioFile.duration) : document.player.getCurrentDurationRendered() }</div> */}
                  <div className="current-time">{this.state.currentTime}</div>
                  {/* <div className="duration">{  document.player.getCurrentDurationRendered() || this.renderDuration(this.state.episode.duration) }</div> */}
                  <div id="duration_pgepisode" className="duration">{ this.state.episode ? this.renderDuration(this.state.currentTimeOriginal , this.state.episode.duration) : "" }</div>
            </div>
          </div>
        }

        <div className="episode-player_actions">
          {this.props.scope != 'episode' && <div className="button-wrapper">
            <button className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
              <Subject className="icon" />
            </button>
            <span className="episode-player_text hide-on-med-and-down"> playlist</span>
          </div>}
          
          { this.props.scope == 'episode' &&
            !this.state.favoritado ?
                <Mutation mutation={FAVORITE_EPISODE_MUTATION} 
                    variables={{
                      episode_id: this.props.episode.id, 
                      type: 'favorites'
                    }}
                  >
                    {
                      postMutation => (
                        
                          (this.state.logado && (!this.props.episode.premium || this.state.assinado)) ?
                            <div id="favoritar_btn" className="button-wrapper">
                              <button onClick={() => { postMutation(); this.setState({favoritado: true}) }} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                                <Star className="icon" />
                              </button>
                              <span id="favoritar_spn" className="episode-player_text hide-on-med-and-down"> favoritar</span>
                            </div>
                          : !this.state.logado ?
                            <div id="favoritar_btn" className="button-wrapper">
                              <button onClick={() => {sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.'); this.abreModalLogin()}} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                                <Star className="icon" />
                              </button>
                              <span id="favoritar_spn" className="episode-player_text hide-on-med-and-down"> favoritar</span>
                            </div>

                          :
                          <div id="favoritar_btn" className="button-wrapper">
                            <button onClick={() => sendMessageToUser('Conteúdo exclusivo para assinantes! Seja premium agora!')} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                              <Star className="icon" />
                            </button>
                            <span id="favoritar_spn" className="episode-player_text hide-on-med-and-down"> favoritar</span>
                          </div>

                        )
                    }
                </Mutation>
            :
                <Mutation mutation={UNFAVORITE_EPISODE_MUTATION} 
                    variables={{
                      episode_id: this.props.episode.id, 
                      type: 'favorites'
                    }}
                  >
                    {
                      postMutation => (
                        
                        (this.state.logado && (!this.props.episode.premium || this.state.assinado)) ?
                          <div id="favoritar_btn" className="button-wrapper">
                            <button onClick={() => { postMutation(); this.setState({favoritado: false}) }} className="btn-floating  waves-effect waves-light green lighten-4 player-actions_button">
                              <Star className="icon" />
                            </button>
                            <span id="desfavoritar_spn" className="episode-player_text hide-on-med-and-down"> desfavoritar</span>
                          </div>
                        :
                        <div id="favoritar_btn" className="button-wrapper">
                          <button onClick={() => sendMessageToUser('Conteúdo exclusivo para assinantes! Seja premium agora!')} className="btn-floating  waves-effect waves-light green lighten-4 player-actions_button">
                            <Star className="icon" />
                          </button>
                          <span id="desfavoritar_spn" className="episode-player_text hide-on-med-and-down"> desfavoritar</span>
                        </div>

                      )
                    }
                </Mutation>

          }
          <div id="like_btn" className="button-wrapper">

            <Mutation mutation={LIKE_UNLIKE_MUTATION} 
              variables={{
                id: this.props.episode.id, 
                unlike: this.props.episode.userLike
              }}
            >
              {
                postMutation => (
                  
                  this.props.episode.userLike?
                    <button onClick={postMutation} className="btn-floating  waves-effect waves-light green lighten-4 player-actions_button">
                      <ThumbUpAlt className="icon" />
                    </button>
                  :
                      (this.state.logado && (!this.props.episode.premium || this.state.assinado)) ?
                        <button onClick={postMutation} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                          <ThumbUpAlt className="icon" />
                        </button>
                    : !this.state.logado ?
                        <button onClick={() => {sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.'); this.abreModalLogin()}} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                          <ThumbUpAlt className="icon" />
                        </button>
                    :
                        <button onClick={() => sendMessageToUser('Conteúdo exclusivo para assinantes! Seja premium agora!')} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                          <ThumbUpAlt className="icon" />
                        </button>
                  )
              }
            
            </Mutation>


            <span id="like_spn" className="episode-player_text hide-on-med-and-down"> {!!this.props.likes_count && this.props.likes_count} like{this.props.likes_count > 1 && 's'}</span>
          </div>

          { this.props.scope != 'episode' && <div className="button-wrapper">
            <button className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
              <Star className="icon" />
            </button>
              <span className="episode-player_text hide-on-med-and-down"> favoritar</span>
          </div>}

          <div id="dislike_btn" className="button-wrapper">
          <Mutation mutation={DISLIKE_UNDISLIKE_MUTATION} 
              variables={{
                id: this.props.episode.id, 
                undislike: this.props.episode.userDislike
              }}
            >
              {
                postMutation => (
                  this.props.episode.userDislike?
                    <button onClick={postMutation} className="btn-floating  waves-effect waves-light red lighten-4 player-actions_button">
                      <ThumbDown className="icon" />
                    </button>
                  :
                    (this.state.logado && (!this.props.episode.premium || this.state.assinado)) ?
                      <button onClick={postMutation} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                        <ThumbDown className="icon" />
                      </button>
                      : !this.state.logado ?
                        <button onClick={() => {sendMessageToUser('Para acessar este conteúdo, faça seu login ou cadastre-se.'); this.abreModalLogin()}} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                        <ThumbDown className="icon" />
                        </button>
                      :
                        <button onClick={() => sendMessageToUser('Conteúdo exclusivo para assinantes! Seja premium agora!')} className="btn-floating  waves-effect waves-light grey lighten-4 player-actions_button">
                          <ThumbDown className="icon" />
                      </button>
                  )
              }
            
            </Mutation>
            <span id="dislike_spn" className='hide-on-med-and-down'> {!!this.props.dislikes_count && this.props.dislikes_count} dislike{this.props.dislikes_count > 1 && 's'}</span>
          </div>
          <div id="share_pgepisode_btn" className="button-wrapper">
                <DropDownShare episode={this.props.episode}/>
          </div>
        </div>
      </div>
    );
  }
}
