import React, { Component } from "react";

import M from "materialize-css";
import { AngleLeft, AngleRight } from "styled-icons/fa-solid";

//component
import EpisodeCard from '../../../../components/EpisodeCardComponent';

import {
    Container,
} from "./CarroselPremium.style";

export default class CarroselPremium extends Component {
    componentDidMount(){
        let premiumElem = document.querySelectorAll('.premium-carrosel');
        this.instancePremium = M.Carousel.init(premiumElem,{
            dist: 0,
            shift: 0,
            padding: 0,
            numVisible: 5,
            fullWidth: true,
            indicators: false,
            noWrap: false,
            onCycleTo: null
        });
        // console.log("PREMIUM: ",premiumElem)
    }
    render() {
        const next = () => {
            this.instancePremium[0].next(1);
        }
        const prev = () => {
            this.instancePremium[0].prev(1);
        }
        return(
            <Container>
                <a className="premium-pagination"
                    onClick={() => prev()}
                >
                <AngleLeft className="paginationIcon" />
                </a>
                <div className="premium-carrosel carousel">
                    {
                        this.props.episodes.map(
                            episode => {
                                // console.log("PREMIUM-CARROCEL: ", episode)
                                return(
                                    <div key={`main-premium-${episode.id}`} className="content-colon carousel-item">
                                        <EpisodeCard episode={episode} />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <a className="premium-pagination"
                    onClick={() => next()}
                >
                    <AngleRight className="paginationIcon" />
                </a>
            </Container>
        )
    }
}
