import React, { Component } from "react";
import { Link } from "react-router-dom";
//icons
import {
    Facebook,
    Google
} from "styled-icons/boxicons-logos";
import { Phone } from "styled-icons/material";


//styles
import { Container, SocialContent } from "./Register.style.js";

//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from "../../../elements/ButtonElement";
import { sendMessageToUser } from '../../../common';
import ImgCadastro from "../../../assets/img/img_cadastro.png";

//elements
import { Title, Text } from "../../../elements/Typography";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import GoogleLogin from 'react-google-login';


class Register extends Component {
    state = {
        phone_or_email: '',
        jaEntrou: false
    }

    componentWillReceiveProps(props){
        if(!!props.state && props.state.emailToken && !this.state.jaEntrou){
            this.setState({phone_or_email: props.state.emailToken});
            this.updateInput({phone_or_email: props.state.emailToken})
            this.setState({jaEntrou: true});
        }
    }

    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }

    updateInput(input){
        this.props.updateState(input)
    }

    _responseFacebookRegister = (response) => {
        // console.log("RESP FACE REGISTER", response);
        if (response.accessToken) {

            // this.props.setModalScreen("login-password");
            this.props.setModalScreen("finalize-registration");


            this.updateInput({phone_or_email: response.email});
            this.updateInput({name: response.name});
            this.updateInput({nickname: response.email.substring(0, response.email.lastIndexOf("@"))});
            this.updateInput({provider: "facebook"});
            this.updateInput({access_token: response.accessToken});

            this.props.submitForm();

            //this.updateInput({phone_or_email: response.email});
            //localStorage.social_provider = 'facebook';
            //localStorage.social_token = response;    

            // this.props.setModalScreen("finalize-registration");
       
        }            
    }

    _responseGoogle = (response) => {
        // console.log("Resp Google Register: ",response);
        if (response.accessToken) {

            // this.props.setModalScreen("login-password");
            this.props.setModalScreen("finalize-registration");

            this.updateInput({phone_or_email: response.profileObj.email});
            this.updateInput({name: response.profileObj.name});
            this.updateInput({nickname: response.profileObj.email.substring(0, response.profileObj.email.lastIndexOf("@"))});
            this.updateInput({provider: "google"});
            this.updateInput({access_token: response.accessToken}); 

            this.props.submitForm();
            
            //this.updateInput({phone_or_email: response.profileObj.email});
            //localStorage.social_provider = 'google';
            //localStorage.social_token = response;    
            //this.props.setModalScreen("finalize-registration");
       
        }            
    }
    // handleKeyPress = (event) => {
    //     if(event.key === 'Enter'){
    //         this.submitForm()
    //     }
    // }

    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        let { phone_or_email} = this.state
        if(phone_or_email.length && phone_or_email.indexOf('@') > -0 && phone_or_email.indexOf('.') > 0 ){
            this._setModalScreen("finalize-registration")
        } else if(phone_or_email.length) {
            sendMessageToUser('Você precisa digitar um e-mail válido para continuar!')
        } else {
            sendMessageToUser('Você precisa digitar um e-mail!')
        }
    }

    render(){
        return(
            <Container>
                {/* <Title size="1" content="Ola, todos seus podcasts num só lugar. E muito mais." typeClass="auth-title" /> */}
                <img src={ImgCadastro} alt="logo ola" className="imgRegister"/>
                <Title size="1" content="Cadastre-se já!" typeClass="auth-title" />
                <h5 typeClass="auth-title" style={{textAlign:"center"}}>
                    {/* {
                        !this.props.state.referrer ? "Preencha seu cadastro e ouça todos os podcasts por 15 dias grátis."
                        : "Preencha seu cadastro e ouça todos os podcasts por 30 dias grátis."
                    } */}
                    Aproveite todas as funcionalidades da OLA Podcasts


                    
                </h5>
                <form onSubmit={this.onSubmit}>
                    <Input
                        id="phone_or_email_register"
                        name="phone_or_email"
                        type="email"
                        className="auth-button"
                        labelContent=""
                        width="100%"
                        labelClasses=""
                        placeholder="seu@email.com.br"
                        borderRadius="3px"
                        background="var(--color-third)"
                        border="1px solid var(--color-third)"
                        value={this.state.phone_or_email}
                        onChange={ (event) => { this.setState({phone_or_email: event.target.value }); this.updateInput({phone_or_email: event.target.value}) } }
                    />
                    <Button
                        type="submit"
                        className="auth-button"
                        Background="var(--color-first)"
                        color="var(--color-zero)"
                        //onClick={() => this.submitForm() }
                    >
                        Cadastre-se
                    </Button>
                </form>
                
                {/* <hr/> */}
                {/* <div className="positionOr">
                    <span>ou</span>
                </div> */}
                {/*<SocialContent>
                    <Link to="#"><Facebook className="icon" /> Entrar com facebook</Link>
                    <Link to="#"><Google className="icon" />Entrar com google</Link>
                </SocialContent>*/}


                <SocialContent>
                    {
                    !this.props.state.lockEmail &&
                    <FacebookLogin
                        appId="938777479814882"
                        fields="name,email"
                        isMobile={true}
                        cookie={true}
                        xfbml={true}
                        autoLoad={false}
                        disableMobileRedirect={true}
                        // callback={this._responseFacebookRegister}
                        callback={(resp) => this._responseFacebookRegister(resp)}
                        render={renderProps => (
                            <React.Fragment>
                                <Link to="#" onClick={renderProps.onClick} >
                                <span><Facebook className="icon" /></span>
                                <span>Entrar com facebook</span>
                                </Link>
                            </React.Fragment>
                            )}                            
                    />
                    }
                    {
                    !this.props.state.lockEmail &&
                    <GoogleLogin
                        clientId="357482474055-56mparnm6hjpomb5qf2caf46cgejqoqe.apps.googleusercontent.com"
                        onSuccess={this._responseGoogle}
                        onFailure={this._responseGoogle}
                        render={renderProps => (
                            <React.Fragment>
                                <Link to="#" onClick={renderProps.onClick} >
                                <span><Google className="icon" /></span>
                                <span>Entrar com google</span>
                                </Link>
                            </React.Fragment>
                            )}
                    />
                    }
                </SocialContent>

                {/* <Button
                    type="submit"
                    className="auth-button"
                    Background="var(--color-first)"
                    color="var(--color-zero)"
                    onClick={() => this._setModalScreen("telephone-operator")}
                >
                    <Phone className="icon"/> Entrar via operadora
                </Button> */}

                {
                !this.props.state.lockEmail &&
                <div className="auth-login">
                    Já tem conta?
                    <Link
                        type="submit"
                        to="/signin"
                        className="login-button"
                        color="var(--color-first)"
                        onClick={(event) => {
                            event.preventDefault();
                            this._setModalScreen("login-password")
                        } }
                    >
                        Faça Login
                    </Link>
                </div>
                }
            </Container>
        )
    }
}

export default Register;
