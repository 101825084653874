import React, { Component } from "react";

//style
import { Container } from "./ChangePassword.style";
import { Link } from "react-router-dom";

//element
import { List } from "../../elements/List";
import { Logo } from "../../elements/Image";
import { Input } from "../../elements/Input";
import { Button, StyledLink } from "../../elements/CTA";
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import Thumb from "../../assets/img/op.jpg";
import { renderComponent } from "recompose";
import {CHANGE_PASSWORD_MUTATION} from './changepassword.graphql';
import { sendMessageToUser } from '../../common';


class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state = {

            password: '',
            newPassword: '',
            confirmPassword: '',

        }
    }
  render(){
    return(
    <Container  >
        <div className="title">
            Segurança
        </div>
       
        <div className="formulario">
            Alterar sua senha
 

            <Input
                    type="password"
                    placeholder="SENHA ATUAL"
                    inputClasses="password"
                    id="password"
                    name="password"
                    font-size="11.5px"
                    font-color="#000"
                    background="#EEEEEE"
                    border="1px solid var(--color-third)"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                />
                
               <Input
                    type="password"
                    placeholder="NOVA SENHA"
                    id="newPassword"
                    name="newPassword"
                    font-size="11.5px"
                    font-color="#000"
                    background="#EEEEEE"
                    border="1px solid var(--color-third)"
                    value={this.state.newPassword}
                    onChange={e => this.setState({ newPassword: e.target.value })}
                />
                
                <Input
                    type="password"
                    placeholder="CONFIRMAR SENHA"
                    id="confirmPassword"
                    name="confirmPassword"
                    font-size="11.5px"
                    font-color="#000"
                    background="#EEEEEE"
                    border="1px solid var(--color-third)"
                    value={this.state.confirmPassword}
                    onChange={e => this.setState({ confirmPassword: e.target.value })}
                />
              <Button
                  type="submit"
                  className="auth-button"
                  borderRadius="3px"
                  onClick={async () => {
                    
                    if(this.state.newPassword === undefined || this.state.newPassword.length < 8 || !this.state.newPassword.match(/[a-z]/i) || !this.state.newPassword.match(/[0-9]/i) ){
                        return sendMessageToUser('Sua nova senha precisa ter, no mínimo, 8 caracteres e conter letras e números');
                    }else if(this.state.password === undefined || this.state.password.length < 8 || !this.state.password.match(/[a-z]/i) || !this.state.password.match(/[0-9]/i)){
                        return sendMessageToUser('Sua senha atual tem, no mínimo, 8 caracteres e contem letras e números');
                    }else if(this.state.newPassword != this.state.confirmPassword){
                        return sendMessageToUser("A nova senha tem que ser igual nos 2 campos.");
                    }else if(this.state.password == this.state.confirmPassword){
                        return sendMessageToUser("A nova senha tem que ser diferente da antiga.");
                    }else{
                        let changePassword = {current_password: this.state.password, new_password: this.state.newPassword}
                        let changePasswordMutation = await this.props.mutate({variables: changePassword, errorPolicy: 'all'}).then(({changed, errors}) => {
                            if(errors){
                                if(errors[0].message == "Unauthorized"){
                                    sendMessageToUser("Desculpe, senha incorreta !");
                                
                                }else{
                                    sendMessageToUser("Ocorreu um erro ao tentar mudar sua senha, por favor tente mais tarde.");
                                    // console.log("Erro ao mudar password: ",errors);
                                }
                            } else {
                                sendMessageToUser("Senha Atualizada com sucesso");
                            }

                        })    
                    }
                  }}
              >
                    ALTERAR
                </Button> 
        </div>

     
    </Container>
  )
    }
}

export default compose(graphql(CHANGE_PASSWORD_MUTATION))(ChangePassword);

