import gql from "graphql-tag";
const CategoryFollowMutation = gql`
mutation($id: Int!, $unfollow: Boolean){
  followChannel(channel_id: $id, unfollow: $unfollow){
    id
    name
    codename
    description
    following
  }
}
`;
export default CategoryFollowMutation;
