import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getTracksRequest: null,
  getTracksSuccess: ["data"]
});

export const TracksTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  currentTrack: null
});

/* Reducers */

export const getSuccess = (state, { data }) => state.merge({ data });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TRACKS_SUCCESS]: getSuccess
});
