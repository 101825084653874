import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ACCESS_TOKEN } from '../../../constants';
import M from 'materialize-css';
import { useQuery } from '@apollo/react-hooks';
import { Mutation, withApollo } from 'react-apollo';
import {
    Container
  } from "./AppSejaPremium.styles";


import { Query, graphql } from 'react-apollo';
import { compose } from 'recompose';
import { PROFILE_QUERY } from '../../../services/graphql/query';
import Layout from "../../../hoc/Layout/Layout";
//component
import queryString from 'query-string'



class AppSejaPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };   

        const paramsUrl = queryString.parse(props.location.search);
        if(!!paramsUrl && !!paramsUrl.userToken){
            this.state = {
                paramsUrl
            }
        }
    }
    
    componentDidMount() {
        if(localStorage.getItem(ACCESS_TOKEN) && !!this.state.paramsUrl && !!this.state.paramsUrl.userToken){
            localStorage.clear();
        }
        //depois do logout setamos o userToken do usuario
        localStorage.setItem('user_token', this.state.paramsUrl.userToken);
        //depois de setar o userToken fazemos a query de profile
        this.props.client.query({
            query: PROFILE_QUERY,
            errorPolicy: 'all'
        })
        .then(results => {
            // console.log("Result: ", results);
            if(results.errors && results.errors[0].message == 'validation'){
                let erroValid = results.errors[0].extensions.validation;
                    for (var [key, value] of Object.entries(erroValid)) {
                        // console.log("ERRO... ",value);
                    }
                    window.location.replace("/");
                
            }else{
                localStorage.setItem('UserAccount', JSON.stringify(results.data.profile));
                if (this.props.location.search.indexOf('?mail_verified') != -1) {
                    window.location.replace("/");
                } else {
                    window.location.replace("/?modalPremium=app");
                }
            }
        })
        .catch(error => {
            // console.log("Error: ", error);
            window.location.replace("/");
        })

        

     }
      
    render() {
        return (
            <Layout>
       
            </Layout>

        );
    }
}

export default withApollo(AppSejaPremium);

