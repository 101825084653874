import React, { Component, useState, useEffect} from "react";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//graphql
import { withApollo } from 'react-apollo';
import { PlayListQuery, UpdatePlayListQuery} from './details.graphql';

//component
import Layout from "../../../hoc/Layout/Layout";


//icon
import { Star, WindowRestore } from "styled-icons/fa-solid";

import M from "materialize-css";


//styles
import { Container } from "./details.styles";
import EpisodeCard from "../../../components/EpisodeCardComponent";
import { ThumbEpisode } from "../../../components/ThumbEpisode";
import { Button} from "../../../elements/CTA";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";
import { Provider } from "react-redux";
const setQueue = (list) => {
  let lista = [];
  list.map(ep => {
    lista.push(ep.episode);
  },document.player.setQueue(lista),
    setPlay(list[0].episode),
  );
  
}

const setPlay = async (episode) => {
  var elems = document.getElementById('modal-restrito-idade');
  // const {episode} = this.props;
  var instances = M.Modal.init(elems, {
    onOpenStart: function () {
      var ev = new CustomEvent('modal-restrito-idade', { "detail": episode});
      document.dispatchEvent(ev);
    }
  });
  if(episode.rating === "18"){
    var instance = M.Modal.getInstance(elems);
    instance.open()
  } else {
    // console.log("SetPlay: ", episode);
    document.player.pause();
    let current = await document.player.setCurrent(episode);
    // let posit = this.props.position ? this.props.position : 0;
    await document.player.setInitialTime(0);
    await document.player.setCurrentTimeFromCache(0);

    // console.log("Current time" + document.player.getCurrentTime());

    
    // console.log("Eh aqui mesmo")
    if(current) document.player.play();
  }
}



const PlaylistDetails = (props) => {
  const [data, setData] = useState({myPlaylists: null});
  const [error, setError] = useState({myPlaylists: null});
  const [loading, setLoading] = useState(false);
  const [episodesWithOrder, setEpisodesWithOrder] = useState(null);
  // const { loading, error, myPlaylists } = props.data;
  const newPositionEpisode = (episode, position) => {
    console.log("EPISODE-ID:",episode.id, "POSITION:", position);

    props.client.mutate(
      {
        mutation: UpdatePlayListQuery, 
        variables: { 
          episode_id: episode.id,
          id: parseInt(props.match.params.id),
          order: position + 1
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      }
    ).then(
      async ({data, errors, loading}) => {
        if(errors){
          console.log("ERROR:",errors)
        }
        if(data){
          console.log(data)
          await setEpisodesWithOrder(data.changeEpisodeOrderFromPlaylist.episodes_with_order)
          setLoading(false)
        }
      }
    )
  }
  useEffect(() => {
    props.client.query(
      {
        query: PlayListQuery,
        variables: { 
          playlistId: props.match.params.id
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      }
    ).then(
      async ({data, error, loading}) => {
        console.log("DATA: ", data)
        loading ? setLoading(loading) : setLoading(false);
        error ? setError(error) : setError(false)
        if (data && data.myPlaylists && data.myPlaylists.data) {
          await setData(data)
          await setEpisodesWithOrder(data.myPlaylists.data[0].episodes_with_order);
        }
      }
    )
  },[props.match.params.id]);

  const orderPlayList = (result) => {
    setLoading(true)
    console.log("Result", episodesWithOrder);
    if(!result.destination) return;
    const items = Array.from(episodesWithOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setEpisodesWithOrder(items)
    newPositionEpisode(episodesWithOrder[result.source.index].episode, result.destination.index)
  }
  
  if(data.myPlaylists && data.myPlaylists.data[0] && data.myPlaylists.data[0].name.length > 0){
    if (loading && !data) {
      return <div> {`Loading: ${loading}`} </div>;
    } else if (error) {
      if (error.networkError) {
          return <div></div>;
      }
      if (error.graphQLErrors) {
          return <div></div>;
      }
      return <div></div>;
    } else
    return(
      <Layout>
        {/* <Query query={ PlayListQuery } variables={{
          playlistId: props.match.params.id
        }}> */}
        <div className="container">
                <div className="contentTitle">
                  <h4>{data.myPlaylists.data[0].name}</h4>
                </div>
                <Container>
                  <div className="colunLeft">
                    
                    <ThumbEpisode data={data}/>
                    {
                      episodesWithOrder && episodesWithOrder.length >= 1 &&
                      <a className="play-all-media" onClick={() => setQueue(episodesWithOrder)}>Tocar todos</a>
                    }
                  </div>
                  <div className="colunRight">
                    <DragDropContext onDragEnd={orderPlayList}>
                      <Droppable droppableId="episodesCard">
                        {
                          (provided) => (
                            <ul {...provided.groppableProps} ref={provided.innerRef}>
                              {
                                episodesWithOrder &&
                                episodesWithOrder.length >= 1 ?
                                episodesWithOrder.map(
                                    (episode,index) => 
                                    <Draggable key={episode.id+"-"+index} draggableId={"drag-"+index} index={index}>
                                      {
                                        (provided) => (
                                          <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div className="contentEpisode">
                                                <div className="contentIndice">{index+1}</div>
                                                <EpisodeCard horizontal showDetails episode={episode.episode} playlist={props.match.params.id}/>
                                            </div>
                                          </li>
                                        )
                                      }
                                    </Draggable>
                                  )
                                :
                                <div className="contentEpisode">
                                    <div>Desculpe, você ainda não possuí episódios na sua playlist.</div>
                                </div>
                              }
                              {provided.placeholder}
                            </ul>
                          )
                        }
                      </Droppable>
                    </DragDropContext>
                    {
                      loading &&
                    <div style={{
                      position: "fixed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(254, 254, 254, 0.75)"
                    }}>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 1545.03 1000" enable-background="new 0 0 1545.03 1000" style={{width: "12em"}} >
                          <rect className="item1" x="0" y="120" width="150.85" height="195.48" fill="#00AE4D" rx="45" />

                          <rect x="232.50" y="0" width="150.85" height="631.24" fill="#1B4178" rx="45" />
                          <rect className="item2" x="232.50" y="216" width="150.85" height="413.08" fill="#0073BC" rx="45" />

                          <rect x="465.03" y="160" width="150.85" height="474.42" fill="#00AE4D" rx="45" />
                          <rect className="item3" x="465.03" y="160" width="150.85" height="333.81" fill="#7BC143" rx="45" />

                          <rect x="697.57" y="0" width="150.85" height="788.93" fill="#ED1C24" rx="45" />
                          <rect className="item4" x="697.57" y="0" width="150.85" height="576.43" fill="#E9477A" rx="45" />

                          <rect x="930.77" y="0" width="150.85" height="788.93" fill="#F57E20" rx="45" />
                          <rect className="item5" x="930.77" y="209" width="150.85" height="580.24" fill="#FFCB05" rx="45" />

                          <rect x="1162.51" y="160" width="150.85" height="474.36" fill="#00AEDF" rx="45" />
                          <rect className="item6" x="1162.51" y="160" width="150.85" height="298.48" fill="#66429A" rx="45" />

                          <rect className="item7" x="1394.24" y="120" width="150.85" height="195.48" fill="#C21E8E" rx="45" />

                          <text x="280" y="960" fill="#000" style={{fontFamily: "Arial", fontSize: "12em"}}>carregando</text>
                      </svg>
                    </div>
                    }
                  </div>
                </Container>
              </div>
        {/* </Query> */}
    </Layout>
    );
  } else {
    return(<Layout></Layout>);
  }
}

export default withApollo(PlaylistDetails)