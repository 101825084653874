import React, { Component } from "react";
import { sendMessageToUser } from '../../common'

//styles
import { Container } from "./ModalCreateChannel.style.js";

import { Identify } from "./Identify";
import { Thumb } from "./Thumb";
import { Cover } from "./Cover";

//graphql
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { CREATE_CHANNEL, UPDATE_COVER_CHANNEL, UPDATE_THUMB_CHANNEL  } from '../../services/graphql/mutations';

class ModalCreateChannel extends Component {
    constructor(props){
        super(props);
        this.state = {
            optionActive: "identfy"
        }
    }

    updateState = (state, callback) => {
        this.setState(state, () => { if(callback) callback() });
    }

    submitForm = async () => {
        switch(this.state.optionActive){
            case "identfy":
                let { codename, name, category_primary: main_category_id, category_secondary: alternate_category_id, description } = this.state;
                let createChannelBody = {
                    codename, name, main_category_id, alternate_category_id, description
                }
                this.props.mutate({mutation: CREATE_CHANNEL, variables: createChannelBody, errorPolicy: 'all' })
                .then(({data, errors}) => {
                    if(errors){
                        // console.log("ERROR: ", errors)
                        let { validation } = errors[0].extensions;
                        if(validation.codename){
                            sendMessageToUser('O codinome já foi usado');
                        }
                    } else {
                        // console.log("DATA: ",data)
                        if(data){
                            sendMessageToUser("Ola, parabéns! Seu canal já foi criado!")
                            window.location.replace(`/channeloption/${data.createChannel.id}`);
                        }
                    }
                });
                break;
            case "thumb":
                let { channel: channelState, thumbnail } = this.state;
                let updateThumbChannel = { channel_id: channelState.id, thumbnail }
                let thumChannel = await this.props.mutate({mutation: UPDATE_THUMB_CHANNEL, variables: updateThumbChannel })
                let { editChannel } = thumChannel.data;
                sendMessageToUser('Imagem enviada com sucesso!')
                return this.setState({channel: editChannel, optionActive: 'cover'})
            case "cover":
                let coverChannel = await this.props.mutate({
                    mutation: UPDATE_COVER_CHANNEL, 
                    variables: {
                        channel_id: this.state.channel.id,
                        cover: this.state.cover
                    }
                })
                this.setState({channel: coverChannel.data.editChannel})
                sendMessageToUser('Imagem enviada com sucesso!')
                window.location.replace(`/channels/${this.state.channel.codename}`)
        }
    }

    finish = () => {
        window.location.replace(`/channels/${this.state.channel.codename}`)
    }
    back = () => {
        this.setState({optionActive: 'thumb'});
    }


    renderContainer(){
        switch(this.state.optionActive){
            case "identfy":
                return <Identify selectOption={this.selectOption} updateState={this.updateState} submitForm={this.submitForm} />;
            case "thumb":
                return <Thumb selectOption={this.selectOption} updateState={this.updateState} submitForm={this.submitForm} />;
            case "cover":
                return <Cover selectOption={this.selectOption} updateState={this.updateState} submitForm={this.submitForm} finish={this.finish} back={this.back} />;      
        }
    }

    selectOption = (option) =>{
        this.setState({optionActive: option})
    }

    render() {
        // console.log('props ModalCreateChannel ', this.props)
        return (
            <div className="bg-secondary start-flex-content">
                {this.renderContainer(this.selectOption)}
            </div>
        )
    }
}
export default compose(
    graphql(CREATE_CHANNEL),
    graphql(UPDATE_COVER_CHANNEL),
    graphql(UPDATE_THUMB_CHANNEL)
)(ModalCreateChannel);
