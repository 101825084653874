import React, { Component } from "react";

import M from "materialize-css";
import { ChevronRight, Check } from "styled-icons/material";
import { Cog } from "styled-icons/fa-solid";


//component
import Layout from "../../../hoc/Layout/Layout";
import { UploadBar } from "../../../components/UploadBar";
import { AboutEpisode } from "../../../components/AboutEpisode";

import ImgChannel from "../../../../src/assets/img/torresmo-image.png";
import { compose } from 'recompose';
import { CHANNEL_QUERY_BY_ID } from '../../../services/graphql/query';
import { CREATE_EPISODE_MUTATION } from '../../../services/graphql/mutations';
import { Query, Mutation, graphql } from 'react-apollo';
import { sendMessageToUser } from '../../../common';


import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

//styles
import { Container } from "./NewEpisode.styles";

class EmAlta extends Component {
    constructor(props){
        super(props);
        this.state = {
            porcent: null,
            audioEdit: true,
            sendInfo: false,
            loading: false,
            midia: false,
            
            tags: false,
            likes: false,
            comments: false,
            monetization: false,
            restricted: false,
            description: false,

        }
    }
    componentDidMount(){
        var elems = document.querySelectorAll('.collapsible');        
        var instances = M.Collapsible.init(elems, { accordion: false });
    }

    updateState = (state: Object) => {  
        this.setState(state);
    }

    fazLoading = () => {
        
         var intervalId = setInterval(this.timer, 1000);
         this.setState({porcent: 0,
                        audioEdit: false, 
                        porcent: 0,
                        intervalId: intervalId 
        })


    }
    timer = () => {
        // setState method is used to update the state
        this.setState({ porcent: this.state.porcent + 3 });
    }

    submitForm = async () => {

        // // console.log("Meu State",this.state);
        let body = {
            channel_id: this.props.match.params.id,
            title: this.state.title,
            description: this.state.description,
            tags: this.state.tags,
            monetized: this.state.monetization,
            restricted: this.state.restricted,
            block_comments: this.state.comments,
            block_likes: this.state.likes,
            thumbnail: this.state.thumbnail,
            content:this.state.midia
        };

        if (this.state.restricted) {
            body.rating = "18";
        }
        //let newEp = await this.props.mutate({mutation: CREATE_EPISODE_MUTATION, variables: body});
        // console.log("Variaveis: ",body);

        this.setState({loading: true})
        this.fazLoading();
        this.props.mutate({
            mutation: CREATE_EPISODE_MUTATION,
            variables: body,
          })
          .then((data) => { 
                sendMessageToUser('Episodio criado com sucesso !') 
                this.setState({ porcent: 100, loading: false, sendInfo: true });
                // console.log("PROPS DE AGORA", this.props)
                clearInterval(this.state.intervalId);
                let id_channel = this.props.data.channels.data[0].id;
                window.setTimeout(function(){window.location.replace(`/channeloption/${id_channel}`)}, 3000);
          })
          .catch((res) => {
            // console.log("Erro aqui", res);
            const errors = res.graphQLErrors.map(error => error.message);
            // console.log("Erro tratado", errors);
            if(!!this.state.loading){
                sendMessageToUser('Erro ao criar episodio') 
            }
            this.setState({loading: false, porcent: false, sendInfo: false})
            clearInterval(this.state.intervalId);
            
          });

    }

    render() {

        return (
            <Layout>
                <Container>
                {!!this.props.data && !!this.props.data.channels &&
                
                    <div className="bread">
                        <img src={this.props.data.channels.data[0].thumbnail}/>
                        <span>{this.props.data.channels.data[0].codename}</span>
                        <span>> NOVO EPISÓDIO</span>
                        <Cog />
                    </div>
                 }
                    <div className="content">
                        {!this.state.sendInfo?
                            <ul className="collapsible collapsible-accordion">
                                <li className="contentSendFile active">
                                    <div className="collapsible-header">
                                        Conteúdo do Episódio
                                        <ChevronRight className="status-dropdown" />
                                    </div>
                                   
                                    <div className="collapsible-body" >
                                        
                                        <UploadBar 
                                            porcent={this.state.porcent}
                                            audioEdit={this.state.audioEdit}
                                            updateState={this.updateState}
                                        />
                                    </div>
                                </li>
                                <li className={`contentInfoEpsode ${this.state.audioEdit?"active":this.state.porcent != null && "active"}`}>
                                    <div className="collapsible-header">
                                        Sobre o Episódio
                                        <ChevronRight className="status-dropdown" />
                                    </div>
                                    { this.state.audioEdit == true ?
                                        <div className="collapsible-body">
                                            <AboutEpisode audioEdit={this.state.audioEdit} updateState={this.updateState} submitForm={this.submitForm}/>
                                        </div>
                                    :
                                    this.state.porcent != null &&
                                        <div className="collapsible-body">

                                            <AboutEpisode audioEdit={this.state.audioEdit}/>
                                        </div>
                                    }
                                </li>
                            </ul>
                        :
                            <div className="contentSucess">
                                <div> <Check className="icon" /></div>
                                <span>
                                    Seu episódio foi enviado com sucesso. Estamos processando seu áudio e logo logo estará disponível em nossa plataforma
                                </span>
                            </div>
                        }
                    </div>
                </Container>
              
            </Layout>
        )
    }
}
const propsToOptions = (props) => {
  
    return ({ 
      
        variables: 
          { 
            channel_id: props.match.params.id, 
            thumbWidth: 300, 
            thumbHeight: 300,
            coverWidth: 800, 
            coverHeight: 300,
          }
      }
    )
  }
  
  export default compose(graphql(CREATE_EPISODE_MUTATION),graphql(CHANNEL_QUERY_BY_ID, { options: propsToOptions }))(EmAlta);
