import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  .details-header_orderby {
    font-family: "Avenir-Book", sans-serif;
    font-size: 30px;
    color: #313131;
    letter-spacing: 0;
    line-height: 54px;
  }
`;

export const HeaderWrapper = styled.section`
  .playlist-title {
    padding: 0 var(--spacing-small);
    font-family: "Avenir-Book", sans-serif;
    font-size: 30px;
    color: var(--color-negative);
    letter-spacing: 0;
    line-height: 54px;
  }
`;

export const ActionsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-small);

  .search-result_button {
    border: 1px solid var(--color-first);
    border-radius: 16.5px;
    font-family: "Avenir-Book", sans-serif;
    font-size: 11px;
    padding: 5px 10px;
    color: var(--color-first);
    letter-spacing: 1.5px;
    text-align: center;
    font-family: "Avenir-Book", sans-serif;
  }
`;

export const WrapperList = styled.section`
  display: flex;
  flex-wrap: wrap;
  .details-card_playlist {
    width: 100%;
    display: flex;
    padding: var(--spacing-small);
    border: 1px solid var(--color-third);
  }
  .card-playlist_image {
    padding: 0;
    position: relative;
    .info-slider {
      position: absolute;
      bottom: 0;
      height: 0px;
      padding: 0;
      width: 100%;
      border-radius: 0px;
      touch-action: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
  .card-image_title {
    font-family: Avenir-Black;
    font-size: 18px;
    color: var(--color-negative);
    letter-spacing: 0;
    line-height: 27px;
  }
  .card-playlist-box {
    margin-left: var(--spacing-small);
    width: 100%;
    > .card-podcast_title {
      font-family: "Avenir-Book", sans-serif;
      font-size: 18px;
      color: #313131;
      letter-spacing: 0;
      line-height: 27px;
    }

    > .card-podcast_title {
      width: 100%;
      font-family: "Avenir-Book", sans-serif;
      font-size: 18px;
      color: var(--color-negative);
      letter-spacing: 0;
      line-height: 27px;
    }

    > .card-podcast_author {
      font-family: "Avenir-Book", sans-serif;
      font-size: 14px;
      color: var(--color-negative);
    }
    .card-podcast_info {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      > .time-length {
        font-family: Avenir-Heavy;
        font-size: 11px;
        color: #7f7f7f;
        letter-spacing: 0;
      }
      > .play-count {
        border: 1px solid #979797;
        border-radius: 16.5px;
        padding: 0 3px;
        font-family: "Avenir-Book", sans-serif;
        font-size: 10px;
        color: #7f7f7f;
        letter-spacing: 1.36px;
        text-align: center;
        margin-left: var(--spacing-small);
      }
    }
  }
`;
