import React from "react";

//styles
import { SignupStepWrapper } from "./styles";

import { validator } from "../../../../validators/validators.js";

//elements
import { Title, Text } from "../../../../elements/Typography";
import { Button, StyledLink } from "../../../../elements/CTA";
import { Input } from "../../../../elements/Input";

//assets
import LogoDefault from "../../../../assets/img/logo-ola-colorido.svg";

const SignupOptions = ({ handleAction, handleInputChange, globalState }) => (
  <SignupStepWrapper
    name="signup-options"
    className="signup-options"
    onSubmit={e => handleAction(e, "password")}
  >
    <div className="auth-logo">
      <img src={LogoDefault} alt="logo ola" />
    </div>

    <Title size="1" content="Lorem ipsum dolor sit" typeClass="auth-title" />
    <Text
      typeClass="auth-subtitle"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisl lectus, viverra a vulputate sit amet, bibendum quis nunc."
    />

    <Button
      className="auth-button"
      Background="var(--color-first)"
      Color="var(--color-zero)"
    >
      Conectar com o Facebook
    </Button>
    <Button
      className="auth-button"
      Background="var(--color-first)"
      Color="var(--color-zero)"
      onClick={() => console.log("facebook login")}
    >
      Conectar com o Google
    </Button>
    <Text typeClass="auth-or" content="ou" />
    <Input
      name="emailOrPhone"
      labelContent="E-mail ou Celular"
      width="100%"
      labelClasses=""
      placeholder="digite o seu e-mail ou celular"
      borderRadius="3px"
      background="var(--color-third)"
      color="var(--color-first)"
      border="1px solid var(--color-third)"
      onChange={e => handleInputChange(e)}
      onBlur={e => validator.handleInputIsEmailOrPhone(e)}
    />
    <Button
      type="submit"
      className="auth-button"
      Background="var(--color-first)"
      Color="var(--color-zero)"
    >
      Criar uma Conta
    </Button>

    <div className="auth-login">
      <Text type="span" content="Já tem uma conta?" />
      <StyledLink
        type="submit"
        to="/signin"
        className="login-button"
        Color="var(--color-first)"
      >
        Entrar
      </StyledLink>
    </div>
  </SignupStepWrapper>
);

export default SignupOptions;
