import React, { Component } from "react";

//styles
import { 
    Container, 
    Trends
 } from "./EmAlta.styles";

 //graphql
import { Query } from 'react-apollo';
import EmAltaQuery from './EmAlta.graphql';

 //elements
 import { Text, Title } from "../../../elements/Typography";
 import { Tag } from "../../../elements/Tag";

//component
import Layout from "../../../hoc/Layout/Layout";

//import { CardComponent } from '../../../components/CardComponent';
import CardComponent from "../../../components/EpisodeCardComponent";
import { BigCardComponent } from '../../../components/BigCardComponent';

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class EmAlta extends Component {
    renderEnAltaTags = (data) => {
        let contentTags = [];
        if(data.length > 0){
            for(let i = 0; i < data.length; i++){
                // console.log("item: ", data);
                let arrayString = data[i].tags.split(' ');
                for(let arr = 0; arr < arrayString.length; arr++){
                    if(arrayString[arr] != ''){
                        contentTags = contentTags.concat(<span key={`tag-${i}-${arr}`}><a href={`/tagview/${arrayString[arr]}`}>#{arrayString[arr]}</a> </span>);
                    }
                }
            }
        }
        return (contentTags);
    }
    renderEnAlta = (data) => {
        let contentLeft = [];
        let contentRight = [];
        let contentDestaque = [];
        
        if(data.length > 0){
            contentDestaque = contentDestaque.concat(
                <div className="contentColunDirection col s12 m6 destaque first">
                    <BigCardComponent
                        showDetails
                        key={data[0].id} 
                        episode={data[0]} />
                </div>
            );
            if(data.length >= 5){
                for(let i = 1; i < 5; i++){
                    contentLeft = contentLeft.concat(
                        <CardComponent 
                            horizontal
                            showDetails
                            key={`cd-${i}-${data[i].id}`} 
                            episode={data[i]} />
                    )
                }
                if(data.length >= 5){
                    for(let i = 5; i < data.length; i++){
                        if(i < 9){
                            contentRight = contentRight.concat(
                                <CardComponent 
                                    horizontal
                                    showDetails
                                    key={`cd-${data[i].id}`}
                                    episode={data[i]} />
                            )
                        } else {
                            contentDestaque = contentDestaque.concat(
                                <div className="contentColunDirection col s12 m6 destaque left">
                                    <BigCardComponent
                                        showDetails
                                        key={`cd-${data[i].id}`}
                                        episode={data[i]} />
                                </div>
                            )
                        }
                    }
                }
            }
        }
        return(
            <>
                <div className="contentLineDirection row">
                    {contentDestaque[0]}
                    <div className="contentColunDirection">
                        {contentLeft}
                    </div>
                </div>
                <div className="contentLineDirection row">
                    <div className="contentColunDirection">
                        {contentRight}
                    </div>
                    {contentDestaque[1]}
                </div>
            </>
        )
    }
    render() {
        return (
            <Layout>
                <div className="container">
                    <Container>
                            <Trends>
                                <Title size="3" typeClass="trend-title" content="Em Alta" />
                                <div className="line-tags">
                                <Query query={EmAltaQuery}>
                                {
                                    
                                    ({ loading, error, data }) => {
                                        // console.log("Retorno: ",data);
                                        if (loading) return <div> {`Loading: ${loading}`} </div>;
                                        if (error) {
                                        if (error.networkError) {
                                            return <div> {`Error: no network`} </div>;
                                        }
                                        if (error.graphQLErrors) {
                                            return <div> {`Error: ${error}`} </div>;
                                        }
                                        return <div> {`Error: ${error}`} </div>;
                                        }
                                        return this.renderEnAltaTags(data.homeTrendsEpisodes.data);
                                    }
                                }
                                </Query>
                                </div>
                            </Trends>
                            <div className="containerAlta">
                                
                                <Query query={EmAltaQuery}>
                                {
                                    ({ loading, error, data }) => {
                                        if (loading) return <div> {`Loading: ${loading}`} </div>;
                                        if (error) {
                                        if (error.networkError) {
                                            return <div> {`Error: no network`} </div>;
                                        }
                                        if (error.graphQLErrors) {
                                            return <div> {`Error: ${error}`} </div>;
                                        }
                                        return <div> {`Error: ${error}`} </div>;
                                        }
                                        return this.renderEnAlta(data.homeTrendsEpisodes.data);
                                    }
                                }
                                </Query>
                            </div>
                    </Container>
                </div>
            </Layout>
        )
    }
}
