import React, { Component } from "react";
import { compose } from 'recompose';
import { graphql } from 'react-apollo';

//style
import { Container } from "./Complaint.style";
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { Button } from "../../elements/CTA";
import { sendMessageToUser } from '../../common';


import { CREATE_COMPLAINT_MUTATION  } from '../../services/graphql/mutations';
import { BUSCA_COMPLAINT_QUERY  } from '../../services/graphql/query';

class Complaint extends Component {
    constructor(props){
        super(props);
        this.state = {
            type: this.props.type,
            object: this.props.object,
            fezAcao: false
        }
    }

    selecionaComplaintType = selected => {
        this.setState({ complaintType: selected })
    }

    selecionaComplaintSubType = selected => {
        this.setState({ complaintSubType: selected })
    }

    sair = () => {
        this.setState({fezAcao: false})
    }

    onSubmit = async () => {
        if(!this.state.complaintType){
            sendMessageToUser("Deve escolher o tipo de denuncia");
        }else{
            let complanint = {
                type_id: this.state.complaintType.value, 
                object_type: this.state.type, 
                object_id: this.state.object.id,
                claimed_url: window.location.href,
            };
            if(this.state.complaintSubType ){
                complanint.subtype_id = this.state.complaintSubType.value;
            }
            // console.log("Enviar antes tratamento : ",complanint);
            let paramsEnviar = Object.entries(complanint).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
            // console.log("Enviar: ",paramsEnviar);
            let sendComplaint = await this.props.mutate({variables: paramsEnviar});
            // console.log(sendComplaint)
            
            this.setState({fezAcao: true});
            // sendMessageToUser("Denuncia feita com sucesso!");
        }
    }

    render(){
        const complaintType = (props) => {
            if(!!props && !!props.data && !!props.data.complaintTypes ){
               
               let primaryOpt = [];
               let qtdComplaintsTypes = props.data.complaintTypes.length;
               for (let i=0; i < qtdComplaintsTypes; i++) {
                  primaryOpt = primaryOpt.concat({ value: props.data.complaintTypes[i].id, label: props.data.complaintTypes[i].description });
               }
               return primaryOpt;
           }
           return [];
        }

        const complaintSubType = (props) => {
            if(!!props && !!props.data && !!props.data.complaintTypes ){
               
               let subTypeOpt = [];
               let qtdComplaintsTypes = props.data.complaintTypes.length;
               for (let i=0; i < qtdComplaintsTypes; i++) {
                   if(props.data.complaintTypes[i].id == this.state.complaintType.value){
                        let qtdComplaintSubTypes = props.data.complaintTypes[i].subtypes.length;
                        for (let k=0; k < qtdComplaintSubTypes; k++) {
                            subTypeOpt = subTypeOpt.concat({ value: props.data.complaintTypes[i].subtypes[k].id, label: props.data.complaintTypes[i].subtypes[k].description });
                        }
                   }
               }
               return subTypeOpt;
           }
            
           return [];
        }
        

        // console.log("State ", this.state);

        return(
            <>
            {!this.state.fezAcao ?
                <Container  >
                    <div className="title">Denunciar o episódio</div>
                    {!!this.props && !!this.props.data && !!this.props.data.complaintTypes && 
                        <div>
                            <Dropdown 
                                options={complaintType(this.props)}
                                value={this.state.complaintType || ""}
                                menuClassName="drop" 
                                controlClassName="drop cidade" 
                                onChange={(selected) => this.selecionaComplaintType(selected)}
                            />
                        </div>            
                    }
                    {!!this.state && !!this.state.complaintType &&
                        <div>
                            <Dropdown 
                                options={complaintSubType(this.props)}
                                value={this.state.complaintSubType || ""}
                                menuClassName="drop" 
                                controlClassName="drop cidade" 
                                onChange={(selected) => this.selecionaComplaintSubType(selected)}
                            />
                        </div>

                    }
                    <div className="content-actions">
                        <Button 
                            type="submit"
                            className="auth-button"
                            onClick={() => this.onSubmit()}
                        >
                            DENUNCIAR
                        </Button>
                        <Button 
                            type="submit"
                            className="auth-button-cancel modal-close"
                            onClick={() => this.sair()}
                            >
                            CANCELAR
                        </Button>
                    </div>
                </Container>
             :
                 <Container  >
                     <div className="titulo-centro">Agradecemos por nos avisar.</div>
                     <div className="subtitulo-centro">Seu feedback ajuda nosso sistema a saber quando algo não esta certo.</div>
                     <div className="botao-centro">
                        <Button 
                            type="submit"
                            className="auth-button-fechar modal-close"
                            onClick={() => this.sair()}
                            >
                            FECHAR
                        </Button>
                    </div>
                    
                </Container>
            }
            </>
        )
    }
}

const propsToOptions = (props) => {
  
    return ({ 
        variables: 
          { 
            object_type: props.type
          }
      }
    )
  }

export default compose(graphql(BUSCA_COMPLAINT_QUERY, { options: propsToOptions }),graphql(CREATE_COMPLAINT_MUTATION))(Complaint);

