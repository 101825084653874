import styled from "styled-components";
import cover from "../../../assets/img/bannerTop.jpg";

export const Container = styled.section`
  width: 100%;
`;

export const PremiumBanner = styled.article`
  display: flex !important;
  height: 310px;
  width: 100% ;
  justify-content: flex-start;
  align-items: center;
  /* border: 5px solid #979797;  */
  img {
    flex:1
  }
`;
export const Trends = styled.section`
  width: 100%;
  margin-top: var(--spacing-default);
  margin-left: var(--spacing-default);
  display: flex;
  flex-direction: column;
  & .trend-title {
    margin-left: var(--spacing-small);
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;
    color: #E8B10E;
  }
  & .trend-list{
    justify-content: start;
    margin-top: var(--spacing-default);
    flex-wrap: wrap;
    display: flex;
    .episode-card{
      margin-bottom: var(--spacing-default);
    }
    width: 90%;
    
  }
  & .premium-badge{
    width: 20px;
    height: 20px;
  }
`;
export const PremiumBox = styled.section`
    .premium-container {
      /* padding-left:250px */
    }
    .roleta{
      margin-top: var(--spacing-default);
    }
`;
export const Verticals = styled.section`
  width: 100%;
  height: auto;
  margin: var(--spacing-default) var(--spacing-default) var(--spacing-default);
  margin-left: var(--spacing-default);

  .vertical-header {
    margin-bottom: var(--spacing-medium);
  }
  .vertical-list.sliderContent {
    width: 1424px;
  }
  .slick-slide {
    width: auto !important;
  }
  .slick-slider {
    width: 100%;
  }
  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .vertical-title {
    color: var(--color-negative);
  }

  .vertical-box {
    width: auto !important;
    display: flex;
    flex-direction: column;

    padding: var(--spacing-small);
    margin-right: var(--spacing-half-big);
    .vertical-logo {
      width: auto;
      text-align: center;
      height: 100%;
      margin-bottom: var(--spacing-medium);
    }
  }
  .vertical-box:hover {
    box-shadow: 1px 0px 5px 1px #ccc;
  }
`;
