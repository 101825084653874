import React, { Component } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css";
import { Share } from "styled-icons/material";
import 'font-awesome/css/font-awesome.min.css';
import { sendMessageToUser } from '../common';

export default class DropDownShare extends Component {
  componentDidMount() {
    let elems = document.querySelectorAll(".dropdown-trigger-share");
    let instances = M.Dropdown.init(elems, {
      alignment: "right",
      inDuration: 300,
      outDuration: 225,
      constrainwidth: false,
      coverTrigger: false, // Displays dropdown below the button
      stopPropogation: true
    });

  }

  getCodeEmbed = () => {
    if(this.props.episode){
      const codigo = `<iframe width='672' height='280' src='https://www.olapodcasts.com/embed/?codename=${this.props.episode.channel.codename}&episode_id=${this.props.episode.id}' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>`;
      return codigo;
    }
  }

  getUrlCompartilhar = () => {
    if(this.props.episode){
      // console.log("Ep compartilhar: ", this.props.episode)
      let urlCompartilhar = "https://share.olapodcasts.com/?id=" + this.props.episode.id;
      // console.log("Url Compartilhar: " + urlCompartilhar)
      return urlCompartilhar;
    }else{
      return window.location.href;
    }
  }

  copyToClipboard = (str) => {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    sendMessageToUser("Link para compartilhamento copiado com sucesso.");    
  };

  render() {
    return (
        <>

        <a
            href="/#"
            className="dropdown-trigger-share btn-floating  waves-effect waves-light grey lighten-4 player-actions_button"
            data-target="dropdown-share"
            data-constrainwidth="false"
        >
                 <Share className="icon" />
        </a>


        <ul id="dropdown-share" className="dropdown-share dropdown-content row"
        >
            <li id="share_facebook_btn" className="dropdown-share_item">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.getUrlCompartilhar())}`}  target="_blank" title="Compartilhar no Facebook" >
                      <i className="fa fa-facebook-official fa-2x" style={{ color: "blue" }} aria-hidden="true"></i> Facebook
                  </a>
            </li>
            <li id="share_twitter_btn" className="dropdown-share_item">
                   <a href={`http://twitter.com/share/?text=OLA Podcasts&url=${encodeURIComponent(this.getUrlCompartilhar())}`}   target="_blank" title="Tweetar" >
                      <i className="fa fa-twitter fa-2x" style={{ color: "#00ACED" }} aria-hidden="true"></i> Twitter
                  </a>
            </li>
            <li id="share_link_btn" className="dropdown-share_item">
                <a href="#" title="Copiar Link"  onClick={() => this.copyToClipboard(this.getUrlCompartilhar())}>
                 <i className="fa fa-link fa-2x" style={{color: "black"}} aria-hidden="true" ></i> Copiar Link
                </a>
            </li>
            <li id="share_code_btn" className="dropdown-share_item">
                <a href="#" title="Copiar Código"  onClick={() => this.copyToClipboard(this.getCodeEmbed())}>
                 <i className="fa fa-code fa-2x" style={{color: "black"}} aria-hidden="true" ></i> Copiar Código
                </a>
            </li>
        </ul>
        </>
    );
  }
}



