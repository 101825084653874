import React, { useState } from "react";

//style
import { Container } from "./ChannelInfo.style";
import { Link } from "react-router-dom";

import { ChannelQuery, UPDATE_CHANNEL } from './channelinfo.graphql';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Query, Mutation, graphql } from 'react-apollo';
import { compose } from 'recompose';
import { Camera } from "styled-icons/fa-solid";

//element
import { List } from "../../elements/List";
import { Logo } from "../../elements/Image";
import { Input } from "../../elements/Input";

import { sendMessageToUser } from "../../common";

import { Button } from '../../elements/ButtonElement';

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import Textarea from "react-textarea-autosize";

import defaultImage from "../../assets/img/thumbDefault.jpg";
import CoverImage from "../../assets/img/coverDefault.jpg";
import CONHECIMENTO from "../../assets/img/Verticais/conhecimento_cover.jpg";
import COTIDIANO from "../../assets/img/Verticais/cotidiano_cover.jpg";
import ESPORTES from "../../assets/img/Verticais/esporte_cover.jpg";
import FAMILIA from "../../assets/img/Verticais/familia_cover.jpg";
import HISTORIAS from "../../assets/img/Verticais/historia_cover.jpg";
import HUMOR from "../../assets/img/Verticais/humor_cover.jpg";
import LIFESTYLE from "../../assets/img/Verticais/lifestyle_cover.jpg";
import MERCADO from "../../assets/img/Verticais/mercado_cover.jpg";
import TECNOLOGIA from "../../assets/img/Verticais/tecnologia_cover.jpg";
import VARIEDADES from "../../assets/img/Verticais/variedades_cover.jpg";

const coverVertical = (name) => {
  switch(name) {
    case "HUMOR":
      return HUMOR;
      break;
    case "CONHECIMENTO":
      return CONHECIMENTO;
      break;
    case "COTIDIANO":
      return COTIDIANO;
      break;
    case "ESPORTES":
      return ESPORTES;
      break;
    case "FAMILIA":
      return FAMILIA;
      break;
    case "HISTORIAS":
      return HISTORIAS;
      break;
    case "LIFESTYLE":
      return LIFESTYLE;
      break;
    case "MERCADO":
      return MERCADO;
      break;
    case "TECNOLOGIA":
      return TECNOLOGIA;
      break;
    case "VARIEDADES":
      return VARIEDADES;
      break;
    default:
      return CoverImage;
      break;

  }
}
/*
//icons
import {
  HomeAlt,
  TrendingUp,
  Headphone,
  EditAlt,
  Diamond
} from "styled-icons/boxicons-regular";

import OptionImage from "../../assets/img/option.png";

import { Explore } from "styled-icons/material";*/
const listType = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/webp'
]

const contains = (arr, element) => {
  for(let i = 0; i < arr.length; i++) {
    if (arr[i] === element) {
      return true;
    }
  }
  return false;
}

const mainCatOptions = (props) => {
  
  if(!!props.data && !!props.data.mainCategories ){
       let maincatoptions = [];
    for (let i=0; i<props.data.mainCategories.length; i++) {
       maincatoptions = maincatoptions.concat({ value: props.data.mainCategories[i].id, label: props.data.mainCategories[i].name });
       
    }
      return maincatoptions;
  }
}

const altCatOptions = (props) => {
  
  if(!!props.data && !!props.data.alternateCategories && !!props.data.alternateCategories.data){
       let altcatoptions = [];
    for (let i=0; i<props.data.alternateCategories.data.length; i++) {
       altcatoptions = altcatoptions.concat({ value: props.data.alternateCategories.data[i].id, label: props.data.alternateCategories.data[i].name });
       
    }
      return altcatoptions;
  }
}

const mostraNovoCover = (cover) => {
  if(contains(listType, cover.type)){
    const fileToUpload = cover;
    const reader = new FileReader();
    const previewImg = document.getElementsByClassName("cover");
    reader.onload = e => {
      let img = new Image;
      img.onload =  () => {
        return previewImg[0].style.backgroundImage = "url('" + e.target.result + "')"
      }
      img.src = reader.result;
    };
    reader.readAsDataURL(fileToUpload);
  } else {
    sendMessageToUser("O arquivo enviado deve ser uma imagem.");
  }
}

const mostraNovoThumb = (thumbnail) => {
  if(contains(listType, thumbnail.type)){
    const fileToUpload = thumbnail;
    const reader = new FileReader();
    const previewImg = document.getElementsByClassName("thumbnail");
    reader.onload = e => {
      // console.log(thumbnail.type)
      let img = new Image;
      img.onload =  () => {
          return previewImg[0].style.backgroundImage = "url('" + e.target.result + "')"
      }
      img.src = reader.result;
    };
    reader.readAsDataURL(fileToUpload);
  } else {
    sendMessageToUser("O arquivo enviado deve ser uma imagem.");
  }
}

const Info = (props) => {
  let { channels } = props.data;
  
  let [updateChannel] = useMutation(UPDATE_CHANNEL);
  let [name, setName] = useState(channels ? channels.data[0].name : null);
  let [cover, setCover] = useState(channels ? channels.data[0].cover : null);
  let [thumb, setThumb] = useState(channels ? channels.data[0].thumbnail : null);

  let [mainCat, setMainCat] = useState(channels ? {
    value:channels.data[0].main_category.id,
    label:channels.data[0].main_category.name
  } : null);
  let [altCat, setAltCat] = useState(channels ? {
    value:channels.data[0].alternate_category.id,
    label:channels.data[0].alternate_category.name
  } : null);
  let [description, setDescription] = useState(channels ? channels.data[0].description : null);

  // console.log("PROPSSSS =>>> ", props.data);
  return (
    
    <Container>
      
      
      {!!channels &&
        
        <>
          <div className="cover"  style={{backgroundImage: `url(${cover || channels.data[0].cover || coverVertical(props.data.channels.data[0].main_category.name)})`}}>
                <span><Camera  className="icon-item"/> Adicionar foto de capa</span>
                <input type="file" name="coverUpload" className="coverUpload"  onChange={(e) => {mostraNovoCover(e.target.files[0]); setCover(e.target.files[0]);}}/>
                 {/* ESCOLHER IMAGEM DE COVER   */}
            </div>
          <div className="perfil-titulo">
            <div className="perfil">
                <span className="thumbnail" style={
              thumb? {backgroundImage: `url(${thumb})`}
              :
              channels.data[0].thumbnail && {
                backgroundImage: `url(${channels.data[0].thumbnail})`
              }}>
                  <Camera  className="icon-item"/>
                  Adicionar foto<br/>
                  do canal
                </span>
               <input type="file" name="thumbUpload" className="thumbUpload"  onChange={(e) => {mostraNovoThumb(e.target.files[0]); setThumb(e.target.files[0])}}/>
                {/* ESCOLHER IMAGEM DE PERFIL */}
            </div>
            <div className="titulo-codename">
              <div className="titulo">
                <Input
                  type="text"
                  placeholder="Adicione o nome do canal"
                  labelContent="Nome do canal *"
                  labelClasses="titleLabel"
                  value={name || channels.data[0].name}
                  id="title"
                  name="title"
                  width="70%"
                  onChange={e => {setName(e.target.value) } }
                />
              </div>
              <div className="codename">{channels.data[0].codename}</div>
            </div>
          </div>
          <div className="dropdowns">
            <div className="primaria">
              <label className="titleLabel" htmlFor={channels.data[0].main_category.id}>
                Vertical *
              </label>
              <Dropdown
                options={mainCatOptions(props)}
                value={mainCat ? {value: mainCat.value , label: mainCat.label} : {value: channels.data[0].main_category.id , label: channels.data[0].main_category.name}}
                menuClassName="drop"
                controlClassName="drop"
                onChange={(selected) =>  {setMainCat(selected) }}
              />
              
            </div>
            <div className="secundaria">
              <label className="titleLabel" htmlFor={channels.data[0].alternate_category.id}>
                Categoria secundaria
              </label>
              <Dropdown
                options={altCatOptions(props)}
                value={altCat ? {value: altCat.value , label: altCat.label} : {value: channels.data[0].alternate_category.id , label: channels.data[0].alternate_category.name}}
                menuClassName="drop"
                controlClassName="drop"
                onChange={(selected) =>  {setAltCat(selected) }}
                
              />
            </div>
          </div>
          <div className="descricao">
            <label className="titleLabel" htmlFor={channels.data[0].alternate_category.id}>
              Descrição *
            </label>
            <Textarea
              className="textArea"
              placeholder="Digite aqui uma breve descrição do seu canal, Exemplo: Lorem ipsun dolor semt log..."
              minRows={6}
              maxRows={8}
              defaultValue={channels.data[0].description}
              onChange={e => {setDescription(e.target.value) } }
            />
          </div>
          <div className="botaoSubmit"> 
            <Button
                  type="submit"
                  className="auth-button"
                  Background="var(--color-first)"
                  color="var(--color-zero)"
                  onClick={() => {
                    let catPri, catSeg;
                    if(!mainCat || !mainCat.value) { catPri = null; } else { catPri = mainCat.value; }
                    if(!altCat || !altCat.value) { catSeg = null; } else { catSeg = altCat.value; }

                    if(thumb instanceof File){
                        //  alert("Arquivo");
    
                    }else{                      
                        // alert("Url");
                    }

                    let params = { 
                      channel_id:  channels.data[0].id,
                      name:  name,
                      thumbnail:  thumb,
                      cover:  cover,
                      main_category_id: catPri,
                      alternate_category_id:  catSeg,
                      description:  description,
                    }

                    let paramsEnviar = Object.entries(params).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
                    
                    // console.log("Enviar: ",paramsEnviar);
                     updateChannel({variables: paramsEnviar })
                     .then(response => 
                        {
                          sendMessageToUser('Salvo com sucesso!');
                          window.location.reload();
                        }

                    );
                  } }
              >
                  Salvar
              </Button>
              <Button className="botao-ep" >
                <Link onClick={()=>{
                    if(thumb instanceof File || cover instanceof File){
                        if(window.confirm('Você não salvou suas alterações. Tem certeza que deseja sair desta página?')){ 
                          props.selectOption('new-episodes')
                        }
                    }else{
                      props.selectOption('new-episodes')
                    }
                  }}>Gravar novo Episódio</Link>
              </Button>
              <Button className="botao-ep" >
                <Link onClick={()=> { 
                    if(thumb instanceof File || cover instanceof File){
                      if(window.confirm('Você não salvou suas alterações. Tem certeza que deseja sair desta página?')){ 
                        window.location.href="/my-channels/"
                      }
                    }else{
                      window.location.href="/my-channels/"
                    }
                  }}>Gravar Alo</Link>
              </Button>
          </div>

        </>
      }
      
    </Container>
  );
};

const propsToOptions = (props) => {
  
  return ({ 
    
      variables: 
        { 
          channel_id: props.channel_id, 
          thumbWidth: 300, 
          thumbHeight: 300,
          coverWidth: 800, 
          coverHeight: 300,
        }
    }
  )
}

export default compose(graphql(ChannelQuery, { options: propsToOptions }))(Info);
