import React, { Component } from "react";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//ducks
import PodcastsActions from "../store/ducks/podcasts";
import TracksActions from "../store/ducks/tracks";
import PlayerActions from "../store/ducks/player";

//graphql
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { HOME_QUERY } from  '../services/graphql/query';


//img
import { TrendingUp } from "styled-icons/material";

//constants
import { Header } from "../components/Header";

//component
import Alos from "../components/alos";

import Banner from "../components/BannerComponent";
import Cards from "../components/cards";
// import Editorials from "../components/editorials";
import Verticals from "../components/verticals";

class Main extends Component {
  componentDidMount() {
    this.loadHome();
  }

  loadHome() {
    const { getPodcastsRequest, getTracksRequest } = this.props;
    getPodcastsRequest();
    getTracksRequest();
  }

  render() {
    return (
      <>
        <Header />
        {/* <NavBar /> */}
        <main>
          {!!this.props.data && !!this.props.data.homeFeaturedAlos &&  <Alos homeFeaturedAlos={this.props.data.homeFeaturedAlos} />}
          {!!this.props.data && !!this.props.data.slides && <Banner slides={this.props.data.slides.data} />}
          {!!this.props.data && !!this.props.data.episodes && <Cards episodes={this.props.data.episodes.data} sectionTitle="Em Alta" icon={TrendingUp} />}
          {/* <Editorials episodes={EPISODES} /> */}
          {!!this.props.data && !!this.props.data.mainCategories && <Verticals verticals={this.props.data.mainCategories} />}
        </main>
      </>
    );
  }
}

const mapStateToProps = state => ({
  player: state.player,
  podcasts: state.podcasts,
  tracks: state.tracks
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...PodcastsActions, ...TracksActions, ...PlayerActions },
    dispatch
  );

const propsToOptions = (props) => {
  return (
    { variables: 
      { id: 1, 
        thumbWidth: 300, 
        thumbHeight: 300, 
        aloWidth: 600,  
        aloHeight: 200,
        banner_id: "2af95051-15e1-439d-ab6f-fa79689d861f"
      }
    }
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(compose(graphql(HOME_QUERY, { options: propsToOptions} ))(Main))
// export default connect(mapStateToProps,mapDispatchToProps)(Main);
