import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: var(--size-small);
  color: #000000;
  width: 400px;
  .title{
    font-weight: 400;
    margin-bottom: 6px;
  }
  .titulo-centro{
    text-align: center;
    font-weight: 600;
    color: #000000;
    margin-bottom: 15px;
    font-size: xx-large;
  }
  .subtitulo-centro{
    text-align: center;
    font-weight: 100;
    color: #7e8f9a;
    margin-bottom: 15px;
    font-size: large;
  }

  .content-actions {
    display: flex;
    justify-content: space-between;
  }
  .auth-button{
      margin-top: var(--size-base);
      background: #2196F3;
      color: #FFF;
      border-radius: 5px;
      width: 30%;
      height: 30px;
      font-weight: 700;
      float: right;
  }
  .auth-button-cancel{
    margin-top: var(--size-base);
    background: #FFF;
    color: #2196F3;
    border-radius: 5px;
    width: 30%;
    height: 30px;
    font-weight: 700;
    float: right;
    border: 3px #2196F3 solid;
    margin-right: 20px;
  }
  .auth-button-fechar{
    margin-top: var(--size-base);
    background: #FFF;
    color: #2196F3;
    border-radius: 5px;
    width: 30%;
    height: 30px;
    font-weight: 700;
    border: 3px #2196F3 solid;
  }
  .content-actions {
    display: flex;
    justify-content: space-between;
    .auth-button,
    .auth-button-cancel {
      width: auto;
      flex: 1;
      margin: var(--size-base) 0;
    }
    .auth-button {
      margin-right: 5px;
    }
    .auth-button-cancel {
      margin-left: 5px;
    }
  }
  .botao-centro{
    text-align: center;
    margin-top: 30px;
  }
  .title{
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: var(--size-medium);
  }
  .signatures{
    border: 1px dashed #D1D6D9; 
    padding: 19px 14px;   
    border-radius: 3px;
    font-family: Avenir;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: var(--size-medium);
    color: #000000;
    .tipo-status{
      display: flex;
      flex-direction: row;
      font-family: Avenir;
      .tipo{
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
        line-height: 33px;
      }
      .status{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        color: #6FCF97;
      }
    }
    .data-pagamento{
      display: flex;
      flex-direction: row;
      font-family: Avenir;
      font-size: 12px;
      line-height: 16px;
      .data{
        display: flex;
        justify-content: flex-start
      }
      .pagamento{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  
`;
