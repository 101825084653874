import React from "react";

import { CheckBoxWrapper } from "./Checkbox.style";

const Checkbox = ({ children, name }) => (
  <CheckBoxWrapper>
    <label className="container">
      {children}
      <input type="checkbox" name={name} />
      <span className="checkmark"></span>
    </label>
  </CheckBoxWrapper>
);

export default Checkbox;
