import React, { Component } from "react";

import EpisodeCard from "../EpisodeCardComponent";

export default class wVerticalComponent extends Component {
  render() {
    let { premium, episodes, top_episodes, code, thumb, name } = this.props.vertical;
    if(!premium) {
      premium = top_episodes[0];
    }
    if(!episodes){
      episodes = top_episodes;
    }

    return (
      <div className={`vertical-itens vertical-${code || name.toLowerCase()}`}>
        <div className="vertcial-thumb_wrapper">
          <img src={thumb} alt={name} />
        </div>
        <div className="card-premium">
          { premium.episde ? (<EpisodeCard key={premium.id} horizontal episode={premium} code />) : (<EpisodeCard key={premium.episode.id} horizontal episode={premium.episode} code />) }
        </div>
        {episodes.length && episodes.map(episode => {
          if(episode.episode){
            return <EpisodeCard key={episode.episode.id} horizontal episode={episode.episode} code />
          }else{
            return <EpisodeCard key={episode.id} horizontal episode={episode} code />
          }
        }
          
        )}
      </div>
    );
  }
}
