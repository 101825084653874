import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container } from "./Cover.style.js";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

//elements
import { Input } from "../../../elements/Input";
import { Button, StyledLink } from "../../../elements/CTA";

//elements
import { Title, Text } from "../../../elements/Typography";
import CoverCanal from "../../../assets/img/covercanal.png";
const Cover = (props) => {
  
    // console.log("minhas props ", props);
  
    return(
        <Container className="container white config-container">
            <div className="div1 bg-header-container">
               Escolha uma imagem para destacar seu canal
            </div>
            <div className="imgThumb">
                <img src={CoverCanal} />
            </div>
            <div className="divBtn row block-contanier-footer">
                <Link to="#" className="pular" onClick={() => props.back()}>
                    VOLTAR
                </Link>
                <Link to="#" className="pular" onClick={() => props.finish()}>
                    PULAR
                </Link>
                <div  className="btnEnviar">
                <div className="file-field input-field col s12">
                    <div className="btn button-primary">
                        <span>ENVIAR UMA IMAGEM</span>
                        <input type="file" onChange={ (event) => { props.updateState({ cover: event.target.files[0] }, () => props.submitForm() ) } } />
                    </div>
                    <div className="file-path-wrapper hidden">
                        <input className="file-path validate" type="text" />
                    </div>
                </div>
                    {/* <Button
                        type="submit"
                        className="auth-button"
                        onClick={() => props.submitForm()}
                    >
                            ENVIAR UMA IMAGEM
                    </Button>  */}
                </div>
            </div>
        </Container>
    )

}

export default Cover;
