import React from 'react';

const AboutChannelComponent = ({description}) => {
    return(
        <div className="channel-description">
            {description}
        </div>
    )
}

export default AboutChannelComponent;
