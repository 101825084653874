import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  .card-panel {
    height: 100%;
  }
`;

export const UserItem = styled.li`
  display: flex;
  align-items: center;
  margin: 40px 0;
  .user-info {
    display: flex;
    flex-direction: column;
    .user-info_email {
      color: #66757f;
      font-family: Avenir;
      font-size: 12px;
      line-height: 16px;
    }
    .user-info_status {
      color: #6fcf97;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .select-wrapper {
    background: #eeeeee;
    border-radius: 3px;
    padding: 0 5px;
    input.select-dropdown {
      border: none;
    }
  }
  .user-delete {
    button {
      color: #eb5757;
    }
  }
`;

export const AddUserWrapper = styled.div`
  margin: 40px 0;
  .add-user {
    color: #2196f3;
    border: 1px solid #2196f3;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .select-wrapper {
    background: #eeeeee;
    border-radius: 3px;
    padding: 0 5px;
    input.select-dropdown {
      border: none;
    }
  }
`;

export const NewUserForm = styled.form`
  border: 1px dashed #2196f3;
  padding: 13px 11px;
  
  .user-form_fields{
    margin-top: 20px;
  }
  .user-form_input {
    background: #eeeeee;
    border-radius: 3px;
    padding: 0 5px;
  }

`;
