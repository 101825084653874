import styled from "styled-components";

export const NavBarContainer = styled.div`
  
  margin-left: var(--size-small);
  margin-top: var(--size-small);
  width: 260px;
  height: 195px;
  .topo-configs{
    display: flex;
    flex-direction: row;
    margin-bottom: var(--size-small);
    img{
      width: 55px;
      height: 55px;
      border-radius: 50%;
    }
    .direita-config{
      font-family: Avenir;
      display: flex;
      flex-direction: column;
      
      color: #000000;
      margin-left: var(--size-small);
      .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;    
           
      }
      .plan{
        font-size: 12px;
        line-height: 16px;
        span:last-child{
          margin-left: var(--size-small);
          color: #2196F3;
        } 
      }
    }
  }
  .lista{
    background: #EEEEEE;
    .configs {
      border-bottom: 1px solid rgba(102, 117, 127, 0.3);
      color: #66757F;
      font-family: Avenir;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: var(--size-small);
      
      .icon{
        margin-top: var(--size-small);
        color: #66757F;
      
        margin-left: var(--size-small);
        height: 22px;
      }
    }
    .menu-item{
      
      margin-left: var(--size-small);
      margin-bottom: var(--size-small);
    }
  }
  @media (max-width: 992px) {
    margin-right: var(--size-small);
    display: flex;
    flex: 1;
    width: auto;
    height: auto;
    flex-direction: column;
  }
`;


export const PlayerWrapper = styled.article`
  width: 100%;
`;
