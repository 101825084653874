import React, { Component } from "react";

//styles
import { Container } from "./FinalizeRegistration.style.js";



//elements
import { Input } from "../../../elements/Input";
import { StyledLink } from "../../../elements/CTA";
import { Button } from '../../../elements/ButtonElement';
import { sendMessageToUser } from '../../../common';

//elements
import { Title, Text } from "../../../elements/Typography";
import LOADING from "../../../assets/img/loading.gif";

class FinalizeRegistration extends Component {
    _setModalScreen = (screen) => {
        this.props.setModalScreen(screen);
    }

    constructor(props){
        super(props);
        this.state = { ...props.state, loading: false };
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.submitForm();
    }

    submitForm = () => {
        // console.log("SUBMIT DO finalizaeRegistration");
        this.setState({loading: true});
        let { name, nickname, password} = this.state;
        if(name && nickname && password && name.length > 3 && nickname.length > 3 && password.length > 7 && password.match(/[a-z]/i) && password.match(/[0-9]/i)){
            this.setState({loading: false});
            this.props.submitForm();
        }else{
            if(name === undefined || name.length <= 3) sendMessageToUser('Você precisa preencher seu nome corretamente')
            if(nickname === undefined || nickname.length <= 3 || nickname.split(" ").length > 1) sendMessageToUser('Você precisa preencher seu nome de usuário corretamente')
            
            if(password === undefined || password.length < 8 || !password.match(/[a-z]/i) || !password.match(/[0-9]/i) ) sendMessageToUser('Sua senha precisa ter, no mínimo, 8 caracteres e conter letras e números')
            this.setState({loading: false});
        }
    }

    render(){
        // console.log("Minhas props",this.props);
        // console.log("Meu State",this.state);
        return(
            <Container>
                { this.state.loading &&
                    <div style={{display:"flex",flex:1,alignItems:"center",justifyContent:"center",position: "absolute", backgroundColor:"#fff",top:0,left:0,right:0,bottom:0,zIndex:999}}>
                        <img src={LOADING} />
                    </div>
                    }
                <Title size="1" content="Falta bem pouco para você dizer Ola para seus podcasts." typeClass="auth-title" />
                {/* <Title size="1" content="Degustação premium" typeClass="auth-title" /> */}
                {/* <h5 typeClass="auth-title" style={{textAlign:"center"}}>
                    {
                        !this.props.state.referrer ? "Preencha seu cadastro e ouça todos os podcasts por 15 dias grátis."
                        : "Preencha seu cadastro e ouça todos os podcasts por 30 dias grátis."
                    }
                </h5> */}
                <Text
                    typeClass="auth-selected-phone"
                    content={this.state.phone_or_email}
                />
                <form onSubmit={this.onSubmit}>
                <Input
                    name="name"
                    type="text"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    placeholder="Nome Sobrenome*"
                    borderRadius="3px"
                    background="var(--color-third)"
                    border="1px solid var(--color-third)"
                    value={this.state.name}
                    onChange={ (event) => { this.setState({name: event.target.value }); this.props.updateState({name: event.target.value}) } }
                />
                <Input
                    name="nickname"
                    type="text"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    placeholder="nome de usuário*"
                    borderRadius="3px"
                    background="var(--color-third)"
                    border="1px solid var(--color-third)"
                    value={this.state.nickname}
                    onChange={ (event) => { this.setState({nickname: event.target.value }); this.props.updateState({nickname: event.target.value}) } }
                />
                <Input
                    name="password"
                    type="password"
                    labelContent=""
                    width="100%"
                    labelClasses=""
                    placeholder="crie sua senha*"
                    borderRadius="3px"
                    background="var(--color-third)"
                    border="1px solid var(--color-third)"
                    value={this.state.password}
                    onChange={ (event) => { this.setState({password: event.target.value }); this.props.updateState({password: event.target.value}) } }
                />
                <Button
                    type="submit"
                    className="auth-button"
                    Background="var(--color-first)"
                    Color="var(--color-zero)"
                    loading={this.props.loading}
                    title="Criar uma Conta"
                />
                </form>

                <div className="auth-login">
                    <a
                        type="submit"
                        href="#"
                        className="login-button"
                        Color="var(--color-first)"
                        onClick={() => this._setModalScreen("register")}
                    >
                        Voltar
                    </a>
                </div>
            </Container>
        )
    }
}

export default FinalizeRegistration;
