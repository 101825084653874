import styled from "styled-components";

export const BlackMaskStyle = styled.section`
  .denuncia{
    display: flex;
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 20px;
    min-height: 350px;
  }
  
  .btn-fechar{
    display:flex;
    flex: 1;
    align-items: center;
    text-align: center;
    color: #000;
    justify-content: center;
    margin-top:5%;
    i{
      font-size: 50px;
    }
  } 
  @media (max-width: 600px) {
    .denuncia{
      width: 90%;
      margin: 25px 5%
    }
  }
`;
