export const USER_TYPE = `
    id
    email
    phone
    access_token
    premium
    signature_expire_at
    celco
    email_verified_at
    msisdn_verified_at
    expires_in
    refresh_token
    subscribed
    nestle
    profile {
        name
        nickname
        gender
        birth
        country_id
        state_id
        city_id
        profilePicture

    }
`;
