import React, { Component } from "react";

 //elements
 import { Title } from "../../../elements/Typography";

//component
import Layout from "../../../hoc/Layout/Layout";
import { MyChannelLine } from "../../../components/MyChannelLine";

//graphql
import { Query } from 'react-apollo';
import MyChanelQuery from './MyChannel.graphql';

//styles
import { Container } from "./MyChannel.styles";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class MyChannel extends Component {
    renderMyChannel(data) {
        const {
          podcasts,
          tracks,
          getCurrentTrack,
          getCurrentPodcast,
          player
        } = this.props;
        const {
            myChannels
        } = data;
        return (
            <Layout>
              <Container>
                <Title size="3" typeClass="trend-title" content="Meus canais" />
                {myChannels.map(myChannel => 
                    <MyChannelLine episode={myChannel} key={myChannel.id} />
                )}
              </Container>
            </Layout>
        );
      }
    render(){
        return(
            <Query query={MyChanelQuery}>
                {/* pollInterval={30000} fetchPolicy="network-only"> */}
                {
                    ({ loading, error, data, refetch }) => {
                        if (loading) return <div> {`Loading: ${loading}`} </div>;
                        if (error) {
                            if (error.networkError) {
                                // console.log("ERRO AQUI OH: ",error);
                                return <div> {`Error: no network`} </div>;
                            }
                            if (error.graphQLErrors) {
                                return <div> {`Error: ${error}`} </div>;
                            }
                            return <div> {`Error: ${error}`} </div>;
                        }
                        return this.renderMyChannel(data);
                    }
                }
            </Query>
        )
    }
}
