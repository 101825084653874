import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Thumb } from "./ThumbEpisode.styles";
import ThumbDefault from "../../assets/img/thumbDefault.jpg";

export const GetThumbChannel = props => {
    let {
        id,
        codename,
        thumbnail
    } = props.episode;
    let size = props.size;
    return(
        <Container className={size&&`${size}`}>
            <Link
                to={`/channels/${codename}`}
                className="thumb_image"
                style={{
                backgroundImage: `url(${thumbnail})`
                }}
            >
            </Link>
        </Container>
    )
}

export const ThumbEpisode = props => {
    const [myPlaylists, setMyPlaylists] = useState({});
    useEffect(() => {
        setMyPlaylists(props.data.myPlaylists)
    },[props.data.myPlaylists]);

    let channelsArray = [];
    let size = "episodeFull";
    let thumbs = [];
    let channelsId = [];

    myPlaylists &&
    myPlaylists.data &&
    myPlaylists.data[0].episodes_with_order.map((list) => {
        console.log(channelsArray.length)
        if (channelsArray.length < 4) {
            if(channelsId.indexOf(list.episode.channel.id) === -1) {
                channelsId.push(list.episode.channel.id)
                channelsArray.push(list.episode.channel)
            }
            // if(channelsArray.indexOf(list.episode.channel) && list.episode.channel.thumbnail ){
            //     console.log("list.episode.channel", list.episode.channel)
            //     channelsArray.push(list.episode.channel)
            // }
        }
    });
    switch(channelsArray.length){
        case 1:
            size = "episodeFull"
        break;
        case 2:
        case 3:
            size="episodeLine"
        break;
        case 4:
            size=""
        break;
    }

    if(channelsArray.length < 1)
        return (
            <Thumb>
                <GetThumbChannel key={0} 
                    episode={{ id: 0, thumbnail: ThumbDefault }} 
                    size={size} />
            </Thumb>
        )
    else
        channelsArray.map((channel,index) => {
            if(channelsArray.length == 3 && index > 0) { size="" }
            thumbs.push(<GetThumbChannel key={channel.id} episode={channel} codename={channel.codename} size={size} />);
        });

    return (
        <Thumb>
            {thumbs.map((thumb) => thumb)}
        </Thumb>
    )
    
}
