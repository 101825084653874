import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    input {
        margin-bottom: 19px;
        width: auto !important;
    }
    hr {
        height: 2px;
        border: none;
        background-color: #2296f3;
        margin: 18px 0 26px 0;
    }
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
    .positionOr {
        color: #2296f3;
        margin-top: -39px;
        margin-bottom: 17px;
        justify-content: center;
        display: flex;
        span {
            background-color: #fafafa;
            padding: 0 7px;
            font-weight: 900;
            letter-spacing: 1px;
        }
    }
    .recoveryAndRegister {
        font-size: 12px;
        margin-top: 8px;
        justify-content: space-between;
        display: flex;
        a {
            color: #0060ad;
        }
    }
    .login-button{
        padding: 10px 10px;
        font-size: 12px;
        cursor: pointer;
        background: var(--color-zero);
        color: #2196F3;
        border: 1px solid #2196F3;
        border-radius: var(--radius-small);
        text-transform: uppercase;
        font-family: "Avenir";
    }
    .auth-button {
        border: none;
        border-radius: 3px;
        outline: none;
        height: 3rem;
        width: auto;
        font-size: 12px;
        margin: 0 0 8px 0;
        padding: 0 5px;
        box-shadow: none;
        box-sizing: content-box;
        transition: box-shadow 0.3s, border 0.3s;
    }
`;

export const SocialContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 19px;
    align-items: center;
    justify-content: space-between;
    a:first-child {
        margin-left: 0;
        margin-right: 5px;
    }
    a:last-child {
        margin-right: 0;
        margin-left: 5px;
    }
    a {
        padding: 0 5px;
        font-size: 12px;
        min-height: 3rem;
        margin: 0 0 8px 0;
        cursor: pointer;
        background: var(--color-zero);
        color: #2196F3;
        border: 1px solid #2196F3;
        border-radius: var(--radius-small);
        text-transform: uppercase;
        font-family: "Avenir";
        display: flex;
        flex: 1;
        .icon {
            height: 35px;
            color: #2196F3;
        }
        span{
            display: flex;
            align-items: center;
        }
    }
`;
