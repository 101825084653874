import styled from "styled-components";

export const EditorialCardContainer = styled.article`
  margin-bottom: 10px;
  .channel-link {
    display: flex;
    flex-direction: column;
  }
  .card-wrapper_text {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    /* margin: var(--spacing-small); */
  }
  .card-top_text {
    display: flex;
    font-size: var(--size-small);
    margin-right: var(--spacing-small);
    margin-bottom: var(--spacing-small);
    line-height: 12px;
    letter-spacing: 0.05em;
    font-size: 10px;
    color: var(--color-third-darken);
  }
  .card-title_text {
    display: flex;
    font-size: var(--size-small);
    min-height: 36px;
    /* margin-right: var(--spacing-small); */
    margin-bottom: var(--spacing-small);
    line-height: 18px;
    letter-spacing: 0.05em;
    font-size: 16px;
    color: var(--color-negative);
  }
  .card-text {
    font-size: 13px;
    line-height: 18px;
  }
  .card-description_text {
    font-size: var(--size-small);
    margin-right: var(--spacing-small);
    line-height: 14px;
    letter-spacing: 0.05em;
    font-size: 14px;
    color: var(--color-negative);
  }
  .card-wrapper_img {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 7px;
  }
  .thumbEditorial {
    width: 100%;
    height: 0;
    padding: 0 0 50% 0;
  }

`;
