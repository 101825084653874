import styled from "styled-components";
import { Search } from "styled-icons/material/Search";

export const Container = styled.div`
  border: 20px solid #F5F5F5;
  padding: 24px;
  display: flex;
  flex:1;
  justify-content: flex-start;
  flex-direction: column;
 
  font-family: Avenir;
  font-size: 18px;
  line-height: 25px;
  .title{
    font-weight: 700;
    margin-bottom: var(--size-medium);
  }
  .ep{
    display: flex;
    flex-direction: row;
    /*margin-bottom: var(--size-small);*/
    border-top: 2px solid #efefef;
    padding-top: 12px;
    .episode-card{
      flex:4;
      .episode-thumb_image {
        min-width: 80px;
        min-height: 80px;
      }
      .wrapper-block{
        .queue-icon{
          display: none;
        }
      }
    }
    .menu-gerente{
      font-family: Avenir;
      font-size: 14px;
      line-height: 10px;
      color: #2196F3;
      flex:1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span{
        margin-left: var(--size-small);
        :last-child{
          color: #EB5757;
        }
      }
    }
  }
  @media (max-width: 992px) {
    border: 10px solid #F5F5F5;
    padding: 12px;
    .episode-card {
      margin-right: 5px;
      .wrapper-block {
        flex: 1;
      }
    }
    .episode-thumb_image {
      display: none !important;
    }
  }
`;

export const SearchIcon = styled(Search)`
  color: var(--color-third-darken);
  height: var(--size-medium);
  right: var(--size-small);
`;

export const WrapperSearch = styled.article`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: var(--size-small);
  border-bottom: 1px solid #66757F;

  .inputSearch {
    border: none;
    border-radius: var(--radius-medium);
    width: auto;
    flex: 3;
  }
  .drop{
    flex:2;
    color: #66757F;
    font-size: 13px;
  }
  
`;
