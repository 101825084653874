import gql from "graphql-tag";
import {EPISODE_TYPE} from './types/EPISODE';
import { CHANNEL_TYPE } from './types/CHANNEL';
export * from './querys/BannerQuerys';
export * from './querys/ChannelQuerys';

let ua = navigator.userAgent;
let contentFormat = "medium";
ua.lastIndexOf('Safari/') > 0 && ua.lastIndexOf('Chrome/') <= 0 ? contentFormat = "download_medium" : contentFormat = "medium";

export const HOME_QUERY = gql`
query Home($aloWidth: Int, $thumbWidth: Int, $aloHeight: Int, $top: String, $bottom: String) {
  top: slides(banner_id: $top, limit: 5){
    data {
      id
      title
      description
      action
      arguments
      hide_title
      hide_description
      visibility
      hint_position
      publishDate
      cover(width: 1200, height: 300)
      initialDate
      finalDate
      keywords
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  bottom: slides(code: $bottom){
    data {
      id
      title
      description
      action
      arguments
      hide_title
      hide_description
      visibility
      hint_position
      publishDate
      cover(width: 1200, height: 300)
      keywords
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  homeTrendsEpisodes(limit: 10){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")
      publishDate
      thumbnail(width: 400, height: 400)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  premium: episodes(original: true, limit: 20){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 400, height: 400)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }

  recommendedEpisodes(page: 1, limit: 20){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 400, height: 400)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    current_page
    has_more_pages
  }

  mainCategories {
    id
    name
    top_episodes{
      episode{
        id
        title
        rating
        duration
        description
        content(format: "${contentFormat}")

        playCount: play_count
        thumbnail(width: 400, height: 400)
        publishDate
        premium
        original
        channel {
          id
          name
          codename
          thumbnail
          premium
          original
          flag
        }
      }
    }
  }
  topChannels(page: 1, limit: 10){
    data {
      channel {
        id
        name
        codename
        thumbnail(width: 400, height: 400)
      }
    }
    total
    current_page
    has_more_pages
  }
  homeFeaturedAlos{
    id
    codename
    thumbnail(width: $thumbWidth, height: $thumbWidth)
    cover(width: $aloWidth, height: $aloHeight)
    alos{
      id
      content(format: "download_medium")
      channel{
        name
        codename
        main_category {
          id
        }
        thumbnail
        cover(width: $aloWidth, height: $aloHeight)
        premium
        original
        flag
      }
      # aloCms{
      #   id_job
      #   alo_id
      #   status
      #   description
      # }
    }
  }
  editorialList(limit: 3){
    data{
      id
      olho
      titulo
      subtitulo
      cover_2x(width: 1000, height: 500)
    }
  }
}`;
export const HOME_QUERY_ALL_PLAYBACKS = gql`
query HomeAndPlaybacks($aloWidth: Int, $thumbWidth: Int, $aloHeight: Int, $top: String, $bottom: String) {
  allPlaybacks {
    position
    episode {
      id
      title
      rating
      restricted
      description
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 200, height: 200)
      premium
      original
      external
      channel {
        id
        codename
        flag
        premium
        original
      }
    }
  }
  userAllEpisodesFollowing(page: 1, limit: 10){
    data{
      id
      title
      rating
      restricted
      description
      duration
      thumbnail
      premium
      original
      content(format: "${contentFormat}")

      publishDate
      tags
      external
      channel{
        id
        thumbnail
        premium
        original
        flag
        codename
      }
    }
    total
    current_page
    has_more_pages
  }
  top: slides(banner_id: $top, limit: 5){
    data {
      id
      title
      description
      action
      arguments
      hide_title
      hide_description
      visibility
      hint_position
      publishDate
      cover(width: 1200, height: 300)
      initialDate
      finalDate
      keywords
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  bottom: slides(code: $bottom){
    data {
      id
      title
      description
      action
      arguments
      hide_title
      hide_description
      visibility
      hint_position
      publishDate
      keywords
      cover(width: 1200, height: 300)
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  homeTrendsEpisodes(limit: 10){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 200, height: 200)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
  premium: episodes(original: true, limit: 20){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 200, height: 200)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }

  recommendedEpisodes(page: 1, limit: 20){
    data{
      id
      title
      rating
      description
      playCount: play_count
      duration
      content(format: "${contentFormat}")

      publishDate
      thumbnail(width: 200, height: 200)
      premium
      original
      external
      channel{
        id
        codename
        flag
        premium
        original
      }
    }
    total
    current_page
    has_more_pages
  }

  mainCategories {
    id
    name
    top_episodes{
      episode{
        id
        title
        rating
        duration
        description
        content(format: "${contentFormat}")

        playCount: play_count
        thumbnail(width: 200, height: 200)
        publishDate
        premium
        original
        channel {
          id
          name
          codename
          thumbnail
          premium
          original
          flag
        }
      }
    }
  }
  topChannels(page: 1, limit: 10){
    data {
      channel {
        id
        name
        codename
        thumbnail(width: 200, height: 200)
        premium
        original
      }
    }
    total
    current_page
    has_more_pages
  }
  homeFeaturedAlos{
    id
    codename
    thumbnail(width: $thumbWidth, height: $thumbWidth)
    cover(width: $aloWidth, height: $aloHeight)
    alos{
      id
      content(format: "download_medium")
      channel{
        name
        codename
        main_category {
          id
        }
        thumbnail
        cover(width: $aloWidth, height: $aloHeight)
        premium
        original
        flag
      }
      # aloCms{
      #   id_job
      #   alo_id
      #   status
      #   description
      # }
    }
  }
  editorialList(limit: 3){
    data{
      id
      olho
      titulo
      subtitulo
      cover_2x(width: 1000, height: 500)
    }
  }
}`;


export const SEARCH_QUERY = gql`
  query SearchQuery($filter: String, $page_channels: Int, $page_episodes: Int){
    episodes(filter: $filter, page: $page_episodes){
      data{
          id
          title
          rating
          description
          playCount: play_count
          duration
          content(format: "${contentFormat}")

          publishDate
          thumbnail(width: 400, height: 400)
          premium
          original
          external
          channel{
              codename
              flag
              premium
              original
          }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
    channels(filter: $filter, page: $page_channels, limit: 10){
      data {
        id
        name
        codename
        main_category {
          id
        }
        flag
        premium
        original
        thumbnail
        # cover(width: 1200, height: 300)
        followers_count
        following
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
    feedChannels(filter: $filter, page: $page_channels, limit: 10){
      data{
        id
        name
        codename: name
        description
        flag
        thumbnail
        following
        followers_count
        main_category {
          id
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
   
  }
`;



export const BUSCA_COMPLAINT_QUERY = gql`
  query complaintTypes($object_type: String!){
    complaintTypes(object_type: $object_type){
      id
      description
      subtypes{
        id
        description
      }
    }
  }
`;


export const CHANNEL_QUERY_BY_ID = gql`
  query GetChannel($channel_id: Int!){
    channels(id: $channel_id){
      data{
        id
        name
        codename
        description
        flag
        premium
        original
        thumbnail(width: 400, height: 400)
        cover(width: 1200, height: 300)
        episodes(limit: 10){
          data{
              id
              title
              rating
              description
              playCount: play_count
              content(format: "${contentFormat}")

              publishDate
              thumbnail(width: 400, height: 400)
              premium
              original
              external
          }
          total
          per_page
          current_page
          from
          to
          last_page
          has_more_pages
        }
        followers_count
        following
        main_category{
          id
          name
        }
        alternate_category{
          id
          name
        }
      }
    }
  }
`;

export const CURRENT_PLAYBACK = gql`
  query currentPlayback{
    currentPlayback{
      position
      episode{
        id
        title
        rating
        duration
        play_count
        publishDate
        premium
        original
        content(format: "${contentFormat}")

        thumbnail
        external
        channel{
          id
          codename
          flag
          premium
          original
          thumbnail
          cover(width: 1200, height: 300)
        }
      }
    }
  }
`;
export const USER_CHANNELS_FOLLOWING = gql`
  query userFeedChannelsFollowing($page_channels: Int, $page_episodes: Int){
    userAllEpisodesFollowing(page: $page_episodes, limit: 30){
      data{
        id
        title
        rating
        description
        duration
        thumbnail
        premium
        original
        content(format: "${contentFormat}")

        publishDate
        tags
        external
        channel{
          id
          thumbnail
          premium
          original
          flag
          codename
        }
      }
      total
      current_page
      has_more_pages
    }
    userFeedChannelsFollowing(page: $page_channels, limit: 30){
      data{
        id
        name
        # codename: name
        description
        flag
        thumbnail
        following
        followers_count
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
    userChannelsFollowing(page: $page_channels, limit: 30){
      data{
        id
        name
        codename
        main_category {
          id
        }
        description
        flag
        premium
        original
        thumbnail(width: 400, height: 400)
        cover(width: 1200, height: 300)
        followers_count
        following
        alos{
          id
          content(format: "download_medium")
          channel{
            codename
            main_category {
              id
            }
            thumbnail
            cover
          }
          # aloCms{
          #   id_job
          #   alo_id
          #   status
          #   description
          # }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
 
`;

export const BUSCA_FEED_RSS = gql`
  query feedChannels($id: String, $filter: String, $page: Int, $limit: Int){
    feedChannels( id: $id, filter: $filter, page: $page, limit: $limit){
      data{
        id
        name
        codename: name
        description
        flag
        thumbnail
        following
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
}
`;

export const BUSCA_FEED_EPISODES_RSS = gql`
  query feedChannels($id: String, $filter: String, $page: Int, $limit: Int, $page_episodes: Int){
    feedChannels( id: $id, filter: $filter, page: $page, limit: $limit){
      data{
        id
        name
        codename: name
        description
        flag
        thumbnail
        following
        followers_count
        episodes(page: $page_episodes, limit: 10){
          data{
            id
            title
            rating
            description
            duration
            thumbnail
            content(format: "${contentFormat}")

            publishDate
            external
            channel{
              id
              thumbnail
              codename: name  
              flag
            }
          }
          total
          per_page
          current_page
          from
          to
          last_page
          has_more_pages
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
}
`;

export const CHANNEL_QUERY_BY_CODENAME = gql`
  query GetChannel($channel_codename: String!, $page_episodes: Int!){
    channels(codename: $channel_codename){
      data{
        id
        name
        codename
        description
        flag
        premium
        original
        thumbnail(width: 400, height: 400)
        cover(width: 1200, height: 300)
        episodes(page: $page_episodes, limit: 10){
          data{
              id
              title
              rating
              description
              playCount: play_count
              content(format: "${contentFormat}")

              publishDate
              thumbnail(width: 400, height: 400)
              premium
              original
              external
              channel{
                id
                name
                codename
              }
          }
          total
          per_page
          current_page
          from
          to
          last_page
          has_more_pages
        }
        followers_count
        following
        main_category{
          id
          name
        }
        alternate_category{
          id
          name
        }
      }
    }
  }
`;
export const PROFILE_QUERY = gql`
  query profile{
    profile(
      refresh: true
    ){
      id
      email
      phone
      access_token
      premium
      subscribed
      signature_expire_at
      celco
      email_verified_at
      msisdn_verified_at
      expires_in
      refresh_token
      profile{
        name
        nickname
        gender
        birth
        country_id
        state_id
        city_id
        profilePicture
      }
    }
  }
`;
export const EPISODE_QUERY = gql`
    query getEpisode($episode_id: Int, $epThumbWidth: Int, $epThumbHeight: Int){
      episodes(id: $episode_id){
        data{
          id
          title
          rating
          duration
          tags
          description
          play_count
          publishDate
          block_comments
          block_likes
          content(format: "${contentFormat}")

          external
          thumbnail(width: $epThumbWidth, height: $epThumbHeight)
          likes
          dislikes
          userLike
          userDislike
          premium
          original
          favorited
          comments{
            data{
              id
              body
              user{
                id
                profile{
                  name
                  profilePicture
                }
              }
              userLiked
              userDisliked
              amountLikes
              amountDislikes
              amountReplies
              updatedAt
              replies{
                data{
                  id
                  body
                  user{
                    id
                    profile{
                      name
                      profilePicture
                    }
                  }
                  userLiked
                  userDisliked
                  amountLikes
                  amountDislikes
                  updatedAt
                }
              }
            }
            total
            per_page
            current_page
            has_more_pages
          }
          channel{
            id
            name
            codename
            main_category {
              id
            }
            thumbnail
            cover(width: 1200, height: 300)
            flag
            owner{
              profile{
                name
                profilePicture
              }
            }
          }
        }

      }
    }
`;

export const RECOMEND_EPISODE_QUERY = gql`
  query getEpisode($category_id: Int, $epThumbWidth: Int, $epThumbHeight: Int){
    recommendedEpisodes(category_id: $category_id, limit: 4){
        data{
          id
          title
          rating
          duration
          description
          play_count
          publishDate
          content(format: "${contentFormat}")

          premium
          original
          external
          thumbnail(width: $epThumbWidth, height: $epThumbHeight)
          channel{
            id
            codename
            thumbnail
            flag
          }
        }
      }
    }
`;

export const CHECK_PASSWORD = gql`
  query($password:String!){
    checkPassword(password: $password)
  }
`;

export const NEXT_EP_TO_PLAY = gql`
    query nextEpisodeToPlay($id: Int!){
      nextEpisodeToPlay(id: $id){
          id
          title
          rating
          description
          playCount: play_count
          duration
          content(format: "${contentFormat}")

          publishDate
          thumbnail(width: 400, height: 400)
          premium
          original
          external
          channel{
              id
              codename
              flag
              premium
              original
          }
      }
    }
`;

export const NEXT_EPS_TO_PLAY = gql`
    query nextEpisodesToPlay($id: Int!){
      nextEpisodesToPlay(id: $id){
          id
          title
          rating
          description
          playCount: play_count
          duration
          content(format: "${contentFormat}")

          publishDate
          thumbnail(width: 400, height: 400)
          premium
          original
          external
          channel{
              id
              codename
              flag
              premium
              original
          }
      }
    }
`;