import styled from "styled-components";

export const Card = styled.article.attrs({
  className: props => props.cardClass
})`
  display: flex;
  background: ${props =>
    !!props.cardImg
      ? `url(${props.cardImg}) no-repeat center center`
      : props.bgColor};

  width: ${props => props.w};
  background-size: cover;
  height: ${props => props.h};
  flex-direction: ${props => props.flexDirection};
  border-radius: ${props => props.borderRadius};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  padding: var(--spacing-small);
  color: var(--color-zero);
`;
