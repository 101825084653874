import React, { Component } from "react";

import {
  Container,
  UserItem,
  AddUserWrapper,
  NewUserForm
} from "./ChannelUserAccess.style.js";

import { ADMIN_USERS_QUERY , ADMIN_USERS_INVITE_MUTATION } from './ChannelUserAccess.graphql';
import { compose } from 'recompose';
import { Query, Mutation, graphql } from 'react-apollo';

import M from "materialize-css";
import { sendMessageToUser } from "../../common";

class ChannelUserAccess extends Component {
  state = {
    enableUserForm: false,
    displayName: '', 
    email: '', 
    type: ''
  };
  componentDidMount() {
    let elems = document.querySelectorAll("select");
    let instances = M.FormSelect.init(elems, {});
  }

  toggleForm = () => {
    if (this.state.enableUserForm) this.setState({ enableUserForm: false });
    else this.setState({ enableUserForm: true });
  };
  onSubmit = (event) => {
    event.preventDefault();
    this.submitForm();
  }
  submitForm = () => {
    let { displayName, email, type } = this.state
    if( !displayName || !displayName.length > 3 ){
      sendMessageToUser('Você precisa digitar um nome!')
    } else if( !email.indexOf('@') > -0 && !email.indexOf('.') > 0 ){
      sendMessageToUser('Você precisa digitar um e-mail válido para continuar!')
    } else if( !type.length || !type.length > 3 ){
      sendMessageToUser('Você precisa digitar a função!')
    } else {
      sendMessageToUser('Ação submit')
      // console.log("THIS PROPS: ",this.props);
      let { channel_id } = this.props;
      let convite = {
        idChannel: channel_id,
        mail: email,
        type
      }
      // console.log("VARIABLE: ", convite);
      this.props.mutate({mutation: ADMIN_USERS_INVITE_MUTATION, variables: convite, errorPolicy: 'all' })
      .then(({data, errors}) => {
        if(errors){
          // console.log("ERROR: ", errors)
          let { validation } = errors[0];
        } else {
          // console.log("DATA: ",data)
        }
      });
    }
}

  render() {
    const { users } = this.props;
    const { managerTypes, channelManagers } = this.props.data;
    // console.log("PROPS: ", this.props);
    return (
      <Container>
        <div className="card-panel">
          <div className="card-title">
            <h6>Permissões e Usuários</h6>
          </div>
          <div className="card-body">
            <ul>
              {
                channelManagers &&
                channelManagers.map(
                  (managers,index) => (
                    <UserItem key={`userItem-${index}`} className="row">
                      <div className="col s1 user-image">
                        <img src={managers.profileImage} alt={managers.displayName} />
                      </div>
                      <div className="col s5 user-info">
                        <strong className="user-info_name">
                          {managers.displayName}
                        </strong>
                        <span className="user-info_email">{managers.manager_email}</span>
                        <span className="user-info_status">{managers.status}</span>
                      </div>
                      <div className="col s4 ">
                        <select className="browser-default">
                          {
                            managerTypes && 
                            managerTypes.map(
                              (type, index) => {
                                // console.log("TYPE: ",type.manager_type," - MANAGER TYPE: ",managers.manager_type)
                                if(type.manager_type) {
                                  if(type.manager_type == managers.manager_type){
                                    return <option key={`option-type-${index}`} defaultValue={managers.manager_description} value={type.manager_type}>{type.manager_description}</option>
                                  } else {
                                    return <option key={`option-type-${index}`} defaultValue={managers.manager_description} value={type.manager_type}>{type.manager_description}</option>
                                  }
                                } else {
                                  return false;
                                }
                              }
                            )
                          }
                        </select>
                      </div>
                      <div className="col s2 user-delete right-align">
                        <button className="waves-effect waves-red btn-flat">
                          Remover
                        </button>
                      </div>
                    </UserItem>
                  )
                )
              }
            </ul>
          </div>

          {!!this.state.enableUserForm && (
            <NewUserForm onSubmit={this.onSubmit}>
              <strong className="user-form_title" >Convidar usuário para gerenciar o canal</strong>
              <div className="row user-form_fields">
                <div className="col s4">
                  <input
                    type="text"
                    className="user-form_input"
                    placeholder="NOME"
                    value={this.state.displayName}
                    onChange={ (event) => { this.setState({displayName: event.target.value }) } }
                  />
                </div>
                <div className="col s4">
                  <input
                    type="text"
                    className="user-form_input"
                    placeholder="E-MAIL"
                    value={this.state.email}
                    onChange={ (event) => { this.setState({email: event.target.value }) } }
                  />
                </div>
                <div className="col s4">
                  <input
                    type="text"
                    className="user-form_input"
                    placeholder="FUNÇÃO"
                    value={this.state.type}
                    onChange={ (event) => { this.setState({type: event.target.value }) } }
                  />
                </div>
              </div>
              <div className="row right-align">
                <button type="button" className="waves-effect waves-blue btn-flat">
                  Cancelar
                </button>
                <button type="submit" className="waves-effect waves-blue  color blue  btn">
                  Convidar
                </button>
              </div>
            </NewUserForm>
          )}

          <AddUserWrapper className="card-footer">
            <button
              className="waves-effect waves-blue btn-flat add-user"
              onClick={this.toggleForm}
            >
              {!!this.state.enableUserForm
                ? "Mostrar menos"
                : "Adicionar usuários"}
            </button>
          </AddUserWrapper>
        </div>
      </Container>
    );
  }
}

export default compose( 
  graphql(ADMIN_USERS_QUERY, {
    options: (props) => ({ variables: { idChannel: props.channel_id } })
  }),
  graphql(ADMIN_USERS_INVITE_MUTATION),
)(ChannelUserAccess);
