import styled from "styled-components";

export const Container = styled.main`
  background: #fff;
  height: 100%;
  width: 100%;
  color: var(--color-zero);

  .auth-back_button,
  .player-container,
  .auth-footer {
    display: none;
  }
  .auth-footer {
    margin-top: 90px;
  }

  @media (min-width: 800px) {
    background: #271581;
    & {
      padding: var(--spacing-big);
    }
    .player-container {
      display: flex;
    }
    .auth-footer {
      display: block;
    }

    .auth-back_button {
      display: flex;
      img {
      }
      span {
        margin-left: 5px;
      }
    }
  }
`;

export const PlayerContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 583px;

  .player-container_title {
    color: #ffffff;
    font-family: "Avenir-Book";
    font-size: 50px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    letter-spacing: -1.811594px;
  }
  .player-container_subtitle {
    color: #e7dff9;
    font-family: "Avenir-Medium";
    font-size: 40px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.48px;
    padding: 1rem;
  }
`;

export const AuthForm = styled.article`
  min-width: 320px;
  max-width: 534px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  color: var(--color-negative-darken);
  background: var(--color-zero);
  display: flex;
  align-items: center;
  padding: 27.5px;

  .auth-logo {
    height: auto;
    width: 143px;
    margin-bottom: 40px;
  }
  .auth-title {
    font-family: "Avenir-Light";
    font-size: 30px;
    color: #313131;
    line-height: 40px;
    letter-spacing: -1.086957px;
    text-align: center;
    margin-bottom: 20px;
  }
  .auth-subtitle {
    font-family: "Avenir-Book";
    font-size: var(--size-small);
    line-height: 20px;
    letter-spacing: -0.336px;
    text-align: center;
    margin-bottom: 45px;
  }
  .auth-button {
    height: 45px;
    font-family: "Avenir-Medium";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.636364px;
    margin-bottom: 5px;
  }

  .auth-login {
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 40px;
    .login-button {
      width: 34.59px;
      height: 19px;
      color: #0e53db;
      font-family: "Avenir-Medium";
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-decoration: underline;
      letter-spacing: -0.336px;
      margin-left: 5px;
    }
  }
  input {
    height: 40px;
    border-radius: 3px;
    border: solid 1px #d1d3d7;
    background: #f6f8fc;
    margin-bottom: 8px;
  }
  @media (min-width: 600px) {
    & {
      margin: 0 auto;
      max-width: 535px;
    }
  }
`;
