import styled from "styled-components";

export const Container = styled.section`
    .modal {
        max-width: 535px;
        max-height: 660px;
        padding: 39px;
    }
    .auth-logo {
        height: auto;
        width: 100%;
        display: flex;
        margin: 0 auto 51px;
        img {
            width: 129px;
        }
        align-items: center;
        justify-content: center;
    }
    .auth-super-title {
        font-family: "Avenir";
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        text-align: center;
    }
    .auth-title {
        font-family: "Avenir";
        font-size: 35px;
        line-height: 35px;
        font-weight: 700;
        text-align: center;
    }
    .auth-subtitle {
        font-family: "Avenir-Book";
        font-size: var(--size-small);
        line-height: 25px;
        -webkit-letter-spacing: -0.336px;
        -moz-letter-spacing: -0.336px;
        -ms-letter-spacing: -0.336px;
        letter-spacing: -0.336px;
        text-align: center;
        margin-bottom: 45px;
    }
    .auth-selected-phone {
        font-family: Avenir;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 14px;
    }
    input {
        height: 40px;
        border-radius: 3px;
        background: #EEEEEE;
        margin-bottom: 15px;
    }
    .auth-button {
        background: #2196F3;
        color: #fff;
        border-radius: 3px;
        font-family: Avenir;
        font-size: 11.5px;
        line-height: 16px;
        margin-bottom: 15px;
    }
    .auth-login, .auth-login-back {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .auth-login-back {
        a {
            text-transform: none;
        }
    }
    .login-button {
        background-color: transparent;
        border: none;
    }
`;
