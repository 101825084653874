import styled from "styled-components";

import ImgChannel from "../../../src/assets/img/torresmo-image.png";

export const Container = styled.section`
    .adjustToCollon {
        display: flex;
    }
    .imageContent {
        display: flex;
        flex: 1;
        .boxcontent{
            width: 80%;
            height: 0;
            padding-bottom: 80%;
            float: left;
            position: relative;
            /*
            background-color: #fff;
            border: 3px solid #2196F3;
            */
            border-radius: 15px;
            span {
                position: absolute;
                top: 5px;
                bottom: 5px;
                left: 5px;
                right: 5px;
                border-radius: 9px;
                background-color: #597e9c;
                opacity: 0.5;
                background-image: url(${ImgChannel});
                background-repeat: no-repeat;
                background-size: cover;
                background-blend-mode: soft-light;
            }
            div {
                position: absolute;
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                width: 100%;
                height: 100%;
                padding: 10%; 
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center; 
                color: #fff;
                font-weight: 900;
                .icon-item {
                    width: 20px;
                }
            }
            .midiaupload{
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
    .formContent {
        display: flex;
        flex: 5;
        flex-direction: column;
    }
    .inputContent {
        display: flex;
        flex-direction: column;
        input {
            width: auto;
            height: 40px;
            border-radius: 3px;
            margin-bottom: 18px;
        }
        input:not([type]):focus:not([readonly]), 
        input[type="text"]:not(.browser-default):focus:not([readonly]) {
            border: none;
            box-shadow: none;
        }
    }
    .contentSwitch {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        margin-bottom: 18px;
        .switch {
            margin-right: 16px;
            label {
                .lever {
                    margin-left: 0;
                }
            }
        }
    }
    .textareaContent {
        textarea {
            width: 100%;
            min-height: 100px;
            border-radius: 3px;
            padding: 13px;
            margin-bottom: 18px;
            border: none;
            box-shadow: none;
        }
    }
    .contentBotton {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        a, button {
            line-height: 35px;
            height: 35px;
            text-transform: uppercase;
            font-size: 12px;
        }
        button {
            background: #2196F3;
            border-radius: 3px;
            color: #fff;
            width: auto;
            margin-left: 12px;
            padding: 0 var(--spacing-default)
        }
    }
    input,textarea {
        width: auto;
        height: 22px;
        padding: 7px 14px;
        margin-bottom: 15px;
        font-family: Avenir;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        border: 1px solid #dbdbdb !important;
        color: #313131;
        background: #fff;
    }

    input::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal }
    input:-moz-placeholder { color: #d5d5d5; font-weight: normal }
    input::-moz-placeholder { color: #d5d5d5; font-weight: normal }
    input:-ms-input-placeholder { color: #d5d5d5; font-weight: normal }
    textarea::-webkit-input-placeholder { color: #d5d5d5; font-weight: normal }
    textarea:-moz-placeholder { color: #d5d5d5; font-weight: normal }
    textarea::-moz-placeholder { color: #d5d5d5; font-weight: normal }
    textarea:-ms-input-placeholder { color: #d5d5d5; font-weight: normal }
`;
