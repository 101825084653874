import React, { Component } from "react";

import M from "materialize-css";
import { AngleLeft, AngleRight } from "styled-icons/fa-solid";

//component
import EpisodeCard from '../../../../components/EpisodeCardComponent';
import ChannelCard from "../../../../components/ChannelCard/ChannelCard";

import {
    Container,
} from "./ListEmAlta.style";

export default class ListEmAlta extends Component {
    componentDidMount(){
    }
    render() {
        return(
            <Container>
                {
                    this.props.channels.map(
                        channel => {
                            return(
                                <ChannelCard 
                                    key={`channelC-${channel.id}`}  
                                    channel={channel} 
                                />
                            )
                        }
                    )
                }
            </Container>
        )
    }
}
