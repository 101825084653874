import React, { Component } from "react";
import { sendMessageToUser } from '../../common';

export default class AloComponent extends Component {
  componentDidMount() {}

  render() {
    const { thumbnail, codename, alos, cover } = this.props.channel;
    const ACCESS_TOKEN = 'user_token';
    const USER_ACCOUNT = 'UserAccount';

    const userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));
    const blockAlo = alos[0].channel.premium && (!localStorage.getItem(ACCESS_TOKEN) || !userAccount.subscribed);
    
    return (
      <div className="carousel-item">
        {!!alos && !!alos[0].channel && !!alos[0].content &&
          <div className="alo">
            <div
              data-target={blockAlo ? "" : "ola-modal"}
              className={`alo-link alo-border--listened btn-floating btn-large waves-effect waves-light modal-trigger`}
              onClick={() => {
                if(blockAlo){
                  sendMessageToUser('Conteúdo exclusivo para assinantes! Seja premium agora!')
                }else{
                  const PLAYER_UPDATE = new Event('playerupdate');
                  document.player.pause();
                  document.dispatchEvent(PLAYER_UPDATE);

                  var ev = new CustomEvent("playAlo", { 'detail': alos });
                  document.dispatchEvent(ev);
                }
              }}
              
            >
              <div className="thumbAlo" style={{ backgroundImage: `url(${thumbnail})` }}></div>
            </div>
            <div className="code-name">
              <span>{codename}</span>
            </div>
          </div>
        }
      </div>
    );
  }
}
