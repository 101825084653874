import React, { Component } from "react";

import {
  BlackMaskStyle,
} from "./BlackMask.style.js";

import { List } from "../../components/Playlist";
import { Complaint } from "../../components/Complaint";

class BlackMask extends Component {

  componentDidMount() {
    document.addEventListener("abreBlackMask", this.abreBlackMask);
    document.addEventListener("fechaBlackMask", this.fecharBlackMask);
  }

  abreBlackMask = async ({detail}) => {
    // this.props.updateState({showBlackMask: true})
    await this.setState({
      episode: detail.episode,
      modalType: detail.modalType,
      showBlackMask: true,
    });

    // // console.log("ABRRE BLACK MASK: ",detail);
  }
  fecharBlackMask = () => {
     this.setState({showBlackMask: false})
  }


  render() {
      
      if(!!this.state && this.state.showBlackMask){
        // // console.log("Estado dentro do BlackMask: ",this.state);
        return (
          <BlackMaskStyle id="blackMask">
            {this.state.modalType == 'choosePlaylist' ?
              <div className="playList">
                   <List episode={this.state.episode} scope="addEpPlaylist"/> 
              </div>
            :
              <div className="denuncia"><Complaint type="EPISODE" object={this.state.episode}/></div>
            }
          </BlackMaskStyle>
        );
      } else {
        return "";
      }
  }
}

export default (BlackMask);
