import React from "react";

import {
  Container,
  Trends,
  PremiumBox,
} from "./VerticalContent.styles";

import Banner from "../../../components/BannerComponent";

import { Title } from "../../../elements/Typography";
import { Wrapper } from "../../../elements/Layout";

//component
import EpisodeCard from '../../../components/EpisodeCardComponent';

import Layout from "../../../hoc/Layout/Layout";

import PremiumBadge from "../../../assets/img/premiumBadge.png";

import { VerticalQuery } from './vertical.graphql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';

//icons
import {
  TrendingUp,
} from "styled-icons/boxicons-regular";

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";
const VerticalContent = props => {

      // console.log("Props Vertical: ", props);
     return (
      <Layout>
        <Container>
          <PremiumBox className="premium-container">
          {!!props.data && !!props.data.slides && <Banner slides={props.data.slides.data} />}
              <Trends className="trends">
                  <Wrapper
                    alignItems="center"
                    justifyContent="flex-start"
                    className="trends-header"
                  >
                    <TrendingUp className="home-icon" />
                      <img src={PremiumBadge} className="premium-badge" />
                    <Title size="5" typeClass="trend-title" content="CONTEUDO PREMIUM" />
                  </Wrapper>
                  {!!props.data && !!props.data.mainCategories && props.data.mainCategories.map((mainCat,indice) => 
                  <>
                  <Wrapper className="trend-list">
                    {mainCat.top_episodes.map((episodeOrder) => {
                      if(!!episodeOrder && !!episodeOrder.episode && episodeOrder.episode.id > 0){
                        return <EpisodeCard key={episodeOrder.episode.id} episode={episodeOrder.episode} />
                      }
                    })}
                  </Wrapper>
                  </>
                )}
              </Trends>
              
        </PremiumBox>

      </Container>
      </Layout>
     );
}

const propsToOptions = (props) => {
  
  return ({ 
    
      variables: 
        { 
          mainCategory: props.match.params.id, 
          thumbWidth: 300, 
          thumbHeight: 300,
          coverWidth: 800, 
          coverHeight: 300,
          code: 'vertical-content-1'
        }
    }
  )
}
export default compose(graphql(VerticalQuery, { options: propsToOptions }))(VerticalContent);
