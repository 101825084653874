import React, { Component } from "react";

//component
import Layout from "../../hoc/Layout/Layout";
import { ModalCreateChannel } from "../../components/ModalCreateChannel";
import { PlanosAssinatura } from "../../screens/Main/PlanosAssinatura";
import { USER_ACCOUNT } from '../../constants';

import OlaFrontWeb from "../../assets/img/OlaFrontWeb.jpg";


class CreateChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      naoPodeCriar: false,
    };   
  }
  componentDidMount(){
    let myChannels = JSON.parse(localStorage.getItem("MY_CHANNELS"));
    let userAccount = JSON.parse(localStorage.getItem(USER_ACCOUNT));

    if(!userAccount.subscribed && !!myChannels && myChannels.length >= 1){
      this.setState({naoPodeCriar: true});
    }else{
      this.setState({naoPodeCriar: false});
    }
  }

  render() {
    if(this.state.naoPodeCriar){
      return (
          <PlanosAssinatura />
      );
    }else{
      return (
        <Layout>
          <ModalCreateChannel />
       </Layout>
      );
    }
  }
}

export default CreateChannel;
