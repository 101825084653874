import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: var(--spacing-default);
    .colunLeft {
        flex: 1;
        padding-right: var(--spacing-default);
        .content-thumbs {
            clear: both;
        }
        .contentTitle {
            clear: both;
            padding: 20px 0;
        }
        .play-all {
            background: #2196F3;
            border-radius: var(--radius-small);
            line-height: 35px;
        }
    }
    .colunRight {
        display: flex;
        flex: 2;
        flex-direction: column;
        .contentDropdown {
            margin-bottom: var(--spacing-default);
            .Dropdown-control {
                border-color: #2196F3;
                color: #2196F3;
                width: max-content;
                border-radius: var(--radius-small);
                padding-right: var(--spacing-very-big);
            }
        }
        .episode-card {
            margin-bottom: var(--spacing-medium);
            box-shadow: none;
        }
        .contentEpisode {
            flex-direction: row;
            display: flex;
            .contentIndice {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
`;
