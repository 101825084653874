import gql from "graphql-tag";
const CategoryFollowMutation = gql`
mutation($channel_id: Int!, $unfollow: Boolean){
  followChannel(channel_id: $channel_id, unfollow: $unfollow){
    id
    name
    codename
    description
    following
  }
}
`;

export default CategoryFollowMutation;
