import gql from "graphql-tag";

const TelaPremiumQuery = gql`

query TelaPremium($top: String!, $bottom: String!, $premium: Boolean, $original: Boolean,$limit: Int!, $page_channels: Int,$limitChannel: Int! ,$thumbWidth: Int!, $thumbHeight: Int!, $coverWidth: Int, $coverHeight: Int, $orderAsc: Boolean) {
  episodes(
      premium: $premium,
      original: $original,
      limit: $limit,
      order_asc: $orderAsc,
    ){
    data{
      id
      title
      rating
      duration
      description
      play_count
      publishDate
      content
      thumbnail(width: $thumbWidth, height: $thumbHeight)
      channel{
        id
        codename
        flag
        premium
        original
        thumbnail(width: $thumbWidth, height: $thumbHeight)
        following
      }
    }
  }
  top: slides(code: $top, limit: 5){
      data{
          id
          action
          arguments
          cover(width: $coverWidth, height: $coverHeight)
      }
      total
  }
  bottom: slides(code: $bottom){
      data{
          id
          action
          arguments
          cover(width: $coverWidth, height: $coverHeight)
      }
      total
  }
  channels(
    premium: $premium,
    original: $original, 
    limit: $limitChannel,
    page: $page_channels,
  ){
    data{
      id
      name
      codename
      flag
      premium
      original
      thumbnail(width: $thumbWidth, height: $thumbHeight)
      cover(width: $coverWidth, height: $coverHeight)
      following
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}`;

export { TelaPremiumQuery };
