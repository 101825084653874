import { call, put } from "redux-saga/effects";
import { USER_ACCOUNT } from '../../constants';

import UserActions from "../ducks/user";

export type UserType = {
  email:String,
  access_token: String,
  profile: {
    name?: String,
    birth?: String,
    city_id?: Integer,
    country_id?: Integer,
    profilePicture?: String,
    state_id?: String
  }
}


export function* getMe(params) {
  try {
    if(localStorage.length){
        let user = localStorage.getItem(USER_ACCOUNT);
        document.userAccount = JSON.parse(user);
        if(user) yield put(UserActions.getMeSuccess(JSON.parse(user)));
    }
  } catch (error) {
    // console.log(error);
  }
}

export function* setMe(params){
  try{
    // console.log('setMe ==> ', params);
    debugger;
    if(localStorage.length && params){
      let user = localStorage.getItem(USER_ACCOUNT);
      if(user){
        let userJSON = JSON.parse(user);
        userJSON = { ...userJSON, ...params}
        localStorage.setItem(USER_ACCOUNT, JSON.stringify(userJSON))
        yield put(UserActions.getMeSuccess(JSON.parse(userJSON)));
      }
    }else if(params){
      let userJSON = params;
      localStorage.setItem(USER_ACCOUNT, JSON.stringify(userJSON))
      yield put(UserActions.getMeSuccess(JSON.parse(userJSON)));
    }
  }catch(e){
    // console.log('error setMe ', e)
  }
}
