import React, { Component } from "react";

//styles
import { Container } from "./Editorial.styles";

import {
  EditorialBox,
  EditorialBanner
} from "./Editorial.styles";

import Layout from "../../../hoc/Layout/Layout"

import { Query } from 'react-apollo';
import EditorialQuery from './editorial.graphql';

//element
import { Title, Text } from "../../../elements/Typography";
import { ContentBanner } from "../../../elements/Banner/ContentBanner/ContentBanner";

export default class Editorial extends Component {

  renderEditorial(data){
    const {
      cover,
      olho,
      titulo,
      subtitulo,
      autor,
      texto
      
    } = data;
    return (
      <>
        <div className="container">  
          <EditorialBanner className="col s12 m12">
              <ContentBanner bannerImage={cover}/>
          </EditorialBanner>
          <Text content={olho} typeClass="editorial-category"/>
            <Title size="2" typeClass="editorial-title" content={titulo} />
              <Text content="03 de agosto, 2019 às 12:00" typeClass="editorial-data"/>
            <Title content={subtitulo} typeClass="editorial-data"/>
          <div className="content-editorial">
            <div className="materia">
                <Text
                  typeClass="editorial-description"
                  content={texto}
                />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Layout>
        <Container>
          
          <EditorialBox className="editorial-container">

            <Query query={EditorialQuery} variables={{id: this.props.match.params.id, coverWidth: 1200, coverHeight: 300 }}>
              {({ loading, error, data }) => {
                
                  if (loading || error) {
                    return("Loading ..." );
                  }
                  return this.renderEditorial(data.editorial);
                }
              }
            </Query>      
           
        </EditorialBox>
        
      </Container>
    </Layout>
    );
  }
}
