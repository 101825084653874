import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;

    .sound-wave {
        width: 100%;
    }
    .contentImage {
        flex: 1;
        overflow: hidden;
        margin-right: var(--size-base);
        border-radius: var(--radius-small);
        .image-position {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            span {
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }
        }
    }
    .contentEpisode {
        display: flex;
        flex: 11;
        align-items: center;
        .title-episode {
            flex: 5;
            flex-direction: column;
            font-weight: 700;
            span{
                display: flex;
                flex: 1;
                color: #666666;
                font-weight: normal;
                font-size: 11px;
                align-items: center;
                img {
                    width: 15px;
                    margin-left: 5px;
                }
            }
        }
        .infor-episode {
            color: #66757F;
            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid rgba(102, 117, 127, 0.3);
        }
    }
    .contentActions {
            display: flex;
            flex: 7;
            justify-content: flex-end;
        .action-admin {
            color: #2196F3;
            border: 1px solid #2196F3;
            border-radius: var(--radius-small);
            padding: 7px 24px 7px 9px;
            display: flex;
            .icon {
                height: 22px;
                margin-right: 18px;
            }
        }
        .action-midia-alo {
            color: #2196F3;
            border: 1px solid #2196F3;
            display: flex;
            background: #fff;
            border-radius: var(--radius-small);
            padding: 8px 24px 7px 8px;
            margin-left: 10px;
            .icon {
                height: 22px;
                margin-right: 18px;
            }
        }
        .action-gravando-alo {
            color: #EB5757;
            border: 1px solid #EB5757;
            background: #fff;
            border-radius: var(--radius-small);
            padding: 7px 44% 7px 9px;
            margin-left: 10px;
            display: flex;
            position: relative;
            .icon {
                height: 22px;
                margin-right: 18px;
            }
            span {
                display: block;
                width: 28px;
                height: 28px;
                line-height: 28px;
                border-radius: 50%;
                top: 4px;
                right: 12px;
                text-align: center;
                background: #EB5757;
                color: #fff;
                position: absolute;
            }
        }
        .action-gravando-alo.play {
            color: var(--color-seventh-darken);
            border: 1px solid var(--color-seventh-darken);
            span {
                background: var(--color-seventh-darken);
            }
        }
        .action-alo {
            color: #fff;
            background: #2196F3;
            border-radius: var(--radius-small);
            padding: 8px 24px 7px 24px;
            margin-left: 10px;
            display: flex;
        }
        .action-more {
            width: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(102, 117, 127, 0.3);
            .icon {
                height: 16px;
            }
        }
    }
    @media (max-width: 992px) {
        .contentImage,
        .infor-episode {
            display: none;
        }
        .contentEpisode {
            flex: 2
        }
        .contentActions {
            flex: 1;
        }
        .action-admin {
            margin-bottom: 2px;
        }
        .action-admin,
        .action-alo {
            flex: 1;
            font-size: 10px;
            padding: 7px 9px !important;
            white-space: nowrap;
            justify-content: center;
        }
        .action-alo {
            margin-left: 0!important;
        }
        .action-admin .icon,
        .action-more {
            display: none !important;
        }
        .contentActions {
            flex-wrap: wrap;
        }
    }
`;
