import React, { Component } from "react";

import {
    Container
  } from "./TagView.styles";

import debounce from "lodash.debounce";

import { Query } from 'react-apollo';
import TagViewQuery from './tagview.graphql';

//component
import Layout from "../../../hoc/Layout/Layout";
import EpisodeCard from "../../../components/EpisodeCardComponent";
import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

export default class TagView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            hasMore: true,
            episodes: [],
            limit: 4,
            page: 1,
            loading: true,
        };   
    }

    handleScroll = debounce(() => {
        const {
            state: {
                loading,
                hasMore,
                page
            },
        } = this;
        

        if (
            window.innerHeight + document.documentElement.scrollTop
            === document.documentElement.offsetHeight && hasMore && !loading
        ) {
            this.setState({
                page: page + 1,
                loading: true
            });     
            // console.log("SetPagina: " + page);   
        }else{
            // console.log("NAO CHEGOU ATE OP FINAL ");
        }
    }, 100);

    componentDidMount() {       
         window.addEventListener('scroll', this.handleScroll, true);
    }
    componentWillUnmount() {
         window.removeEventListener('scroll', this.handleScroll);
    }

    renderEps(episodios){
        if (this.state.loading) {
            this.setState({
                loading: false,
                hasMore: episodios.has_more_pages,
                page: episodios.current_page,
                episodes: this.state.episodes.concat(episodios.data),
            });
        }
        
        return (
          <>
              {this.state.episodes.map(episode => {
                    return <EpisodeCard 
                        key={episode.id} 
                        horizontal 
                        showDetails 
                        episode={episode} 
                    />;
                })}
          </>
        );
    }

    render() {
        const {
            page,
            limit,
        } = this.state;
        
        return (
        <Layout>
            <Container>
            <div className="tagview">
                <div className="tag">Tag <span>#{this.props.match.params.code}</span></div>
                <div className="eps">

                <Query query={TagViewQuery} variables={{tag: this.props.match.params.code, page: page, limit: limit,  thumbWidth: 200, thumbHeight: 200 }}>
                    {({ loading, error, data }) => {
                        if (loading || error) {
                            return("Loading ..." );
                        }
                            return this.renderEps(data.episodes);
                        }
                    }
                </Query>
                </div>
            </div>
            </Container>
        </Layout>
        );
    }
}
