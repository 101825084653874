import React from 'react';
import { Button } from '../elements/CTA';


const ButtonDefault = (props) => {
    const { type, className, Background, Color, onClick, title, loading, children } = props;
    if(!loading){
        return(
            <Button
                Type={type}
                className={`button-primary ${className || ''}`}
                Background={Background}
                Color={Color}
                onClick={onClick}
            >
              {title ? title : children}  
            </Button>
        )
    } else {
        return(
            <div className={`${className} button-primary disabled`} >
                {title ? title : children}
            </div>
        )
    }
}

export { ButtonDefault as Button }
