import styled from "styled-components";

export const EpisodeCardContainer = styled.article`
  width: 210px;
  height: auto;
  margin-right: var(--spacing-default);

  .episode-card_image {
    width: 100%;
    height: 200px;
    /* border-radius: 3px; */
    position: relative;
    .card-image_icon {
      color: var(--color-zero);
    }
  }

  .episode-card-body {
  }

  @keyframes example {
    from {opacity: 0;}
    to {opacity: 0.6}
  }
  .episode-card_image .play-icon {
    position: absolute;
    width: 100%;
    padding: 25%;
    opacity: 0.6;
    animation-name: example;
    animation-duration: 1s;
    margin-bottom: var(--spacing-small);
    margin-right: var(--spacing-small);
    display: none;
  }
  .episode-card_image:hover .play-icon {
    display: block;
  }

  .episode-title {
    width: 200px;
    height: 36px;s
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  .episode-info_wrapper {
    display: flex;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #666666;
    .play-count {
      display: flex;
      align-items: center;
      font-size: 10px;
      line-height: 16px;
      .play-icon {
        height: 15px;
      }
    }
    .time-ago {
      margin-left: auto;
    }
  }
`;
