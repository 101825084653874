import React, { Component } from "react";

//styles
import {
  Container,
} from "./VerticalList.style";

//graphql
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { CategoryVerticalQuery }  from './VerticalList.graphql';
import VerticalListCarrocel from "./VerticalListCarrocel";

class VerticalList extends Component {
  render(){
    const {
      mainCategories,
      loading
    } = this.props.data;
    if (loading && !mainCategories) return <div className="progress">
      <div className="indeterminate"></div>
    </div>;
    return(
      <Container className="cotegorys-vertical">
        {
          mainCategories &&
          mainCategories.map(
            (category,catIndex) => {
              if(!!category)
              return (
                <VerticalListCarrocel key={`category-vertical-id-${category.id}-${catIndex}`} category={category} catIndex={catIndex} />
              )
            }
          )
        }
      </Container>
    )
  }
};

export default compose(graphql(CategoryVerticalQuery))(VerticalList);
