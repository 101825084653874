import styled from "styled-components";

export const Wrapper = styled.article`
  width: ${props => props.size};
  height: ${props => props.size};
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
`;

export const Content = styled.div.attrs({
  className: props => props.classContent
})`
  display: flex;
  flex: 1;
`;
