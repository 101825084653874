import styled from "styled-components";
import { Search } from "styled-icons/material/Search";
import { Close } from "styled-icons/material/Close";

export const SearchIcon = styled(Search)`
  color: var(--color-third-darken);
  height: var(--size-medium);
  position: absolute;
  right: var(--size-small);
`;

export const CloseIcon = styled(Close)`
  color: var(--color-third-darken);
  height: 1.5em;
  position: absolute;
  right: 0.2em;
`;

export const HeaderContainer = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
  background: #FFF;
  z-index: 100;
  .headerLinksChannel {
    font-size: 13px;
    font-weight: 900;
    line-height: 18px;
    a {
      display: flex;
      margin-right: 10px;
      .menu-icon {
        margin-right: 10px;
      }
    }
  }
  .logo-component {
    justify-content: flex-start;
    a {
      margin: 19px 20px 16px 20px;
      display: flex;
    }
  }
`;
export const WrapperLogo = styled.article`
  height: 100%;
  display: inline-flex;
  width: 250px;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    height: auto;
    width: 100%;
  }
`;
export const WrapperSearch = styled.article`
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  position: relative;
  margin-right: 28px;

  input {
    border: none;
    background: #eee;
    border-radius: var(--radius-medium);
  }
  input::-webkit-input-placeholder { color: #999; }
  input:-moz-placeholder {  color: #999; }
  input::-moz-placeholder {  color: #999; }
  input:-ms-input-placeholder {  color: #999; }

  @media (max-width: 800px) {
    .search-icon {
      right: 2.5em;
    }
    #search {
      z-index: 1;
    }
    .searchMobile {
      .search-icon {
        z-index: 1;
      }
    }
    .form-search{
      button{
        right: 8px;
        top: 0px;
        width: 42px;
        height: 42px;
        z-index: 2;
      }
      input {
        height: 40px;
        max-width: 335px;
        border-radius: 27.17px;
        background-color: #F2F2F2;
      }
    }
  }
  ::placeholder {
    color: var(--color-third);
    font-style: 16px;
  }
`;

export const WrapperProfile = styled.article`
  display: flex;
  margin-right: 28px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 800px) {
    & {
      display: none;
    }
  }

  .contentUserSession {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-primary {
      height: 3rem;
    }
  }
  .btnPremiunAcess {
    min-height: 2.9379rem; 
    border-radius: 23px;
    background-color: var(--color-first);
    text-transform: none;
    white-space: nowrap;
    padding: 0 2.5em
  }
  
  .userImage {
    width: 100%;
    height: 100%;
  }

  .header-user-image {
    height: 0;
    padding: 0 0 100% 0;
  }


  .btnPremiunAcess a {
    color: #fff;
  }
  .contentUserName {
    white-space: nowrap;
  }
  .defaultUser {
    background-color: var(--color-third-darken);
    color: var(--color-zero);
    padding: 8px 5px 0px 5px;
    width: 100%;
  }
`;

export const WrapperProfileMobile = styled.article`
  display: none;
  margin-right: 28px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 800px) {
    & {
      margin-right: 0;
      display: flex;
      
    }
    .btnLogin {
      margin-left: -35px !important;
      color: var(--color-third-darken);
    }
  }

  .contentUserSession {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-primary {
      height: 3rem;
    }
  }
`;
