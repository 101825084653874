import React from 'react'

import Layout from '../../../hoc/Layout/Layout'

import { Category } from "../../../components/Category"

//graphql
import { Query } from 'react-apollo';
import CategoryQuery from './category.graphql';

import OlaFrontWeb from "../../../assets/img/OlaFrontWeb.jpg";

const loadMore = (data) => {
    if(data.has_more_pages){
        //data
    }
}

const updateQueryCategory = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
        return previousResult;
    }
    // // console.log("More: ", fetchMoreResult);
    return {
        ...previousResult,
        topChannels: {
            ...previousResult.topChannels,
            data: [
                ...previousResult.topChannels.data,
                ...fetchMoreResult.topChannels.data,
            ],
            current_page: fetchMoreResult.topChannels.current_page,
            has_more_pages: fetchMoreResult.topChannels.has_more_pages
        },
    };
};

const updateQueryScroll = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
        return previousResult;
    }
    // console.log("More: ", fetchMoreResult);
    return {
        ...previousResult,
        latestEpisodes: {
            ...previousResult.latestEpisodes,
            data: [
                ...previousResult.latestEpisodes.data,
                ...fetchMoreResult.latestEpisodes.data,
            ],
            current_page: fetchMoreResult.latestEpisodes.current_page,
            has_more_pages: fetchMoreResult.latestEpisodes.has_more_pages
        },
    };
};
  
const returnCategoryView = (data, loading, fetchMore) => {
    const {
        topChannels,
        topEpisodes,
        episodes,
        mainCategories,
        latestEpisodes
    } = data;
    return (
        <Layout>
            <Category 
                onButtonMore={() => fetchMore({
                    variables: {
                        pageTopChannels: topChannels.current_page + 1
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return updateQueryCategory(previousResult, { fetchMoreResult })
                    }
                })}
                onScrollMore={() => fetchMore({
                    variables: {
                        pageLatestEpisodes: latestEpisodes.current_page +1
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        return updateQueryScroll(previousResult, { fetchMoreResult })
                    },
                })}
                loading={loading}
                topChannels={topChannels}
                topEpisodes={topEpisodes}
                episodes={episodes}
                mainCategories={mainCategories}
                latestEpisodes={latestEpisodes}
            />
        </Layout>
    )
}
//const nextpage = (page) => page+1;

const categoryView = (props) => {
    // console.log(props.match.params.main_category_id)
    let categoryId = props.match.params.main_category_id;
    let imgW=275, imgH=275, imgCW=750, imgCH=142;
    return (
        <Query query={CategoryQuery} variables={{ 
            id: categoryId,
            w: imgW,
            h: imgH,
            cw: imgCW,
            ch: imgCH,
            pageTopChannels: 1,
            pageLatestEpisodes: 1
        }}>
        {/* pollInterval={30000} fetchPolicy="network-only"> */}
        {
            ({ loading, error, data, fetchMore }) => {
                if (loading && !data) return <div> {`Loading: ${loading}`} </div>;
                if (error && !data) {
                    if (error.networkError) {
                        return <div> {`Error: no network`} </div>;
                    }
                    if (error.graphQLErrors) {
                        return <div> {`Error: ${error}`} </div>;
                    }
                    return <div> {`Error: ${error}`} </div>;
                }
                // console.log(data)
                return returnCategoryView(data, loading, fetchMore);
            }
        }
        </Query>
    )}

export default categoryView
