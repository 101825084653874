import gql from "graphql-tag";

const MyChanelQuery = gql`
query{
    myChannels{
      id
      name
      codename
      main_category {
        id
      }
      premium
      original
      followers_count
      episodes{
        total
      }
      thumbnail(width: 100, height: 100)
      cover(width: 250, height: 250)
      main_category {
        id
        name
      }
    }
  }
`;

export default MyChanelQuery;
