import styled from "styled-components";

export const Container = styled.div`
    form {
        display: flex;
        flex-direction: column;
    }
    .auth-title {
        margin-bottom: 17px;
        font-size: 18px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.8);
    }
    .content-select {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }
    select:first-child {
        margin-right: 11px;
    }
    select {
        display: flex;
        flex:1
        line-height: 16px;
        color: #66757F;
        border: 1px solid var(--color-third);
        border-radius: 3px;
        background: var(--color-third);
        padding: var(--size-very-small);
    }
    input {
        margin-bottom: 19px;
    }
    .inputPhone {
        flex: 1;
        margin-bottom: 5px;
        height: 45px;
    }
    .inputPass {
        box-sizing: inherit !important;
        height: 45px;
        margin-bottom: 19px;
    }
    input::-webkit-input-placeholder { color: #999; }
    input:-moz-placeholder { color: #999; }
    input::-moz-placeholder { color: #999; }
    input:-ms-input-placeholder { color: #999; }
    .auth-button {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 35px;
        padding: 0 15px;
        text-transform: uppercase;
    }
`;
