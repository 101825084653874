import styled from "styled-components";

export const Container = styled.section`
  padding: var(--spacing-half-big);
  width: 86.67%;
  .titlePlaylist {
    background-color: rgba(0,0,0,0.7);
    padding: var(--radius-medium);
    margin-top: 10px;
    color: white;
  }
  @media (max-width: 800px) {
    & {
      width: 100%;
      padding: 0;
    }
  }
`;

export const HeaderWrapper = styled.section`
  .playlist-title {
    font-family: "Avenir-Book", sans-serif;
    font-size: 30px;
    color: var(--color-negative);
    letter-spacing: 0;
    line-height: 54px;
  }
  @media (max-width: 800px) {
    & {
      .playlist-title {
        line-height: 32px;
        margin: 20px 10px 10px 10px;
      }
    }
  }
`;
export const WrapperList = styled.section`
  display: flex;
  flex-wrap: wrap;
  .playlist-card, .thumb-content {
    margin: 5px 10px;
  }
  .row .col {
    margin-left: 0;
  }
  .conteiner-list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    width: 65%;
  }
  .thumb-content {
    width: 15%
  }
  .addMore {
    color: "#fff";
    background-color: #2196F3;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-small);
    display: flex;
    flex: 1;
  }
  @media (max-width: 800px) {
    & {
      .playlist-card, .thumb-content {
        margin: 0 0 10px 0;
        width: 15%
      }
    }
  }
`;
