import React, { Component } from "react";

// import { Container } from './styles';

import { ALOS } from "../../constants/alos";

import AloComponent from "./AloComponent";

export default class Alos extends Component {
  render() {
    return (
      <section className="alos">
         {this.props.homeFeaturedAlos.map((channel) => 
            <>                                
                <AloComponent key={`aloCOmp-${channel.id}`} id={channel.id} channel={channel}  /> 
            </>
        )}
      </section>
    );
  }
}
